import {
	INTERVALS,
  is_internal,
	isMarketOpen,
} from '../../../logic/u'

/*
In order to plot our own charts, we create fake, empty candles
*/
export const get_empty_bars = (params: any) => {
  let { timeframe, limit, start, end } = params

  const intervalMs = INTERVALS[timeframe]
  if (!intervalMs) {
    throw new Error(`Invalid timeframe: ${timeframe}`)
  }

  const bars: any[] = []
  const endTime = new Date(end).getTime()
  let currentTime

  if (start) {
    currentTime = new Date(start).getTime()

    // Adjust start time to reasonable snap-to
    const snapTo = get_recent_even_multiple(currentTime, timeframe)
    currentTime = snapTo.getTime()

    while (currentTime <= endTime && bars.length < limit) {
      if (intervalMs < 86400000) { // Check market open only if interval is less than a day
        if (isMarketOpen(currentTime)) {
          bars.push({ t: currentTime })
        }
      } else {
        bars.push({ t: currentTime }) // Assume open for intervals of a day or more
      }
      currentTime += intervalMs
    }
  } else {
    currentTime = endTime

    while (bars.length < limit) {
      if (intervalMs < 86400000) { // Check market open only if interval is less than a day
        if (isMarketOpen(currentTime)) {
          bars.push({ t: currentTime })
        }
      } else {
        bars.push({ t: currentTime }) // Assume open for intervals of a day or more
      }
      currentTime -= intervalMs
    }
  }

  // Convert timestamp to ISO string format outside of the loop if necessary
  bars.forEach(bar => bar.t = new Date(bar.t).toISOString())

  return bars
}


export const get_recent_even_multiple = (timestamp: number, interval: string) => {
  let date = new Date(timestamp)

  // Determine the interval type and value
  const intervalType = interval.slice(-1) // 'm' for minutes, 'h' for hours, 'd' for days, 'w' for weeks
  const intervalValue = parseInt(interval.slice(0, -1), 10) // numeric value of interval

  if (intervalType === 'm') {
    // For minute intervals
    const minutes = date.getMinutes()
    const nearestMultiple = minutes - (minutes % intervalValue)
    date.setMinutes(nearestMultiple)
  } else if (intervalType === 'h') {
    // For hour intervals
    const hours = date.getHours()
    const nearestMultiple = hours - (hours % intervalValue)
    date.setHours(nearestMultiple)
    date.setMinutes(0) // Reset minutes for hour intervals
  } else if (intervalType === 'd') {
    // For day intervals
    date.setHours(0, 0, 0, 0) // Reset to the start of the day
  } else if (intervalType === 'w') {
    // For week intervals
    const dayOfWeek = date.getDay()
    date.setDate(date.getDate() - dayOfWeek)
    date.setHours(0, 0, 0, 0) // Reset to the start of the week
  }

  // Reset seconds and milliseconds to zero
  date.setSeconds(0)
  date.setMilliseconds(0)

  return date
}


// // How far to step for a given fixed timeframe
// export const STEP_BACK_INTERALS = {
// 	'1D': 1000 * 60 * 60 * 24,
// 	'1W': 1000 * 60 * 60 * 24 * 7,
// 	'1M': 1000 * 60 * 60 * 24 * 30,
// 	'3M': 1000 * 60 * 60 * 24 * 30 * 3,
// 	'6M': 1000 * 60 * 60 * 24 * 30 * 6,
// 	'1Y': 1000 * 60 * 60 * 24 * 365,
// 	'5Y': 1000 * 60 * 60 * 24 * 365 * 5,
// 	'All': 1000 * 60 * 60 * 24 * 365 * 100,
// }

// // TB, not alpaca
// export const TIMEFRAMES_MS: any = {
//   '1m': 1 * 60 * 1000,
//   '5m': 5 * 60 * 1000,
//   '30m': 30 * 60 * 1000,
//   '1h': 60 * 60 * 1000,
//   '2h': 2 * 60 * 60 * 1000,
//   '4h': 4 * 60 * 60 * 1000,
//   '1d': 24 * 60 * 60 * 1000,
//   '1w': 7 * 24 * 60 * 60 * 1000,
//   '1M': 30 * 24 * 60 * 60 * 1000
// }