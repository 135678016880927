import './DividerPane.scss'

import React, { useRef, useEffect, useState } from 'react';
import { TBChart } from '../TBChart/TBChart'
import {
  loggedInUserAtom,
  openModalAtom,
  selectedEmptyChartAtom,
} from '../../types/global_types'
import { useAtom } from 'jotai'

export const ChartDividerPane = ()=> {

  const [user] = useAtom(loggedInUserAtom)
  const [selectedChart, setSelectedChart] = useAtom(selectedEmptyChartAtom)
  const [, setOpenModal] = useAtom(openModalAtom)

  if (!user) return null
  const configuration = user.chartSettingsConfig || '1'

  const get_placeholder = (chartNo: number) => {
    const sel_msg = 'Choose chart...'
    const empty_msg = 'Click here'
    return (
      <div
        className={`empty-slot ${selectedChart === chartNo ? 'selected' : ''}`}
        onClick={() => {
          if (selectedChart === chartNo) {
            setSelectedChart(0)
          } else {
            setSelectedChart(chartNo)
          }
        }}
      >
        <div className='empty-slot-header'>
        </div>
        <div className='empty-slot-box'>
          <div>{selectedChart === chartNo ? sel_msg : empty_msg}</div>
        </div>
      </div>
    )
  }

  const get_chart = (chartCode: string, chartNo: number) => {
    if (!chartCode) return get_placeholder(chartNo)

    // let width = measuredWidth
    let width = 'calc(100% - 2px)'
    let height = measuredHeight + 'px'
    if (['4'].includes(configuration)) {
      width = '50%'
    }
    if (['2-one-top-one-bottom', '4'].includes(configuration)) {
      height = `${(measuredHeight / 2) - 5}px`
    }


    // TODO: handle things that are not symbols
    return (
      <>
        <TBChart
          symbol={chartCode}
          chartIndex={chartNo}
          width={width}
          height={height}
        />
      </>
    )
  }




  // Figure out how much space we have to work with
  const dividerPaneRef = useRef<HTMLDivElement>(null)
  const [measuredHeight, setMeasuredHeight] = useState(0)
  const [measuredWidth, setMeasuredWidth] = useState(0)
  useEffect(() => {
    const measureSize = () => {
        if (dividerPaneRef.current) {
            const { width, height } = dividerPaneRef.current.getBoundingClientRect()
            setMeasuredHeight(height)
            setMeasuredWidth(width)
        }
    }
    measureSize()

    const resizeObserver = new ResizeObserver(measureSize)
    if (dividerPaneRef.current) {
        resizeObserver.observe(dividerPaneRef.current)
    }

    window.addEventListener("resize", measureSize)

    return () => {
        if (dividerPaneRef.current) {
            resizeObserver.unobserve(dividerPaneRef.current)
        }
        window.removeEventListener("resize", measureSize)
    }
}, [dividerPaneRef.current])


  if (configuration === '1') {
    return (
      <div className='divider-pane' ref={dividerPaneRef}>
        {get_chart(user.chartSettingsCH1, 1)}
      </div>
    )
  } else if (configuration === '2-one-top-one-bottom') {
    return (
    <div className='divider-pane' ref={dividerPaneRef}>
      {get_chart(user.chartSettingsCH1, 1)}
      {get_chart(user.chartSettingsCH2, 2)}
    </div>
    )
  } else if (configuration === '4') {
    return (
    <div className='divider-pane' ref={dividerPaneRef}>
      <div className='row'>
        {get_chart(user.chartSettingsCH1, 1)}
        {get_chart(user.chartSettingsCH2, 2)}
      </div>
      <div className='row'>
        {get_chart(user.chartSettingsCH3, 3)}
        {get_chart(user.chartSettingsCH4, 4)}
      </div>
    </div>
    )
  } else {
    return null
  }


}

