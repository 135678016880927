/*
Replacement, two-column add indicator modal

TODO
- fix this to work with new storage
*/

import { useAtom } from 'jotai'
// import firebase from 'firebase/app'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Checkbox } from '../reusable/Checkbox'
import { DropdownSelect } from '../reusable/DropdownSelect'
import { Modal, showModal } from '../reusable'
import {
  loggedInUserAtom,
} from '../../types/global_types'
import { getConfig } from '../../config'
import { db } from '../../firebase'
import { update_abbrev_string } from '../../logic/indicator_helpers'
// import { make_abbrev_string } from '../../logic/indicator_helpers'
const config = getConfig() as any

export interface IndicatorModalProps {
  symbol: string
  intervalId: string
  onClose: any
  modalTitle: string
}
export const EditIndicatorModal = (props: IndicatorModalProps) => {
  const [user] = useAtom(loggedInUserAtom)
  if (!user) return null

  const indicator = user.charts[props.symbol].indicators[props.intervalId]

  const no_input_tab = Object.keys(indicator.fields).length === 0
  const starting_tab = !no_input_tab ? 'input' : 'plot'
  const [subtab, setSubtab] = useState<'input' | 'plot'>(starting_tab)

  /* INPUTS SUBTAB */
  const fields: any = []
  Object.keys(indicator.fields).forEach((key: string) => {
    fields.push(indicator.fields[key])
  })
  fields.sort((a: any, b: any) => a.index - b.index)
  const field_inputs: any = []

  const field_keys = Object.keys(indicator.fields)
  for (let i = 0; i < field_keys.length; i++) {
    const field_key = field_keys[i]
    const field = indicator.fields[field_key]

    // So far, "number" is the only type
    if (field.type === 'number') {
      field_inputs.push(
        <Input
          key={field.id}
          label={field.label}
          type={field.type}
          initialValue={field.value}
          min={field.min}
          max={field.max}
          onBlur={async (value: any) => {
            const userDoc = db.collection('users').doc(user.uid)
            userDoc.update({
              [`charts.${props.symbol}.indicators.${props.intervalId}.fields.${field_key}.value`]: Number(value)
            })
            const ind_copy = JSON.parse(JSON.stringify(indicator))
            ind_copy.fields[field_key].value = Number(value)
            await update_abbrev_string(ind_copy, user.uid, props.symbol)
          }}
          style={{marginTop: 15}}
        />
      )
    }
  }


  /* Style SUBTAB */
  const plots: any = []
  Object.keys(indicator.plots).forEach((plot_key: string) => {
    plots.push(indicator.plots[plot_key])
  })
  plots.sort((a: any, b: any) => a.index - b.index)
  const plot_inputs: any = []


  const plot_keys = Object.keys(indicator.plots)
  plot_keys.sort((a, b) => indicator.plots[a].index - indicator.plots[b].index)

  const is_single_plot = plot_keys.length === 1
  for (let i = 0; i < plot_keys.length; i++) {
    const plot_key = plot_keys[i]
    const plot = indicator.plots[plot_key]

    plot_inputs.push(
      <div className='column'>
        <div
          className={'offwhite-text'}
          style={{
            textTransform: 'capitalize',
            marginBottom: 15,
            marginTop: 15,
            fontWeight: 'bold',
            fontSize: 16
          }}
        >
          {plot_key}
        </div>

        {/* Visible checkbox,  */}
        {!is_single_plot ? <div className='row margin-bottom-15'>
          <Checkbox
            className='margin-right-10'
            value={plot.visible}
            onChange={(val) => {
              const userDoc = db.collection('users').doc(user.uid)
              userDoc.update({
                [`charts.${props.symbol}.indicators.${props.intervalId}.plots.${plot_key}.visible`]: val
              })
            }}
          />
          <div className='white-text font-size-13 bold'>
            Visible
          </div>
        </div> : null}

        <div style={{display: 'flex', marginBottom: 15}}>
          {/* Line weight */}
          {plot.visible || is_single_plot ? <DropdownSelect
            label={'Line weight'}
            options={[
              {display: '1', value: 1},
              {display: '2', value: 2},
              {display: '3', value: 3},
            ]}
            width={167}
            value={plot.weight}
            onChange={(val) => {
              const userDoc = db.collection('users').doc(user.uid)
              userDoc.update({
                [`charts.${props.symbol}.indicators.${props.intervalId}.plots.${plot_key}.weight`]: val
              })
            }}
          /> : null}

          {/* Color */}
          {plot.visible || is_single_plot ? <DropdownSelect
            label={'Color'}
            options={get_color_options()}
            width={167}
            style={{marginLeft: 10}}
            value={plot.color}
            onChange={(val) => {
              const userDoc = db.collection('users').doc(user.uid)
              userDoc.update({
                [`charts.${props.symbol}.indicators.${props.intervalId}.plots.${plot_key}.color`]: val
              })
            }}
          /> : null}


        </div>


      </div>
    )
  }


  return (
    <Modal
      title={props.modalTitle}
      contents={[
        <div className='indicators-modal-container' style={{minHeight: 400, maxHeight: 400}}>
          <div className='indicators-modal-subheader no-margin'>
              {!no_input_tab ? <div
                className={'indicators-modal-subtab' + (subtab === 'input' ? ' selected' : '')}
                onClick={() => {
                  setSubtab('input')
                }}
                >
                Inputs
              </div> : null}
              <div
                className={'indicators-modal-subtab' + (subtab === 'plot' ? ' selected' : '')}
                onClick={() => {
                  setSubtab('plot')
                }}
                >
                Style
              </div>
            </div>
          <div className='column'>
            {subtab === 'input' ? field_inputs : null}
            {subtab === 'plot' ? plot_inputs : null}
          </div>
        </div>
      ]}
      onClose={props.onClose}
      yesButtonText='Done'
      onYes={async () => {
        // const indicator_copy = JSON.parse(JSON.stringify(indicator))
        // indicator.abbrev_str = make_abbrev_string(indicator)

        // debugger
        console.log('todo')
      }}
    />
  )
}



function get_color_options() {
  const make_color = (hex_code: string, color_name: string) => {
    return {
      display: (
        <div style={{display: 'flex'}}>
          <div style={{
            height: 15,
            width: 15,
            borderRadius: '50%',
            backgroundColor: hex_code,
            marginTop: 1,
            marginRight: 10
          }}/>
          <div>{color_name}</div>
        </div>
      ),
      value: hex_code
    }
  }
  const ret: any = []
  ret.push(make_color('#FFD702', 'yellow'))
  ret.push(make_color('#E66533', 'orange'))
  ret.push(make_color('#D5971A', 'mustard'))
  ret.push(make_color('#49E9A6', 'green'))
  ret.push(make_color('#DA70D6', 'pale purple'))
  ret.push(make_color('#D67E5C', 'dull orange'))
  ret.push(make_color('#DF769B', 'pink'))
  ret.push(make_color('#17A3B6', 'blue'))
  ret.push(make_color('#7060EB', 'purple'))

  return ret
}