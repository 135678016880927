import React, { useState, useImperativeHandle, forwardRef } from "react";
import { useAtom } from "jotai";
import {
  ohlcAtom,
  ordersAtom,
  toolModeAtom,
  openModalAtom,
  isDraggingAtom,
  userLiveDocAtom,
  loggedInUserAtom,
  streamingQuotesAtom,
  selectedAnnotationAtom,
  annotationInProgressAtom,
} from '../../types/global_types';
import { Icon2 } from '../reusable/Icon2';
import { IndicatorModal } from '../modals/IndicatorModal';
import { EditIndicatorModal } from '../modals/EditIndicatorModal';
import { PositionIndicator } from './PositionIndicator';
import { OpenOrderIndicator } from './OpenOrderIndicator';
import { get_contextmenu_off } from "../../logic/u";
import { ORANGE, ORANGE_FAINT, CANDLE_RED, CANDLE_GREEN, TAG_GREEN, TAG_RED, } from "../../logic/colors";

import {
  isMarketOpen,
  period_string,
  roundToSigFigs,
  formatStockPrice,
  human_price_to_number,
  format_price_appropriately,
  is_internal,
} from "../../logic/u";
import { delete_indicator, get_indicators_for_chart, toggle_indicator_visibility } from "../../logic/indicator_helpers";
// import { TIMEFRAMES_MS } from "./chart_utils";
import { Trendline, write_trendline_to_db } from "./annotations/Trendline";
import { db } from '../../firebase';
import { DEFAULT_INDICATOR_COLORS } from '../../logic/u';
import { isInteger } from "lodash";
import { TickerDropdown } from "../dropdowns/TickerDropdown";
import { OrderFullfillmentIndicator } from "./OrderFullfillmentIndicator";
import { make_create_order_string } from "../../logic/make_create_order_string";
import { TickerModal } from "../modals/TickerModal";

const USE_SNAP_TO = true;
const SHOW_OHLC_ALWAYS = true;

interface ChartOverlayProps {
  symbol: string;
  get_price_for_y: (y: number) => any;
  snap_tos: number[];
  data: any[];
  get_candle_index_for_timestamp: (timestamp: number) => number;
  get_x_for_candle_index: (index: number) => number;
  get_y_for_price: (price: number) => number;
  width: number;
  height: number;
  stepBack: number;
  setStepBack: (stepBack: number) => void;
  chartMode: 'candles' | 'line';
  interval: string;
  futureCandlesCount: number;
}

export const ChartOverlay = forwardRef((props: ChartOverlayProps, ref) => {
  const [cursorPos, setCursorPos] = useState<any>({ left: -1, top: -1 });

  const lastCandle = props.data[props.data.length - 1];
  const [ohlc, setOhlc] = useState<any>({
    ...lastCandle,
    overall_o: props.data[0].o || props.data[0].c,
  });
  const [crosshair_date, setCrosshairDate] = useState<any>({ human: '', iso: '' });

  let crosshair_price = props.get_price_for_y(cursorPos.top);
  if (props.chartMode === 'line') {
    crosshair_price = crosshair_date.price;
  }

  const [toolMode, setToolMode] = useAtom(toolModeAtom);
  const [selectedAnnotation, setSelectedAnnotation] = useAtom(selectedAnnotationAtom);
  const [user] = useAtom(loggedInUserAtom);
  const [uld] = useAtom(userLiveDocAtom);
  const [isDragging, setIsDragging] = useAtom(isDraggingAtom);
  const [annotationTool, setAnnotationTool] = useState<string | null>(null);
  const [orders] = useAtom(ordersAtom);
  const [, setOpenModal] = useAtom(openModalAtom);
  const [annotationInProgress, setAnnotationInProgress] = useAtom(annotationInProgressAtom);
  const [ohlcG, setOhlcG] = useAtom(ohlcAtom);
  const [streamingQuotes] = useAtom(streamingQuotesAtom)
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)


  if (!user) return null;
  if (!uld) return null;

  useImperativeHandle(ref, () => ({
    updateCrosshair: () => {
      const rect = document.querySelector('.crosshairs')?.getBoundingClientRect();
      let x = cursorPos.left;
      let y = cursorPos.top;

      let hoveredCandleIndex = -1;
      if (USE_SNAP_TO) {
        hoveredCandleIndex = props.snap_tos.reduce((prevIndex, currValue, currIndex, array) => {
          const prevValue = array[prevIndex];
          return Math.abs(currValue - x) < Math.abs(prevValue - x) ? currIndex : prevIndex;
        }, 0);

        const hoveredCandle = props.data[hoveredCandleIndex];
        const no_time = props.interval === '1d' || props.interval === '1w';
        const formatted_datetime = format_date_for_crosshairs(hoveredCandle.t, no_time);
        setCrosshairDate({ human: formatted_datetime, iso: hoveredCandle.t, price: hoveredCandle.c });
        setOhlc({
          ...hoveredCandle,
          overall_o: props.data[0].o || props.data[0].c,
        });
        setOhlcG({
          ...hoveredCandle,
          hoveredCandleIndex,
          overall_o: props.data[0].o || props.data[0].c,
        });
        setCursorPos({
          left: props.snap_tos[hoveredCandleIndex],
          top: y,
        });
      }
    },
  }));

  const getYOnLineForX = (xCoord) => {
    for (let i = 0; i < props.data.length - 1; i++) {
      const startX = props.get_x_for_candle_index(props.data[i].relative_index);
      const endX = props.get_x_for_candle_index(props.data[i + 1].relative_index);

      if (xCoord >= startX && xCoord <= endX) {
        const startY = props.get_y_for_price(props.data[i].c);
        const endY = props.get_y_for_price(props.data[i + 1].c);
        const ratio = (xCoord - startX) / (endX - startX);
        return startY + ratio * (endY - startY);
      }
    }
    return null;
  };

  const chartData = user.charts[props.symbol];
  let annotationData: any = [];
  if (chartData && chartData.annotations) {
    annotationData = chartData.annotations;
  }

  const annotationSVGs: any = [];
  for (let i = 0; i < annotationData.length; i++) {
    const annotation_elements = Trendline({
      annotation: annotationData[i],
      get_candle_index_for_timestamp: props.get_candle_index_for_timestamp,
      get_x_for_candle_index: props.get_x_for_candle_index,
      get_y_for_price: props.get_y_for_price,
      selectedAnnotation,
      setSelectedAnnotation,
      user,
      setIsDragging,
      selectedChart: props.symbol,
    });
    annotationSVGs.push(annotation_elements);
  }

  const current_line_components: any = [];
  if (annotationInProgress) {
    const x1_candle_index = props.get_candle_index_for_timestamp(new Date(annotationInProgress.x1).getTime());
    const x1_pixel = props.get_x_for_candle_index(x1_candle_index);
    const y1_pixel = props.get_y_for_price(annotationInProgress.y1);

    const line = (
      <line
        x1={x1_pixel}
        y1={y1_pixel}
        x2={cursorPos.left}
        y2={cursorPos.top}
        stroke={ORANGE}
        strokeDasharray={"5, 5"}
        strokeWidth={2}
        key={'in-progress-trendline'}
      />
    );
    current_line_components.push(line);

    current_line_components.push(
      <circle
        cx={x1_pixel}
        cy={y1_pixel}
        r={5}
        stroke={'blue'}
        strokeWidth={2}
        key={'in-progress-circleA'}
      />
    );
  }

  /* POSITION INDICATORS */
  const position_indicator_divs: any = []
  if (uld.liveData?.POSITIONS && uld.liveData?.POSITIONS[props.symbol]) {
    const pos = uld.liveData.POSITIONS[props.symbol]
    const price = pos.cost_basis / pos.qty
    const y_position_pixel = props.get_y_for_price(price);
    position_indicator_divs.push(
      <PositionIndicator
        position={uld.liveData.POSITIONS[props.symbol]}
        price={price}
        y_position_pixel={y_position_pixel}
        chartHeight={props.height}
        setOpenModal={setOpenModal}
      />
    )
  }


  /* OPEN ORDER INDICATORS */
  const open_order_indicator_divs: any = [];
  let open_orders = orders?.working || [];
  open_orders = open_orders.filter((order: any) => {
    return (order.status !== 'rejected') && (order.symbol === props.symbol);
  });
  for (let i = 0; i < open_orders.length; i++) {
    if (open_orders[i].type === 'market') continue;
    open_order_indicator_divs.push(
      <OpenOrderIndicator
        order={open_orders[i]}
        chartHeight={props.height}
        y_position_pixel={props.get_y_for_price(open_orders[i].limit_price)}
        setOpenModal={setOpenModal}
      />
    );

  }

  /* PAST ORDER INDICATORS */
  const past_order_indicator_divs: any = [];
  const filled_orders_for_this_chart = (orders?.filled || []).filter((order: any) => {
    return order.symbol === props.symbol;
  });

  // filled_orders_for_this_chart.forEach((order: any) => {
  //   const order_string = make_create_order_string(order)
  //   console.log(order_string)
  // })


  // Sort orders into buckets by candle
  const orders_bucketed_by_candle: any = {}
  filled_orders_for_this_chart.forEach((order: any) => {
    const candle_index = props.get_candle_index_for_timestamp(new Date(order.created_at).getTime());
    let candle_date = props.data[candle_index]?.t || order.created_at

    const date_human = format_date_for_crosshairs(new Date(order.filled_at).toISOString())
    let order_string = make_create_order_string(order) + ` - ${date_human}`
    // remove "order: " from start of order_string
    order._string = order_string.slice(7)
    if (candle_index === -1) return;
    if (candle_index === null) return;
    if (!orders_bucketed_by_candle[candle_index]) {
      orders_bucketed_by_candle[candle_index] = {
        candle_date,
        orders: []
      }
    }
    orders_bucketed_by_candle[candle_index].orders.push(order)
  })
  const filled_order_buckets = Object.values(orders_bucketed_by_candle)

  // Make OrderFullfillmentIndicators
  filled_order_buckets.forEach(({orders, candle_date}: any) => {
    // const y_position_pixel = props.get_y_for_price(order.filled_avg_price)
    // const x_position_pixel = props.get_x_for_candle_index(props.get_candle_index_for_timestamp(new Date(order.created_at).getTime()))
    // let display_string = ${order.side.toUpperCase()} ${order.filled_qty} @ ${order.filled_avg_price}
    // let display_string = make_create_order_string(order)

    // TODO: make better display string
    past_order_indicator_divs.push(
      <OrderFullfillmentIndicator
        orders={orders}
        chartHeight={props.height}
        chartWidth={props.width}
        get_x_position_pixel={(order) => {
          const ts = new Date(order.created_at).getTime()
          const candle_index = props.get_candle_index_for_timestamp(ts)
          return props.get_x_for_candle_index(candle_index)
        }}
        get_y_position_pixel={(order) => props.get_y_for_price(order.filled_avg_price)}
        // y_position_pixel={y_position_pixel}
        // x_position_pixel={x_position_pixel}
        setOpenModal={setOpenModal}
      />
    )
  })



  const past_order_indicators: any[] = [];
  const TRIANGLE_SIZE = 5;
  const TS = TRIANGLE_SIZE;

  for (let i = 0; i < filled_orders_for_this_chart.length; i++) {
    const order = filled_orders_for_this_chart[i];
    const candleIndex = props.get_candle_index_for_timestamp(new Date(order.created_at).getTime());

    if (candleIndex === -1 || isNaN(candleIndex)) continue;

    const y_position_pixel = props.get_y_for_price(order.filled_avg_price);
    const x_position_pixel = props.get_x_for_candle_index(candleIndex);

    if (y_position_pixel < 0 || y_position_pixel > props.height || x_position_pixel < 0 || x_position_pixel > props.width) {
      continue;
    }

    if (order.side.toUpperCase() === 'SELL') {
      past_order_indicators.push(
        <polygon
          key={`past-order-triangle-sell-${i}`}
          points={`${x_position_pixel},${y_position_pixel + TS} ${x_position_pixel - TS},${y_position_pixel - TS} ${x_position_pixel + TS},${y_position_pixel - TS}`}
          fill="#F0616d"
          stroke="white"
          cursor="pointer"
          onClick={() => setOpenModal(<TickerModal symbol={props.symbol} orderId={order.id} />)}
        />
      );
    } else {
      past_order_indicators.push(
        <polygon
          key={`past-order-triangle-buy-${i}`}
          points={`${x_position_pixel},${y_position_pixel - TS} ${x_position_pixel - TS},${y_position_pixel + TS} ${x_position_pixel + TS},${y_position_pixel + TS}`}
          fill="#27ad75"
          stroke="white"
          cursor="pointer"
          onClick={() => setOpenModal(<TickerModal symbol={props.symbol} orderId={order.id} />)}
        />
      );
    }
  }

  const indicator_labels: any = [];
  const indicators_map = get_indicators_for_chart(user, props.symbol, props.interval);
  const indicators: any = Object.values(indicators_map).sort((a: any, b: any) => {
    return a.index - b.index;
  });

  const have_oscillators = indicators.some((indicator: any) => indicator.oscillator_settings)
  const oscillators_hidden = chartData?.oscillatorsHidden

  for (let i = 0; i < indicators.length; i++) {
    const indicator = indicators[i];
    const is_oscillator = indicator.oscillator_settings ? true : false;
    if (is_oscillator) continue;        // do the rest of this too
    let indicator_plots = Object.keys(indicator.plots);
    indicator_plots = indicator_plots.sort();

    const is_single_plot = indicator_plots.length === 1;
    const plot_colors: any = [];
    indicator_plots.forEach((plot_key: string) => {
      const color = indicator.plots[plot_key].color || DEFAULT_INDICATOR_COLORS[indicator.value];
      plot_colors.push(color);
    });

    let ind_value_divs: any = [];
    indicator_plots.forEach((plot_key: string) => {
      let ind_val = '';


      // Fill in indicator value divs
      try {

        if (is_single_plot && !is_oscillator) {
          // if (is_oscillator) ind_val = ohlc.oscillators[indicator.abbrev_str];
          ind_val = ohlc.overlays[indicator.abbrev_str];
          const formatted = format_price_appropriately(ind_val);
          ind_value_divs.push(
            <div
              className='value-label'
              style={{ color: plot_colors[0] }}
              title={plot_key}
            >
              {formatted || 'n/a'}
            </div>
          );
        }

        else if (!is_oscillator) {
          // if (is_oscillator) ind_val = ohlc.oscillators?.[indicator.abbrev_str]?.[plot_key];
          ind_val = ohlc.overlays[indicator.abbrev_str]?.[plot_key];
          const formatted = format_price_appropriately(ind_val);
          const plot_color = indicator.plots[plot_key].color || DEFAULT_INDICATOR_COLORS[indicator.value];
          ind_value_divs.push(
            <div
              className='value-label'
              style={{ color: plot_color }}
              title={plot_key}
            >
              {formatted || 'n/a'}
            </div>

          );
        }
      }

      // This just means we don't have a value for this candle
      catch (err) {
        //@ts-ignore
      }
    });




    indicator_labels.push(
      <div className='ind-row'>
        <div className={indicator.isHidden ? 'ind-label hidden-from-chart' : 'ind-label'} title={indicator.abbrev_str_hovertext}>{indicator.abbrev_str}</div>
        {!indicator.isHidden ? <div className={'ind-value-container'}>{ind_value_divs}</div> : null}
        <div className='ind-icon-container'>
          {indicator.isHidden ? <Icon2
            className={'row-icon'}
            icon={indicator.isHidden ? 'eye-slash' : 'eye'}
            size={13}
            style={{ marginTop: 1.5, marginRight: 5 }}
            onClick={() => {
              toggle_indicator_visibility(indicator.id, user);
            }}
          /> : null}
          <div className='other-icons-container'>
            {!indicator.isHidden ? <Icon2
              className={'row-icon'}
              icon={indicator.isHidden ? 'eye-slash' : 'eye'}
              size={13}
              style={{ marginTop: 1.5, marginRight: 5 }}
              onClick={() => {
                toggle_indicator_visibility(indicator.id, user);
              }}
            /> : null}
            <Icon2
              className={'row-icon'}
              icon='gear'
              size={11}
              style={{ marginTop: 1.2, marginRight: 5}}
              onClick={() => {
                setOpenModal(
                  <EditIndicatorModal
                    symbol={props.symbol}
                    intervalId={indicator.id}
                    onClose={() => setOpenModal(null)}
                    modalTitle={indicator.abbrev_str}
                  />
                );
              }}
            />
            <Icon2
              className={'row-icon'}
              icon='xmark'
              set='regular'
              size={9.5}
              style={{ marginTop: -.5, marginRight: -1}}
              onClick={() => {
                delete_indicator(indicator.id, user);
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  // Button to reveal oscillators if hidden
  if (have_oscillators && oscillators_hidden) {
    indicator_labels.push(
      <div
        className='show-osc-tag'
        onClick={() => {
          const userDoc = db.collection('users').doc(user.uid);
          userDoc.update({[`charts.${props.symbol}.oscillatorsHidden`]: false});
        }}
      >
        Show oscillator plots
      </div>
    )
  }

  let date_label_offset = 0;
  if (cursorPos.left < 86) {
    date_label_offset = 86 - cursorPos.left;
  } else if (cursorPos.left > props.width - 87) {
    date_label_offset = props.width - cursorPos.left - 87;
  }
  let price_label_offset = 0;
  if (cursorPos.top < 10) {
    price_label_offset = 10 - cursorPos.top;
  }

  let formatted_crosshair_price = parseFloat(crosshair_price)
    .toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  if (formatted_crosshair_price.length > 6) {
    formatted_crosshair_price = parseFloat(crosshair_price)
      .toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  }

  const pd_num = ohlc.c - ohlc.overall_o;
  let no_price_delta_pc = false;
  let price_delta = format_price_appropriately(pd_num);

  let price_delta_pc = ((pd_num / ohlc.overall_o) * 100).toFixed(2);
  if (ohlc.overall_o === 0) no_price_delta_pc = true;
  price_delta_pc = `[${price_delta_pc}%]`;
  let pd_color_class = pd_num > 0 ? 'green' : 'red';
  if (Math.abs(pd_num) < 0.01) {
    pd_color_class = 'zero';
  }

  // String describing the time period
  const start_date_iso = props.data[0]?.t
  const end_date_iso = props.data[props.data.length - 1]?.t
  let timeframe_label = period_string(start_date_iso, end_date_iso, props.interval)


  // Is market open? Are we currently receiving live data?
  let mkt_open = isMarketOpen();

  // let is_scrolled_right: any = false;
  // if (mkt_open) {
  //   const most_recent_bar_time = new Date(props.data[props.data.length - 1].t).getTime();
  //   const intervalMs = TIMEFRAMES_MS[props.interval];
  //   const now = new Date().getTime();
  //   if (now - most_recent_bar_time < 3 * intervalMs) {
  //     is_scrolled_right = true;
  //   }
  // }


  let have_live_data = false;
  // let msi_hovertext = `Market is closed`

  if (streamingQuotes && streamingQuotes[props.symbol]) {
    const most_recent_quote_time = streamingQuotes[props.symbol]?.timestamp
    if (most_recent_quote_time) {
      const ts = new Date(most_recent_quote_time).getTime()
      const now = new Date().getTime()
      const seconds_ago = (now - ts) / 1000
      if (seconds_ago < 60) {
        have_live_data = true
      }
    }
  }
  // if (mkt_open && !have_live_data) msi_hovertext = `Data not live`


  let show_receiving = have_live_data //&& mkt_open
  let right_icon = show_receiving ? 'signal-stream' : 'signal-stream-slash'



  let market_status_indicator: any = (
    <div className={`live-streaming-ind ${show_receiving ? 'lit' : ''}`}>
      <Icon2
        icon={right_icon}
        size={14}
        style={{marginTop: 4.5, marginLeft: 2}}
        hoverText={'Receiving live data'}
      />
    </div>
  )
  if (!mkt_open) market_status_indicator = null
  if (!have_live_data) market_status_indicator = null

  const ohlc_o_formatted = formatStockPrice(ohlc.o, true)
  const ohlc_h_formatted = formatStockPrice(ohlc.h, true)
  const ohlc_l_formatted = formatStockPrice(ohlc.l, true)
  const ohlc_c_formatted = formatStockPrice(ohlc.c, true)

  return (
    <div
      className='crosshairs'
      onMouseMove={(e) => {
        if (selectedAnnotation) {
          if (isDragging) {
            e.stopPropagation();
            const rect = e.currentTarget.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            setSelectedAnnotation({
              id: selectedAnnotation.id,
              point: selectedAnnotation.point,
              xVal: x,
              yVal: y,
              xDate: crosshair_date.iso,
              yPrice: human_price_to_number(crosshair_price),
            });
          }
        }

        const rect = e.currentTarget.getBoundingClientRect();
        let x = e.clientX - rect.left;
        let y: any = e.clientY - rect.top;

        if (props.chartMode === 'line') {
          y = getYOnLineForX(cursorPos.left);
        }

        let hoveredCandleIndex = -1;
        if (USE_SNAP_TO) {
          hoveredCandleIndex = props.snap_tos.reduce((prevIndex, currValue, currIndex, array) => {
            const prevValue = array[prevIndex];
            return Math.abs(currValue - x) < Math.abs(prevValue - x) ? currIndex : prevIndex;
          }, 0);

          let hoveredCandle = props.data[hoveredCandleIndex];
          let formatted_datetime;

          if (hoveredCandleIndex >= props.data.length - props.futureCandlesCount) {
            // This is a future candle
            // formatted_datetime = 'Future - ' + format_date_for_crosshairs(hoveredCandle.t, props.interval === '1d' || props.interval === '1w');
            formatted_datetime = format_date_for_crosshairs(hoveredCandle.t, props.interval === '1d' || props.interval === '1w');
          } else {
            const no_time = props.interval === '1d' || props.interval === '1w';
            formatted_datetime = format_date_for_crosshairs(hoveredCandle.t, no_time);
          }

          setCrosshairDate({ human: formatted_datetime, iso: hoveredCandle.t, price: hoveredCandle.c });
          setOhlc({
            ...hoveredCandle,
            overall_o: props.data[0].o || props.data[0].c,
          });
          setOhlcG({
            ...hoveredCandle,
            hoveredCandleIndex,
            overall_o: props.data[0].o || props.data[0].c,
          })

          setCursorPos({
            left: props.snap_tos[hoveredCandleIndex],
            top: y,
          });
        }
      }}
      onMouseLeave={() => {
        setCursorPos({ left: -1, top: -1 });

        const firstCandle = props.data[0];
        const lastCandle = props.data[props.data.length - 1];
        let overall_l = 999999999;
        let overall_h = 0;
        for (let i = 0; i < props.data.length; i++) {
          const candle = props.data[i];
          if (candle.l < overall_l) {
            overall_l = candle.l;
          }
          if (candle.h > overall_h) {
            overall_h = candle.h;
          }
        }
        setOhlc({
          o: firstCandle.o,
          h: overall_h,
          l: overall_l,
          c: lastCandle.c,
          overlays: lastCandle.overlays,
          oscillators: lastCandle.oscillators,
          overall_o: firstCandle.o || firstCandle.c,
        });
        setOhlcG({
          o: firstCandle.o,
          h: overall_h,
          l: overall_l,
          c: lastCandle.c,
          hoveredCandleIndex: -1,
          overlays: lastCandle.overlays,
          oscillators: lastCandle.oscillators,
          overall_o: firstCandle.o || firstCandle.c,
        });
      }}
      onClick={(e) => {
        if (toolMode === 'trendline') {
          if (!annotationInProgress) {
            const x1 = crosshair_date.iso;
            const y1 = human_price_to_number(crosshair_price);
            setAnnotationInProgress({ x1, y1, x2: null, y2: null });
          } else {
            const x1 = annotationInProgress.x1;
            const y1 = annotationInProgress.y1;
            const x2 = crosshair_date.iso;
            const y2 = human_price_to_number(crosshair_price);
            setAnnotationInProgress(null);
            setToolMode(null);
            write_trendline_to_db({ x1, y1, x2, y2, user, selectedChart: props.symbol });
          }
        }
      }}
      onContextMenu={(event) => {
        if (get_contextmenu_off()) return
        event.preventDefault()
        const width = 220
        const clickX = event.clientX + 5
        const clickY = event.clientY - 10
        setOpenDropdown(
          <TickerDropdown
            symbol={props.symbol}
            left={clickX}
            top={clickY}
            width={width}
            onClose={() => setOpenDropdown(null)}
            fromChart={true}
            priceForY={props.get_price_for_y(cursorPos.top)}
          />
        )
      }}
    >
      {openDropdown}
      <svg
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1,
        }}
        onClick={(e) => {
          if (isDragging) {
            return;
          }
          setSelectedAnnotation(null);
        }}
      >
        {annotationSVGs}
        {current_line_components}
        {/* {past_order_indicators} */}
        {/* {position_indicators} */}
        {/* {open_order_indicators} */}

        {props.chartMode === 'line' && ![null, -1].includes(cursorPos.top) ? (
          <circle
            cx={cursorPos.left}
            cy={cursorPos.top}
            r={5}
            fill="#FF8F0E"
          />
        ) : null}
      </svg>

      {/* OHLC */}
      {(cursorPos.left > 0 && cursorPos.top > 0) || SHOW_OHLC_ALWAYS ? (
        <div style={{ height: '100%', width: '100%' }}>
          <div className='on-chart-price-diff-section'>
            <div className='price-diff-label'>{timeframe_label}</div>
            <div className={pd_color_class}>{price_delta}</div>
            {!no_price_delta_pc ? <div className={pd_color_class}>{price_delta_pc}</div> : null}
            {/* {market_status_indicator} */}
          </div>
          {ohlc.v ? (
            <div className='ohlc-container'>
              <div className='ohlc-label'>O</div>


              <div className={pd_num > 0 ? 'ohlc-val' : 'ohlc-val neg-val'}>{ohlc_o_formatted}</div>
              <div className='ohlc-label'>H</div>
              <div className={pd_num > 0 ? 'ohlc-val' : 'ohlc-val neg-val'}>{ohlc_h_formatted}</div>
              <div className='ohlc-label'>L</div>
              <div className={pd_num > 0 ? 'ohlc-val' : 'ohlc-val neg-val'}>{ohlc_l_formatted}</div>
              <div className='ohlc-label'>C</div>
              <div className={pd_num > 0 ? 'ohlc-val' : 'ohlc-val neg-val'}>{ohlc_c_formatted}</div>
              {ohlc.v ? <div className='ohlc-label'>V</div> : null}
              {ohlc.v ? <div className={pd_num > 0 ? 'ohlc-val' : 'ohlc-val neg-val'}>{ohlc.v?.toLocaleString()}</div> : null}
            </div>
          ) : (
            <div className='ohlc-container'>
              {!is_internal(props.symbol) ? <div className='ohlc-label'>
                <div className={pd_num >= 0 ? 'ohlc-val' : 'ohlc-val neg-val'}>{formatStockPrice(ohlc.c, true)}</div>
              </div> : null}
            </div>
          )}
          {props.chartMode === 'candles' ? (
            <div className='indicator-label-section'>
              {indicator_labels}
              <div
                className='add-ind-btn'
                onClick={() => {
                  setOpenModal(<IndicatorModal symbol={props.symbol} interval={props.interval} />);
                }}
              >
                + Add indicator
              </div>
            </div>
          ) : null}
          {cursorPos.left > 0 && cursorPos.top > 0 ? (
            <div style={{ height: '100%', width: '100%' }}>
              <div className='horiz' style={{ top: cursorPos.top }}>
                <div className='label' style={{ top: price_label_offset }}>{formatted_crosshair_price}</div>
              </div>
              <div className='vert' style={{ left: cursorPos.left }}>
                <div className='label-v' style={{ left: date_label_offset }}>{crosshair_date.human}</div>
              </div>
            </div>
          ) : null}

          {market_status_indicator}

        </div>
      ) : null}

      {/* POSITION & ORDER INDICATORS */}
      {position_indicator_divs}
      {open_order_indicator_divs}
      {past_order_indicator_divs}

    </div>
  );
});

const format_date_for_crosshairs = (dateStr: string, skip_time?: boolean): string => {
  const utcDate = new Date(dateStr);

  const dateOptions: Intl.DateTimeFormatOptions = {
    timeZone: 'America/New_York',
    weekday: 'short',
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
  };

  const timeOptions: Intl.DateTimeFormatOptions = {
    timeZone: 'America/New_York',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  };

  const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
  const timeFormatter = new Intl.DateTimeFormat('en-US', timeOptions);

  const [{ value: weekday }, , { value: month }, , { value: day }, , { value: year }] = dateFormatter.formatToParts(utcDate);

  const formattedDate = `${weekday} ${month}/${day}/${year}`;

  if (skip_time) {
    return formattedDate;
  } else {
    const [{ value: hour }, , { value: minute }, , { value: period }] = timeFormatter.formatToParts(utcDate);
    return `${formattedDate} ${hour}:${minute}${period}`;
  }
};

export default ChartOverlay;
