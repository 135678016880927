

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Modal } from '../reusable'
import { updateLiveData, uuid } from '../../logic/u'
import { loggedInUserAtom, openModalAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { FunctionObj } from '../../types/user_types'
import { db } from '../../firebase'

interface DuplicateFunctionModalProps {
  functionObj: FunctionObj
}

export const DuplicateFunctionModal = (props: DuplicateFunctionModalProps) => {
  const {functionObj} = props

  // General helpers
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)

  // Form fields
  const [name, setName] = useState<string>(functionObj?.name.replace(/\s/g, '_') + '_COPY' || '')

  // Check for errors when onclick isnt running
  let errorMessage = ''
  if (!buttonIsLoading) {
    // Check if name is unique
    let conflictingFunctions: FunctionObj[] = []
    conflictingFunctions = user?.functions?.filter(f => f.name === name) || []
    if (conflictingFunctions.length) {
      errorMessage = 'Function name is being used'
    }

    // Limit to 50 characters
    if (name?.length > 50) {
      errorMessage = 'Function name must be < 50 characters'
    }

    // Ensure name doesn't start with number
    if (/^\d/.test(name)) {
      errorMessage = 'Function name must not start with a number'
    }
  }

  useEffect(() => {
    setButtonEnabled(!!name)
  }, [name, errorMessage]);

  if (!user || !functionObj) return null

  const content = (
    <div className='column width-full'>
      <Input
        label={'Name'}
        type={'text'}
        style={{width: '100%'}}
        initialValue={name}
        onChange={(val) => {
          let modified = val.replace(/\s/g, '_');
          modified = modified.slice(0, 50);
          setName(modified);
        }}
        />
      {name && <div className='under-input-label'>
        {'Compiled: ' + name}
      </div>}

      {/* Error message, if relevant */}
      <div
        className={errorMessage ? 'font-size-10 red-text' : 'display-none'}
        style={{
          marginTop: 15,
          marginBottom: -10,
        }}
      >
        {errorMessage}
      </div>
    </div>
  )

  return (
    <Modal
      title={'Duplicate'}
      contents={[content]}
      yesButtonText={'Duplicate'}
      isButtonDisabled={!buttonEnabled || buttonIsLoading}
      isButtonLoading={buttonIsLoading}
      onYes={async () => {
        try {
          setButtonIsLoading(true)

          let newFunctionObj = {...functionObj as FunctionObj}
          newFunctionObj.id = uuid()
          newFunctionObj.createdAt = new Date()
          newFunctionObj.updatedAt = new Date()
          newFunctionObj.name = name
          newFunctionObj.isRunning = false

          let currentFunctions = user.functions || []
          currentFunctions.push(newFunctionObj)

          await db.collection('users').doc(user.uid).update({functions: currentFunctions})
          updateLiveData(user)
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

