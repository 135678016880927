import { utcToZonedTime } from 'date-fns-tz'
import {
	INTERVALS,
  is_internal,
	isMarketOpen,
  barISOToNYCHuman,
	getMostRecentMarketOpenTime,
} from '../../../logic/u'
import { db } from '../../../firebase'
import { currentUser } from '../../../firebase'
import axios from 'axios'
import { add_indicators_to_dataset } from '../../../logic/indicator_helpers'
// import { loadAlpacaData } from './loadAlpacaData'
import { load_internal_data } from './load_internal_data'
import { get_empty_bars } from './get_empty_bars'

import { getConfig } from '../../../config'
import { UserLiveDoc } from '../../../types/user_types'
const config: any = getConfig()




interface load_bars_props {
  symbol: string
  interval: string
  start: Date | undefined
  end: Date | undefined
  user: any
  uld: any
}

export const load_bars = async ({symbol, interval, start, end, user, uld}: load_bars_props) => {
  //@ts-ignore
  window.TBChartLoadingInProgress = true

  // Is this an internal chart? (e.g. "BALANCES.cash" as opposed to "NVDA")
  const internalChart = is_internal(symbol)

  const _end = end || new Date()
  let endISO = end ? end.toISOString() : ''

  // Start: a day ago
  let startISO = new Date(_end.getTime() - 1000 * 60 * 60 * 24).toISOString()

  // Default start dates, by timeframe
  if (!start) {
    if (interval === '1m') {
      startISO = new Date(_end.getTime() - 1000 * 60 * 60 * 24).toISOString()
    } else if (interval === '5m') {
      startISO = new Date(_end.getTime() - 1000 * 60 * 60 * 24 * 7).toISOString()
    } else if (interval === '30m') {
      startISO = new Date(_end.getTime() - 1000 * 60 * 60 * 24 * 30).toISOString()
    } else if (interval === '1h') {
      startISO = new Date(_end.getTime() - 1000 * 60 * 60 * 24 * 30 * 2).toISOString()
    } else if (interval === '2h') {
      startISO = new Date(_end.getTime() - 1000 * 60 * 60 * 24 * 30 * 3).toISOString()
    } else if (interval === '4h') {
      startISO = new Date(_end.getTime() - 1000 * 60 * 60 * 24 * 30 * 6).toISOString()
    } else if (interval === '1d') {
      startISO = new Date(_end.getTime() - 1000 * 60 * 60 * 24 * 30 * 12).toISOString()
    } else {
      startISO = new Date(_end.getTime() - 1000 * 60 * 60 * 24 * 30 * 12 * 10).toISOString()
    }
  } else {
    startISO = start.toISOString()
  }

  // Indicators - TODO: remove ones hidden
  let indicators: string[] = []
  if (user && !internalChart) {
    const chart = user.charts[symbol]
    indicators = Object.keys(chart.indicators || {})
  }

  let rawBars: any[] = []

  if (internalChart) {
    let endDate = new Date()
    if (endISO) endDate = new Date(endDate)
    // Separate function for getting alpaca/EELogs data
    const data = await load_internal_data({
      user,
      uld,
      symbol,
      startDate: new Date(startISO),
      endDate,
      interval
    })
    data.sort((a: any, b: any) => a[0] - b[0])

    // Concepts of intervals go out the window here, just timestamps and close values
    let bars: any[] = []
    for (let i = 0; i < data.length; i++) {
      const timestamp = data[i][0]
      const value = data[i][1]
      bars.push({t: timestamp, c: value})
    }
    return bars

  } else {
    // Load bars from alpaca
    const token = await currentUser()?.getIdToken()
    const res = await axios.get(`${config.api_root_url}getBarsV5`, {
      params: {
        symbol,
        timeframe: interval,
        start: startISO,
        end: endISO,
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    rawBars = res.data.bars
    const indicators_for_chart = user.charts[symbol].indicators
    rawBars = add_indicators_to_dataset({
      indicators: indicators_for_chart,
      bars: rawBars,
      interval,
      uld,
      symbol
    })
  }

  //@ts-ignore
  window.TBChartLoadingInProgress = false
  return rawBars
}



// marc-todo: next up, load_more_bars here, using getBackMarketHoursSIPBars




export const get_recent_even_multiple = (timestamp: number, interval: string) => {
  let date = new Date(timestamp)

  // Determine the interval type and value
  const intervalType = interval.slice(-1) // 'm' for minutes, 'h' for hours, 'd' for days, 'w' for weeks
  const intervalValue = parseInt(interval.slice(0, -1), 10) // numeric value of interval

  if (intervalType === 'm') {
    // For minute intervals
    const minutes = date.getMinutes()
    const nearestMultiple = minutes - (minutes % intervalValue)
    date.setMinutes(nearestMultiple)
  } else if (intervalType === 'h') {
    // For hour intervals
    const hours = date.getHours()
    const nearestMultiple = hours - (hours % intervalValue)
    date.setHours(nearestMultiple)
    date.setMinutes(0) // Reset minutes for hour intervals
  } else if (intervalType === 'd') {
    // For day intervals
    date.setHours(0, 0, 0, 0) // Reset to the start of the day
  } else if (intervalType === 'w') {
    // For week intervals
    const dayOfWeek = date.getDay()
    date.setDate(date.getDate() - dayOfWeek)
    date.setHours(0, 0, 0, 0) // Reset to the start of the week
  }

  // Reset seconds and milliseconds to zero
  date.setSeconds(0)
  date.setMilliseconds(0)

  return date
}


// How far to step for a given fixed timeframe
export const STEP_BACK_INTERALS = {
	'1D': 1000 * 60 * 60 * 24,
	'1W': 1000 * 60 * 60 * 24 * 7,
	'1M': 1000 * 60 * 60 * 24 * 30,
	'3M': 1000 * 60 * 60 * 24 * 30 * 3,
	'6M': 1000 * 60 * 60 * 24 * 30 * 6,
	'1Y': 1000 * 60 * 60 * 24 * 365,
	'5Y': 1000 * 60 * 60 * 24 * 365 * 5,
	'All': 1000 * 60 * 60 * 24 * 365 * 100,
}

// TB, not alpaca
export const TIMEFRAMES_MS: any = {
  '1m': 1 * 60 * 1000,
  '5m': 5 * 60 * 1000,
  '30m': 30 * 60 * 1000,
  '1h': 60 * 60 * 1000,
  '2h': 2 * 60 * 60 * 1000,
  '4h': 4 * 60 * 60 * 1000,
  '1d': 24 * 60 * 60 * 1000,
  '1w': 7 * 24 * 60 * 60 * 1000,
  '1M': 30 * 24 * 60 * 60 * 1000
}

/*
STREAMING HELPERS

Use these to turn a stream of latest quotes into an array of bars
*/
let LIVESTREAM_DATA = {
  bars: <any> [],
  timeframe: '',
  timeframe_ms: 0,
  symbol: '',
  start_date_iso: '',
  last_bar_timestamp: 0,        // close of historical dataset
  last_bar_close: 0,
  latest_price: 0,
  latest_price_time_iso : '',
  most_recent_bar_close_ts: 0,    // close of recent streaming bar
  current_bar: {o: 0, h: 0, l: 0, c: 0, t: ''}
}
export const initialize_streaming_data = (symbol: string, timeframe: string, start_date_iso: string, last_bar_close: number) => {
  const timeframe_ms = TIMEFRAMES_MS[timeframe]
  const last_bar_timestamp = new Date(start_date_iso).getTime()

  LIVESTREAM_DATA = {
    bars: [],
    timeframe,
    timeframe_ms,
    symbol,
    start_date_iso,
    last_bar_timestamp,
    last_bar_close,
    latest_price: 0,
    latest_price_time_iso: '',
    most_recent_bar_close_ts: last_bar_timestamp,
    current_bar: {o: 0, h: 0, l: 0, c: 0, t: ''}
  }
}

// Assumes we are correctly initialized
export const add_streaming_datapoint = (value: number, time_iso: string) => {
  const LSD = LIVESTREAM_DATA
  LSD.latest_price = value
  LSD.latest_price_time_iso = time_iso

  // How many bars ago was the last bar we have?
  const ts = new Date(time_iso).getTime()
  const time_since_last_bar = ts - LSD.last_bar_timestamp
  let how_many_bars_ago = time_since_last_bar / LSD.timeframe_ms

  // If this was multiple bars ago, create multiple "empty" bars to fill in the gap
  while (how_many_bars_ago > 2) {
    const price = LSD.latest_price || LSD.last_bar_close
    const timestamp = LSD.last_bar_timestamp + LSD.timeframe_ms
    const iso = new Date(timestamp).toISOString()
    const bar = {o: price, h: price, l: price, c: price, t: iso}
    LSD.bars.push(bar)

    how_many_bars_ago -= 1
  }

  // If we have a bar in progress that has finished, finish it, begin new bar
  if (how_many_bars_ago > 1) {
    LSD.current_bar.c = value
    if (!LSD.current_bar.o) {
      LSD.current_bar.t = new Date(LSD.most_recent_bar_close_ts + LSD.timeframe_ms).toISOString()
      LSD.current_bar.o = value
      LSD.current_bar.h = value
      LSD.current_bar.l = value
    }
    LSD.bars.push(LSD.current_bar)
    LSD.most_recent_bar_close_ts = new Date(LSD.current_bar.t).getTime()

    // Begin new bar
    const new_bar_timestamp = LSD.most_recent_bar_close_ts + LSD.timeframe_ms
    const new_bar_t = new Date(new_bar_timestamp).toISOString()
    LSD.current_bar = {
      o: value,
      h: value,
      l: value,
      c: value,
      t: new_bar_t
    }
  }

  // If we have a bar in progress which is not yet finished, add to it
  if (how_many_bars_ago <= 1) {

    // Do we have a latest_bar initialized? If not, initialize it
    if (LSD.current_bar.o === 0) {
      const new_bar_timestamp = LSD.most_recent_bar_close_ts + LSD.timeframe_ms
      const new_bar_t = new Date(new_bar_timestamp).toISOString()
      LSD.current_bar = {
        o: value,
        h: value,
        l: value,
        c: value,
        t: new_bar_t
      }
    }

    LSD.current_bar.h = Math.max(LSD.current_bar.h, value)
    LSD.current_bar.l = Math.min(LSD.current_bar.l, value)
    LSD.current_bar.c = value
  }
}

export const get_streaming_bars = () => {
  const ret = JSON.parse(JSON.stringify(LIVESTREAM_DATA.bars))

  // Dump whatever we've got for the current bar
  if (LIVESTREAM_DATA.current_bar.o !== 0) {
    ret.push(LIVESTREAM_DATA.current_bar)
  }

  return ret
}

export const splice_on_streaming_bars = (dataset: any) => {
  let ret = dataset
  const new_bars = get_streaming_bars()
  const last_ts = new Date(dataset[dataset.length - 1].t).getTime()
  for (let i = 0; i < new_bars.length; i++) {
    const bar = new_bars[i]
    const ts = new Date(bar.t).getTime()
    if (ts > last_ts) {
      ret.push(bar)
    }
  }
  return ret
}

export const initialize_streaming_data_if_necessary = (symbol: string, timeframe: string, start_date_iso: string, last_bar_close: number) => {
  const lst_dne = LIVESTREAM_DATA.start_date_iso === ''
  const different_symbol = LIVESTREAM_DATA.symbol !== symbol
  const different_timeframe = LIVESTREAM_DATA.timeframe !== timeframe
  const new_day = new Date(start_date_iso).getDate() !== new Date(LIVESTREAM_DATA.start_date_iso).getDate()

  if (lst_dne || different_symbol || different_timeframe || new_day) {
    initialize_streaming_data(symbol, timeframe, start_date_iso, last_bar_close)
  }
}

/*
TODO:
- initialize:
  - if not initialized
  - if new symbol selected
  -
*/

export const formatNumber = (num: any) => {
  if (num === undefined || num === null) return ''
  num = Number(num)
  let formatted_price = parseFloat(num)
    .toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  if (formatted_price.length > 6) {
    formatted_price = parseFloat(num)
      .toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  }
  return formatted_price
}


export const updateChartDataWithStreamingData = (streamingBars: any, existingCandles: any) => {
  if (!streamingBars || streamingBars.length === 0) return existingCandles;

  const updatedCandles = [...existingCandles];
  const lastExistingCandleTime = new Date(updatedCandles[updatedCandles.length - 1].t).getTime();

  streamingBars.forEach((streamingBar: any) => {
    const streamingBarTime = new Date(streamingBar.t).getTime();

    if (streamingBarTime > lastExistingCandleTime) {
      // This is a new candle, add it to the end
      updatedCandles.push({
        ...streamingBar,
        relative_index: updatedCandles[updatedCandles.length - 1].relative_index + 1
      });
    } else {
      // Update an existing candle
      const existingCandleIndex = updatedCandles.findIndex(candle =>
        new Date(candle.t).getTime() === streamingBarTime
      );

      if (existingCandleIndex !== -1) {
        updatedCandles[existingCandleIndex] = {
          ...updatedCandles[existingCandleIndex],
          ...streamingBar
        };
      }
    }
  });

  return updatedCandles;
};