/*
Single-purpose component for SSN input
*/

import React, { useState } from "react"
import InputMask from 'react-input-mask'

interface SIProps {
  label: string
  onBlur: (value: string) => void
  style: any
  className: string
}

export function SSNInput(props: SIProps) {
  const [isError, setIsError] = useState<boolean>(false)

  let classList = 'input-component'
  if (props.className) {
    classList += ' ' + props.className
  }

  let inputClassName = ''
  if (isError) {
    inputClassName += ' error'
  }

  return (
    <div
      className={classList}
      style={{
        ...props.style,
        width: '100%'
      }}
    >
      <div className='label'>{props.label}</div>
      <InputMask
        mask="999-99-9999"
        // placeholder="Enter SSN"
        className={inputClassName}
        onChange={(e) => {
          if (isError) {
            const isValidSSN = validateSSN(e.target.value)
            if (isValidSSN) {
              setIsError(false)
            }
          }
        }}
        onBlur={(e) => {
          const isValidSSN = validateSSN(e.target.value)
          if (!isValidSSN) {
            setIsError(true)
          } else if (isError && isValidSSN) {
            setIsError(false)
          }
          props.onBlur(e.target.value)
        }}
      >
        {(inputProps) => <input {...inputProps} />}
      </InputMask>
    </div>
  )
}

const validateSSN = (val: string) => {
  val = val.replace(/\D/g, '') // Remove all non-digit characters
  const isValidSSN = val.length === 9 // SSN should have 9 digits
  return isValidSSN
}
