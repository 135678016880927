import { INTERVALS } from '../../../logic/u'


interface MCFLDPProps {
  datapoints: any[],
  interval: string,
  last_candle?: any
}
export const make_candles_from_live_datapoints = (props: MCFLDPProps) => {
  const { datapoints, interval, last_candle } = props;
  const intervalMs = INTERVALS[interval];
  if (!intervalMs) {
    throw new Error(`Invalid interval: ${interval}`);
  }
  if (datapoints.length === 0) return []

  const candles: any[] = [];
  let currentCandle: any = null;

  datapoints.forEach((datapoint) => {
    const timestamp = new Date(datapoint.timestamp).getTime();
    const intervalStart = Math.floor(timestamp / intervalMs) * intervalMs;

    if (!currentCandle || intervalStart > new Date(currentCandle.t).getTime()) {
      // Start a new candle
      if (currentCandle) {
        candles.push(currentCandle);
      }
      currentCandle = {
        o: datapoint.price,
        h: datapoint.price,
        l: datapoint.price,
        c: datapoint.price,
        t: new Date(intervalStart).toISOString()
      };
    } else {
      // Update current candle
      currentCandle.h = Math.max(currentCandle.h, datapoint.price);
      currentCandle.l = Math.min(currentCandle.l, datapoint.price);
      currentCandle.c = datapoint.price;
    }
  });

  // Add the last candle if it exists
  if (currentCandle) {
    candles.push(currentCandle);
  }

  // Add relative index, starting at 0
  candles.forEach((candle, index) => {
    candle.relative_index = index;
  });

  return candles;
};

// MARC TODO: last candle?