import { useAtom } from 'jotai';
import React, { useEffect, useRef, useState } from 'react'
import {
  openModalAtom,
  userLiveDocAtom,
  loggedInUserAtom,
  consoleOutputAtom,
  selectedCodeEditorSlotAtom,
  functionsWithUnsavedChangesAtom,
  cmdSAtom, cmdTAtom, cmdOAtom
} from '../../types/global_types';
import { DropdownMenu, DropdownSelect, Icon, Switch } from '../reusable';
import { RunFunctionModal } from '../modals/RunFunctionModal';
import { RunFunctionOnceModal } from '../modals/RunFunctionOnceModal';
import { TestFunctionModal } from '../modals/TestFunctionModal';
import { CodeEditor } from './CodeEditor';
import { FunctionObj } from '../../types/user_types';
import { db } from '../../firebase';
import { FunctionDropdown } from '../dropdowns/FunctionDropdown';
import { createEELog, formatDateForTable, timeAgo } from '../../logic/u';

interface CodeEditorWrapperProps {
  codeEditorSlot: number
  functionObj?: FunctionObj | undefined
}

export const CodeEditorWrapper = (props: CodeEditorWrapperProps) => {
  const {codeEditorSlot, functionObj} = props


  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [, setConsoleOutput] = useAtom(consoleOutputAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [selectedCodeEditorSlot, setSelectedCodeEditorSlot] = useAtom(selectedCodeEditorSlotAtom)
  const [ dropdown, setDropdown ] = useState<any>()
  const [functionsWithUnsavedChanges, setFunctionsWithUnsavedChanges] = useAtom(functionsWithUnsavedChangesAtom)
  const [triggerType, setTriggerType] = useState<FunctionObj['triggerType'] | undefined>(functionObj?.triggerType)
  const [triggerInterval, setTriggerInterval] = useState<FunctionObj['triggerInterval'] | undefined>(functionObj?.triggerInterval)
  const [cmdS] = useAtom(cmdSAtom)
  const [cmdT] = useAtom(cmdTAtom)
  const [cmdO] = useAtom(cmdOAtom)

  const page_load_start_ts = new Date().getTime()

  // Save on cmd + s, see keyboard listener in app
  useEffect(() => {
    if (!cmdS) return
    do_save()
  }, [cmdS])

  // // Cmd + T to test function, see keyboard listener in app
  // useEffect(() => {
  //   if (!functionObj) return
  //   if (!cmdT) return
  //   do_save().then((new_code) => {
  //     if (new_code) functionObj.codeBlock = new_code
  //     setOpenModal(<TestFunctionModal functionObj={functionObj} />)
  //   })
  // }, [cmdT])

  // // Shift + cmd + r to run function once
  // useEffect(() => {
  //   if (!functionObj) return
  //   if (!cmdO) return
  //   do_save().then((new_code) => {
  //     if (new_code) functionObj.codeBlock = new_code
  //     setOpenModal(<RunFunctionOnceModal functionObj={functionObj}/>)
  //   })
  // }, [cmdO])

  // Also returns new_code, for the sake of saving prior to executing
  const do_save = async () => {
    if (!functionObj) return
    if (!functionsWithUnsavedChanges[functionObj.id]?.unsavedEditorValue) return
    const new_code = functionsWithUnsavedChanges[functionObj.id]?.unsavedEditorValue
    await save_function_code(functionObj, user, functionsWithUnsavedChanges[functionObj.id]?.unsavedEditorValue)
    let newState = {...functionsWithUnsavedChanges}
    delete newState[functionObj.id]
    setFunctionsWithUnsavedChanges(newState || {})
    return new_code
  }


  let codeEditorWrapperClass = 'code-editor-wrapper'
  if (user?.codeEditorConfig === '2-one-left-one-right') {
    codeEditorWrapperClass += ' two-left-right'
  }
  if (!functionObj) {
    codeEditorWrapperClass += ' empty'
  }

  let emptyHeaderClass = 'tab-header empty'
  let emptyBoxClass = 'empty-box'
  let emptyText = 'Click here'
  if (selectedCodeEditorSlot === codeEditorSlot) {
    emptyHeaderClass += ' selected'
    emptyBoxClass += ' selected'
    emptyText = 'Choose a function...'
  }


  let formatted_date = '-'
  let time_ago = '-'
  if (uld?.functionsLastRun && functionObj && uld.functionsLastRun[functionObj.id]) {
    const last_ran_date = new Date(uld.functionsLastRun[functionObj.id])
    time_ago = timeAgo(last_ran_date)
    formatted_date = formatDateForTable(last_ran_date)
  }

  if (!user || !uld) return null

  if (!functionObj) return (
    <div className={codeEditorWrapperClass}>
      <div className={emptyHeaderClass}>
      </div>
      <div
        className={emptyBoxClass}
        onClick={() => {
          setSelectedCodeEditorSlot(selectedCodeEditorSlot ? null : codeEditorSlot)
        }}
      >
        {emptyText}
      </div>
    </div>
  )

  return (
    <div className={codeEditorWrapperClass}>
      {dropdown}
      <div className='tab-header'>
        <div className='row align-center'>
          <div className={user.codeEditorConfig !== '1' ? 'function-tab-name smaller' : 'function-tab-name'}>
            {functionObj?.name}
          </div>
          <div 
            className='gray-text font-size-10' 
            title={formatted_date}
            style={{marginTop: 3, marginRight: 0}}  
          >
            last run {time_ago}
          </div>
          {functionsWithUnsavedChanges[functionObj.id]?.unsavedEditorValue ? <div
            className='functions-tab-header-btn'
            style={{marginLeft: 12}}
            onClick={async () => {
              await save_function_code(functionObj, user, functionsWithUnsavedChanges[functionObj.id].unsavedEditorValue)
              let newState = {...functionsWithUnsavedChanges}
              delete newState[functionObj.id]
              setFunctionsWithUnsavedChanges(newState || {})
            }}
          >
            <Icon
              icon='floppy-disk'
              className='functions-tab-header-icon'
              hoverText='Run function once'
              set='sharp-solid'
              size={12}
              style={{marginTop: 1, marginRight: 5}}
              onClick={() => {
                // for css
              }}
            />
            <div>
              Save
            </div>
          </div> : null}
        </div>

        {/* Right */}
        <div className='row'>          
          {/* Test function button */}
          <div
            className='functions-tab-header-btn'
            style={{marginRight: 12}}
            onClick={async () => {
              do_save().then((new_code) => {
                if (new_code) functionObj.codeBlock = new_code
                setOpenModal(<TestFunctionModal functionObj={functionObj} />)
              })
            }}
          >
            <Icon
              icon='flask'
              className='functions-tab-header-icon'
              hoverText='Test function'
              set='sharp-solid'
              size={12}
              style={{marginTop: 1, marginRight: 5}}
              onClick={() => {
                // for hover
              }}
              />
            <div>
              Test
            </div>
          </div>

          {/* Run once button */}
          <div
            className='functions-tab-header-btn'
            onClick={async () => {
              do_save().then((new_code) => {
                if (new_code) functionObj.codeBlock = new_code
                setOpenModal(<RunFunctionOnceModal functionObj={functionObj}/>)
              })
            }}
          >
            <Icon
              icon='circle-play'
              className='functions-tab-header-icon'
              hoverText='Run function once'
              set='sharp-solid'
              size={14}
              style={{marginTop: 1, marginRight: 5}}
              onClick={() => {
                // for hover
              }}
            />
            <div>
              Run once
            </div>
          </div>

          <div
            className='gray-text'
            style={{marginTop: 3, marginLeft: 10, marginRight: 10}}
          >
            •
          </div>

          {/* Interval vs callable selector */}
          <DropdownSelect
            variant={'borderless'}
            width={'auto'}
            dropDownMenuWidth={150}
            value={functionObj.triggerType}
            isDriven={true}
            chevronMarginTop={4}
            style={{
              marginRight: triggerType === 'callable' ? 0 : 12,
              marginTop: 2,
              // marginLeft: 12
            }}
            onChange={async (val) => {
              if (val === 'interval') {
                setTriggerInterval('1h')
              }
              setTriggerType(val)

              const updatedFunctionObj: FunctionObj = {
                ...functionObj,
                triggerType: val,
                triggerInterval: val === 'interval' ? '1h' : functionObj.triggerInterval,
                isRunning: triggerType === 'callable' ? false : functionObj.isRunning,
                updatedAt: new Date()
              }
              let currentFunctions = user.functions || []
              const functionObjToUpdateIndex = currentFunctions.findIndex(f => f.id === functionObj.id)
              currentFunctions[functionObjToUpdateIndex] = updatedFunctionObj
              await db.collection('users').doc(user.uid).update({ functions: currentFunctions })

              // Create EELog if function is running
              if (functionObj.isRunning) {

                // If we change the trigger, make a log, turn it off if going back to manual
                if (functionObj.triggerType !== val) {
                  if (val === 'callable') {
                    const log_message = `Function \`${functionObj.name}\` trigger changed to run only when run once; function turned OFF`
                    const console_output = [{className: 'log', text: log_message}]
                    await createEELog(db, user.uid, 'function_activation', console_output)
                  }
                  if (val === 'callable') {
                    const log_message = `Function \`${functionObj.name}\` trigger changed to run on interval`
                    const console_output = [{className: 'log', text: log_message}]
                    await createEELog(db, user.uid, 'function_activation', console_output)
                  }
                }
              }
            }}
            options={[
              {display: 'interval', value: 'interval'},
              {display: 'run once only', value: 'callable'}
            ]}
            fontSize={12}
          />

          {/* Interval dropdown */}
          {triggerType === 'interval' ? <>
            <DropdownSelect
              variant={'borderless'}
              width={'auto'}
              dropDownMenuWidth={50}
              value={functionObj.triggerInterval}
              isDriven={true}
              chevronMarginTop={4}
              style={{
                marginRight: 12,
                marginTop: 2
              }}
              fontSize={12}
              onChange={async (val) => {
                setTriggerInterval(val)
                const updatedFunctionObj: FunctionObj = {
                  ...functionObj,
                  triggerInterval: val,
                  updatedAt: new Date()
                }
                let currentFunctions = user.functions || []
                const functionObjToUpdateIndex = currentFunctions.findIndex(f => f.id === functionObj.id)
                currentFunctions[functionObjToUpdateIndex] = updatedFunctionObj
                await db.collection('users').doc(user.uid).update({ functions: currentFunctions })

                // Create EELog if function is running
                if (functionObj.isRunning) {
                  // If we change the interval, make a log
                  if (functionObj.triggerInterval !== val) {
                    const log_message = `Function \`${functionObj.name}\` interval changed to ${val}`
                    const console_output = [{className: 'log', text: log_message}]
                    await createEELog(db, user.uid, 'function_activation', console_output)
                  }
                }
              }}
              options={[
                {display: '1m', value: '1m'},
                {display: '5m', value: '5m'},
                {display: '15m', value: '15m'},
                {display: '30m', value: '30m'},
                {display: '1h', value: '1h'},
                {display: 'open', value: 'open'}
              ]}
            />

            {/* Run switch (interval only) */}
            <Switch
              label={functionObj.isRunning ? 'ON' : 'OFF'}
              title={'Run function on interval'}
              value={functionObj?.isRunning || false}
              onChange={async (val) => {
                setOpenModal(<RunFunctionModal functionObj={functionObj} />)
              }}
            />
          </> : null}
          <Icon
            icon='gear'
            set='regular'
            size={14}
            style={{marginLeft: 12, marginTop: 5.5}}
            onClick={(e) => {
              const rect = e.target.getBoundingClientRect()
              if (rect) {
                const width= 175
                const left = rect.left - width + 10
                const top = rect.bottom
                setDropdown(<FunctionDropdown
                  left={left}
                  top={top}
                  functionObj={functionObj}
                  width={width}
                  onClose={() => setDropdown(null)}
                />)
              }


            }}
          />
        </div>
      </div>
      <CodeEditor functionObj={functionObj} />
    </div>
  )
}

const save_function_code = async(functionObj: any, user: any, editorValue: string) => {
  let currentFunctions = user?.functions || []
  const functionObjToUpdateIndex = currentFunctions.findIndex(f => f.id === functionObj?.id)
  currentFunctions[functionObjToUpdateIndex] = {
    ...functionObj,
    codeBlock: editorValue,
    updatedAt: new Date()
  }
  db.collection('users').doc(user?.uid).update({ functions: currentFunctions })

  // Unless function is running, clear functionsLastRun date
  if (!functionObj.isRunning) {
    db.collection('userLiveDocs')
      .doc(user?.uid)
      .update({ [`functionsLastRun.${functionObj.id}`]: null})
  }
}