/*
IntInput, based on old NumInput. Consider replacing.
*/


import React from 'react';
import NumericInput from "react-enhanced-numeric-input";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import './Input.scss';


interface IntInputProps {
  className?: string
  label: string
  onChange?: (value: number) => void
  onBlur?: (value: number) => void
  value?: number
  hoverText?: string
  style?: any
  variant?: undefined | 'compact'
}

export function IntInput(props: IntInputProps) {
  const inputRef: any = React.createRef()
  const [value, setValue] = React.useState<number>(props.value || 0)

  // const minimum = props.minimum || 0

  let classList = 'input-component int'
  if (props.className) {
    classList += ' ' + props.className
  }

  const getLabel = () => {
    let label
    let infoIcon

    let labelClassName = 'label'
    if (props.variant === 'compact') {
      labelClassName += ' compact'
    }
    if (props.label) {
        label = <div className={labelClassName}>{props.label}</div>
      if (props.hoverText) {
        infoIcon = <FontAwesomeIcon
          className={'info-btn'}
          icon={faInfoCircle}
          title={props.hoverText}
        />
      }
    }

    return (
      <div className={'label-row'}>
        {label}
        {infoIcon}
      </div>
    )
  }

  return (
    <div className={classList} style={props.style}>
      {getLabel()}


      <div className={'border-container'}>
        <NumericInput
          decimalPrecision={0}
          maxLength={9}
          className={''}
          placeholder={'0'}
          // min={0}
          value={value}
          ref={inputRef}
          onChange={(e: any) => {
            if (props.onChange) {
              props.onChange(e.target.value || 0)
              setValue(e.target.value || 0)
            }
          }}
          onBlur={(e: any) => {
            if (props.onBlur) {
              props.onBlur(e.target.value || 0)
              setValue(e.target.value || 0)
            }
          }}
          style={{
            // width: 65,
            border: 'none',
          }}
          />

        {/* Up/down buttons */}
        <div
          style={{
            color: 'white',
            position: 'absolute',
            top: 4,
            right: 2,
            display: 'flex',
            flexDirection: 'column'
          }}
          >
          <FontAwesomeIcon
            className='up-down-caret-button'
            icon={faCaretUp}
            onClick={() => {
              const newVal = Number(value) + 1
              setValue(newVal)
              if (props.onChange) {
                props.onChange(newVal)
              }
              if (props.onBlur) {
                props.onBlur(newVal)
              }
            }}
          />
          <FontAwesomeIcon
            className='up-down-caret-button'
            icon={faCaretDown}
            onClick={() => {
              if (value > 0) {
                const newVal = Number(value) - 1
                setValue(newVal)
                if (props.onChange) {
                  props.onChange(newVal)
                }
                if (props.onBlur) {
                  props.onBlur(newVal)
                }
              }
            }}
          />


        </div>
      </div>


    </div>
  )
}





