import { currentUser } from '../../../firebase'
import axios from 'axios'

import { getConfig } from '../../../config'
const config: any = getConfig()

import { get_empty_bars } from './get_empty_bars'



// For datasets we get directly from alpaca
export const loadAlpacaData = async (startDate: Date, endDate: Date, interval: string) => {
  const timeDiff = endDate.getTime() - startDate.getTime()
  const daysDiff = timeDiff / (1000 * 3600 * 24)

  let _period: string
  let _timeframe: string

  if (daysDiff <= 1) {
    _period = '1D'
    _timeframe = '1Min'
  } else if (daysDiff <= 7) {
    _period = '7D'
    _timeframe = '1H'
  } else if (daysDiff <= 30) {
    _period = '1M'
    _timeframe = '1D'
  } else if (daysDiff <= 180) {
    _period = '6M'
    _timeframe = '1D'
  } else if (daysDiff <= 365) {
    _period = '1A'
    _timeframe = '1D'
  } else {
    _period = '10A'
    _timeframe = '1D'
  }

  const token = await currentUser()?.getIdToken()
  const res = await axios.get(`${config.api_root_url}getAccountPortfolioHistory`, {
    params: {
      period: _period,
      timeframe: _timeframe,
      start: startDate.toISOString(),
      end: endDate.toISOString()
    },
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  // Unpack alpaca response
  const equity_arr: any[] = []
  const profit_loss_arr: any[] = []
  const pl_pct_arr: any[] = []
  for (let i = 0; i < res.data.timestamp.length; i++) {
    const timestamp = res.data.timestamp[i] * 1000
    const equity = res.data.equity[i]
    const profit_loss = res.data.profit_loss[i]
    const pl_pct = res.data.profit_loss_pct[i]
    equity_arr.push([timestamp, equity])
    profit_loss_arr.push([timestamp, profit_loss])
    pl_pct_arr.push([timestamp, pl_pct])
  }

  /* At this point we have data directly consumable by our chart, but it isn't mapped to the appropriate times */

  // Get empty bars and map what we've got onto them, for each dataset
  const emptyBars = get_empty_bars({
    timeframe: interval,
    limit: 1000,
    start: startDate,
    end: endDate
  })
  let eq = 0
  let pl = 0
  let pl_pct = 0
  let equity_arr_processed: any[] = []
  let profit_loss_arr_processed: any[] = []
  let pl_pct_arr_processed: any[] = []

  // For >= one day timeframe, no need for further processing
  if (['1d', '1w'].includes(interval)) {
    return {
      equity: equity_arr,
      profit_loss: profit_loss_arr,
      pl_pct: pl_pct_arr,
    }
  }

  // Find most recent value for each empty bar time
  for (let i = 0; i < emptyBars.length; i++) {
    const timestamp = new Date(emptyBars[i].t).getTime()
    const next_timestamp = emptyBars[i + 1] ? new Date(emptyBars[i + 1].t).getTime() : 0

    // Filter the datapoints that come after timestamp but before next_timestamp
    equity_arr.filter((log, i) => {
      const ts = log[0]
      const ret = ts >= timestamp && ts < next_timestamp
      if (ret) {
        eq = log[1]
        pl = profit_loss_arr[i][1]
        pl_pct = pl_pct_arr[i][1] || 0
      }
      return ret
    })

    equity_arr_processed.push([timestamp, eq])
    profit_loss_arr_processed.push([timestamp, pl])
    pl_pct_arr_processed.push([timestamp, pl_pct])
  }

  return {
    equity: equity_arr_processed,
    profit_loss: profit_loss_arr_processed,
    pl_pct: pl_pct_arr_processed,
  }
}