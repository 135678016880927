

export const compensate_for_stock_splits = (bars: any) => {
  if (bars.length < 2) return bars;

  const adjustedBars = [...bars];
  let splitFactor = 1;

  for (let i = adjustedBars.length - 1; i > 0; i--) {
    const currentBar = adjustedBars[i];
    const previousBar = adjustedBars[i - 1];

    // Detect potential split
    const priceDifference = previousBar.c / currentBar.o;
    if (priceDifference > 1.5 || priceDifference < 0.67) {
      // Potential split detected
      const newSplitFactor = Math.round(priceDifference);
      console.log(`Potential split detected at index ${i}, factor: ${newSplitFactor}`);

      // Adjust split factor
      splitFactor *= newSplitFactor;

      // Adjust all previous bars
      for (let j = 0; j < i; j++) {
        const bar = adjustedBars[j];
        bar.o /= newSplitFactor;
        bar.h /= newSplitFactor;
        bar.l /= newSplitFactor;
        bar.c /= newSplitFactor;
        if (bar.v) bar.v *= newSplitFactor; // Adjust volume if available
      }
    }
  }

  return adjustedBars;
};