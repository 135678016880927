/*
Given bars and other relevant parameters, determines start and end index to display.
*/

interface GetStartEndIndexParams {
  bars: any[]
  _startDate: any
  _endDate: any
}

interface GetStartEndIndexReturn {
  _startIndex: number
  _endIndex: number
  _startIndexAbs: number
  _endIndexAbs: number
  bars_present: boolean       // does the dataset contain the necessary dates
  close_to_end: boolean       // should loadAdditionalBars be called
}

export const get_start_end_index = (params: GetStartEndIndexParams): GetStartEndIndexReturn => {
  const {
    bars,
    _startDate,
    _endDate,
  } = params

  if (bars.length === 0) {
    return { _startIndex: 0, _endIndex: 0, _startIndexAbs: 0, _endIndexAbs: 0, bars_present: false, close_to_end: false }
  }

  // Otherwise, we need to find the indices corresponding to start and end dates
  let _startIndex = 0
  let _endIndex = 0
  let _startIndexAbs = 0
  let _endIndexAbs = bars.length - 1

  let have_start = false
  let have_end = false

  // Find _startIndex
  for (let i = 0; i < bars.length; i++) {
    const d = new Date(bars[i].t)
    if (d >= _startDate) {
      _startIndex = bars[i].relative_index
      _startIndexAbs = i
      have_start = true
      break
    }
  }

  // Find _endIndex
  for (let i = 0; i < bars.length; i++) {
    const d = new Date(bars[i].t)
    if (d >= _endDate) {
      _endIndex = bars[i].relative_index
      _endIndexAbs = i
      have_end = true
      break
    }
  }

  // (assuming that we're scrolling backwards, saves us worring about now)
  const bars_present = have_start // && have_end
  const close_to_end = _startIndexAbs <= 50

  return { _startIndex, _endIndex, _startIndexAbs, _endIndexAbs, bars_present, close_to_end }
}


// Get max and min
// interface PrepareSetYParams {
//   bars: any
//   startIndex: number
//   endIndex: number
// }
// interface MaxMinReturn {
//   maxPrice: number
//   minPrice: number
// }
// export const get_max_and_min = (params: PrepareSetYParams): MaxMinReturn => {
//   const {bars, startIndex, endIndex } = params

//   const visibleCandles: any[] = []
//   for (let i = 0; i < bars.length; i++) {
//     const candle = bars[i]
//     if (candle.relative_index >= startIndex && candle.relative_index <= endIndex) {
//       visibleCandles.push(candle)
//     }
//   }

//   // Get max and min prices
//   let maxPrice = Math.max(...visibleCandles.map((candle: any) => candle.h || candle.c))
//   let minPrice = Math.min(...visibleCandles.map((candle: any) => candle.l || candle.c))
//   if (visibleCandles.length === 0) {      // handling empty
//     maxPrice = 100
//     minPrice = 10
//   }

//   let margin = (maxPrice - minPrice) * 0.05
//   minPrice = minPrice - margin
//   maxPrice = maxPrice + margin

//   return { maxPrice, minPrice }
// }

interface MaxMinReturn {
  maxPrice: number
  minPrice: number
}
export const get_max_and_min = (bars: any): MaxMinReturn => {
  if (bars.length === 0) return {maxPrice: 100, minPrice: 10}

  let maxPrice = 0
  let minPrice = 0
  for (let i = 0; i < bars.length; i++) {
    const candle = bars[i]
    if (i === 0) {
      maxPrice = candle.h || candle.c || 0
      minPrice = candle.l || candle.c || 0
    } else {
      maxPrice = Math.max(maxPrice, (candle.h || candle.c))
      minPrice = Math.min(minPrice, (candle.l || candle.c))
    }
  }

  return {maxPrice, minPrice}
}