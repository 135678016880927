import React, { useState } from 'react'
import { LogsTab } from './LogsTab';
import { StateTab } from './StateTab';

const SUBTABS = [
  {display: 'Logs', value: 'logs'},
  {display: 'State', value: 'state'},
]

export const ActivityTab = () => {
  const [ subTab, setSubTab ] = useState('logs')

  let subTabContent: any = null
  switch (subTab) {
    case 'logs':
      subTabContent = <LogsTab />
      break
    case 'state':
      subTabContent = <StateTab />
      break
  }

  return (
    <div className='tab-parent'>
      <div className='tab-header'>
        <div>
          Activity
        </div>
      </div>
      <div className='subtab-header'>
        {SUBTABS.map((s) => {
          let className = 'subtab'
          if (s.value === subTab) {
            className += ' selected'
          }
          return (
            <div
              className={className}
              onClick={() => setSubTab(s.value)}
            >
              {s.display}
            </div>
          )
        })}
      </div>
      {subTabContent}
    </div>
  )
}