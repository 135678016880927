import axios  from 'axios'
import React, { useState } from 'react'
import { Modal } from '../reusable'
import { getConfig } from '../../config'
import { useAtom } from 'jotai'
import { openModalAtom, loggedInUserAtom } from '../../types/global_types'
import { currentUser, db } from '../../firebase'
import { EvaluationRow } from '../reusable/EvaluationRow'
import { FunctionObj } from '../../types/user_types'
import { ErrorModal } from './ErrorModal'
import { createEELog } from '../../logic/u'

const config = getConfig() as any

interface RunFunctionModalProps {
  functionObj: FunctionObj
}
export const RunFunctionModal = (props: RunFunctionModalProps) => {
  const {functionObj} = props

  const [user] = useAtom(loggedInUserAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [, setOpenModal] = useAtom(openModalAtom)

  if (!user || !functionObj) return null

  return (
    <Modal
      title={functionObj.isRunning ? 'Turn off' : `Turn on`}
      contents={[
        functionObj.isRunning ? <div>
          This operation will turn the function off.
          <br />
          <br />
          Do you want to proceed?
        </div> : <div>
          This operation will turn the function on, causing it to be evaluated when the market is open on your selected interval: {functionObj.triggerInterval}.
          <br />
          <br />
          Do you want to proceed?
        </div>
      ]}
      yesButtonText={functionObj.isRunning ? 'Turn off' : `Turn on`}
      isButtonLoading={buttonIsLoading}
      isDanger={true}
      twoStepConfirmation={true}
      onYes={ async () => {
        setButtonIsLoading(true)
        const new_val = !functionObj.isRunning
        const updatedFunctionObj: FunctionObj = {
          ...functionObj,
          isRunning: new_val,
          updatedAt: new Date()
        }
        let currentFunctions = user.functions || []
        const functionObjToUpdateIndex = currentFunctions.findIndex(f => f.id === functionObj.id)
        currentFunctions[functionObjToUpdateIndex] = updatedFunctionObj

        // Update global isRunning, used by scheduledTick
        let isRunning = user.isRunning
        if (new_val === true) {
          isRunning = true
          console.log('isRunning is on globally')
        } else {
          isRunning = currentFunctions.some(f => f.isRunning)
          if (!isRunning) console.log('turning off isRunning globally')
        }

        // Write user changes
        await db.collection('users').doc(user.uid).update({ functions: currentFunctions, isRunning })

        // Create EELog
        let log_message = `Function \`${functionObj.name}\` turned ON, interval is ${functionObj.triggerInterval}`
        if (new_val === false) {
          log_message = `Function \`${functionObj.name}\` turned OFF`
        }
        const console_output = [{className: 'log', text: log_message}]
        createEELog(db, user.uid, 'function_activation', console_output)
        setButtonIsLoading(false)
      }}
    />
  )
}