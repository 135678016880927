import '../SignUp.scss'
import React, { useState, useEffect } from 'react'
import { Input, Button, DropdownSelect } from '../../reusable'
import { db } from '../../../firebase'
import { loggedInUserAtom, openModalAtom } from '../../../types/global_types'
import {
  SignUpStage,
  AlpacaContextType,
} from '../../../types/user_types'
import { useAtom } from 'jotai'
import {
  usStates,
} from '../../../logic/u'
import { ErrorModal } from '../../modals/ErrorModal'

export const DisclosuresAffiliatedExchangeOrFinraStep = () => {
  const [loggedInUser] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)

  // This signup stage
  const context_type: AlpacaContextType = 'AFFILIATE_FIRM'
  const [isAffiliatedExchangeOrFinra, setIsAffiliatedExchangeOrFinra] = useState<boolean | undefined>(loggedInUser?.alpacaObject?.disclosures?.is_affiliated_exchange_or_finra)
  const [companyName, setCompanyName] = useState<string>(loggedInUser?.alpacaObject?.disclosures?.context?.find(context => context?.context_type === context_type)?.company_name || '')
  const [streetAddress, setStreetAddress] = useState<string>(loggedInUser?.alpacaObject?.disclosures?.context?.find(context => context?.context_type === context_type)?.company_street_address?.[0] || '')
  const [city, setCity] = useState<string>(loggedInUser?.alpacaObject?.disclosures?.context?.find(context => context?.context_type === context_type)?.company_city || '')
  const [state, set_state] = useState<string>(loggedInUser?.alpacaObject?.disclosures?.context?.find(context => context?.context_type === context_type)?.company_state || '')
  const [country, setCountry] = useState<string>(loggedInUser?.alpacaObject?.disclosures?.context?.find(context => context?.context_type === context_type)?.company_country || '')
  const [companyComplianceEmail, setCompanyComplianceEmail] = useState<string>(loggedInUser?.alpacaObject?.disclosures?.context?.find(context => context?.context_type === context_type)?.company_compliance_email || '')

  const previousSignUpStage: SignUpStage = 'FinancialProfile'
  const nextSignUpStage: SignUpStage = 'DisclosuresControlPerson'

  useEffect(() => {
    if (isAffiliatedExchangeOrFinra) {
      setButtonEnabled(!!companyName && !!streetAddress && !!city && !!state && !!country && !!companyComplianceEmail);
    }
  }, [companyName, streetAddress, city, state, country, companyComplianceEmail, isAffiliatedExchangeOrFinra]);

  useEffect(() => {
    if (!isAffiliatedExchangeOrFinra && isAffiliatedExchangeOrFinra !== undefined && isAffiliatedExchangeOrFinra !== null) {
      setButtonEnabled(true);
    }
  }, [isAffiliatedExchangeOrFinra]);

  if (!loggedInUser) return null

  return (
    <div className='signup-container'>
      <div className='signup-form-container'>
        <div className='signup-context-title'>
          Disclosures (1 of 4)
        </div>
        <DropdownSelect
          label={'Are you or an immediate family member affiliated with or employed by a stock exchange, regulatory body, member firm of an exchange, FINRA or a municipal securities broker-dealer?'}
          options={[
            {display: 'Yes', value: true},
            {display: 'No', value: false},
          ]}
          className={'margin-top-15'}
          width={494}
          height={32}
          value={isAffiliatedExchangeOrFinra}
          onChange={(val) => {setIsAffiliatedExchangeOrFinra(val)}}
        />
        {!isAffiliatedExchangeOrFinra ? null : (
          <div className='column width-full'>
            <Input
              label={'Company name'}
              type={'text'}
              className={'margin-top-20'}
              style={{width: '100%'}}
              initialValue={companyName}
              onChange={(val) => {setCompanyName(val)}}
            />
            <Input
              label={'Street address'}
              type={'text'}
              className={'margin-top-20'}
              style={{width: '100%'}}
              initialValue={streetAddress}
              onChange={(val) => {setStreetAddress(val)}}
            />
            <div className='input-container'>
              <Input
                label={'City'}
                type={'text'}
                className={'margin-top-20'}
                style={{width: 160}}
                initialValue={city}
                onChange={(val) => {setCity(val)}}
              />
              <DropdownSelect
                label={'State'}
                options={usStates}
                className={'margin-top-20'}
                width={154}
                height={32}
                value={state}
                onChange={(val) => {set_state(val)}}
              />
              <DropdownSelect
                label={'Country'}
                options={[{display: 'USA', value: 'USA'}]}
                className={'margin-top-20'}
                width={154}
                height={32}
                value={country}
                onChange={(val) => {setCountry(val)}}
              />
            </div>
            <Input
              label={'Company compliance email'}
              type={'email'}
              className={'margin-top-20'}
              style={{width: '100%'}}
              initialValue={companyComplianceEmail}
              onChange={(val) => {setCompanyComplianceEmail(val)}}
            />
          </div>
        )}
        <Button
          display={'Next'}
          className={'margin-top-25'}
          style={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
          isDisabled={!buttonEnabled || buttonIsLoading}
          isLoading={buttonIsLoading}
          onClick= {async () => {
            try {
              setButtonIsLoading(true)
              const contextArray = loggedInUser.alpacaObject?.disclosures?.context || []
              const newContextObj = {
                context_type: context_type,
                company_name: companyName,
                company_street_address: [streetAddress],
                company_city: city,
                company_state: state,
                company_country: country,
                company_compliance_email: companyComplianceEmail
              }

              const foundIndex = contextArray.findIndex(contextObj => contextObj.context_type === context_type)
              if (foundIndex !== -1) {
                contextArray.splice(foundIndex, 1, newContextObj)
              } else {
                contextArray.push(newContextObj)
              }

              await db.collection('users').doc(loggedInUser.uid).update({
                'alpacaObject.disclosures.is_affiliated_exchange_or_finra': isAffiliatedExchangeOrFinra,
                'alpacaObject.disclosures.context': isAffiliatedExchangeOrFinra ? contextArray : null,
                signUpStage: nextSignUpStage
              })
              setButtonIsLoading(false)
            } catch (err) {
              setButtonIsLoading(false)
              setOpenModal( <ErrorModal errorMessage={err.message}/> )
            }
          }}
        />
        <Button
          display={'Back'}
          className={'margin-top-15'}
          variant={'secondary'}
          style={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
          onClick= {async () => { await db.collection('users').doc(loggedInUser.uid).update({signUpStage: previousSignUpStage})}}
        />
      </div>
    </div>
  )

}