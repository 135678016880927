import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Modal } from '../reusable'
import { updateLiveData, uuid } from '../../logic/u'
import { loggedInUserAtom, openModalAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { FunctionObj } from '../../types/user_types'
import { currentUser, db } from '../../firebase'
import { getConfig } from '../../config'
import axios from 'axios'
import { TickerModal } from './TickerModal'
const config = getConfig() as any


interface ClosePositionModalProps {
  symbol: string
  showBack?: boolean
}

export const ClosePositionModal = (props: ClosePositionModalProps) => {    
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const {symbol} = props

  if (!user) return null

  return (
    <Modal
      title={'Close position'}
      contents={[
        <div className='column width-full font-size-13 orange-text'>
          <div className='row'>
            Are you sure you want to close your {symbol} position?
          </div>
        </div>
      ]}
      yesButtonText={'Submit'}
      isDanger
      isButtonLoading={buttonIsLoading}
      twoStepConfirmation={true}
      backIconOnClick={props.showBack ? () => setOpenModal(props.symbol ? <TickerModal symbol={props.symbol} /> : null) : null}
      onYes={ async () => {
        try {
          setButtonIsLoading(true)
          const token = await currentUser()?.getIdToken()
          const closePositionUrl = `${config.api_root_url}closePosition?symbol=${symbol}`
          await axios.delete(closePositionUrl, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          updateLiveData(user)
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

