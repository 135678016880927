/*
DANGER: TOUCH THIS FILE AT YOUR PERIL

Many hours have been wasted here. Do not upgrade to v9.
*/

// import { toHaveFocus } from "@testing-library/jest-dom/dist/matchers";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth"

import { getConfig } from './config'
const config = getConfig()


// const { initializeApp, applicationDefault, cert } = require('firebase-admin/app');
// const { getFirestore, Timestamp, FieldValue } = require('firebase-admin/firestore');

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-liimage.pngbraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//https://firebase.google.com/docs/web/setup?authuser=1&hl=en
// const firebaseConfig = {
//   apiKey: "AIzaSyDAfEAK85rfagUiHkQhqZuP0W03yQ4wW6M",
//   authDomain: "tickerbot-14f5c.firebaseapp.com",
//   projectId: "tickerbot-14f5c",
//   storageBucket: "tickerbot-14f5c.appspot.com",
//   messagingSenderId: "1048458062050",
//   appId: "1:1048458062050:web:40ee1d0394093b3af9d6bb",
//   measurementId: "G-FCX3KMPJNT"
// };


const firebaseConfig = config.firebase_config

console.log('database initializing')


// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
const auth = firebase.auth()

export const getFirebase = () => {
  return firebase
}


export const createUserWithEmailAndPassword = async ({email, password}) => {
  let userCred
  try {
    // const defaultAuth = firebase.auth();
    userCred = await auth.createUserWithEmailAndPassword(email, password)

    await userCred.user.sendEmailVerification({
      // url: 'https://tickerbot.io'
      // url: 'https://localhost:3000'
      url: 'https://' + window.location.host
    })
  } catch(err) {
    console.log('ERROR: failed creating user auth entry')
    logout()
    return {error: err.message}
  }

  try {
    await createUserDoc()
    return {user: userCred.user}
  } catch(err) {
    console.log('ERROR: failed creating user doc')
    logout()
    return {error: err.message}
  }
}




export const signInUserWithEmailAndPassword = async ({email, password}) => {
  try {
    const defaultAuth = firebase.auth();
    const userCred = await defaultAuth.signInWithEmailAndPassword(email, password)
    return {user: userCred.user}
  } catch(err) {
    return {error: err.message}
  }
}

export const resetPassword = async({email}) => {
  try {
      await firebase.auth().sendPasswordResetEmail(email, {
        url: `${config.hostname}/login`
      })
  } catch(err) {
    return {error: err.message}
  }
}

export const deleteAccount = async() => {
  try {
    const user = firebase.auth().currentUser
    await user.delete()
  } catch(err) {
    return {error: err.message}
  }
}

export const updatePassword = async({newPassword}) => {
  try {
    await firebase.auth().currentUser.updatePassword(newPassword)
  } catch(err) {
    return {error: err.message}
  }
}


export const logout = async () => {
  try {
    const defaultAuth = firebase.auth();
    await defaultAuth.signOut()
  } catch (err) {
    console.log('error logging out', err)
  }
  // await firebase.auth().signOut()
}

export const currentUser = () => {
  try {
    return firebase.auth().currentUser
  } catch(err) {
    return null
  }
}

// User document is separate from auth entry
export const createUserDoc = async () => {
  const user = firebase.auth().currentUser
  firebase.firestore().collection('users').doc(user.uid).set({
    email: user.email,
    uid: user.uid,
    createdAt: firebase.firestore.FieldValue.serverTimestamp()
  })
  return user
}

export const getFullUserDoc = async () => {
  const userId = currentUser().uid
  const userRef = firebase.firestore().collection('users').doc(userId)
  const snapshot = await userRef.get()
  return snapshot.data()
}


// TODO For development purposes
export const authFunctionsOnWindow = () => {
  window.createUserWithEmailAndPassword = createUserWithEmailAndPassword
  window.signInUserWithEmailAndPassword = signInUserWithEmailAndPassword
  window.logout = logout
  window.deleteAccount = deleteAccount
  window.resetPassword = resetPassword
  window.updatePassword = updatePassword
  window.currentUser = currentUser
  window.createUserDoc = createUserDoc
  window.deleteAlgos = deleteAlgos
}

/* NUCLEAR OPTION FOR DEVELOPMENT */
export const deleteAlgos = async () => {
  // const algos = await db.collection('algos').get()
  // algos.forEach(algo => {
  //   db.collection('algos').doc(algo.id).delete()
  //   console.log('deleted ' + algo.id)
  // })

  const couplets = await db.collection('couplets').get()
  couplets.forEach(couplet => {
    db.collection('couplets').doc(couplet.id).delete()
    console.log('deleted couplet: ' + couplet.id)
  })
}








// //Algo data structure: [collection]algo -> [doc]algo -> [collection]couplets -> [doc]couplet -> [collection]actions + [field]conditions
// export async function GetAllAlgos(){
//   var list = []
//   db.collection("algos")
//   .withConverter(AlgoConverter)
//   .get()
//   .then((querySnapshot) => {
//     querySnapshot.forEach((doc) => {
//       var algo = doc.data()
//       list.push(algo)
//     })
//   })

//   return list
// }

// export function GetAlgo(id){
//   return db.collection('algos').doc(id).get()
// }

// export async function QueryFilteredAlgos(filters) {
//   var query = db.collection("algos")

//   if(filters.userID !== "Any"){
//     query = query.where('userID', '==', filters.userID)
//   }

//   if(filters.charts !== "Any"){
//     query = query.where('charts',"array-contains",filters.charts)
//   }

//   const querySnapshot = await query.withConverter(AlgoConverter).get()
//   const list = querySnapshot.docs.map((doc) => doc.data())
//   return list

// }

// //Algo data structure: [collection]algo -> [doc]algo+[field]charts+[field]userID -> [collection]couplets -> [doc]couplet -> [collection]actions + [field]conditions
// export class Algo {
//   constructor (data) {
//     this.id = data.id
//     this.userID = data.userID
//     this.charts = data.charts
//     this.couplets = data.couplets
//   }
//   toString() {
//     return 'ID:'+this.id+' , userID:'+ this.userID + ' , charts:'+this.charts+ ' , couplets:'+this.couplets
//   }

// }
// export const AlgoConverter = {
//   toFirestore: (algo) => {
//     return {
//       id: algo.id,
//       userID: algo.userID,
//       charts: algo.charts,
//       couplets: algo.couplets
//     }
//   },
//   fromFirestore: (snapshot, options)=>{
//     const data = snapshot.data(options)
//     var path = snapshot.ref.path

//     //convert couplets collection
//     var couplets = []
//     db.collection(path+'/couplets')
//     .withConverter(CoupletConverter)
//     .get().then((querySnapshot) => {
//       querySnapshot.forEach((doc) => {
//         var couplet = doc.data()
//         couplets.push(couplet)
//       })
//     })

//     return new Algo({id:snapshot.id, userID: data.userID, charts:data.charts, couplets:couplets})
//   }
// }

// export class Couplet {
//   constructor (data) {
//     this.id = data.id
//     this.actions = data.actions
//     this.conditions = data.conditions
//   }
//   toString() {
//     return "actions:"+this.actions + ' , conditions:'+this.conditions
//   }
// }
// const CoupletConverter = {
//   toFirestore: (couplet) => {
//     return {
//       actions: couplet.actions,
//       conditions: couplet.conditions
//     }
//   },
//   fromFirestore: (snapshot, options)=>{
//     const data = snapshot.data(options)
//     var path = snapshot.ref.path

//     //convert actions collectins
//     var actions = []
//     db.collection(path+'/actions').
//     withConverter(ActionConverter)
//     .get().then((querySnapshot) => {
//       querySnapshot.forEach((doc) => {
//         var action = doc.data()
//         console.log("action converter:",action)
//         actions.push(action)
//       })
//     })

//     return new Couplet({id:snapshot.id, actions:actions, conditions: data.Collections})
//   }
// }
// export class Action{
//   constructor (data) {
//     this.type = data.type
//     this.amount = data.amount
//     this.orderID = data.orderID
//     this.symbol = data.symbol
//   }
//   toString() {
//     return "type:"+this.type + ' , amount:'+this.amount + ' , orderID:'+this.orderID + ' , symbol:',this.symbol
//   }
//   toJson() {
//     var js = {type: this.type}
//     if (this.amount!=undefined && this.symbol != undefined) {
//       js.amount = this.amount
//       js.symbol = this.symbol
//     }
//     if (this.orderID != undefined) {
//       js.orderID = this.orderID
//     }
//     return js
//   }

// }

// const ActionConverter = {
//   toFirestore: (action) => {
//     return {
//       type: action.type,
//       amount: action.amount,
//       orderID: action.orderID,
//       symbol: action.symbol
//     }
//   },
//   fromFirestore: (snapshot, options)=>{
//     const data = snapshot.data(options)
//     var path = snapshot.ref.path
//     return new Action({id: data.id, type:data.type, symbol:data.symbol, amount:data.amount, order:data.orderID})
//   }
// }

// // export function CreateAlgo(algo){
// //   console.log("CreateAlgo is called")
// //   //TODO: validation rules

// //   //create algo without
// //   addDoc(db, "algos",{userID: algo.userID, charts: algo.charts})
// //   .then((algoRef) => {
// //     console.log('algoid:',algoRef.id)
// //     algo.couplets.forEach((couplet) => {
// //       addDoc(algoRef,"couplets",{conditions:couplet.conditions})
// //       .then((coupletRef) => {
// //         couplet.actions.forEach((action) => {
// //           addDoc(coupletRef,"actions",action.toJson())
// //         })
// //       })
// //     })
// //   })
// // }

// export async function CreateAlgo(algo){
//   console.log("CreateAlgo is called")
//   //TODO: validation rules

//   //create algo without
//   const algoRef = await addDoc(db, "algos",{userID: algo.userID, charts: algo.charts})
//   console.log('algoid:',algoRef.id)
//   algo.couplets.forEach((couplet) => {
//     addDoc(algoRef,"couplets",{conditions:couplet.conditions})
//     .then((coupletRef) => {
//       couplet.actions.forEach((action) => {
//         addDoc(coupletRef,"actions",action.toJson())
//       })
//     })
//   })

// }
// function addDoc(ref, collectionName, dataJson={}) {
//   return ref.collection(collectionName).add(dataJson)
// }
// export function update(){}




/*
USING FIREBASE - EXAMPLES

// Get all docs in a collection
const colDocs = await db.collection('algos').get()
const col = expDocs.docs.map(doc => doc.data());

ALSO: see App.js -> subscribeToAlgoUpdates

// Get one doc
const algoDoc = await db.collection('algos').doc(algoId).get()
const algo = await algoDoc.data()

// Get one doc, subscribe to updates
await db.collection('users').doc(userId).onSnapshot((doc) => {
  const userData = doc.data()
  userData.id = doc.id
  this.setGlobal({loggedInUser: userData})
})

// Get all docs in a subordinate collection
const coupletDocs = await db.collection('algos').doc(algoId).collection('couplets').get()
const couplet = expDocs.docs.map(doc => doc.data());

// Set totality of a doc
await db.collection('algos')
    .doc(this.couplet.algoId)
    .collection('couplets')
    .doc(this.couplet.id)
    .set({newStuff: 'here'})

// Set a doc, specifying the id
firebase.firestore().collection('users').doc(user.email).set({
    email: user.email,
    createdAt: firebase.firestore.FieldValue.serverTimestamp()
  })

// Create a new doc in a collection
await db.collection('algos')
  .doc(this.global.loadedAlgo.id)
  .collection('backtests')
  .add

// Update a doc
await db.collection('algos')
    .doc(this.couplet.algoId)
    .collection('couplets')
    .doc(this.couplet.id)
    .update({newStuff: 'here'})

// Add a doc to a collection
await db.collection('algos')
.doc(this.algo.id)
.collection('couplets')
.add({
  title: 'New condition',
  conditions: [],
  actions: []
})


*/
