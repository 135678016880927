import { User } from '../types/user_types'
import { currentUser } from '../firebase'
import { getConfig } from '../config'
import axios from 'axios'

const config = getConfig()

interface RunFunctionOnceProps {
  user: User
  code: string
  liveData: any
  consoleOutput: any
  setConsoleOutput: any
  setFunctionExecuting: any
  function_id?: string
  function_name?: string
  simulated_mode: boolean
  cancelToken: any  // Pass the cancel token
}

export const run_function_once = async (params: RunFunctionOnceProps) => {
  const {
    user,
    code,
    liveData,
    setConsoleOutput,
    setFunctionExecuting,
    function_id,
    function_name,
    simulated_mode,
    cancelToken,
  } = params;

  if (!config) throw new Error('Config not found');

  const url = `${config.api_root_url}executeUserCode`;
  const token = await currentUser()?.getIdToken();
  const alpacaId = user?.alpacaObject.id;

  console.log("Running function with cancellation token:", cancelToken);

  // Set initial console output to notify start
  setFunctionExecuting(true)
  let placeholder = {className: 'summary', text: `Running ${function_name}...`}
  if (simulated_mode) {
    placeholder = {
      className: 'summary',
      text: `Running ${function_name} [simulated mode]...`
    }
  }

  setConsoleOutput((prev: any[]) => Array.isArray(prev) ? [...prev, placeholder] : [placeholder]);

  try {
    const res = await axios.post(url, {
      code,
      liveData,
      alpacaId,
      function_id,
      function_name,
      is_directly_called: true,
      simulated_mode
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      cancelToken: cancelToken
    });


    // HACK - if it was canceled, don't add the result
    //@ts-ignore
    if (window.functionCanceled) {
      //@ts-ignore
      window.functionCanceled = false
      return
    }

    if (Array.isArray(res.data.console_output)) {
      setConsoleOutput((prev: any[]) => Array.isArray(prev) ? [...prev, ...res.data.console_output] : [...res.data.console_output]);
      console.log(`Console output updated: ${res.data.console_output.length} items`);
    }

    setFunctionExecuting(false)
    return res.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      console.log('Request canceled', err.message);
      setConsoleOutput((prev: any[]) => Array.isArray(prev) ? [...prev, {className: 'err', text: 'Function execution canceled.'}] : [{className: 'err', text: 'Function execution canceled.'}]);
    } else {
      console.log(`Failed running code: ${err}`);
      setConsoleOutput((prev: any[]) => Array.isArray(prev) ? [...prev, {className: 'err', text: 'UNEXPECTED ERROR'}] : [{className: 'err', text: 'UNEXPECTED ERROR'}]);
    }
    setFunctionExecuting(false);
    return { console_output: [{className: 'err', text: 'UNEXPECTED ERROR'}] };
  }
};
