import React, { useState } from 'react'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  ordersAtom,
} from '../../types/global_types'
import { useAtom } from 'jotai'
import { BORDER_INPUT, SECOND_SURFACE, TEXT_GRAY_LIGHT, formatDateForTable, formatNumberForTable, handleMUISorting, tableSX, valueForTable, roundToSigFigs } from '../../logic/u'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { TickerDropdown } from '../dropdowns/TickerDropdown'
import { Icon } from '../reusable'
import { CreateOrderModal } from './CreateOrderModal'
import { ClosePositionModal } from './ClosePositionModal'
import { CancelOpenOrderModal } from './CancelOpenOrderModal'

const CustomNoRowsOverlay = () => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: TEXT_GRAY_LIGHT
  }}>
    <p>No orders associated with this ticker</p>
  </div>
);

interface TickerModalProps {
  symbol: string
  orderId?: string
}

export const TickerModal = (props: TickerModalProps) => {
  const [ user ] = useAtom(loggedInUserAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)
  const [orders] = useAtom(ordersAtom)

  const { symbol, orderId } = props

  const isPosition = Object.keys(uld?.liveData.POSITIONS).includes(symbol)

  const orderRows = orders?.unsorted.filter(o => o.symbol === symbol)

  const handleContextMenuClick = (event: any, params: any, openLeft?: boolean) => {
    event.preventDefault()
    const width = 220
    const clickX = openLeft? event.clientX - (width + 5) : event.clientX + 5
    const clickY = event.clientY - 10
    const symbol = params.row.symbol
    setOpenDropdown(
      <TickerDropdown
        symbol={symbol}
        left={clickX}
        top={clickY}
        width={width}
        onClose={() => setOpenDropdown(null)}
        orderObj={params.row}
        fromTable={true}
      />
    )
  }

  if (!user || !uld) return null

  return (
    <>
      <div
        className='modal-scrim'
        onClick={() => {
          setOpenModal(null)
        }}
      />      
      <div
        className='modal'
        style={{
          height: 675,
          width: 1000,
          top: 60,
          left: 'calc(50vw - 500px)'
        }}
      >
        {openDropdown}
        <div className='top'>
          <div className='title-row'>
            <div className='row'>
              <div>
                {symbol}
              </div>
              <div
                className='row font-size-10'
                style={{
                  marginTop: 6.5,
                  marginLeft: 25,
                  color: TEXT_GRAY_LIGHT
                }}
              >
                <div>
                  {uld.liveData[symbol].price} current_price
                </div>
                {isPosition && <>
                  <div style={{marginLeft: 5, marginRight: 5}} >•</div>
                  <div>
                    {uld.liveData[symbol].avg_entry_price} avg_entry_price
                  </div>
                </>}
              </div>
            </div>
            <div className='row font-size-10'>              
              <Icon
                hoverText='Buy'
                icon='circle-arrow-up'
                set='sharp-solid'
                size={13}
                style={{marginRight: 14, marginTop: 3}}
                onClick={() => {
                  setOpenModal(<CreateOrderModal symbol={symbol} showBack={true} orderId={orderId}  />)
                }}
              /> 
              <Icon
                hoverText='Sell'
                icon='circle-arrow-down'
                set='sharp-solid'
                size={13}
                style={{marginRight: 15, marginTop: 3}}
                onClick={() => {
                  setOpenModal(<CreateOrderModal symbol={symbol} sell={true} showBack={true} orderId={orderId} />)                  
                }}
              /> 
              <Icon
                hoverText='Close position'
                icon='folder-closed'
                set='sharp-solid'
                size={13}
                style={{marginRight: 15, marginTop: 3}}
                onClick={() => {
                  setOpenModal(<ClosePositionModal symbol={symbol} showBack={true}/>)                  
                }}
              /> 
              <Icon
                hoverText='Cancel all open orders'
                icon='octagon-minus'
                set='sharp-solid'
                size={13}
                style={{marginRight: 0, marginTop: 3}}
                onClick={() => {
                  setOpenModal(<CancelOpenOrderModal symbol={symbol} showBack={true} />)
                }}
              />               
            </div>
          </div>
          <div className='modal-body'>
            <div className='white-text bold margin-bottom-10'>Position</div>
            <div className='summary-table modal-version'>
              <div className='column'>
                <div>
                  qty
                </div>
                <div
                  style={{
                    fontWeight: 400,
                    marginTop: 10
                  }}
                >
                  {valueForTable('qty', uld.liveData[symbol].qty)}
                </div>
              </div>
              <div className='row'>
                <div className='summary-bit modal-version'>
                  <div>cost_basis</div>
                  <div className='val-thing'>{valueForTable('cost_basis', uld.liveData[symbol].cost_basis)}</div>
                </div>
                <div className='summary-bit modal-version'>
                  <div>mkt_value</div>
                  <div className='val-thing'>{valueForTable('mkt_value', uld.liveData[symbol].mkt_value)}</div>
                </div>
                <div className='summary-bit modal-version'>
                  <div>unrealized P/L since yesterday</div>
                  <div className='val-thing'>
                    <div className=''>{valueForTable('day_unrealized_pl', uld.liveData[symbol].day_unrealized_pl)}</div>
                    <div className=''>{`[${valueForTable('day_unrealized_pl_pc', uld.liveData[symbol].day_unrealized_pl_pc)}]`}</div>
                  </div>
                </div>
                <div className='summary-bit modal-version last-thing'>
                  <div>overall unrealized P/L</div>
                  <div className='val-thing'>
                    <div className=''>{valueForTable('unrealized_pl', uld.liveData[symbol].unrealized_pl)}</div>
                    <div className=''>{`[${valueForTable('unrealized_pl_pc', uld.liveData[symbol].unrealized_pl_pc)}]`}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='margin-top-20 white-text bold margin-bottom-10'>Orders</div>
            <div className='table-container' style={{height: 400}}>
              <DataGridPro
                className='uppercase-grid'
                rows={orderRows}
                columns={[
                  {
                    field: 'side',
                    headerName: 'side',
                    width: 60,
                    type: 'string',
                    sortComparator: handleMUISorting,
                    renderCell: (params) => (
                      <div
                        style={{
                          width: '100%',
                          padding: 10,
                        }}
                        onContextMenu={(event) => {
                          handleContextMenuClick(event, params)
                        }}
                      >
                        {params.row.side}
                      </div>
                    )
                  },
                  {
                    field: 'type',
                    headerName: 'type',
                    width: 80,
                    // headerAlign: 'right',
                    // align: 'right',
                    type: 'string',
                    sortComparator: handleMUISorting,
                    renderCell: (params) => (
                      <div
                        style={{
                          width: '100%',
                          padding: 10,
                          // textAlign: 'right'
                        }}
                        onContextMenu={(event) => {
                          handleContextMenuClick(event, params)
                        }}
                      >
                        {params.row.type}
                      </div>
                    )
                  },
                  {
                    field: 'notional',
                    headerName: 'notional',
                    width: 110,
                    headerAlign: 'right',
                    align: 'right',
                    type: 'number',
                    sortComparator: handleMUISorting,
                    renderCell: (params) => (
                      <div
                        style={{
                          width: '100%',
                          padding: 10,
                          textAlign: 'right'
                        }}
                        onContextMenu={(event) => {
                          handleContextMenuClick(event, params)
                        }}
                      >
                        {formatNumberForTable((params.row.notional))}
                      </div>
                    )
                  },
                  {
                    field: 'qty',
                    headerName: 'qty',
                    width: 110,
                    headerAlign: 'right',
                    align: 'right',
                    type: 'number',
                    sortComparator: handleMUISorting,
                    renderCell: (params) => (
                      <div
                        style={{
                          width: '100%',
                          padding: 10,
                          textAlign: 'right'
                        }}
                        onContextMenu={(event) => {
                          handleContextMenuClick(event, params)
                        }}
                      >
                        {formatNumberForTable(params.row.qty)}
                      </div>
                    )
                  },
                  {
                    field: 'filled_qty',
                    headerName: 'filled_qty',
                    width: 110,
                    headerAlign: 'right',
                    align: 'right',
                    type: 'number',
                    sortComparator: handleMUISorting,
                    renderCell: (params) => {
                      const symbol = params.row.symbol
                      const stock_price = uld.liveData[symbol].current_price || 200
                      return <div
                        style={{
                          width: '100%',
                          padding: 10,
                          textAlign: 'right'
                        }}
                        onContextMenu={(event) => {
                          handleContextMenuClick(event, params)
                        }}
                      >
                        {roundToSigFigs(params.row.filled_qty, stock_price)}
                      </div>
                    }
                  },
                  {
                    field: 'filled_avg_price',
                    headerName: 'filled_avg_price',
                    flex: 1,
                    headerAlign: 'right',
                    align: 'right',
                    type: 'number',
                    sortComparator: handleMUISorting,
                    renderCell: (params) => (
                      <div
                        style={{
                          width: '100%',
                          padding: 10,
                          textAlign: 'right'
                        }}
                        onContextMenu={(event) => {
                          handleContextMenuClick(event, params)
                        }}
                      >
                        {formatNumberForTable(params.row.filled_avg_price)}
                      </div>
                    )
                  },
                  {
                    field: 'submitted_at',
                    headerName: 'submitted_at',
                    width: 155,
                    headerAlign: 'right',
                    align: 'right',
                    type: 'date',
                    sortComparator: handleMUISorting,
                    renderCell: (params) => (
                      <div
                        style={{
                          width: '100%',
                          padding: 10,
                          textAlign: 'right'
                        }}
                        onContextMenu={(event) => {
                          handleContextMenuClick(event, params)
                        }}
                      >
                        {formatDateForTable(params.row.submitted_at)}
                      </div>
                    )
                  },
                  {
                    field: 'filled_at',
                    headerName: 'filled_at',
                    width: 155,
                    headerAlign: 'right',
                    align: 'right',
                    type: 'date',
                    sortComparator: handleMUISorting,
                    renderCell: (params) => (
                      <div
                        style={{
                          width: '100%',
                          padding: 10,
                          textAlign: 'right'
                        }}
                        onContextMenu={(event) => {
                          handleContextMenuClick(event, params)
                        }}
                      >
                        {formatDateForTable(params.row.filled_at)}
                      </div>
                    )
                  },
                  {
                    field: 'status',
                    headerName: 'status',
                    width: 80,
                    headerAlign: 'right',
                    align: 'right',
                    type: 'string',
                    sortComparator: handleMUISorting,
                    renderCell: (params) => (
                      <div
                        style={{
                          width: '100%',
                          padding: 10,
                          textAlign: 'right'
                        }}
                        onContextMenu={(event) => {
                          handleContextMenuClick(event, params, true)
                        }}
                      >
                        {params.row.status}
                      </div>
                    )
                  },
                ]}
                disableColumnResize
                disableColumnMenu
                disableColumnPinning
                disableSelectionOnClick
                disableColumnReorder
                density='compact'
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                sx={{
                  ...tableSX,
                  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                    p: '0px',
                    borderBottom: `1px solid ${BORDER_INPUT}`
                  },
                  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-columnHeaders': {
                    color: TEXT_GRAY_LIGHT,
                    backgroundColor: SECOND_SURFACE,
                    borderBottom: `2px solid ${BORDER_INPUT}`,
                  },
                  '&.MuiDataGrid-root--densityCompact': {
                    border: `2px solid ${BORDER_INPUT}`,
                    borderRadius: 0
                  },
                  '&.MuiDataGrid-root .MuiDataGrid-iconSeparator': {
                    color: TEXT_GRAY_LIGHT
                  },
                  '&.MuiDataGrid-root': {
                    color: TEXT_GRAY_LIGHT,
                  },
                  '&.MuiDataGrid-root .MuiDataGrid-footerContainer': {
                    borderTop: `1px solid ${BORDER_INPUT}`
                  },
                  '& .MuiDataGrid-overlay': {
                    backgroundColor: SECOND_SURFACE,
                  },                  
                }}                
                localeText={{
                  footerTotalRows: 'Total orders:',
                }}
                getRowClassName={(params) => 
                  params.row.id === orderId ? 'selected-modal-table-row' : ''
                }
              />
            </div>
            <div className='margin-top-20 white-text bold' style={{marginBottom: 6}}>Watchlists</div>
            <div className='row gray-text-light'>
              {user.watchlists.filter(w => w.tickers.includes(symbol)).map((watchlist, i, filteredWatchlists) => {
                const isLastItem = i === filteredWatchlists.length - 1
                return <>
                  <div className='margin-right-10'>{watchlist.name}</div>
                  {!isLastItem ? <div className='margin-right-10'>•</div> : null}
                </>
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}