import React from 'react';
import { BACKGROUND_HOVER, GREEN, PRIMARY } from '../../logic/u';
import { ORANGE } from '../../logic/colors';

interface ProgressBarProps {
  progressPercent: number; // Progress value between 0 and 100
  width?: number
  style?: any
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ progressPercent, width, style }) => {
  const progressStyle = {
    width: `${progressPercent}%`,
    backgroundColor: ORANGE,
    height: '100%',
    transition: 'width 0.3s ease-in-out',
  };

  return (
    <div style={{ width: width ? width: 100, border: `1px solid ${ORANGE}`, height: 9, ...style}}>
      <div style={progressStyle}></div>
    </div>
  );
};

export default ProgressBar;
