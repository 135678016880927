/*
Login page
*/

import './SignUp.scss'
import React, { useState, useEffect } from 'react'
import { Input, Button } from '../reusable'
import { ErrorModal } from '../modals/ErrorModal'
import { signInUserWithEmailAndPassword, currentUser } from '../../firebase'
import { appTabAtom, loggedOutTabAtom, openModalAtom } from '../../types/global_types'
import { useAtom } from 'jotai'

interface LoginProps {
  subscribeToUserUpdates: (string) => void
}

export function Login(props: LoginProps) {
  const [, setLoggedOutTab] = useAtom(loggedOutTabAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [appTab, setAppTab] = useAtom(appTabAtom)


  // Login form
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)

  useEffect(() => {
    setButtonEnabled(!!email && !!password);
  }, [email, password]);

  return (
    <div className='logged-out-screen'>
      <div className='signup-container'>
        <div className='signup-form-container'>
          <div className='signup-context-title text-align-left'>
            Log in to TickberBot
          </div>
          <div className='signup-context-subtitle text-align-left'>
            Welcome back.
          </div>
          <Input
            label={'Email'}
            type={'email'}
            // placeholder={'john.doe@example.com'}
            style={{width: '100%'}}
            onChange={(val) => {setEmail(val)}}
          />
          <Input
            label={'Password'}
            type={'password'}
            className={'margin-top-20'}
            style={{width: '100%'}}
            onChange={(val) => {setPassword(val)}}
          />
          <div className='row width-full'>
            <div
              className='text-button margin-top-5 font-size-11 text-align-left'
              onClick={() => {setLoggedOutTab('reset_pw')}}

            >
              Forgot Password
            </div>
          </div>
          <Button
            display={'Log in'}
            className={'margin-top-20'}
            style={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
            isDisabled={!buttonEnabled || buttonIsLoading}
            isLoading={buttonIsLoading}
            onClick={async () => {

              setButtonIsLoading(true)

              let res: any = null
              res = await signInUserWithEmailAndPassword({email, password})

              if (res.error) {
                setButtonIsLoading(false)
                setOpenModal( <ErrorModal errorMessage={res.error}/> )
                return
              }

              try {
                const user = currentUser()
                props.subscribeToUserUpdates(user?.uid)
              } catch (err) {
                setButtonIsLoading(false)
                setOpenModal( <ErrorModal errorMessage={err.message}/> )
              }
            }}
          />
          <div className='signup-print'>
            Don't have an account?
            <span
              className='text-button margin-left-10'
              onClick={() => {setLoggedOutTab('sign_up')}}
            >
              Sign up
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}




