/*
JUN 2024 - DISUSED

DynamicInput

Allows user to enter python. Executes immediately to test, and later at runtime.

NOTE: execution at runtime must be implemented in the runTick for each usage!
*/


import './Input.scss';
import { useAtom } from 'jotai'
import React, { useState } from 'react';
// import NumericInput from "react-numeric-input";
import NumericInput from "react-enhanced-numeric-input";
import { loggedInUserAtom, userLiveDocAtom } from '../../types/global_types'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { getConfig } from '../../config'
import { currentUser } from '../../firebase'

const config = getConfig() as any


interface DynamicInputProps {
  className: string
  type: 'boolean' | 'number' | 'any'
  label: string
  placeholder?: string
  value: number
  onChange?: (value: number) => void
  onBlur?: (value: number) => void
  isError?: (value: number) => boolean
  width?: number
  hoverText?: string
}
export function DynamicInput(props: DynamicInputProps) {
  const inputRef: any = React.createRef()
  // const [dsPython] = useAtom(dsPythonAtom)
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  // const [inError, setInError] = useState(false)
  // const [resType, setResType] = useState<string | undefined>(undefined)
  const [testingInProgress, setTestingInProgress] = useState<boolean>(false)
  const [testRes, setTestRes] = useState<any>(null)
  const [value, setValue] = useState(props.value)

  if (!user) return null
  if (!uld) return null

  let classList = 'input-component'
  if (props.className) {
    classList += ' ' + props.className
  }

  // const checkError = () => {
  //   const inError = props.isError(inputRef?.current?.value)
  //   setInError(inError)
  //   inputRef.current.classList.remove('error')
  //   if (inError) {
  //     inputRef.current.classList.add('error')
  //   }
  // }

  const getLabel = () => {
    let label
    let infoIcon
    if (props.label) {
        label = <div className={'label'}>{props.label}</div>
      if (props.hoverText) {
        infoIcon = <FontAwesomeIcon
          className={'info-btn'}
          icon={faInfoCircle}
          title={props.hoverText}
        />
      }
    }

    return (
      <div className={'label-row'}>
        {label}
        {infoIcon}
      </div>
    )
  }

  let test_result: any = null

  if (testingInProgress) {
    test_result = (
      <div className='test-result'>...</div>
    )

  } else if (testRes?.stderr) {
    test_result = (
      <div className='test-result error'>
        ERROR - invalid expression
      </div>
    )
  } else if (testRes?.result !== undefined) {

    const result = cast_appropriately(testRes.result, props.type)
    let result_type: any = typeof result

    test_result = (
      <div className='test-result'>
        {/* Evaluates to {testRes.result} */}
        {result}
      </div>
    )
    if ((result_type !== props.type) && (props.type !== 'any')) {
      test_result = (
        <div className='test-result error'>
          {`ERROR: Resolves to ${testRes.type}, should be ${props.type}`}
        </div>
      )
    } else if (isNaN(result)) {
      test_result = (
        <div className='test-result error'>
          {`ERROR: Resolves to NaN, should be ${props.type}`}
        </div>
      )
    }
  }
  if (!props.value) {
    test_result = null
  }

  let style: any = {}
  if (props.width) {
    style.width = props.width
  }

  return (
    <div className={classList} style={style}>
      {getLabel()}
      <input
        type={'text'}
        className={'input'}
        placeholder={props.placeholder || `Enter an expression resolving to a ${props.type}`}
        value={value}
        ref={inputRef}
        onChange={(e: any) => {
          setTestingInProgress(false)
          setValue(e.target.value)
          if (props.onChange) {
            props.onChange(e.target.value)
          }
        }}
        onBlur={async (e: any) => {
          if (props.onBlur) {
            props.onBlur(e.target.value)
          }
          setTestingInProgress(true)
          const res = await test_dynamic_input({
            code: e.target.value,
            liveData: uld.liveData,
          })
          setTestRes(res)
          setTestingInProgress(false)
        }}

      />

      {test_result}

    </div>
  )
}


interface TestDynamicInputProps {
  liveData: any
  code: string
}
const test_dynamic_input = async (props: TestDynamicInputProps) => {
  let { liveData, code } = props
  code = `return ${code}`
  const url = `${config.api_root_url}executeUserCode`
  const token = await currentUser()?.getIdToken()
  try {
    const res = await axios.post(url, {code, liveData}, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    return res.data
  } catch (err) {
    throw new Error(`Error check failed: ${err}`)
  }
}

const cast_appropriately = (value: any, type: string) => {
  if (type === 'number') {
    return Number(value)
  } else if (type === 'boolean') {
    return Boolean(value)
  } else {
    return value
  }
}
