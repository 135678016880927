
export const get_initial_daily_log = () => {
  return {
    time_iso: new Date().toISOString(),
    isDayPoint: true,
    opType: 'daily_log',
    pl_pc: 0,
    cash: 0,
    equity: 0,
    _liveData: {
      day_equity_change_pc: 0,
      equity: 0,
      cost_basis: 0,
      daytrading_buying_pwr: 0,
      last_daytrading_buying_pwr: 0,
      day_unrealized_pl_pc: 0,
      cash_withdrawable: 0,
      last_equity: 0,
      day_equity_change: 0,
      day_unrealized_pl: 0,
      last_cash: 0,
      last_buying_pwr: 0,
      pend_transfer_out: 0,
      pl: 0,
      realized_pl: 0,
      long_mkt_value: 0,
      pl_pc: 0,
      unrealized_pl_pc: 0,
      net_deposits: 0,
      last_daytrade_count: 0,
      daytrade_count: 0,
      cash: 0,
      unrealized_pl: 0,
      buying_pwr: 0,
      // pend_transfer_in: 0,
      pattern_day_trader: false,
      last_long_mkt_value: 0
    }
  }
}
