

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Modal } from '../reusable'
import { updateLiveData, uuid } from '../../logic/u'
import { loggedInUserAtom, openModalAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { WatchlistObj } from '../../types/user_types'
import { db } from '../../firebase'

interface DeleteWatchlistModalProps {
  watchlistId: string
}

export const DeleteWatchlistModal = (props: DeleteWatchlistModalProps) => {
  const {watchlistId} = props

  // General helpers
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)

  const watchlistObj = (user?.watchlists || []).find(w => w.id === watchlistId)

  if (!user || !watchlistObj) return null

  return (
    <Modal
      title={'Delete'}
      contents={[
        <div className='column width-full font-size-13 orange-text'>
          <div className='row'>
            Are you sure you want to delete this Watchlist? This operation cannot be undone.
          </div>
        </div>
      ]}
      yesButtonText={'Delete'}
      isDanger
      isButtonLoading={buttonIsLoading}
      twoStepConfirmation={true}
      onYes={async () => {
        try {
          setButtonIsLoading(true)
          let currentWatchlists = user.watchlists || []
          const newWatchlists = currentWatchlists.filter(w => w.id !== watchlistObj?.id)
          await db.collection('users').doc(user.uid).update({ watchlists: newWatchlists })

          // Handle various delete scenarios
          const defaultId = user?.watchlists.find(w => w.isDefault)?.id
          let targetId = defaultId
          
          // If we have other watchlists
          if (newWatchlists.length) {
            targetId = user.watchlists[0].id
          }

          // Set watchlist tab to target
          await db.collection('users').doc(user.uid).update({ selectedWatchlistIdWatchlistsTab: targetId })
          
          // Set home tab to target
          await db.collection('users').doc(user.uid).update({ selectedWatchlistIdHomeTab: targetId })
    
          updateLiveData(user)
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

