import './EvaluationsBit.scss'
import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { EELog } from '../../types/user_types'
import { format_price, valueForTable } from '../../logic/u'
import { loggedInUserAtom, openModalAtom, userLiveDocAtom } from '../../types/global_types'
import { db, currentUser } from '../../firebase'
import { EvaluationRow } from '../reusable/EvaluationRow'
import { ORANGE, ORANGE_FAINT } from '../../logic/colors'
import { LogModal } from '../modals/LogModal'

const LOAD_INCREMENT = 50

export const EvaluationsBit = () => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [logs, setLogs] = useState<EELog[]>([])
  const containerRef = useRef<HTMLDivElement>(null)
  const [, setOpenModal] = useAtom(openModalAtom)

  const [haveMore, setHaveMore] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)

  // Used for incremental load after we've exhausted the cache
  const load_more = async () => {
    setLoadingMore(true);

    try {
      await new Promise<void>((resolve) => {
        setLogs((currentLogs) => {

          // No logs to load more case
          if (currentLogs.length === 0) {
            setLoadingMore(false);
            resolve();
            return currentLogs;
          }

          const last_log = currentLogs[currentLogs.length - 1];

          // No last log case
          if (!last_log) {
            setLoadingMore(false);
            resolve();
            return currentLogs;
          }

          // Load previous chunk from firebase
          const beforeTime = new Date(last_log.time_iso);
          const query = db
            .collection('users')
            .doc(user?.uid)
            .collection('EELogs')
            .where('time', '<', beforeTime)
            .orderBy('time', 'desc')
            .limit(LOAD_INCREMENT);

          query.get().then((snapshot) => {
            const newLogs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // If none, we're done
            if (newLogs.length === 0) {
              setHaveMore(false);
              setLoadingMore(false);
              resolve();
              return currentLogs;
            }

            // Otherwise append to current logs. If less than LOAD_INCREMENT, we're done
            setHaveMore(newLogs.length >= LOAD_INCREMENT);
            setLoadingMore(false);
            resolve();
            return [...currentLogs, ...newLogs];
          });

          return currentLogs; // Return current logs while waiting for the query
        });
      });
    } catch (error) {
      console.error('Error loading more logs:', error);
      setLoadingMore(false);
    }
  };


  // Load more if user's scrolled with 200px of the bottom
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScroll = async () => {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollHeight - scrollTop - clientHeight < 200) {
        if (!haveMore || loadingMore) return
        console.log(`past check, loading more. haveMore: ${haveMore}, loadingMore: ${loadingMore}`)
        await load_more()
      }
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  // Initially load cached logs
  useEffect(() => {
    if (uld?.cachedEELogs) {
      const sortedLogs = [...uld.cachedEELogs].sort((a, b) => new Date(b.time_iso).getTime() - new Date(a.time_iso).getTime());
      setLogs(sortedLogs);
    } else {
      setLogs([]);
    }
  }, [uld]);

  if (!user || !uld) return null

  return (
    <div className='evaluations-bit' ref={containerRef} style={{ maxHeight: '80vh', overflowY: 'auto' }}>
      {!logs.length ? (
        <div className='no-evals-text'>
          No logs.
        </div>
      ) : null}

      {logs.map((log: EELog) => {
        if (Object.keys(log).length === 0) return null

        const date = new Date(log.time_iso)
        const formatted_date = format_date(date)
        const equity = format_price(log._liveData?.BALANCES?.equity)
        const mkt_value = valueForTable('mkt_value', log._liveData?.BALANCES?.mkt_value)
        const day_unrealized_pl = valueForTable('day_unrealized_pl', log._liveData?.BALANCES?.day_unrealized_pl)
        const day_unrealized_pl_pc = valueForTable('day_unrealized_pl_pc', log._liveData?.BALANCES?.day_unrealized_pl_pc)

        let formatted_stats = `${equity} • ${mkt_value} • ${day_unrealized_pl} • [${day_unrealized_pl_pc}]`

        if (log._liveData?.BALANCES === undefined) formatted_stats = '-'

        return <div 
          key={log.id} 
          className='outer-eval-row'
          onClick={() => {
            setOpenModal(<LogModal log={log} />)
          }}
        >
          <div className='row space-between'>
            <div className='er-date'>{formatted_date}</div>
            <div className='er-stats'>{formatted_stats}</div>
          </div>
          <EvaluationRow log={log} />
        </div>
      })}

      {/* Bottom message, if loading or no more present */}
      {!haveMore || loadingMore ? <div
        style={{
          textAlign: 'center',
          padding: 10,
          height: 20,
          color: ORANGE,
          backgroundColor: ORANGE_FAINT,
          fontSize: 11,
        }}
      >
        {!haveMore ? "You've reached the end" : null}
        {loadingMore ? "Loading more..." : null}
      </div> : null}

    </div>
  )
}

// Helper functions (unchanged)
function format_date(date) {
  const options: any = {
    timeZone: 'America/New_York',
    weekday: 'short',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  const parts = formatter.formatToParts(date);

  let dateString = '';
  for (let part of parts) {
    dateString += part.value;
  }
  return dateString;
}

export const formatPercent = (value: number) => {
  if (value === null || value === undefined) {
    return '-'
  }
  return (Number(value) * 100).toFixed(2) + '%'
}