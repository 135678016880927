import React, { useState, useRef } from 'react';
import './FileInput.scss';


interface FileInputProps {
  className?: string
  label?: string
  onChange: any
  style?: any
}

export function FileInput(props: FileInputProps) {
  const { className, label, onChange, style } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileNames, setFileNames] = useState<string[]>([]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const names = files?.length ? Array.from(files).map(file => file.name) : [];
    setFileNames(names);
    if (onChange) {
      onChange(e);
    }
  };

  const clearFileInput = () => {
    setFileNames([]);
    if (inputRef.current) {
      inputRef.current.value = ''; // Clear the input value
    }
  };

  return (
    <div className={`tb-file-uploader ${className}`} style={style}>
      {label && <div className='label'>{label}</div>}
      <div className='file-input-wrapper'>
        <input
          className='file-input'
          type='file'
          ref={inputRef}
          onChange={handleFileChange}
          accept='.pdf, .png, .jpeg, .jpg'
          multiple
        />
        <span className='file-name'>{fileNames.join(', ') || 'Upload document(s)'}</span>
      </div>
      {fileNames.length > 0 && <div className='clear-btn' onClick={clearFileInput}>{'Clear'}</div>}
    </div>
  );
}




