/*
Duplicate of the one on the backend
*/

import { format_price } from "./u"
import { ArgsSetTrade } from "../types/user_types"

export const make_create_order_string = (args: ArgsSetTrade) => {

  // Shares vs dollar amount
  let qty_string = args.qty?.toString()
  if (args.notional) {
    qty_string = '$' + args.notional.toString()
  }

  let s = `order: ${args.side} ${args.symbol} ${args.type} ${qty_string} ${args.time_in_force}`
  if (args.type === 'limit') {
    s =`order: ${args.side} ${args.symbol} ${args.type} ${qty_string} @ ${args.limit_price} ${args.time_in_force}`
  }
  if (args.type === 'stop_limit') {
    const fmt_stop = format_price(args.stop_price || 0)
    const fmt_limit = format_price(args.limit_price || 0)
    s += ` @ ${fmt_stop}-${fmt_limit}`
  }
  return s.toLocaleUpperCase()
}