

import { useAtom } from 'jotai'
import firebase from 'firebase/app'
import React, { useEffect, useState } from 'react'
import { Modal } from '../reusable'
import { updateLiveData } from '../../logic/u'
import { loggedInUserAtom, openModalAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { Variable } from '../../types/user_types'
import { CreateVariableModal } from './CreateVariableModal'
import { db } from '../../firebase'

export interface DeleteVariableModalProps {
  variable: Variable
}

export const DeleteVariableModal = (props: DeleteVariableModalProps) => {
  // General helpers
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  
  if (!user) return null

  return (
    <Modal
      title='Delete variable'
      contents={[`Are you sure you want to delete the variable ${props.variable?.symbol}? This operation cannot be undone.`]}
      yesButtonText={'Delete'}
      isDanger
      twoStepConfirmation
      isButtonLoading={buttonIsLoading}
      onYes={async () => {
        try {
          setButtonIsLoading(true)
          const variables = user?.variables
          delete variables?.[props.variable?.symbol]
          await db.collection('users').doc(user?.uid).update({variables})
          updateLiveData(user)
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

