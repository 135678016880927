import React, { useState } from 'react'
import { Button } from '../reusable'
import axios  from 'axios'
import { currentUser } from '../../firebase'
import { getConfig } from '../../config'
import { db } from '../../firebase'
import { User } from '../../types/user_types'
import { loadingAtom, loggedInUserAtom, openModalAtom } from '../../types/global_types'
import { useAtom } from 'jotai'
import { CreateTransferModal } from './CreateTransferModal'
import { ErrorModal } from '../modals/ErrorModal'

const config = getConfig() as any

export interface CreatePlaidLinkButtonModalProps {
  fetchBankingRelationshipData?: any
}

export function CreatePlaidLinkButtonModal(props: CreatePlaidLinkButtonModalProps) {
  const [ loggedInUser ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [loading, setLoading] = useAtom(loadingAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)

  if (!loggedInUser) return null

  return (
    <Button
      display='Connect bank'
      style={{height: 32, width: 175}}
      isLoading={buttonIsLoading}
      onClick= {async () => {

        setButtonIsLoading(true)

        try {

          const token = await currentUser()?.getIdToken()

          // Get a link token from Plaid
          const createLinkTokenUrl = `${config.api_root_url}createLinkToken`
          const linkTokenResponse = await axios.get(createLinkTokenUrl, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          const link_token = linkTokenResponse.data.link_token


          // Run the plaid modal. Exchange link token for public token
          //@ts-ignore
          const plaidLinkHandler = window.Plaid.create({
            token: link_token,
            onSuccess: async (public_token, metadata) => {

              // Get a Plaid access token
              const accessTokenRequest = {
                public_token
              }
              const createAccessTokenUrl = `${config.api_root_url}createPlaidAccessToken`
              const accessTokenResponse = await axios.post(createAccessTokenUrl, accessTokenRequest, {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json'
                }
              })
              // Get a Plaid processor token
              const processorTokenRequest = {
                access_token: accessTokenResponse.data.access_token,
                account_id: metadata.account_id

              }
              const createProcessorTokenUrl = `${config.api_root_url}createPlaidProcessorToken`
              const proccesorTokenResponse = await axios.post(createProcessorTokenUrl, processorTokenRequest, {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json'
                }
              })

              const achRelationshipRequest = {
                processor_token: proccesorTokenResponse.data.processor_token
              }
              const createAchRelationshipUrl = `${config.api_root_url}createAchRelationship`
              await axios.post(createAchRelationshipUrl, achRelationshipRequest, {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json'
                }
              })

              // If we have this prop, it means we're using this component from within the app
              if (props.fetchBankingRelationshipData) {
                await props.fetchBankingRelationshipData()
              }
              // setButtonIsLoading(true)
            },
            onLoad: () => {
              // setButtonIsLoading(true)
              return
            },
            onExit: (err, metadata) => {
              // setButtonIsLoading(true)
              return
            },
            onEvent: (eventName, metadata) => {
              // setButtonIsLoading(true)
              return
            },
            //required for OAuth; if not using OAuth, set to null or omit:
            // receivedRedirectUri: window.location.href,
          })

          plaidLinkHandler.open()
        } catch (err) {
          setButtonIsLoading(false)
          setOpenModal( <ErrorModal errorMessage={err.message}/> )
        }
      }}
    />
  )
}