import '../SignUp.scss'
import React, { useState, useEffect } from 'react'
import { db } from '../../../firebase'
import {loggedInUserAtom, openModalAtom } from '../../../types/global_types'
import {
  AlpacaFundingSource,
  SignUpStage,
} from '../../../types/user_types'
import { useAtom } from 'jotai'
import { Button, DropdownSelect } from '../../reusable'
import { ErrorModal } from '../../modals/ErrorModal'
import { fundingSourceOptions, returnFinancialProfileMinMax } from '../../../logic/u'

export const FinancialProfileStep = () => {
  const [ loggedInUser ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)

  // This signup stage
  const [annualIncomeMin, setAnnualIncomeMin] = useState<string | null>(loggedInUser?.alpacaObject?.identity?.annual_income_min || '')
  const [annualIncomeMax, setAnnualIncomeMax] = useState<string | null>(loggedInUser?.alpacaObject?.identity?.annual_income_max || '')
  const [annualIncomeVal, setAnnualIncomeVal] = useState<string | null>(annualIncomeMin)

  const [liquidAssetsMin, setLiquidAssetsMin] = useState<string | null>(loggedInUser?.alpacaObject?.identity?.liquid_net_worth_min || '')
  const [liquidAssetsMax, setLiquidAssetsMax] = useState<string | null>(loggedInUser?.alpacaObject?.identity?.liquid_net_worth_max || '')
  const [liquidAssetsVal, setliquidAssetsVal] = useState<string | null>(liquidAssetsMin)

  const [estimatedNetWorthMin, setEstimatedNetWorthMin] = useState<string | null>(loggedInUser?.alpacaObject?.identity?.total_net_worth_min || '')
  const [estimatedNetWorthMax, setEstimatedNetWorthMax] = useState<string | null>(loggedInUser?.alpacaObject?.identity?.total_net_worth_max || '')
  const [netWorthVal, setNetWorthVal] = useState<string | null>(estimatedNetWorthMin)

  const [accountFundingSource, setAccountFundingSource] = useState<AlpacaFundingSource | ''>(loggedInUser?.alpacaObject?.identity?.funding_source?.[0] || '')

  const previousSignUpStage: SignUpStage = 'Employment'
  const nextSignUpStage: SignUpStage = 'DisclosuresAffiliatedExchangeOrFinra'

  const handleChangeAnnualIncome = (val: string) => {
    const [min, max] = returnFinancialProfileMinMax(val)
    setAnnualIncomeMin(min)
    setAnnualIncomeMax(max)
    setAnnualIncomeVal(val)
  }
  const handleChangeLiquidAssets = (val: string) => {
    const [min, max] = returnFinancialProfileMinMax(val)
    setLiquidAssetsMin(min)
    setLiquidAssetsMax(max)
    setliquidAssetsVal(val)
  }
  const handleChangeNetWorth = (val: string) => {
    const [min, max] = returnFinancialProfileMinMax(val)
    setEstimatedNetWorthMin(min)
    setEstimatedNetWorthMax(max)
    setNetWorthVal(val)
  }

  useEffect(() => {
    setButtonEnabled(
      !!annualIncomeMin &&
      !!liquidAssetsMin &&
      !!estimatedNetWorthMin &&
      !!accountFundingSource
    );
  }, [annualIncomeMin, liquidAssetsMin, estimatedNetWorthMin, accountFundingSource]);

  if (!loggedInUser) return null

  return (
    <div className='signup-container'>
      <div className='signup-form-container'>
        <div className='signup-context-title'>
          Financial profile
        </div>
        <DropdownSelect
          label={'Annual income'}
          options={[
            {display: '$0 - $24,999', value: '0'},
            {display: '$25,000 - $99,999', value: '25000'},
            {display: '$100,000 - $499,999', value: '100000'},
            {display: '$500,000 - $999,999', value: '500000'},
            {display: '$1,000,000+', value: '1000000'}
          ]}
          className={'margin-top-15'}
          width={494}
          height={32}
          value={annualIncomeVal}
          onChange={(val) => {
            handleChangeAnnualIncome(val)
          }}
        />
        <DropdownSelect
          label={'Liquid assets'}
          options={[
            {display: '$0 - $24,999', value: '0'},
            {display: '$25,000 - $99,999', value: '25000'},
            {display: '$100,000 - $499,999', value: '100000'},
            {display: '$500,000 - $999,999', value: '500000'},
            {display: '$1,000,000+', value: '1000000'}
          ]}
          className={'margin-top-20'}
          width={494}
          height={32}
          value={liquidAssetsVal}
          onChange={(val) => {
            handleChangeLiquidAssets(val)
          }}
        />
        <DropdownSelect
          label={'Estimated net worth'}
          options={[
            {display: '$0 - $24,999', value: '0'},
            {display: '$25,000 - $99,999', value: '25000'},
            {display: '$100,000 - $499,999', value: '100000'},
            {display: '$500,000 - $999,999', value: '500000'},
            {display: '$1,000,000+', value: '1000000'}
          ]}
          className={'margin-top-20'}
          width={494}
          height={32}
          value={netWorthVal}
          onChange={(val) => {
            handleChangeNetWorth(val)
          }}
        />
        <DropdownSelect
          label={'Account funding source'}
          options={fundingSourceOptions}
          className={'margin-top-20'}
          width={494}
          height={32}
          value={accountFundingSource}
          onChange={(val) => { setAccountFundingSource(val) }}
        />
        <Button
          display={'Next'}
          className={'margin-top-25'}
          style={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
          isDisabled={!buttonEnabled || buttonIsLoading}
          isLoading={buttonIsLoading}
          onClick= {async () => {
            try {
              setButtonIsLoading(true)
              await db.collection('users').doc(loggedInUser.uid).update({
                'alpacaObject.identity.annual_income_min': annualIncomeMin,
                'alpacaObject.identity.annual_income_max': annualIncomeMax,
                'alpacaObject.identity.liquid_net_worth_min': liquidAssetsMin,
                'alpacaObject.identity.liquid_net_worth_max': liquidAssetsMax,
                'alpacaObject.identity.total_net_worth_min': estimatedNetWorthMin,
                'alpacaObject.identity.total_net_worth_max': estimatedNetWorthMax,
                'alpacaObject.identity.funding_source': [accountFundingSource],
                signUpStage: nextSignUpStage,
              })
              setButtonIsLoading(false)
            } catch (err) {
              setButtonIsLoading(false)
              setOpenModal( <ErrorModal errorMessage={err.message}/> )
            }
          }}
        />
        <Button
          display={'Back'}
          className={'margin-top-15'}
          variant={'secondary'}
          style={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
          onClick= {async () => {await db.collection('users').doc(loggedInUser.uid).update({signUpStage: previousSignUpStage})}}
        />
      </div>
    </div>
  )

}