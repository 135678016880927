import { useAtom } from 'jotai'
import {
  loggedInUserAtom,
  openModalAtom,
  selectedCodeEditorSlotAtom,
} from '../../types/global_types'
import { db, currentUser } from '../../firebase'
import {
  Icon,
  DropdownMenu,
} from '../reusable'
import React, { useState, useEffect } from 'react'
import { DividerPaneIcon } from '../reusable/DividerPaneIcon'
import { CreateFunctionModal } from '../modals/CreateFunctionModal'
import { DuplicateFunctionModal } from '../modals/DuplicateFunctionModal'
import { DeleteFunctionModal } from '../modals/DeleteFunctionModal'
import { FunctionObj } from '../../types/user_types'
import { TEXT_GRAY } from '../../logic/u'

interface FunctionDropdownProps {
  functionObj: FunctionObj
  codeEditorSlot?: number
  flagUnsavedChangesBeforeChangingFunctions?: (functionId: string, changeSelection: boolean) => void
  left: number
  top: number
  width: number
  onClose: () => void
  fromFunctionsList?: boolean
}

export const FunctionDropdown = (props: FunctionDropdownProps) => {
  const [user ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [selectedCodeEditorSlot, setSelectedCodeEditorSlot] = useAtom(selectedCodeEditorSlotAtom)

  const {functionObj, codeEditorSlot, flagUnsavedChangesBeforeChangingFunctions, left, top, width, onClose, fromFunctionsList} = props

  interface DropdownItem {
    display: JSX.Element
    onClick?: () => void
  }
  let contents: DropdownItem[] = []

  if (fromFunctionsList) {
    contents.push(
      {
        display: (
          <div className='left'>
            <Icon
              icon='file-check'
              set='sharp-solid'
              size={9}
              style={{marginRight: 10}}
            />
            <div>Open</div>
          </div>
        ),
        onClick: () => {
          flagUnsavedChangesBeforeChangingFunctions?.(functionObj.id, true)
        }
      },
    )
  }
  if (!codeEditorSlot) {
    contents.push(
      {
        display: (
          <div className='left'>
            <Icon
              icon='square-pen'
              set='sharp-solid'
              size={9}
              style={{marginRight: 10, marginTop: 1}}
            />
            <div>Update</div>
          </div>
        ),
        onClick: () => {
          setOpenModal(<CreateFunctionModal functionObj={functionObj} />)
        }
      },
      {
        display: (
          <div className='left'>
            <Icon
              icon='copy'
              set='sharp-solid'
              size={10}
              style={{marginRight: 10}}
            />
            <div>Duplicate</div>
          </div>
        ),
        onClick: async () => {
          setOpenModal(<DuplicateFunctionModal functionObj={functionObj} />)
        }
      },
      {
        display: (
          <div className='left'>
            <Icon
              icon='trash'
              set='sharp-solid'
              size={9}
              style={{marginRight: 10, marginTop: .5}}
            />
            <div>Delete</div>
          </div>
        ),
        onClick: () => {
          setOpenModal(<DeleteFunctionModal functionObj={functionObj} />)
        }
      },
    )
  }
  
  if (user?.codeEditorSlot1 === functionObj.id || user?.codeEditorSlot2 === functionObj.id) {
    contents.push(
      {
        display: (
          <div
            style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginTop: 3, marginBottom: 5}}
          ></div>
        )
      },
      {
        display: (
          <div className='left'>
            <Icon
              icon='circle-x'
              set='sharp-solid'
              size={9}
              style={{marginRight: 10}}
            />
            <div>{user.codeEditorSlot1 === functionObj.id && user.codeEditorSlot2 === functionObj.id ? 'Clear editor slots' : 'Clear editor slot'}</div>
          </div>
        ),
        onClick: () => {   
          
          flagUnsavedChangesBeforeChangingFunctions?.(functionObj.id, false)

          if (codeEditorSlot) {
            if (codeEditorSlot === 1) {
              db.collection('users').doc(user?.uid).update({ codeEditorSlot1: '' });
            }
            if (codeEditorSlot === 2) {
              db.collection('users').doc(user?.uid).update({ codeEditorSlot2: '' });
            }
          }  
          
          // The case of the left pane
          if (user.codeEditorSlot1 === functionObj.id) {
            db.collection('users').doc(user?.uid).update({ codeEditorSlot1: '' });
          }
          if (user.codeEditorSlot2 === functionObj.id) {
            db.collection('users').doc(user?.uid).update({ codeEditorSlot2: '' });
          }
        }
      },
    )
  }

  return (
    <DropdownMenu
      //@ts-ignore
      posLeft={left}
      posTop={top}
      width={width}
      height={500}
      onClose={() => onClose()}
      noArrowKeys={true}
      contents={contents}
    />
  )
}