

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Modal } from '../reusable'
import { updateLiveData, uuid } from '../../logic/u'
import { loggedInUserAtom, openModalAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { FunctionObj } from '../../types/user_types'
import { db } from '../../firebase'

interface DeleteFunctionModalProps {
  functionObj: FunctionObj
}

export const DeleteFunctionModal = (props: DeleteFunctionModalProps) => {  
  const {functionObj} = props

  // General helpers
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)

  if (!user || !functionObj) return null

  return (
    <Modal
      title={'Delete'}
      contents={[
        <div className='column width-full font-size-13 orange-text'>
          <div className='row'>
            Are you sure you want to delete this Function? This operation cannot be undone.
          </div>
        </div>
      ]}
      yesButtonText={'Delete'}
      isDanger
      isButtonLoading={buttonIsLoading}
      twoStepConfirmation={true}
      onYes={async () => {
        try {
          setButtonIsLoading(true)
          let currentFunctions = user.functions || []
          const newFunctions = currentFunctions.filter(f => f.id !== functionObj?.id)  
          await db.collection('users').doc(user.uid).update({ functions: newFunctions })                       
          updateLiveData(user)
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

