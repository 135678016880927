import React, { useState } from 'react'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  ordersAtom,
} from '../../types/global_types'
import { useAtom } from 'jotai'
import { BORDER_INPUT, SECOND_SURFACE, TEXT_GRAY_LIGHT, formatDateForTable, formatNumberForTable, handleMUISorting, tableSX, valueForTable, roundToSigFigs } from '../../logic/u'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { TickerDropdown } from '../dropdowns/TickerDropdown'
import { Icon } from '../reusable'
import { CreateOrderModal } from './CreateOrderModal'
import { EELog } from '../../types/user_types'
import { EvaluationRow } from '../reusable/EvaluationRow'
import { LogsModalFunctionsTab } from '../tabs/LogsModalFunctionsTab'
import { LogsModalWatchlistsTab } from '../tabs/LogsModalWatchlistsTab'
import { LogsModalPositionsTab } from '../tabs/LogsModalPositionsTab'
import { LogsModalBalancesTab } from '../tabs/LogsModalBalancesTab'

const CustomNoRowsOverlay = () => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: TEXT_GRAY_LIGHT
  }}>
    <p>No orders associated with this ticker</p>
  </div>
);

const SUBTABS = [
  {display: 'Functions', value: 'functions'},
  {display: 'Watchlists', value: 'watchlists'},
  {display: 'Positions', value: 'positions'},
  {display: 'Balances', value: 'balances'},
]

interface LogModalProps {
  log: EELog
}

export const LogModal = (props: LogModalProps) => {
  const [ user ] = useAtom(loggedInUserAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)
  const [orders] = useAtom(ordersAtom)

  const { log } = props

  const [ subTab, setSubTab ] = useState('positions')

  let subTabContent: any = null
  switch (subTab) {
    case 'functions':
      subTabContent = <LogsModalFunctionsTab />
      break
    case 'watchlists':
      subTabContent = <LogsModalWatchlistsTab />
      break
    case 'positions':
      subTabContent = <LogsModalPositionsTab />
      break
    case 'balances':
      subTabContent = <LogsModalBalancesTab />
      break    
  }

  if (!user || !uld) return null

  return (
    <>
      <div
        className='modal-scrim'
        onClick={() => {
          setOpenModal(null)
        }}
      />      
      <div
        className='modal'
        style={{
          height: 675,
          width: 1000,
          top: 60,
          left: 'calc(50vw - 500px)'
        }}
      >
        {openDropdown}
        <div className='top'>
          <div className='title-row'>
            <div className='row'>
              <div>
                {'Log'}
              </div>              
            </div>           
          </div>
          <div className='modal-body'>
            <div className='white-text bold' style={{marginBottom: 6}}>Message</div>
            <div className='outer-eval-row' style={{padding: 0}}>
              <EvaluationRow log={log} />
            </div>
            <div className='modal-tab-container'>
              {SUBTABS.map((s) => {
                let className = 'modal-tab'
                if (s.value === subTab) {
                  className += ' selected'
                }
                return (
                  <div
                    className={className}
                    onClick={() => setSubTab(s.value)}
                  >
                    {s.display}
                  </div>
                )
              })}
            </div>
            {subTabContent}
          </div>
        </div>
      </div>
    </>
  )
}