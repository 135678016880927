import React, { useState } from 'react'
import { Icon } from './Icon'

export function Spinner() {
  return (
    <div className='column' style={{width: '100%', height: '100%'}}>
      <Icon
        icon='spinner'
        set='sharp-solid'
        size={18}
        spin={true}
      />
    </div>
  )
}