import React, { useEffect, useState } from 'react'
import { Modal, Input, PhoneInput } from '../reusable'
import axios  from 'axios'
import { currentUser } from '../../firebase'
import { getConfig } from '../../config'
import {
  openModalAtom,
  loggedInUserAtom,
} from '../../types/global_types'
import { useAtom } from 'jotai'
import { ErrorModal } from './ErrorModal'
const config = getConfig() as any

export function TrustedContactModal() {
  const [loggedInUser] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)
  const [ firstName, setFirstName ] = useState<string>(loggedInUser?.alpacaObject?.trusted_contact?.given_name || '')
  const [ lastName, setLastName ] = useState<string>(loggedInUser?.alpacaObject?.trusted_contact?.family_name || '')
  const [ emailAddress, setEmailAddress ] = useState<string>(loggedInUser?.alpacaObject?.trusted_contact?.email_address || '')
  const [ phoneNumber, setPhoneNumber ] = useState<string>(loggedInUser?.alpacaObject?.trusted_contact?.phone_number || '')

  useEffect(() => {
    setButtonEnabled(!!firstName && !!lastName && !!emailAddress && !!phoneNumber)
  }, [firstName, lastName, emailAddress, phoneNumber])

  if (!loggedInUser) {return null}
  
  return (
    <Modal 
      title='Trusted contact'
      contents={(
        <div className='column width-full'>
          <Input
            label={`First name`}
            type={'text'}
            style={{width: '100%'}}
            initialValue={firstName}
            onBlur={(val) => {setFirstName(val)}}
          />
          <Input
            label={`Last name`}
            type={'text'}
            className={'margin-top-20'}
            style={{width: '100%'}}
            initialValue={lastName}
            onBlur={(val) => {setLastName(val)}}
          />
          <Input
            label={`Email address`}
            type={'email'}
            className={'margin-top-20'}
            style={{width: '100%'}}
            initialValue={emailAddress}
            onBlur={(val) => {setEmailAddress(val)}}
          />
          <PhoneInput
            label={`Phone number (US only)`}
            style={{width: '100%'}}
            className={'margin-top-20'}
            initialValue={phoneNumber}
            onBlur={(val) => {setPhoneNumber(val)}}
          />
        </div>
      )}
      yesButtonText='Update'
      isButtonDisabled={!buttonEnabled || buttonIsLoading}
      isButtonLoading={buttonIsLoading}
      onYes={async () => {
        try {
          setButtonIsLoading(true)
          const token = await currentUser()?.getIdToken()
          const endpoint = `${config.api_root_url}updateAccount`
          const data = {
            trusted_contact: {
              given_name: firstName,
              family_name: lastName,
              email_address: emailAddress,
              phone_number: phoneNumber
            }
          }
          await axios.post(endpoint, data, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          setButtonIsLoading(false)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}