import { utcToZonedTime } from 'date-fns-tz';

export const make_x_axis_labels = (timestampArray: number[], interval: string, numCandles: number, width: number) => {
  const result: { label: string, timestamp: string }[] = []
  const pixels_per_candle = width / numCandles

  for (let i = 0; i < numCandles; i++) {
    const ts = timestampArray[i]
    let date: any = ts ? new Date(ts) : undefined
    let nyc = date ? getNYCDate(date) : undefined
    let label: string | undefined

    if (nyc) {
      switch (interval) {
        case '1m':
          if (pixels_per_candle > 5) {
            if (nyc.getHours() === 9 && nyc.getMinutes() === 30) {
              label = `${getDayOfWeek(nyc)}`
            } else if (nyc.getMinutes() === 0 && nyc.getHours() !== 16) {
              // Skip 4pm
              label = `${getHours(nyc)}`  // Changed from getHoursMinutes to getHours
            } else if (numCandles > 30 && date.getMinutes() % 10 === 0) {
              label = `${getHoursMinutes(nyc)}`
            } else if (numCandles <= 30 && date.getMinutes() % 30 === 0) {
              label = `${getHoursMinutes(nyc)}`
            }
          } else if (pixels_per_candle > 1) {
            if (nyc.getHours() === 9 && nyc.getMinutes() === 30) {
              label = `${getDayOfWeek(nyc)}`
            } else if (nyc.getMinutes() === 0 && nyc.getHours() !== 16) {
              // Skip 4pm
              label = `${getHours(nyc)}`  // Changed from getHoursMinutes to getHours
            } else if (pixels_per_candle > 3 && nyc.getMinutes() === 30) {
              label = `${getHoursMinutes(nyc)}`
            }
          } else {
            if (nyc.getHours() === 9 && nyc.getMinutes() === 30) {
              label = `${getDayOfWeek(nyc)}`
            }
          }
          break

        case '5m':
          if (nyc.getHours() === 9 && nyc.getMinutes() === 30) {
            label = `${getDayOfWeek(nyc)}`
          } else if (nyc.getHours() === 16 && nyc.getMinutes() === 0) {
            continue    // skip 4:00p, so as not to collide with next morning
          } else if (pixels_per_candle > 5) {
            if (nyc.getMinutes() === 0) {
              label = `${getHours(nyc)}`  // Changed from getHoursMinutes to getHours
            }
          } else if (pixels_per_candle > 3) {
            if ([12, 14, 16].includes(nyc.getHours()) && nyc.getMinutes() === 0) {
              label = `${getHours(nyc)}`  // Changed from getHoursMinutes to getHours
            }
          } else if (pixels_per_candle > 2) {
            if ([13].includes(nyc.getHours()) && nyc.getMinutes() === 0) {
              label = `${getHours(nyc)}`  // Changed from getHoursMinutes to getHours
            }
          }
          break

          case '30m':
            if (pixels_per_candle < 2) {
              // Very zoomed out: only show first of each month
              if (is_first_of_month(timestampArray, i)) {
                label = `${getDateOrMonth(nyc, timestampArray, false)}`
              }
            } else if (pixels_per_candle < 4) {
              // Moderately zoomed out: show every 5 days
              if (nyc.getHours() === 9 && nyc.getMinutes() === 30 && nyc.getDate() % 5 === 1) {
                label = `${getDate(nyc, false)}`
              }
            } else if (pixels_per_candle < 6) {
              // Less zoomed out: show every other day
              if (nyc.getHours() === 9 && nyc.getMinutes() === 30 && nyc.getDate() % 2 === 1) {
                label = `${getDate(nyc, false)}`
              }
            } else {
              // Default behavior (same as before)
              if (nyc.getHours() === 9 && nyc.getMinutes() === 30) {
                label = `${getDate(nyc, false)}`
              }
              if (numCandles < 150 && nyc.getHours() === 13 && nyc.getMinutes() === 0 && numCandles > 10) {
                label = '1p'
              }
            }
            break;

          case '1h':
            if (pixels_per_candle > 8) {
              // Label each day at market open
              if (nyc.getHours() === 9 && nyc.getMinutes() === 0) {
                label = `${getDate(nyc, false)}`
              }
            } else if (pixels_per_candle >= 6 && pixels_per_candle <= 8) {
              // Label every couple of days at market open
              if (nyc.getHours() === 9 && nyc.getMinutes() === 0 && nyc.getDate() % 2 === 0) {
                label = `${getDate(nyc, false)}`
              }
            } else {
              if (is_first_of_month(timestampArray, i)) {
                label = `${getDateOrMonth(nyc, timestampArray, false)}`
              }
            }
            break

        case '2h':
          if (pixels_per_candle > 20) {
            if (nyc.getHours() === 10 && nyc.getMinutes() === 0) {
              label = `${getDateOrMonth(nyc, timestampArray, false)}`
            }
          } else if (pixels_per_candle > 10) {
            if (nyc.getHours() === 10 && nyc.getMinutes() === 0 && nyc.getDate() % 2 === 0) {
              label = `${getDateOrMonth(nyc, timestampArray, false)}`
            }
          } else if (pixels_per_candle > 5) {
            if (nyc.getHours() === 10 && nyc.getMinutes() === 0 && nyc.getDate() % 3 === 0) {
              label = `${getDateOrMonth(nyc, timestampArray, false)}`
            }
          } else {
            if (is_first_of_month(timestampArray, i)) {
              label = `${getDateOrMonth(nyc, timestampArray, false)}`
            }
          }
          break;

        case '4h':
          if (pixels_per_candle > 30) {
            if (nyc.getHours() === 8 && nyc.getMinutes() === 0) {
              label = `${getDateOrMonth(nyc, timestampArray, false)}`
            }
          } else if (pixels_per_candle > 20) {
            if (nyc.getHours() === 8 && nyc.getMinutes() === 0 && nyc.getDate() % 2 === 0) {
              label = `${getDateOrMonth(nyc, timestampArray, false)}`
            }
          } else if (pixels_per_candle > 10) {
            if (nyc.getHours() === 8 && nyc.getMinutes() === 0 && nyc.getDate() % 3 === 0) {
              label = `${getDateOrMonth(nyc, timestampArray, false)}`
            }
          } else if (pixels_per_candle > 5) {
            if (nyc.getHours() === 8 && nyc.getMinutes() === 0 && nyc.getDate() % 5 === 0) {
              label = `${getDateOrMonth(nyc, timestampArray, false)}`
            }
          } else {
            if (is_first_of_month(timestampArray, i)) {
              label = `${getDateOrMonth(nyc, timestampArray, false)}`
            }
          }
          break;

        case '1d':
          if (is_first_of_year(timestampArray, i)) {
            label = `${nyc.getFullYear()}`;
          } else if (is_first_of_month(timestampArray, i)) {
            if (pixels_per_candle >= 20 || i === 0) {
              // Show full month name if there's enough space or it's the first label
              label = nyc.toLocaleString('default', { month: 'long' });
            } else {
              // Show abbreviated month name if space is limited
              label = nyc.toLocaleString('default', { month: 'short' });
            }
          } else if (pixels_per_candle >= 10 && nyc.getDate() === 15) {
            // Show day number for the middle of the month if there's enough space
            label = nyc.getDate().toString();
          }
          break;

          case '1w':
            if (pixels_per_candle > 8) {
              if (is_first_of_year(timestampArray, i)) {
                label = `${nyc.getFullYear()}`;
              } else if (is_first_of_month(timestampArray, i)) {
                label = `${nyc.toLocaleString('default', { month: 'short' })}`;
              }
            } else if (pixels_per_candle > 4) {
              if (is_first_of_year(timestampArray, i)) {
                label = `${nyc.getFullYear()}`;
              } else if (is_quarter(timestampArray, i)) {
                label = `${nyc.toLocaleString('default', { month: 'short' })}`;
              }
            } else if (pixels_per_candle > 2) {
              if (is_first_of_year(timestampArray, i)) {
                label = `${nyc.getFullYear()}`;
              } else if (is_half_year(timestampArray, i)) {
                label = `${nyc.toLocaleString('default', { month: 'short' })}`;
              }
            } else {
              if (is_first_of_year(timestampArray, i)) {
                label = `${nyc.getFullYear()}`;
              }
            }
            break;

        case '1mo':
          if (numCandles < 150 && nyc.getMonth() === 0) {
            label = `${nyc.getFullYear()}`
          }
          break
      }
    }

    // NOTE: problem is lack of timestamp in the timestamp array, because numCandles is higher

    if (label) {
      result.push({ label, timestamp: date?.toISOString() || '' })
    }
  }

    // New logic to remove labels that are too close to each other
  const filteredResult = result.filter((item: any, index, array) => {
    if (index === 0) return true; // Always keep the first label

    const prevItem: any = array[index - 1];
    const distance = item.index - prevItem.index;

    if (distance <= 10) {
      // If labels are too close, keep the current one and "remove" the previous one
      prevItem.label = '';
      return true;
    }

    return true;
  });

  // Remove empty labels
  return filteredResult.filter(item => item.label !== '').map(({ label, timestamp }) => ({ label, timestamp }));

  // return result
}

const is_half_year = (timestampArray: number[], index: number): boolean => {
  const current = new Date(timestampArray[index])
  const prev = new Date(timestampArray[index - 1] || 0)
  return current.getMonth() === 6 && prev.getMonth() !== 6
}

const is_every_other_year = (timestampArray: number[], index: number): boolean => {
  const current = new Date(timestampArray[index])
  const prev = new Date(timestampArray[index - 1] || 0)
  return current.getFullYear() % 2 === 0 && prev.getFullYear() !== current.getFullYear()
}

const is_first_of_month = (timestampArray: number[], i: number) => {
	const date = new Date(timestampArray[i])
	const prevDate = new Date(timestampArray[i - 1])
	return date.getMonth() !== prevDate.getMonth()
}

// True if first of January, April, July, or October
const is_quarter = (timestampArray: number[], i: number) => {
	const date = new Date(timestampArray[i])
	const prevDate = new Date(timestampArray[i - 1])
	return Math.floor(date.getMonth() / 3) !== Math.floor(prevDate.getMonth() / 3)
}

const is_first_of_year = (timestampArray: number[], i: number) => {
	const date = new Date(timestampArray[i])
	const prevDate = new Date(timestampArray[i - 1])
	return date.getFullYear() !== prevDate.getFullYear()
}

// Returns 15, 16, or 16, or false
const is_middle_of_month_and_get = (timestampArray: number[], i: number) => {
	const date = new Date(timestampArray[i])
	if (date.getDay() === 15) return 15
	if (date.getDay() === 16) {
		const prevDate = new Date(timestampArray[i - 1])
		if (prevDate.getDay() !== 15) return 16
	}
	if (date.getDay() === 17) {
		const prevDate = new Date(timestampArray[i - 1])
		if (prevDate.getDay() !== 16) return 17
	}
	return false
}


export const getDayOfWeek = (date: Date) => {
	const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
	return days[date.getDay()]
}

export const getNYCDate = (date: Date) => {
	const nycTimezone = 'America/New_York'
	return utcToZonedTime(date, nycTimezone)
}

// 11a, 3p
export const getHours = (date: Date) => {
  const milHours = date.getHours();
  if (milHours === 0) {
      return '12a';
  } else if (milHours < 12) {
      return `${milHours}a`;
  } else if (milHours === 12) {
      return '12p';
  } else {
      return `${milHours - 12}p`;
  }
}

// 11:30a, 3:35p
export const getHoursMinutes = (date: Date) => {
  const milHours = date.getHours();
  const minutes = date.getMinutes();

  if (minutes === 0) {
      return getHours(date);
  }

  const formattedHours = milHours === 0 ? 12 : (milHours > 12 ? milHours - 12 : milHours);
  const ampm = milHours < 12 ? 'a' : 'p';

  return `${formattedHours}:${minutes.toString().padStart(2, '0')}${ampm}`;
}

export const getDate = (date: Date, includeYear = true) => {
  const month = date.getMonth() + 1; // getMonth() returns 0-11
  const day = date.getDate();
  const year = date.getFullYear();
  if (includeYear) {
      return `${month}/${day}/${year.toString().slice(2)}`;
  } else {
      return `${month}/${day}`;
  }
}


export const getDateOrMonth = (date: Date, timestampArray: number[], includeYear = false) => {
  const firstTimestampDate = new Date(timestampArray[0]);

  // Convert all timestamps to Date objects
  const dateArray: any = timestampArray.map(ts => new Date(ts));

  // Check if the date is the first occurrence of that month in timestampArray
  const isFirstDateOfMonthInArray = () => {
      const datesOfTheSameMonth = dateArray.filter((d: { getMonth: () => number; getFullYear: () => number }) => d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear());
      if (datesOfTheSameMonth.length === 0) return false;
      const earliestDateOfTheMonth = new Date(Math.min(...datesOfTheSameMonth));
      return earliestDateOfTheMonth.toDateString() === date.toDateString();
  }

  const formatDate = (dateObj: Date) => {
      const month = dateObj.getMonth() + 1; // getMonth() returns 0-11
      const day = dateObj.getDate();

      if (includeYear) {
          const year = dateObj.getFullYear().toString().slice(-2);
          return `${month}/${day}/${year}`;
      } else {
          return `${month}/${day}`;
      }
  }

  if (date.toDateString() === firstTimestampDate.toDateString()) {
      return formatDate(date);
  } else if (isFirstDateOfMonthInArray()) {
      // Change this part to return the month name instead of the date
      return date.toLocaleString('default', { month: 'short' });
  } else {
      return date.getDate().toString();
  }
}






/* NEWER, EXPERIMENTAL SYSTEM */
// interface LabelData {
//   label: string;
//   timestamp: string;
//   bold: boolean;
//   index: number;
//   priority: number;
// }

// type LabelingRegime = {
//   condition: (interval: string, numCandles: number) => boolean;
//   getLabelData: (date: Date, prevDate: Date, index: number) => LabelData | null;
// };

// const labelingRegimes: LabelingRegime[] = [
//   {
//     condition: (interval) => ['1m', '5m'].includes(interval),
//     getLabelData: (date, prevDate, index) => {
//       if (date.getFullYear() !== prevDate.getFullYear()) {
//         return { label: date.getFullYear().toString(), timestamp: date.toISOString(), bold: true, index, priority: 5 };
//       }
//       if (date.getMonth() !== prevDate.getMonth()) {
//         return { label: date.toLocaleString('default', { month: 'short' }), timestamp: date.toISOString(), bold: true, index, priority: 4 };
//       }
//       if (date.getDate() !== prevDate.getDate()) {
//         return { label: date.getDate().toString(), timestamp: date.toISOString(), bold: true, index, priority: 3 };
//       }
//       if (date.getHours() !== prevDate.getHours()) {
//         return { label: formatTime(date), timestamp: date.toISOString(), bold: false, index, priority: 2 };
//       }
//       if (date.getMinutes() % 30 === 0 && prevDate.getMinutes() % 30 !== 0) {
//         return { label: formatMinutes(date), timestamp: date.toISOString(), bold: false, index, priority: 1 };
//       }
//       return null;
//     }
//   },
//   {
//     condition: (interval) => ['15m', '30m', '1h'].includes(interval),
//     getLabelData: (date, prevDate, index) => {
//       if (date.getFullYear() !== prevDate.getFullYear()) {
//         return { label: date.getFullYear().toString(), timestamp: date.toISOString(), bold: true, index, priority: 4 };
//       }
//       if (date.getMonth() !== prevDate.getMonth()) {
//         return { label: date.toLocaleString('default', { month: 'short' }), timestamp: date.toISOString(), bold: true, index, priority: 3 };
//       }
//       if (date.getDate() !== prevDate.getDate()) {
//         return { label: date.getDate().toString(), timestamp: date.toISOString(), bold: true, index, priority: 2 };
//       }
//       if (date.getHours() !== prevDate.getHours()) {
//         return { label: formatTime(date), timestamp: date.toISOString(), bold: false, index, priority: 1 };
//       }
//       return null;
//     }
//   },
//   {
//     condition: (interval) => ['2h', '4h', '1d'].includes(interval),
//     getLabelData: (date, prevDate, index) => {
//       if (date.getFullYear() !== prevDate.getFullYear()) {
//         return { label: date.getFullYear().toString(), timestamp: date.toISOString(), bold: true, index, priority: 3 };
//       }
//       if (date.getMonth() !== prevDate.getMonth()) {
//         return { label: date.toLocaleString('default', { month: 'short' }), timestamp: date.toISOString(), bold: true, index, priority: 2 };
//       }
//       if (date.getDate() !== prevDate.getDate()) {
//         return { label: date.getDate().toString(), timestamp: date.toISOString(), bold: false, index, priority: 1 };
//       }
//       return null;
//     }
//   },
//   {
//     condition: (interval) => ['1w'].includes(interval),
//     getLabelData: (date, prevDate, index) => {
//       if (date.getFullYear() !== prevDate.getFullYear()) {
//         return { label: date.getFullYear().toString(), timestamp: date.toISOString(), bold: true, index, priority: 2 };
//       }
//       if (date.getMonth() !== prevDate.getMonth()) {
//         return { label: date.toLocaleString('default', { month: 'short' }), timestamp: date.toISOString(), bold: true, index, priority: 1 };
//       }
//       return null;
//     }
//   },
// ];

// export const make_x_axis_labels = (
//   timestampArray: number[],
//   interval: string,
//   numCandles: number,
//   chartWidth: number
// ): LabelData[] => {
//   const nycDates = timestampArray.map(ts => getNYCDate(new Date(ts)));
//   // const firstDate = nycDates[0];
//   // const lastDate = nycDates[nycDates.length - 1];

//   const regime = labelingRegimes.find(r => r.condition(interval, numCandles));
//   if (!regime) {
//     console.warn('No suitable labeling regime found. Using default labeling.');
//     return defaultLabeling(nycDates, chartWidth);
//   }

//   const allLabels: LabelData[] = [];

//   for (let i = 0; i < nycDates.length; i++) {
//     const date = nycDates[i];
//     const prevDate = i > 0 ? nycDates[i - 1] : new Date(date.getTime() - 1);

//     const labelData = regime.getLabelData(date, prevDate, i);
//     if (labelData) {
//       allLabels.push(labelData);
//     }
//   }

//   return distributeLabelsDensity(allLabels, chartWidth, numCandles, interval);
// };

// const distributeLabelsDensity = (
//   labels: LabelData[],
//   chartWidth: number,
//   numCandles: number,
//   interval: string
// ): LabelData[] => {
//   const targetPixelsBetweenLabels = getTargetPixelsBetweenLabels(interval);
//   const pixelsPerCandle = chartWidth / numCandles;
//   const targetCandlesBetweenLabels = Math.max(1, Math.round(targetPixelsBetweenLabels / pixelsPerCandle));

//   const selectedLabels: LabelData[] = [];
//   let lastSelectedIndex = -Infinity;

//   // Ensure we always include the first and last label
//   if (labels.length > 0) {
//     selectedLabels.push(labels[0]);
//     lastSelectedIndex = labels[0].index;
//   }

//   for (let i = 1; i < labels.length - 1; i++) {
//     const label = labels[i];
//     const nextLabel = labels[i + 1];

//     // Always include high priority labels (year, month, day)
//     if (label.priority >= 3) {
//       selectedLabels.push(label);
//       lastSelectedIndex = label.index;
//       continue;
//     }

//     // For hour labels in short intervals, ensure we include them more frequently
//     if (['1m', '5m', '15m', '30m'].includes(interval) && label.priority === 2) {
//       if (label.index - lastSelectedIndex >= Math.max(1, targetCandlesBetweenLabels / 2)) {
//         selectedLabels.push(label);
//         lastSelectedIndex = label.index;
//         continue;
//       }
//     }

//     // Include this label if it's far enough from the last one and the next important label
//     if (label.index - lastSelectedIndex >= targetCandlesBetweenLabels &&
//         (nextLabel.priority < 3 || nextLabel.index - label.index >= targetCandlesBetweenLabels)) {
//       selectedLabels.push(label);
//       lastSelectedIndex = label.index;
//     }
//   }

//   // Ensure we always include the last label if it's not too close to the previous one
//   if (labels.length > 1 && labels[labels.length - 1].index - lastSelectedIndex >= targetCandlesBetweenLabels) {
//     selectedLabels.push(labels[labels.length - 1]);
//   }

//   return selectedLabels;
// };

// const getTargetPixelsBetweenLabels = (interval: string): number => {
//   switch (interval) {
//     case '1m':
//     case '5m':
//       return 50;  // More frequent labels for short intervals
//     case '15m':
//     case '30m':
//       return 75;
//     default:
//       return 100;
//   }
// };

// const formatTime = (date: Date): string => {
//   const hours = date.getHours();
//   const minutes = date.getMinutes();
//   const ampm = hours >= 12 ? 'p' : 'a';
//   const formattedHours = hours % 12 || 12;
//   return `${formattedHours}${minutes === 0 ? '' : `:${minutes.toString().padStart(2, '0')}`}${ampm}`;
// };

// const formatMinutes = (date: Date): string => {
//   return date.getMinutes().toString().padStart(2, '0');
// };

// const formatDateForLog = (date: Date): string => {
//   return date.toLocaleString('en-US', {
//     weekday: 'short',
//     year: 'numeric',
//     month: 'short',
//     day: 'numeric',
//     hour: '2-digit',
//     minute: '2-digit',
//     second: '2-digit',
//     timeZoneName: 'short'
//   });
// };

// const getNYCDate = (date: Date): Date => {
//   const nycTimezone = 'America/New_York';
//   return utcToZonedTime(date, nycTimezone);
// };

// const defaultLabeling = (dates: Date[], chartWidth: number): LabelData[] => {
//   const allLabels = dates.map((date, index) => ({
//     label: formatTime(date),
//     timestamp: date.toISOString(),
//     bold: false,
//     index: index,
//     priority: 0
//   }));
//   return distributeLabelsDensity(allLabels, chartWidth, dates.length, '1m');
// };