import { useAtom } from 'jotai'
import {
  loggedInUserAtom,
  openModalAtom,
  selectedEmptyChartAtom,
  streamingQuotesAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { db, currentUser } from '../../firebase'
import {
  DropdownMenu,
  Icon,
} from '../reusable'
import React, { useState, useEffect } from 'react'
import { TEXT_GRAY, returnChartPaneContextObj, set_open_chart, updateLiveData } from '../../logic/u'
import { CreateOrderModal } from '../modals/CreateOrderModal'
import { ClosePositionModal } from '../modals/ClosePositionModal'
import { TickerModal } from '../modals/TickerModal'
import { CancelOpenOrderModal } from '../modals/CancelOpenOrderModal'

interface TickerDropdownProps {
  symbol: string 
  watchlistId?: string
  orderObj?: any // order obj
  left: number
  top: number
  width: number
  onClose: () => void
  fromTable?: boolean
  fromChart?: boolean
  priceForY?: number
  marketPrice?: number
}

export const TickerDropdown = (props: TickerDropdownProps) => {
  const [user ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [selectedEmptyChart] = useAtom(selectedEmptyChartAtom)


  if (!user) return null

  const {symbol, watchlistId, orderObj, left, top, width, onClose, fromTable, fromChart, priceForY} = props

  const isPosition = Object.keys(uld?.liveData.POSITIONS).includes(symbol)
  const marketPrice = uld?.liveData[symbol].current_price

  interface DropdownItem {
    display: JSX.Element
    isDisabled?: boolean
    onClick?: () => void
  }
  let contents: DropdownItem[] = []
  contents.push(
    {
      display: (
        <div className='font-size-10 gray-text'>
          {symbol}
        </div>
      )
    },
  )
  if (orderObj) {
    contents.push(
      {
        display: (
          <div className='left'>
            <Icon
              icon='file-circle-xmark'
              set='sharp-solid'
              size={10.5}
              className={orderObj && ['filled', 'canceled', 'expired'].includes(orderObj?.status) ? 'menu-item-icon-disabled' : ''}
              style={{marginRight: 10, marginTop: 0}}
            />   
            <div>Cancel order</div>
          </div>
        ),
        isDisabled: orderObj && ['filled', 'canceled', 'expired'].includes(orderObj?.status),
        onClick: () => {
          setOpenModal(<CancelOpenOrderModal symbol={symbol} orderId={orderObj.id} />)
        }
      },
      {
        display: (
          <div
            style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginTop: 3, marginBottom: 5}}
          ></div>
        )
      },
    )
  }

  if (fromChart) {
    contents.push(
      {
        display: (
          <div className='left'>  
            <Icon
              icon='circle-arrow-up'
              set='sharp-solid'
              size={9}
              style={{marginRight: 10, marginTop: 0}}
              className={priceForY && priceForY > marketPrice ? 'menu-item-icon-disabled' : ''}
            />        
            <div>Buy limit {priceForY}</div>
          </div>
        ),
        isDisabled: priceForY ? priceForY > marketPrice : false,
        onClick: () => {
          setOpenModal(<CreateOrderModal symbol={symbol} limitPrice={priceForY} />)
        }
      },
      {
        display: (
          <div className='left'>  
            <Icon
              icon='circle-arrow-down'
              set='sharp-solid'
              size={9}
              style={{marginRight: 10, marginTop: 0}}
              className={!isPosition || (priceForY ? priceForY < marketPrice : false) ? 'menu-item-icon-disabled' : ''}
            />     
            <div>Sell limit {priceForY}</div>
          </div>
        ),
        isDisabled: !isPosition || (priceForY ? priceForY < marketPrice : false),
        onClick: () => {
          setOpenModal(<CreateOrderModal symbol={symbol} sell={true} limitPrice={priceForY} />)
        }
      },
      // {
      //   display: (
      //     <div className='left'>  
      //       <Icon
      //         icon='caret-up'
      //         set='sharp-solid'
      //         size={9}
      //         style={{marginRight: 10, marginTop: 1.5}}
      //         className={priceForY && priceForY < marketPrice ? 'menu-item-icon-disabled' : ''}
      //       />        
      //       <div>Buy stop {priceForY}</div>
      //     </div>
      //   ),
      //   isDisabled: priceForY ? priceForY < marketPrice : false,
      //   onClick: () => {
      //     setOpenModal(<CreateOrderModal symbol={symbol} stopPrice={priceForY} />)
      //   }
      // },
      // {
      //   display: (
      //     <div className='left'>  
      //       <Icon
      //         icon='caret-down'
      //         set='sharp-solid'
      //         size={9}
      //         style={{marginRight: 10, marginTop: .25}}
      //         className={priceForY && priceForY > marketPrice ? 'menu-item-icon-disabled' : ''}
      //       />       
      //       <div>Sell stop {priceForY}</div>
      //     </div>
      //   ),
      //   isDisabled: !isPosition || (priceForY ? priceForY > marketPrice : false),
      //   onClick: () => {
      //     setOpenModal(<CreateOrderModal symbol={symbol} sell={true} stopPrice={priceForY} />)
      //   }
      // },
      {
        display: (
          <div
            style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginTop: 3, marginBottom: 5}}
          ></div>
        )
      },
    )
  }
  
  
  contents.push(
    {
      display: (
        <div className='left'>  
          <Icon
            icon='circle-arrow-up'
            set='sharp-solid'
            size={9}
            style={{marginRight: 10, marginTop: 0}}
          />        
          <div>Buy</div>
        </div>
      ),
      onClick: () => {
        setOpenModal(<CreateOrderModal symbol={symbol} />)
      }
    },
    {
      display: (
        <div className='left'>
          <Icon
            icon='circle-arrow-down'
            set='sharp-solid'
            size={9}
            style={{marginRight: 10, marginTop: 0}}
            className={!isPosition ? 'menu-item-icon-disabled' : ''}
          /> 
          <div>Sell</div>
        </div>
      ),
      isDisabled: !isPosition,
      onClick: async () => {
        setOpenModal(<CreateOrderModal symbol={symbol} sell={true} />)
      }
    },
    {
      display: (
        <div className='left'>
          <Icon
            icon='folder-closed'
            set='sharp-solid'
            size={9}
            style={{marginRight: 10}}
            className={!isPosition ? 'menu-item-icon-disabled' : ''}
          /> 
          <div>Close position</div>
        </div>
      ),
      isDisabled: !isPosition,
      onClick: async () => {
        setOpenModal(<ClosePositionModal symbol={symbol} />)
      }
    },
    {
      display: (
        <div className='left'>
          <Icon
            icon='octagon-minus'
            set='sharp-solid'
            size={9}
            style={{marginRight: 10}}
            className={!isPosition ? 'menu-item-icon-disabled' : ''}
          /> 
          <div>Cancel open orders</div>
        </div>
      ),
      isDisabled: !isPosition,
      onClick: async () => {
        setOpenModal(<CancelOpenOrderModal symbol={symbol} />)
      }
    },
  )

  if (watchlistId) {
    contents.push(
      {
       display: (
         <div
           style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginTop: 3, marginBottom: 5}}
         ></div>
       )
     },
     {
       display: (
         <div className='left'>
            <Icon
              icon='trash'
              set='sharp-solid'
              size={9}
              style={{marginRight: 10, marginTop: .5}}
            /> 
           <div>Remove from watchlist</div>
         </div>
       ),
       onClick: async () => {
        const tickers = user?.watchlists.find(w => w.id === watchlistId)?.tickers            
        const updatedTickers = tickers?.filter(t => t !== symbol)
        let charts = user?.charts
        if (charts) {
          delete charts[symbol]
          await db.collection('users').doc(user?.uid).update({
            watchlists: user?.watchlists.map(w =>
              w.id === watchlistId ? { ...w, tickers: updatedTickers, updatedAt: new Date() } : w
            ),
            charts
          })
          const chartContext = returnChartPaneContextObj(user, symbol)
          if (chartContext?.chartIsVisible) {
            const panes = chartContext.panesWhereChartIsVisible
            if (panes.length) {
              panes.forEach(p => {
                set_open_chart(user, uld, p, null)
              })
            }
          }
          await updateLiveData(user)
        }
       }
     },
    )
  }

  contents.push(
    {
      display: (
        <div
          style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginTop: 3, marginBottom: 5}}
        ></div>
      )
    },
    {
      display: (
        <div className='left'>
          <Icon
            icon='memo-circle-info'
            set='sharp-solid'
            size={10.5}
            style={{marginRight: 10, marginTop: .5}}
            // className={!isPosition ? 'menu-item-icon-disabled' : ''}
          /> 
          <div>View ticker details</div>
        </div>
      ),
      // isDisabled: !isPosition,
      onClick: () => {
        setOpenModal(<TickerModal symbol={symbol} />)
      }
    },
  )

  if (!fromTable && !fromChart) {
    contents.push(
      {
       display: (
         <div
           style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginTop: 3, marginBottom: 5}}
         ></div>
       )
      },
      {
       display: (
         <div className='left'>
            <Icon
              icon='chart-candlestick'
              set='sharp-solid'
              size={10}
              style={{marginRight: 10, marginTop: .5}}
            />
           <div>View chart</div>
         </div>
       ),
       onClick: () => {
         set_open_chart(user, uld, selectedEmptyChart, symbol)
       }
     },
    )
  }

  const chartConext = returnChartPaneContextObj(user, symbol)

  if (chartConext?.panesWhereChartIsVisible.length) {
    contents.push(
      {
        display: (
          <div
            style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginTop: 3, marginBottom: 5}}
          ></div>
        )
       },
     {
       display: (
         <div className='left'>
            <Icon
              icon='circle-x'
              set='sharp-solid'
              size={9}
              style={{marginRight: 10}}
            />
           <div>{chartConext.panesWhereChartIsVisible.length === 1 ? 'Clear chart slot' : 'Clear chart slots'}</div>
         </div>
       ),
       onClick: () => {
         chartConext.panesWhereChartIsVisible.forEach(pNum => {
           set_open_chart(user, uld, pNum, null)
         })
       }
     },
    )
  }

  return (
    <DropdownMenu
      //@ts-ignore
      posLeft={left}
      posTop={top}
      width={width}
      height={500}
      onClose={() => onClose()}
      noArrowKeys={true}
      contents={contents}
    />
  )
}