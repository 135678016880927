import React, { useMemo } from "react"
import {
  DEFAULT_INDICATOR_COLORS,
  format_price_appropriately,
} from "../../logic/u"
import { useAtom } from 'jotai'
import { Icon2 } from '../reusable/Icon2'
import { Indicator } from '../../types/user_types'
import { EditIndicatorModal } from '../modals/EditIndicatorModal'
import { CANDLE_RED, CANDLE_GREEN } from '../../logic/colors'
import { ohlcAtom, openModalAtom, loggedInUserAtom } from '../../types/global_types'

interface Point {
  x: number;
  val: number;
  isFutureCandle?: boolean;
}

export interface IndicatorSeriesData {
  data: Point[]
  color: string
  weight: number
  style: 'solid' | 'dashed' | 'histogram'
  name: string
}

export interface OscillatorPlotParams {
  zero_band: boolean
  upper_band: number | null
  lower_band: number | null
  enforce_zero?: boolean
  max: number | null
  min: number | null
  auto_limits: boolean
}

interface OscillatorPlotProps {
  title: string
  width: number
  series_data: IndicatorSeriesData[]
  indicator: Indicator
  symbol: string
}

export const OscillatorPlot = (props: OscillatorPlotProps) => {
  const { title, width, series_data, indicator, symbol } = props

  const [ohlcG] = useAtom(ohlcAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [user] = useAtom(loggedInUserAtom)

  if (!user) return null

  const CHART_HEIGHT = 80
  const LABEL_OFFSET = 55
  const SVG_WIDTH = width - LABEL_OFFSET

  if (!indicator?.oscillator_settings) return null

  const zero_band = indicator.oscillator_settings?.zero_band || false
  const upper_band = indicator.oscillator_settings?.upper_band || null
  const lower_band = indicator.oscillator_settings?.lower_band || null
  const auto_limits = indicator.oscillator_settings?.auto_limits || true
  const enforce_zero = indicator.oscillator_settings?.enforce_zero || false

  const filterValidData = (data: Point[]): Point[] => {
    const lastValidIndex = data.findIndex(point => point.isFutureCandle === true);
    return lastValidIndex === -1 ? data : data.slice(0, lastValidIndex);
  }

  const [min, max, dataMin, dataMax] = useMemo(() => {
    let localMin = Infinity
    let localMax = -Infinity
    let allZero = true

    for (let series of series_data) {
      const filteredData = filterValidData(series.data);
      for (let point of filteredData) {
        const val = point.val
        if (val !== undefined && val !== null) {
          if (val !== 0) allZero = false
          if (val > localMax) localMax = val
          if (val < localMin) localMin = val
        }
      }
    }

    let dataMin = localMin
    let dataMax = localMax

    if (allZero && enforce_zero) {
      return [-0.2, 1.2, 0, 0]
    }

    if (auto_limits) {
      if (upper_band !== null && lower_band !== null) {
        const bandCenter = (upper_band + lower_band) / 2
        const bandRange = upper_band - lower_band
        const dataRange = dataMax - dataMin
        const effectiveRange = Math.max(bandRange, dataRange)
        const padding = effectiveRange * 0.1
        localMin = Math.min(dataMin, lower_band, bandCenter - (effectiveRange / 2)) - padding
        localMax = Math.max(dataMax, upper_band, bandCenter + (effectiveRange / 2)) + padding
      } else if (zero_band) {
        const absoluteMax = Math.max(Math.abs(localMin), Math.abs(localMax))
        localMin = -absoluteMax
        localMax = absoluteMax
      } else {
        const range = localMax - localMin
        localMax += range * 0.1
        localMin -= range * 0.1
      }

      if (enforce_zero && localMin > 0) {
        const offset = localMax * 0.2
        localMin = 0 - offset
        localMax = localMax + offset
      }
    } else {
      localMin = indicator.oscillator_settings?.min || 0
      localMax = indicator.oscillator_settings?.max || 0
    }

    return [localMin, localMax, dataMin, dataMax]
  }, [series_data, auto_limits, zero_band, enforce_zero, upper_band, lower_band, indicator.oscillator_settings])

  const [effectiveMin, effectiveMax] = [min, max];

  // Update window properties with effectiveMin and effectiveMax
  //@ts-ignore
  window.oscillatorChartMin = effectiveMin
  //@ts-ignore
  window.oscillatorChartMax = effectiveMax

  const labelPositions = useMemo(() => {
    let positions: any = [];
    const range = dataMax - dataMin;
    const significantNegativeRange = dataMin < 0 && Math.abs(dataMin) > range * 0.1;
    const isRSILike = lower_band !== null && upper_band !== null && lower_band >= 0;

    if (isRSILike) {
      if (upper_band !== null) positions.push({ perc: (upper_band - effectiveMin) / (effectiveMax - effectiveMin), value: upper_band });
      if (lower_band !== null) positions.push({ perc: (lower_band - effectiveMin) / (effectiveMax - effectiveMin), value: lower_band });
    } else {
      const absMax = Math.max(Math.abs(dataMin), Math.abs(dataMax))
      positions.push({ perc: (dataMax - effectiveMin) / (effectiveMax - effectiveMin), value: absMax });
      positions.push({ perc: (dataMin - effectiveMin) / (effectiveMax - effectiveMin), value: -absMax });

      if (effectiveMin <= 0 && effectiveMax >= 0 && dataMin !== 0 && dataMax !== 0) {
        positions.push({ perc: (0 - effectiveMin) / (effectiveMax - effectiveMin), value: 0 });
      }

      if (upper_band !== null && upper_band >= effectiveMin && upper_band <= effectiveMax) {
        positions.push({ perc: (upper_band - effectiveMin) / (effectiveMax - effectiveMin), value: upper_band });
      }
      if (lower_band !== null && lower_band >= effectiveMin && lower_band <= effectiveMax) {
        positions.push({ perc: (lower_band - effectiveMin) / (effectiveMax - effectiveMin), value: lower_band });
      }

      if (positions.length < 3) {
        let intermediate;
        if (significantNegativeRange) {
          intermediate = dataMax / 2;
        } else {
          intermediate = (Math.max(0, dataMin) + dataMax) / 2;
        }

        const magnitude = Math.pow(10, Math.floor(Math.log10(Math.abs(intermediate))));
        intermediate = Math.round(intermediate / magnitude) * magnitude;

        if (intermediate !== 0 && intermediate !== dataMax && intermediate !== dataMin &&
            (upper_band === null || intermediate !== upper_band) &&
            (lower_band === null || intermediate !== lower_band)) {
          positions.push({ perc: (intermediate - effectiveMin) / (effectiveMax - effectiveMin), value: intermediate });
        }
      }
    }

    return positions.sort((a, b) => b.perc - a.perc)
      .filter((pos, index, self) =>
        index === self.findIndex((t) => t.value === pos.value)
      );
  }, [effectiveMin, effectiveMax, dataMin, dataMax, upper_band, lower_band]);

  const get_y_for_val = (val: number) => {
    return CHART_HEIGHT - ((val - effectiveMin) / (effectiveMax - effectiveMin) * CHART_HEIGHT);
  }

  const y_axis_labels = labelPositions.map((pos, idx) => (
    <text
      key={`label-${idx}`}
      x={pos.value >= 0 ? SVG_WIDTH + 63 : SVG_WIDTH + 56}
      y={get_y_for_val(pos.value) + 4}
      fontSize="13"
      fill="#999999"
      textAnchor="start"
    >
      {pos.value.toFixed(2)}
    </text>
  ))

  const paths = series_data.map((series) => {
    const filteredData = filterValidData(series.data);

    if (series.style === 'histogram') {
      return filteredData.map((point, index) => {
        const val = point.val
        if (val !== undefined && val !== null) {
          const y = get_y_for_val(val)
          const height = Math.abs(CHART_HEIGHT / 2 - y)
          const barWidth = 5 // Adjustable bar width
          const x = point.x - barWidth / 2
          const barY = val >= 0 ? y : CHART_HEIGHT / 2
          const color = val >= 0 ? CANDLE_GREEN : CANDLE_RED
          return (
            <rect
              key={`histogram-${series.name}-${index}`}
              x={x}
              y={barY}
              width={barWidth}
              height={height}
              fill={color}
            />
          )
        }
        return null
      })
    } else {
      let pathData = ''
      const validPoints = series.data.filter(point =>
        point.val !== undefined && point.val !== null && !isNaN(point.val)
      );

      if (validPoints.length > 0) {
        pathData = `M ${validPoints[0].x} ${get_y_for_val(validPoints[0].val)}`
        for (let i = 1; i < validPoints.length; i++) {
          pathData += ` L ${validPoints[i].x} ${get_y_for_val(validPoints[i].val)}`
        }
      }

      return pathData ? (
        <path
          d={pathData}
          stroke={series.color || DEFAULT_INDICATOR_COLORS['RSI']}
          strokeWidth={series.weight || 2}
          strokeDasharray={series.style === 'dashed' ? '5,5' : 'none'}
          fill="none"
          key={series.name}
        />
      ) : null
    }
  })

  const indicator_value_divs: any = []
  let indicator_plots = Object.keys(indicator.plots);
  indicator_plots = indicator_plots.sort();
  const is_single_plot = indicator_plots.length === 1;

  const plot_colors: any = [];
  indicator_plots.forEach((plot_key: string) => {
    const color = indicator.plots[plot_key].color || DEFAULT_INDICATOR_COLORS[indicator.value];
    plot_colors.push(color);
  });

  if (is_single_plot) {
    let ind_val = ''
    if (ohlcG?.oscillators && indicator.value) {
      ind_val = ohlcG.oscillators[indicator.value];
    }
    const formatted = format_price_appropriately(ind_val);
    indicator_value_divs.push(
      <div
        key="single-plot"
        className={'value-label'}
        style={{ color: plot_colors[0] }}
        title={indicator.value}
      >
        {formatted || 'n/a'}
      </div>
    )
  } else {
    indicator_plots.forEach((plot_key: string) => {
      let ind_val = ohlcG?.oscillators?.[indicator.abbrev_str]?.[plot_key] || '';
      const formatted = format_price_appropriately(ind_val);
      const plot_color = indicator.plots[plot_key].color || DEFAULT_INDICATOR_COLORS[indicator.value];
      indicator_value_divs.push(
        <div
          key={plot_key}
          className={'value-label'}
          style={{ color: plot_color }}
          title={plot_key}
        >
          {formatted || 'n/a'}
        </div>
      );
    })
  }

  return (
    <div className='oscillator-plot'>
      <div className='ind-row plot'>
        <div className={indicator.isHidden ? 'ind-label hidden-from-chart' : 'ind-label'} title={indicator.abbrev_str_hovertext}>{indicator.abbrev_str}</div>
        {!indicator.isHidden ? <div className={'ind-value-container'}>{indicator_value_divs}</div> : null}
        <div className='ind-icon-container'>
          <div className='other-icons-container'>
            <Icon2
              className={'row-icon'}
              icon='gear'
              size={11}
              style={{ marginLeft: -2, marginRight: -2, marginTop: .5}}
              onClick={() => {
                setOpenModal(
                  <EditIndicatorModal
                    symbol={props.symbol}
                    intervalId={indicator.id}
                    onClose={() => setOpenModal(null)}
                    modalTitle={indicator.abbrev_str}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>

      <svg style={{
        width: width,
        height: CHART_HEIGHT,
        borderTop: '1px solid #282828',
        borderBottom: '1px solid #282828',
        overflow: 'visible'
      }}>
        {zero_band ? <line
          x1={0}
          y1={get_y_for_val(0)}
          x2={SVG_WIDTH}
          y2={get_y_for_val(0)}
          stroke="#444444"
          strokeWidth="1"
          key='zero-line'
        /> : null}
        {upper_band !== null ? <line
          x1={0}
          y1={get_y_for_val(upper_band)}
          x2={SVG_WIDTH}
          y2={get_y_for_val(upper_band)}
          stroke="#444444"
          strokeWidth="1"
          strokeDasharray="5,5"
          key='upper-bound'
        /> : null}
        {lower_band !== null ? <line
          x1={0}
          y1={get_y_for_val(lower_band)}
          x2={SVG_WIDTH}
          y2={get_y_for_val(lower_band)}
          stroke="#444444"
          strokeWidth="1"
          strokeDasharray="5,5"
          key='lower-bound'
        /> : null}
        {paths}
        {y_axis_labels}
      </svg>
    </div>
  )
}