import {
  Icon,
} from '../reusable'
import { logout } from '../../firebase'
import React, { useState } from 'react'
import { DocumentsTab } from './DocumentsTab'
import { SettingsTab } from './SettingsTab'
import { SubscriptionTab } from './SubscriptionTab'
import { DisclosuresTab } from './DisclosuresTab'
import { AdminSocket } from './AdminSocket'

const ACCOUNT_SUBTABS = [
  {display: 'Socket server', value: 'socket'},
  {display: 'scheduledTick', value: 'scheduledTick'},
]

export function AdminTab() {
  const [ subTab, setSubTab ] = useState('socket')

  let subTabContent: any = null
  switch (subTab) {
    case 'socket':
      subTabContent = <AdminSocket />
      break
    case 'scheduledTick':
      subTabContent = <div>not implemented</div>
      break
  }

  return (
    <div className='tab-parent'>
      <div className='tab-header'>
        <div>
          Internal admin dashboard
        </div>
        <div
          className='row align-center font-size-13 white-text pointer'
          onClick={() => {
            logout()
          }}
        >
          <Icon
            icon='right-from-bracket'
            set='sharp-solid'
            className='margin-right-5'
          />
          <div>Sign out</div>
        </div>
      </div>
      <div className='subtab-header'>
        {ACCOUNT_SUBTABS.map((s) => {
          let className = 'subtab'
          if (s.value === subTab) {
            className += ' selected'
          }
          return (
            <div
              className={className}
              onClick={() => setSubTab(s.value)}
            >
              {s.display}
            </div>
          )
        })}
      </div>
      {subTabContent}
    </div>
  )
}
