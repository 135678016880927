import React, { useState, useEffect, useRef } from 'react'
import { Modal, Input, SliderToggle, Icon, showModal, DropdownSelect, CurrencyInput, Button } from '../reusable'
import axios  from 'axios'
import { currentUser, db } from '../../firebase'
import { getConfig } from '../../config'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  consoleOutputAtom,
  functionExecutingAtom,
} from '../../types/global_types'
import { useAtom } from 'jotai'
import { ErrorModal } from './ErrorModal'
import firebase from 'firebase/app';
import { BORDER_INPUT, formatNumberForTable, formatStockPrice, returnChartPaneContextObj, stringToFormattedAmount, updateLiveData, valueForTable } from '../../logic/u'
import { Editor, loader } from '@monaco-editor/react'
import { CodeConsole } from '../functions/CodeConsole'
import { RunFunctionOnceModal } from './RunFunctionOnceModal'
import { TestFunctionModal } from './TestFunctionModal'
import { CreateOrderModal } from './CreateOrderModal'
import { run_function_once } from '../../logic/run_function_once'

const config = getConfig() as any

export const RunCodeModal = () => {
  // General helpers
  const [ user ] = useAtom(loggedInUserAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)
  const [editorValue, setEditorValue] = useState<any>()
  const containerRef: any = useRef(null)
  const editorRef = useRef<any>(null)
  const [showConfirmationStep, setShowConfirmationStep] = useState<boolean>(false)
  const [ consoleOutput, setConsoleOutput ] = useAtom(consoleOutputAtom)
  const [ , setFunctionExecuting ] = useAtom(functionExecutingAtom)

  useEffect(() => {
    setConsoleOutput([])
  }, [])


  useEffect(() => {
    loader.init().then(monaco => {
      monaco.editor.defineTheme('my-theme', {
        base: 'vs-dark',
        inherit: true,
        rules: [],
        colors: {
          'editor.background': '#00000000',
          'minimap.background': '#00000000',
          'minimapSlider.background': '#55555533',
          'minimapSlider.hoverBackground': '#66666633',
          'minimapSlider.activeBackground': '#88888833',
        },
      })
    })

    return () => {
      setEditorValue(null)
    };
  }, [])

  const handleEditorChange = value => {
    setEditorValue(value)
  }

  let initialSymbol = ''
  const chartcontext = returnChartPaneContextObj(user ? user : undefined, initialSymbol)
  if (chartcontext?.visibleCharts[0]?.length) {
    initialSymbol = chartcontext.visibleCharts[0]
  }

  const defaultText = `order(${initialSymbol}, qty=1, notional=None, side="buy", order_type="market", limit_price=None, time_in_force="day")`

  if (!user || !uld) return null

  return (
    <>
      <div 
        className='modal-scrim'
        onClick={() => {
          setOpenModal(null)
        }}
      />
      <div 
        className='modal'
        style={{
          height: 675,
          width: 1000,
          top: 60,
          left: 'calc(50vw - 500px)'
        }}
      >
        <div className='top'>
          <div className='title-row'>
            <div className='row'>
              Run
            </div>
            {!showConfirmationStep ? <div className='row'>
              <div
                className='functions-tab-header-btn'
                style={{marginRight: 12}}
                onClick={async () => {
                  try {
                    run_function_once({
                      user,
                      code: editorValue,
                      liveData: uld?.liveData,
                      consoleOutput,
                      setConsoleOutput,
                      setFunctionExecuting,                                              
                      simulated_mode: true,
                      cancelToken: null,                     
                    })                    
                  } catch (error) {
                    setButtonIsLoading(false)
                    setOpenModal(<ErrorModal errorMessage={error}/>)
                  }
                }}
              >
                <Icon
                  icon='flask'
                  className='functions-tab-header-icon'
                  hoverText='Test function'
                  set='sharp-solid'
                  size={12}
                  style={{marginTop: 1, marginRight: 5}}
                  onClick={() => {
                    // for hover
                  }}
                  />
                <div>
                  Test
                </div>
              </div>
              <div
                className='functions-tab-header-btn'
                onClick={async () => {
                  setShowConfirmationStep(true)
                }}
              >
                <Icon
                  icon='circle-play'
                  className='functions-tab-header-icon'
                  hoverText='Run function once'
                  set='sharp-solid'
                  size={14}
                  style={{marginTop: 1, marginRight: 5}}
                  onClick={() => {
                    // for hover
                  }}
                />
                <div>
                  Run code
                </div>
              </div>
            </div> : <div className='row'>
              <div 
                className='font-size-11 gray-text italic'
                style={{marginTop: 4, marginRight: 20}}
              >
                  Your code will run for real. Are you sure?
              </div>
              <Button 
                display='No'
                onClick={() => {
                  setShowConfirmationStep(false)
                }}
                variant='secondary'
                style={{
                  width: 60,
                  height: 24,
                  marginRight: 10
                }}
              />
              <Button 
                display='Yes'
                onClick={ async () => {
                  try {
                    run_function_once({
                      user,
                      code: editorValue,
                      liveData: uld?.liveData,
                      consoleOutput,
                      setConsoleOutput,
                      setFunctionExecuting,                                              
                      simulated_mode: false,
                      cancelToken: null,
                    })                    
                    setShowConfirmationStep(false)
                  } catch (error) {
                    setButtonIsLoading(false)
                    setOpenModal(<ErrorModal errorMessage={error}/>)
                  }
                }}
                style={{
                  width: 60,
                  height: 24
                }}
              />
            </div>}
          </div>
          <div className='modal-body'>
            <div className='column'>
              <div 
                className='column' 
                style={{
                  height: 444,
                  border: `1px solid ${BORDER_INPUT}`,
                  marginBottom: 5,
                  // borderRadius: 5
                }}
              
              >
                <Editor
                  height={'100%'}
                  theme="my-theme"
                  defaultLanguage="python"
                  value={editorValue || defaultText}
                  onChange={handleEditorChange}
                  onMount={(editor) => {
                    editorRef.current = editor
                  }}
                  options={{
                    readOnly: false,
                    padding: {
                      top: 10,                
                    },
                    minimap: {
                      enabled: true
                    },
                    scrollbar: {
                    },
                  }}
                />
              </div>
              <CodeConsole fromModal={true} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}