import { useAtom } from 'jotai';
import React, { useEffect, useRef, useState } from 'react'
import {
  loggedInUserAtom,
} from '../../types/global_types';
import { CodeEditorWrapper } from './CodeEditorWrapper';

export const CodeEditorDividerPane = () => {
  // General helpers
  const [user] = useAtom(loggedInUserAtom)
  if (!user) return null

  if (user.codeEditorConfig === '2-one-left-one-right') {
    return (
      <div className='multi-code-editor-wrapper'>
        <CodeEditorWrapper
          codeEditorSlot={1}
          functionObj={user.functions?.find(f => f.id === user.codeEditorSlot1)}
        />
        <CodeEditorWrapper
          codeEditorSlot={2}
          functionObj={user.functions?.find(f => f.id === user.codeEditorSlot2)}
        />
      </div>
    )
  }
  return (
    <div className='multi-code-editor-wrapper'>
      <CodeEditorWrapper
        codeEditorSlot={1}
        functionObj={user.functions?.find(f => f.id === user.codeEditorSlot1)}
      />
    </div>
  )

  
}