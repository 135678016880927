import axios  from 'axios'
import { useAtom } from 'jotai'
import {
  Input,
  PhoneInput,
  Button,
  showModal,
} from '../reusable'
import { currentUser } from '../../firebase'
import React, { useState, useEffect } from 'react'
import { getConfig } from '../../config'
import { loggedInUserAtom, openModalAtom } from '../../types/global_types'
import { ErrorModal } from '../modals/ErrorModal'
import { CreatePlaidLinkButtonModal } from '../modals/CreatePlaidLinkButtonModal'
import { DeleteAchRelationshipModal } from '../modals/DeleteAchRelationshipModal'
import { TrustedContactModal } from '../modals/TrustedContactModal'

const config = getConfig() as any

export function SettingsTab() {
  const [loggedInUser] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)

  if (!loggedInUser) { return null }
  
  return (
    <div className='tab-contents has-subtab'>
      <div className='form-container'>
        
        
        <div className='bold header-text-size margin-bottom-10'>Basic info</div>
        <div className='row space-between margin-bottom-5 body-text-size'>
          <div className='orange-text'>
            full_name:
          </div>
          <div className='orange-text'>
            {loggedInUser.alpacaObject?.identity?.given_name } {loggedInUser.alpacaObject?.identity?.family_name}
          </div>
        </div>
        <div className='row space-between margin-bottom-5 body-text-size'>
          <div className='orange-text'>
            email_address:
          </div>
          <div className='orange-text'>
            {loggedInUser.alpacaObject?.contact?.email_address}
          </div>
        </div>
        <div className='row space-between margin-bottom-5 body-text-size'>
          <div className='orange-text'>
            street_address:
          </div>
          <div className='orange-text'>
            {loggedInUser.alpacaObject?.contact?.street_address[0]}
          </div>
        </div>
        <div className='row space-between margin-bottom-5 body-text-size'>
          <div className='orange-text'>
            phone_number:
          </div>
          <div className='orange-text'>
            {loggedInUser.alpacaObject?.contact?.phone_number?.replace(/\+(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4')}
          </div>
        </div>
        <div className='row space-between margin-bottom-5 body-text-size'>
          <div className='orange-text'>
            account_status:
          </div>
          <div className='orange-text'>
            {loggedInUser.alpacaObject?.status}
          </div>
        </div>
        <div className='row space-between body-text-size'>
          <div className='orange-text'>
            account_number:
          </div>
          <div className='orange-text'>
            {loggedInUser.alpacaObject?.account_number}
          </div>
        </div>

        <div className='bold header-text-size margin-top-40 margin-bottom-10'>Banking relationship</div>
        <BankingContent />
        
        <div className='bold header-text-size margin-bottom-10' style={{marginTop: 43}}>Trusted contact</div>
        <div className='row space-between body-text-size'>
          <div className='orange-text'>
            first_name:
          </div>
          <div className='orange-text'>
            {loggedInUser?.alpacaObject?.trusted_contact?.given_name || ''}
          </div>
        </div>
        <div className='row space-between body-text-size'>
          <div className='orange-text'>
            last_name:
          </div>
          <div className='orange-text'>
            {loggedInUser?.alpacaObject?.trusted_contact?.family_name || ''}
          </div>
        </div>
        <div className='row space-between body-text-size'>
          <div className='orange-text'>
            email_address:
          </div>
          <div className='orange-text'>
            {loggedInUser?.alpacaObject?.trusted_contact?.email_address || ''}
          </div>
        </div>
        <div className='row space-between body-text-size'>
          <div className='orange-text'>
            phone_number:
          </div>
          <div className='orange-text'>
            {loggedInUser?.alpacaObject?.trusted_contact?.phone_number?.replace(/\+(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4') || ''}
          </div>
        </div>
        <Button
          display='Change'
          style={{marginTop: 10, width: 150}}
          onClick={(e) => {
            setOpenModal(<TrustedContactModal />)
          }}
        />
      </div>
    </div>
  )
}

export const BankingContent = () => {
  const [loggedInUser] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [ bankingRelationship, setBankingRelationship ] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  const fetchBankingRelationshipData = async () => {
    try {
      setLoading(true)
      const token = await currentUser()?.getIdToken()
      const endpoint = `${config.api_root_url}getAchRelationship`
      const res = await axios.get(endpoint, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      setBankingRelationship(res.data[0])
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error)
    }
  };

  useEffect(() => {
    fetchBankingRelationshipData()
  }, [])

  if (!loggedInUser) { return null }

  if (loading) {
    return (
      <div className='form-container' style={{height: 130}}>
        <div className='orange-text' style={{marginBottom: 3}}>
          Loading...
        </div>
      </div>
    )
  }
  if (!bankingRelationship?.id) {
    return (
      <div className='form-container' style={{height: 130}}>
        <div className='row space-between margin-bottom-15 body-text-size'>
          <div className='orange-text' style={{marginBottom: 3}}>
            Connect a bank to make deposits/withdrawals to/from Tickerbot promgramitcally and manually.
          </div>
        </div>
        <CreatePlaidLinkButtonModal fetchBankingRelationshipData={fetchBankingRelationshipData}  />
      </div>
    )
  }
  return (
    <div className='form-container'>
      <div className='row space-between margin-bottom-5 body-text-size'>
        <div className='orange-text'>
          account_owner_name:
        </div>
        <div className='orange-text'>
          {bankingRelationship?.account_owner_name}
        </div>
      </div>
      <div className='row space-between margin-bottom-5 body-text-size'>
        <div className='orange-text'>
          bank_account_number:
        </div>
        <div className='orange-text'>
          {bankingRelationship?.bank_account_number}
        </div>
      </div>
      <div className='row space-between margin-bottom-5 body-text-size'>
        <div className='orange-text'>
          bank_account_type:
        </div>
        <div className='orange-text'>
          {bankingRelationship?.bank_account_type}
        </div>
      </div>
      <div className='row space-between margin-bottom-5 body-text-size'>
        <div className='orange-text'>
          status:
        </div>
        <div className='orange-text'>
          {bankingRelationship?.status}
        </div>
      </div>
      <Button
        display='Delete'
        variant='danger'
        style={{marginTop: 10, width: 150}}
        onClick={(e) => {
          setOpenModal(<DeleteAchRelationshipModal fetchBankingRelationshipData={fetchBankingRelationshipData} />)
        }}
      />
    </div>
  )
}