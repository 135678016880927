import { useAtom } from 'jotai'
import {
  loggedInUserAtom,
  openModalAtom,
  selectedCodeEditorSlotAtom,
  selectedEmptyChartAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { db, currentUser } from '../../firebase'
import {
  Icon,
  DropdownMenu,
} from '../reusable'
import React, { useState, useEffect } from 'react'
import { TEXT_GRAY, returnChartPaneContextObj, set_open_chart } from '../../logic/u'
import { CreateVariableModal } from '../modals/CreateVariableModal'
import { Variable } from '../../types/user_types'
import { DeleteVariableModal } from '../modals/DeleteVariableModal'

interface VariableDropdownProps {
  variableObj: Variable
  left: number
  top: number
  width: number
  onClose: () => void
}

export const VariableDropdown = (props: VariableDropdownProps) => {
  const [user ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [selectedEmptyChart] = useAtom(selectedEmptyChartAtom)

  if (!user) return null

  const {variableObj: variable, left, top, width, onClose} = props

  const chart = 'STATE.' + variable.symbol

  interface DropdownItem {
    display: JSX.Element
    onClick?: () => void
  }
  let contents: DropdownItem[] = []
  contents = [
    {
      display: (
        <div className='left'>
          <Icon
            icon='square-pen'
            set='sharp-solid'
            size={9}
            style={{marginRight: 10}}
          />
          <div>Update</div>
        </div>
      ),
      onClick: () => {
        setOpenModal(<CreateVariableModal variable={variable} />)
      }
    },
    {
      display: (
        <div className='left'>
          <Icon
            icon='trash'
            set='sharp-solid'
            size={9}
            style={{marginRight: 10, marginTop: .5}}
          />
          <div>Delete</div>
        </div>
      ),
      onClick: () => {
        setOpenModal(<DeleteVariableModal variable={variable} />)
      }
    },

    {
      display: (
        <div
          style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginTop: 3, marginBottom: 5}}
        ></div>
      )
    },
    {
      display: (
        <div className='left'>
          <Icon
            icon='chart-line'
            set='sharp-solid'
            size={10}
            style={{marginRight: 10, marginTop: .5}}
          />
          <div>View chart</div>
        </div>
      ),
      onClick: () => {
        set_open_chart(user, uld, selectedEmptyChart, chart)
      }
    },
  ]

  const chartConext = returnChartPaneContextObj(user, chart)

  if (chartConext?.panesWhereChartIsVisible.length) {
    contents.push(
      {
        display: (
          <div
            style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginTop: 3, marginBottom: 5}}
          ></div>
        )
      },
     {
       display: (
         <div className='left'>
            <Icon
              icon='circle-x'
              set='sharp-solid'
              size={9}
              style={{marginRight: 10}}
            />
           <div>{chartConext.panesWhereChartIsVisible.length === 1 ? 'Clear chart slot' : 'Clear chart slots'}</div>
         </div>
       ),
       onClick: () => {
         chartConext.panesWhereChartIsVisible.forEach(pNum => {
           set_open_chart(user, uld, pNum, null)
         })
       }
     },
    )
  }

  return (
    <DropdownMenu
      //@ts-ignore
      posLeft={left}
      posTop={top}
      width={width}
      height={500}
      onClose={() => onClose()}
      noArrowKeys={true}
      contents={contents}
    />
  )
}