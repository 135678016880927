/*
Factored-out RSI plot
*/

import "./TBChart.scss"
import React, { useEffect, useState, useRef } from "react"
import {
  DEFAULT_INDICATOR_COLORS,
  format_price_appropriately,
} from "../../logic/u"
import { useAtom } from 'jotai'
import { db } from '../../firebase'
import { Icon2 } from '../reusable/Icon2'
import { ohlcAtom } from '../../types/global_types'
import { Indicator, User } from '../../types/user_types'
import { delete_indicator, list_oscillators } from "../../logic/indicator_helpers"


// import { getConfig } from '../../config'
// const config: any = getConfig()


interface Point { x: number, val: number }



interface OscillatorPlotTabBarProps {
  symbol: string
  // indicators_present: Indicator[]
  user: User
}

export const OscillatorPlotTabBar = (props: OscillatorPlotTabBarProps) => {
  const { symbol, user } = props

  const oscillators = list_oscillators(user, symbol)

  let selected_oscillator = ''
  Object.keys(oscillators).forEach(indicator_key => {
    const indicator = oscillators[indicator_key]
    if (!indicator.isHidden) {
      selected_oscillator = indicator.abbrev_str
    }
  })

  const tab_divs: any = []
  for (let i = 0; i < oscillators.length; i++) {
    const indicator = oscillators[i]
    const tab_text = indicator.abbrev_str
    // const tab = oscillator_names[i]
    tab_divs.push(
      <div
        key={tab_text}
        className={`oscillator-plot-tab ${tab_text === selected_oscillator ? 'active' : ''}`}
        onClick={async () => {
          await set_selected_oscillator(tab_text, user, symbol)
        }}
      >
        <div>{tab_text}</div>
        <Icon2
          className={'row-icon'}
          icon='xmark'
          size={6}
          style={{ marginTop: 1, marginLeft: 5}}
          onClick={() => {
            delete_indicator(indicator.id, user);
          }}
        />

      </div>
    )
  }

  return (
    <div
      className='oscillator-plot-tab-bar'
      onClick={() => {
        if (user.charts[symbol].oscillatorsHidden) {
          const userDoc = db.collection('users').doc(user.uid)
          userDoc.update({
            [`charts.${symbol}.oscillatorsHidden`]: false
          })
        }
      }}
    >
      {tab_divs}

      <div className='collapse-btn'>
        <Icon2
          // icon={'xmark'}
          icon={'square-caret-down'}
          size={10}
          style={{ marginLeft: 1}}
          onClick={() => {
            const userDoc = db.collection('users').doc(user.uid)
            userDoc.update({
              [`charts.${symbol}.oscillatorsHidden`]: !user.charts[symbol].oscillatorsHidden
            })
          }}
        />
      </div>
    </div>

  )
}

const set_selected_oscillator = async (selection_abbr: string, user: User, symbol: string) => {
  const indicators_for_symbol = user.charts[symbol].indicators
  Object.keys(indicators_for_symbol).forEach(indicator_key => {
    const indicator = indicators_for_symbol[indicator_key]
    if (indicator.abbrev_str === selection_abbr) indicator.isHidden = false
    else indicator.isHidden = true
  })
  const userDoc = db.collection('users').doc(user.uid)
  await userDoc.update({
    [`charts.${symbol}.indicators`]: indicators_for_symbol
  })
}