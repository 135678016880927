import './LeftPane.scss'
import Split from 'react-split'
import { useAtom } from 'jotai'
import { db } from "../../firebase"
import React, { useState, useEffect, useRef } from 'react';
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  streamingQuotesAtom,
  selectedEmptyChartAtom,
} from '../../types/global_types'
import { valueForTable, GREEN_PARTIAL, RED_PARTIAL, returnChartPaneContextObj, set_open_chart, formatDateForTable, timeAgo } from '../../logic/u'

export const BalancesPane = () => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [selectedEmptyChart] = useAtom(selectedEmptyChartAtom)

  if (!user) return null
  if (!uld) return null

  const balancesArr = [
    {
      path: 'cash',  // from alpaca
      description: 'Cash balance',
    },
    {
      path: 'long_mkt_value',  // from alpaca
      description: 'Porftfolio value of all long positions',
    },
    {
      path: 'mkt_value', // tb
      description: 'Portfolio value of all positions',
    },
    {
      path: 'equity', // from alpaca
      description: 'Total account value (cash + mkt_value)',
    },



    {
      path: 'P/L change since yesterday ($)',
      description: '',
      isDivider: true
    },
    {
      path: 'day_unrealized_pl', // from alpaca
      description: 'Portfolio unrealized profit/loss since yesterday',
    },
    {
      path: 'day_realized_pl',  // tb
      description: 'Realized profit/loss since yesterday',
    },
    {
      path: 'day_pl', // tb
      description: 'Unrealized + realized profit/loss since yesterday',
    },
    {
      path: 'day_equity_change', // from alpaca
      description: 'Equity change since yesterday',
    },




    {
      path: 'P/L change since yesterday (%)',
      description: '',
      isDivider: true
    },
    {
      path: 'day_unrealized_pl_pc', // tb
      description: 'Portfolio unrealized profit/loss percent change since yesterday',
    },
    {
      path: 'day_realized_pl_pc',  // tb
      description: 'Realized profit/loss percent change since yesterday',
    },
    {
      path: 'day_pl_pc', // tb
      description: 'Unrealized + realized profit/loss percent change since yesterday',
    },
    {
      path: 'day_equity_change_pc', // from alpaca
      description: 'Equity percent change since yesterday',
    },



    {
      path: 'P/L total ($)',
      description: '',
      isDivider: true
    },
    {
      path: 'unrealized_pl', // from alpaca
      description: 'Portfolio unrealized profit/loss',
    },
    {
      path: 'realized_pl',  // tb
      description: 'Realized profit/loss',
    },
    {
      path: 'pl', // tb
      description: 'Unrealized + realized profit/loss',
    },






    {
      path: 'P/L total (%)',
      description: '',
      isDivider: true
    },
    {
      path: 'unrealized_pl_pc', // from alpaca
      description: 'Portfolio unrealized profit/loss percent change',
    },
    {
      path: 'realized_pl_pc', // tb
      description: 'Realized profit/loss percent change',
    },
    {
      path: 'pl_pc', // tb
      description: 'Unrealized + realized profit/loss judged against net deposits',
    },



    {
      path: 'MISC',
      description: '',
      isDivider: true
    },
    {
      path: 'net_deposits',  // tb
      description: 'Total deposits minus total withdrawals',
    },
    {
      path: 'cost_basis', // from alpaca
      description: 'Portfolio cost basis',
    },
    {
      path: 'buying_pwr',  // from alpaca
      description: 'Cash buying power',
    },
    {
      path: 'cash_on_orders',
      description: 'Cash tied up in open orders',
    },
    {
      path: 'daytrading_buying_pwr', // from alpaca
      description: 'Buying power for day trades',
    },
    {
      path: 'daytrade_count', // alpaca
      description: 'The current number of daytrades that have been made in the last 5 trading days inclusive of today',
    },
  ]

  return (
    <div className='pane-body'>
      {balancesArr.map(balance => {

        const chart = 'BALANCES.' +  balance.path
        const chartPaneContextObj = returnChartPaneContextObj(user, chart)
        const chartIsVisible = chartPaneContextObj?.chartIsVisible
        let divClass = 'entry smaller'
        if (chartIsVisible) {
          divClass += ' selected'
        }
        if (balance.isDivider) {
          return <div className='entry smaller balance-divider'>
            <div className='row'>
              {balance.path}
            </div>
          </div>
        }
        return (
          <div
            className={divClass}
            onClick={() => {
              set_open_chart(user, uld, selectedEmptyChart || 1, chart)
            }}
          >
            <div className='top-row'>
              <div title={balance.description}>
                {balance.path}
              </div>
              <div>{valueForTable(balance.path, uld.liveData.BALANCES[balance.path])}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}