import React, { useState } from 'react'

import { PositionsTab } from './PositionsTab';
import { BalancesTab } from './BalancesTab';
import { OrdersTab } from './OrdersTab';
import { TransfersTab } from './TransfersTab';
import { TransactionsTab } from './TransactionsTab';

const SUBTABS = [
  {display: 'Positions', value: 'positions'},
  {display: 'Balances', value: 'balances'},
  {display: 'Orders', value: 'orders'},
  {display: 'Transfers', value: 'transfers'},
  {display: 'Transactions', value: 'transactions'},
]

export const PortfolioTab = () => {
  const [ subTab, setSubTab ] = useState('positions')

  let subTabContent: any = null
  switch (subTab) {
    case 'positions':
      subTabContent = <PositionsTab />
      break
    case 'balances':
      subTabContent = <BalancesTab />
      break
    case 'orders':
      subTabContent = <OrdersTab />
      break
    case 'transfers':
      subTabContent = <TransfersTab />
      break
    case 'transactions':
      subTabContent = <TransactionsTab />
      break
  }

  return (
    <div className='tab-parent'>
      <div className='tab-header'>
        <div>
          Portfolio
        </div>
      </div>
      <div className='subtab-header'>
        {SUBTABS.map((s) => {
          let className = 'subtab'
          if (s.value === subTab) {
            className += ' selected'
          }
          return (
            <div
              className={className}
              onClick={() => setSubTab(s.value)}
            >
              {s.display}
            </div>
          )
        })}
      </div>
      {subTabContent}
    </div>
  )
}