import { useAtom } from 'jotai';
import React, { useEffect, useRef, useState } from 'react'
import {
  userLiveDocAtom,
  loggedInUserAtom,
  openModalAtom,
} from '../../types/global_types';
import Split from 'react-split';
import { db } from '../../firebase';
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import { v4 as uuidv4 } from 'uuid';
import { BACKGROUND, formatDateForTable, formatNumberForTable, handleMUISorting, tableSX, timeAgo, valueForTable } from '../../logic/u';
import { Icon } from '../reusable';
import { CustomGridOverlay } from '../tabs/CustomGridOverlay';
import { TickerModal } from '../modals/TickerModal';
import { TickerDropdown } from '../dropdowns/TickerDropdown';
import { BacktestDropdown } from '../dropdowns/BacktestDropdown';
import { Backtest } from '../../types/backtest_types';
import { PositionsTab } from '../tabs/PositionsTab';
import { BalancesTab } from '../tabs/BalancesTab';
import { OrdersTab } from '../tabs/OrdersTab';
import { TransfersTab } from '../tabs/TransfersTab';
import { TransactionsTab } from '../tabs/TransactionsTab';
import { BacktestSummaryTab } from './BacktestSummaryTab';
import { BacktestFunctionsTab } from './BacktestFunctionsTab';
import { BacktestWatchlistsTab } from './BacktestWatchlistsTab';
import { LogsTab } from '../tabs/LogsTab';
import { StateTab } from '../tabs/StateTab';

const SUBTABS = [
  {display: 'Summary', value: 'summary'},
  {display: 'Functions', value: 'functions'},
  {display: 'Watchlists', value: 'watchlists'},
  {display: 'Positions', value: 'positions'},
  {display: 'Balances', value: 'balances'},
  {display: 'Orders', value: 'orders'},
  {display: 'Transfers', value: 'transfers'},
  {display: 'Logs', value: 'logs'},
  {display: 'State', value: 'state'},
]

export const BacktestTab = () => {
  // General helpers
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)
  const [backtest, setBacktest] = useState<Backtest | null>(null); // Initialize state
  const [ subTab, setSubTab ] = useState('summary')

  let subTabContent: any = null
  switch (subTab) {
    case 'summary':
      subTabContent = <BacktestSummaryTab backtest={backtest} />
      break
    case 'functions':
      subTabContent = <BacktestFunctionsTab backtest={backtest} />
      break
    case 'watchlists':
      subTabContent = <BacktestWatchlistsTab backtest={backtest} />
      break
    case 'positions':
      subTabContent = <PositionsTab backtest={backtest} />
      break
    case 'balances':
      subTabContent = <BalancesTab backtest={backtest} />
      break
    case 'orders':
      subTabContent = <OrdersTab backtest={backtest}/>
      break
    case 'transfers':
      subTabContent = <TransfersTab backtest={backtest}/>
      break
    case 'logs':
      subTabContent = <LogsTab backtest={backtest}/>
      break
    case 'state':
      subTabContent = <StateTab backtest={backtest}/>
      break
  }

  useEffect(() => {
    if (!user || !user.selectedBacktestId) return; // Ensure user and backtest ID are present

    const backtestRef = db
      .collection('users')
      .doc(user.uid)
      .collection('backtests')
      .doc(user.selectedBacktestId);

    const unsubscribe = backtestRef.onSnapshot(doc => {
      if (doc.exists) {
        const backtestObj = { id: doc.id, ...doc.data() } as Backtest;
        setBacktest(backtestObj); // Update state with backtest data
      } else {
        console.log("No such backtest found!");
        setBacktest(null); // Set state to null if the document does not exist
      }
    });

    return () => unsubscribe(); // Cleanup the listener on unmount
  }, [user]);

  if (!user || !uld || !backtest) return null

  let backtestsColClass = 'backtests-col'
  if (user.backtestsTreeClosed) {
    backtestsColClass += ' backtests-tree-closed'
  }

  const startDate = formatDateForTable(backtest?.start_time_iso).split(' ')[0]
  const endDate = formatDateForTable(backtest?.end_time_iso).split(' ')[0]
  const dateString = startDate + ' - ' + endDate

  return (
    <div className={backtestsColClass} >
      {openDropdown}
      <div className='tab-header'>
        <div className='row'>
          <div className='backtest-tab-name'>
            {backtest?.name}
          </div>
          {backtest.status === 'completed' ? <div 
            className='gray-text font-size-10' 
            title={dateString}
            style={{marginTop: 3, marginRight: 10}}  
          >
            {dateString} • viewing data as of {endDate} (final output)
          </div> : null} 
        </div>
        <div className='row'>          
          <Icon 
            icon='gear'
            set='regular'
            size={14}
            style={{marginLeft: 12, marginTop: 2}}
            onClick={(e) => {      
              const rect = e.target.getBoundingClientRect()
              if (rect) {
                const width= 175
                const left = rect.left - width + 10
                const top = rect.bottom
                setOpenDropdown(<BacktestDropdown 
                  left={left}
                  top={top}
                  backtestObj={backtest}
                  width={width}
                  onClose={() => setOpenDropdown(null)}
                />)
              }
            }}
          />
        </div>

      </div>
      {backtest.status === 'completed' ? <>
        <div className='subtab-header'>
          {SUBTABS.map((s) => {
            let className = 'subtab'
            if (s.value === subTab) {
              className += ' selected'
            }
            return (
              <div
                className={className}
                onClick={() => setSubTab(s.value)}
              >
                {s.display}
              </div>
            )
          })}
        </div>
        {subTabContent}
      </> : <NoResultsComponent backtest={backtest} />}
    </div>
  )
}

interface NoResultsComponentProps {
  backtest: Backtest
}
const NoResultsComponent = (props: NoResultsComponentProps) => {
  let explanation = ''
  if (props.backtest.status === 'failed') {
    explanation = 'has failed.'
  } else {
    explanation = `is ${props.backtest.status}.`
  }

  return (
  <div className='tab-contents'>
    <div className='gray-text' style={{textAlign: 'center', marginTop: 100}}>
      No results. This backtest {explanation}
    </div>
  </div>
  )
}
