import './MenuBar.scss';

import { useAtom } from 'jotai'
import {
  appTabAtom,
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { CreateOrderModal } from '../modals/CreateOrderModal'
import { CreateTransferModal } from '../modals/CreateTransferModal'
import { db } from '../../firebase'
import {
  Icon,
} from '../reusable'
import React, { useState, useEffect } from 'react'
import { TEXT_GRAY, formatDateForTable, isMarketOpen, timeAgo, valueForTable } from '../../logic/u'
import { CancelAllOpenOrdersModal } from '../modals/CancelAllOpenOrdersModal'
import { LayoutDropdown } from '../dropdowns/LayoutDropdown';
import { Backtest } from '../../types/backtest_types';
import { RunCodeModal } from '../modals/RunCodeModal';
import { CloseAllPositionsModal } from '../modals/CloseAllPositionsModal';

export const MenuBar = () => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [appTab, setAppTab] = useAtom(appTabAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [functionsOnCount, setFunctionsOnCount] = useState<number>()

  const [isLayoutDropdownOpen, setIsLayoutDropdownOpen] = useState(false)
  const [layoutDropdownPosition, setLayoutDropdownPosition] = useState({ left: 0, top: 0 })

  const [isBacktestDropdownOpen, setIsBacktestDropdownOpen] = useState(false)
  const [backtestDropdownPosition, setBacktestDropdownPosition] = useState({ left: 0, top: 0 })

  const [backtests, setBacktests] = useState<any[]>()

  useEffect(() => {
    setFunctionsOnCount(user?.functions?.filter(f => f.isRunning).length)
  }, [user])

  if (!user) {return null}
  if (!uld) {return null}

  const balances = uld.liveData?.BALANCES

  const getRunningIconHoverText = () => {
    let hoverText: string[] = [functionsOnCount + ' ON']
    hoverText = [...hoverText].concat(user.functions?.filter(f => f.isRunning).map(func => {
      let formatted_date = '-'
      let time_ago = '-'
      if (uld.functionsLastRun && uld.functionsLastRun[func.id]) {
        const last_ran_date = new Date(uld.functionsLastRun[func.id])
        time_ago = timeAgo(last_ran_date)
        formatted_date = formatDateForTable(last_ran_date)
      }
      return func.name + ' • ' + time_ago
    }))
    return hoverText.join(`\n\n`)
  }

  const date = uld.liveDataLastUpdated?.toDate()
  const time_ago = timeAgo(date)
  const formatted_date = formatDateForTable(date)

  return (
    <div className={'menu-bar'}>
      <div className={'left'}>
        <Icon
          icon={balances.day_unrealized_pl >= 0 ? 'arrow-trend-up' : 'arrow-trend-down'}
          set='sharp-solid'
          size={20}
          className={'logo-icon ' + (balances.day_unrealized_pl >= 0 ? 'up ' : 'down ') + 'margin-left-3 margin-top-2'}
        />
        <div className={'margin-left-15 font-size-14 white-text'}>
          Tickerbot
        </div>
        <div className='row'>
          <div title={'equity'} className={'margin-left-15 font-size-14 white-text'} >
            {valueForTable('equity', balances?.equity)}
          </div>
          <div title={'mkt_value'} className={'margin-left-15 font-size-14 white-text'} >
            {valueForTable('mkt_value', balances?.mkt_value)}
          </div>
          <div title={'day_unrealized_pl'} className={'margin-left-15 font-size-14' + (balances?.day_unrealized_pl >= 0 ? ' pos' : ' neg')}>
            {valueForTable('day_unrealized_pl', balances.day_unrealized_pl)}
          </div>
          <div title={'day_unrealized_pl_pc'} className={'margin-left-15 font-size-14' + (balances?.day_unrealized_pl >= 0 ? ' pos' : ' neg')}>
            {'[' + valueForTable('day_unrealized_pl_pc', balances.day_unrealized_pl_pc) + ']'}
          </div>
          <div className='as-of'>
            as of {formatted_date} • {isMarketOpen() ? 'market open' : 'market closed'}
          </div>
        </div>
      </div>
      <div className={'right'}>
        <Icon
          hoverText='Modify layout'
          icon='grid-2-plus'
          set='sharp-solid'
          size={18}
          style={{marginRight: 15, marginTop: 1}}
          onClick={(e) => {
            const rect = e.target.getBoundingClientRect()
            if (rect) {
              setLayoutDropdownPosition({
                left: rect.left - 80,
                top: rect.bottom + 15
              })
              setIsLayoutDropdownOpen(true)
            }
          }}
        />
        <div className='gray-text' style={{marginRight: 15}}>
          |
        </div>        
        <Icon
          hoverText='Transfer'
          icon='building-columns'
          set='sharp-solid'
          size={18}
          style={{marginRight: 15}}
          onClick={() => {
            setOpenModal(<CreateTransferModal />)
          }}
        />
        <Icon
          hoverText='Trade'
          icon='right-left'
          set='sharp-solid'
          size={18}
          style={{marginRight: 15}}
          onClick={() => {
            setOpenModal(<CreateOrderModal />)
          }}
        />
        <Icon
          hoverText='Close all positions'
          icon='folder-closed'
          set='sharp-solid'
          size={19}
          style={{marginRight: 15, marginTop: 1}}
          onClick={() => {
            setOpenModal(<CloseAllPositionsModal />)
          }}
        />
        <Icon
          hoverText='Cancel all open orders'
          icon='octagon-minus'
          set='sharp-solid'
          size={18}
          style={{marginRight: 15}}
          onClick={() => {
            setOpenModal(<CancelAllOpenOrdersModal />)
          }}
        />
        <Icon
          hoverText='Execute code'
          icon='rectangle-code'
          set='sharp-solid'
          size={19}
          style={{marginTop: .5, marginRight: 15}}
          onClick={() => {
            setOpenModal(<RunCodeModal />)
          }}
        />
        <div className='gray-text' style={{marginRight: 15}}>
          |
        </div>
        <div
          className={`status-indicator`}
          title={getRunningIconHoverText()}
          onClick={() => {
            setAppTab('functions')
          }}
        >
          <div>
            {functionsOnCount}
          </div>
          <Icon
            icon='function'
            set='sharp-solid'
            className='status-icon'
            size={15}
            style={{marginTop: 0, marginLeft: 8, marginRight: 7}}
          />
          <div>
            ON
          </div>
        </div>
      </div>
      {isLayoutDropdownOpen && (
        <LayoutDropdown
          left={layoutDropdownPosition.left}
          top={layoutDropdownPosition.top}
          onClose={() => setIsLayoutDropdownOpen(false)}
        />
      )}
    </div>
  )
}