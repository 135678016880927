import './Switch.scss';
import React, { useState, useEffect } from 'react';

interface SwitchProps {
  label: string
  value: boolean
  title?: string
  onChange: (value: boolean) => void
  className?: string
  style?: any
  isDisabled?: boolean
  maintainLocalState?: boolean        // hack for use on menus without access to live user state
}

export function Switch(props: SwitchProps) {
  const [localValue, setLocalValue] = useState(props.value);

  useEffect(() => {
    if (!props.maintainLocalState) {
      setLocalValue(props.value);
    }
  }, [props.value, props.maintainLocalState]);

  const handleClick = () => {
    if (props.isDisabled) return;

    if (props.maintainLocalState) {
      setLocalValue(!localValue);
    }

    props.onChange(!localValue);
  }

  const currentValue = props.maintainLocalState ? localValue : props.value;

  let mainClassName = 'switch';
  if (props.className) {
    mainClassName += ' ' + props.className;
  }
  if (props.isDisabled) {
    mainClassName += ' disabled';
  }

  let trackClassName = 'track';
  let thumbClassName = 'thumb';
  if (currentValue) {
    trackClassName += ' on';
    thumbClassName += ' on';
  }

  let label = 'label'
  if (!currentValue) {
    label += ' off'
  }

  return (
    <div
      title={props.title}
      className={mainClassName + ' row align-center'}
      style={props.style}
      onClick={handleClick}
    >
      <div className={trackClassName}>
        <div className={thumbClassName}></div>
      </div>
      <div className={label}>{props.label}</div>
    </div>
  )
}




// interface SwitchProps {
//   label: string
//   value: boolean
//   onChange: (value: boolean) => void
//   className?: string
//   style?: any
//   isDisabled?: boolean
// }
// export function Switch(props: SwitchProps) {

//   let mainClassName = 'switch'
//   if (props.className) {
//     mainClassName += ' ' + props.className
//   }
//   if (props.isDisabled) {
//     mainClassName += ' disabled'
//   }


//   let trackClassName = 'track'
//   let thumbClassName = 'thumb'
//   if (props.value) {
//     trackClassName += ' on'
//     thumbClassName += ' on'
//   }

//   return (
//     <div
//       className={mainClassName}
//       style={props.style}
//       onClick={() => {
//         if (props.isDisabled) return
//         props.onChange(!props.value)
//       }}
//     >
//       <div
//         className={trackClassName}
//       >
//         <div className={thumbClassName}></div>
//       </div>
//       <div className='label'>{props.label}</div>

//     </div>
//   )

// }
