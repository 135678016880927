// NOTE: f0186b8a030ac250ec19d0705f98d5ba6a538ce3 worked

const SUBMIT_ORDER_DOCUMENTATION_STRING = `Parameters:
- ticker: the stock symbol.
- qty?: number of shares
- notional?: dollar value, use in lieu of qty
- side: "buy" or "sell"
- order_type?: "market" | "limit" | "stop"
- limit_price?: use for limit_order
- time_in_force?: "day" | "GTC"`;

const CANCEL_ORDERS_DOCUMENTATION_STRING = `Parameters:
- tickers: array of ticker symbols
- order_id?: optionally pass a single order ID instead
- orders_of_type?: 'market' | 'limit' | 'stop'`;

const CLOSE_POSITIONS_DOCUMENTATION_STRING = `Parameters:
- tickers?: array of symbols. Leave blank to close all
- cancel_orders: boolean, pass True to also close open orders for relevant tickers`;

const SUBMIT_TRANSFER_DOCUMENTATION_STRING = `Parameters:
- amount: dollar amount
- direction: "deposit" | "withdraw"`;

const SEND_EMAIL_DOCUMENTATION_STRING = `Parameters:
- recipients: array of email addresses
- subject: subject line
- body: body text`;

const PRICE_DOCUMENTATION_STRING = `(no parameters)`
const BARS_DOCUMENTATION_STRING = `Parameters:
- interval: "1m" | "5m" | "30m" | "1h" | "2h" | "4h" | "1d" | "1w"
- num_bars: (number)`
const SMA_DOCUMENTATION_STRING = `Parameters:
- interval: "1m" | "5m" | "30m" | "1h" | "2h" | "4h" | "1d" | "1w"
- period: how many bars to base calculation on`
const EMA_DOCUMENTATION_STRING = `Parameters:
- interval: "1m" | "5m" | "30m" | "1h" | "2h" | "4h" | "1d" | "1w"
- period: how many bars to base calculation on`

const BOLL_DOCUMENTATION_STRING = `Parameters:
- interval: "1m" | "5m" | "30m" | "1h" | "2h" | "4h" | "1d" | "1w"
- period: how many bars to base calculation on
- stddev: standard deviation, determines the width of the bands, reflecting the volatility of the asset by adjusting the distance from the moving average based on price fluctuations`
const VWAP_DOCUMENTATION_STRING = `Parameters:
- interval: "1m" | "5m" | "30m" | "1h" | "2h" | "4h" | "1d" | "1w"
- period: how many bars to base calculation on`
const RSI_DOCUMENTATION_STRING = `Parameters:
- interval: "1m" | "5m" | "30m" | "1h" | "2h" | "4h" | "1d" | "1w"
- period: how many bars to base calculation on`
const MACD_DOCUMENTATION_STRING = `Parameters:
- interval: "1m" | "5m" | "30m" | "1h" | "2h" | "4h" | "1d" | "1w"
- fastPeriod: defines the shorter moving average period for MACD, influencing its sensitivity to price movements
- slowPeriod: sets the longer moving average period for MACD, determining its responsiveness to longer-term price trends
- signalPeriod: specifies the period for the signal line, used to smooth the MACD line and identify potential trend reversals
`

const get_top_level_functions = (monaco: any, range: any) => [
  {
    label: 'order',
    kind: monaco.languages.CompletionItemKind.Function,
    insertText: 'order("NVDA", qty=1, notional=None, side="buy", order_type="market", limit_price=None, time_in_force="day")',
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    detail: 'Submits a trading order.',
    documentation: SUBMIT_ORDER_DOCUMENTATION_STRING,
    range: range
  },
  {
    label: 'cancel_orders',
    kind: monaco.languages.CompletionItemKind.Function,
    insertText: 'close_positions(tickers: ["${1:NVDA}"], order_id=${2:None}, orders_of_type: ${3:None})',
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    detail: 'Cancels specified orders.',
    documentation: CANCEL_ORDERS_DOCUMENTATION_STRING,
    range: range
  },
  {
    label: 'close_positions',
    kind: monaco.languages.CompletionItemKind.Function,
    insertText: 'close_positions(tickers: ["${1:NVDA}"], cancel_orders=${4:False})',
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    detail: 'Closes positions for some or all tickers.',
    documentation: CLOSE_POSITIONS_DOCUMENTATION_STRING,
    range: range
  },
  {
    label: 'transfer',
    kind: monaco.languages.CompletionItemKind.Function,
    insertText: 'transfer(1000, direction="${2:deposit}")',
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    detail: 'Initiates an ACH transfer into or our of your account.',
    documentation: SUBMIT_TRANSFER_DOCUMENTATION_STRING,
    range: range
  },
  {
    label: 'send_email',
    kind: monaco.languages.CompletionItemKind.Function,
    insertText: 'send_email(recipients=["${1:recipient1@example.com}"], subject="${2:subject line}", body="${3:body text}")',

    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    detail: 'Sends an email to the specified recipients.',
    documentation: SEND_EMAIL_DOCUMENTATION_STRING,
    range: range
  },
  {
    label: 'turn_off_function',
    kind: monaco.languages.CompletionItemKind.Function,
    insertText: 'turn_off_function()',
    detail: 'Turns off this function, such that it stops running.',
    range: range
  },
  {
    label: 'log',
    kind: monaco.languages.CompletionItemKind.Function,
    insertText: 'log("")',
    detail: 'Writes a log message',
    range: range
  }
];

const get_ticker_methods = (ticker: string, monaco: any, range: any) => {
  const commonMethods = [
    {
      name: 'price',
      insertText: 'price()',
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      detail: 'Returns the current price of the ticker.',
      documentation: 'Returns the current price for the specified ticker.',
      range: range
    },
    {
      name: 'bars',
      insertText: 'bars(interval=${1:"1m"}, num_bars=${2:100})',
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      detail: 'Returns the specified number of OHLCV bars.',
      documentation: BARS_DOCUMENTATION_STRING,
      range: range
    },
    {
      name: 'sma',
      insertText: 'sma(interval=${1:"1d"}, period=${2:9})',
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      detail: 'Calculates the Simple Moving Average (SMA) for the ticker.',
      documentation: SMA_DOCUMENTATION_STRING,
      range: range
    },
    {
      name: 'ema',
      insertText: 'ema(interval=${1:"1d"}, period=${2:9})',
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      detail: 'Calculates the Exponential Moving Average (EMA) for the ticker.',
      documentation: EMA_DOCUMENTATION_STRING,
      range: range
    },
    {
      name: 'boll',
      insertText: 'boll(interval=${1:"1d"}, period=${2:20}, stdDev=${3:2})',
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      detail: 'Calculates Bollinger Bands for the ticker.',
      documentation: BOLL_DOCUMENTATION_STRING,
      range: range
    },
    {
      name: 'vwap',
      insertText: 'vwap(interval=${1:"1d"}, period=${2:14})',
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      detail: 'Calculates the Volume Weighted Average Price (VWAP) for the ticker.',
      documentation: VWAP_DOCUMENTATION_STRING,
      range: range
    },
    {
      name: 'rsi',
      insertText: 'rsi(interval=${1:"1d"}, period=${2:9})',
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      detail: 'Calculates the Relative Strength Index (RSI) for the ticker.',
      documentation: RSI_DOCUMENTATION_STRING,
      range: range
    },
    {
      name: 'macd',
      insertText: 'macd(interval=${1:"1d"}, fastPeriod=${2:12}, slowPeriod=${3:26}, signalPeriod=${4:9})',
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      detail: 'Calculates the Moving Average Convergence Divergence (MACD) for the ticker.',
      documentation: MACD_DOCUMENTATION_STRING,
      range: range
    }
  ];

  return commonMethods.map(method => ({
    label: method.name,
    kind: monaco.languages.CompletionItemKind.Method,
    insertText: method.insertText,
    insertTextRules: method.insertTextRules,
    detail: method.detail,
    documentation: method.documentation,
    range: range
  }));
};



export const registerCompletionProvider = (monaco: any, tickers: string[]) => {
  monaco.languages.registerCompletionItemProvider('python', {
    triggerCharacters: ['.'],
    provideCompletionItems: function(model: any, position: any) {
      const word = model.getWordUntilPosition(position);
      const range = {
        startLineNumber: position.lineNumber,
        startColumn: word.startColumn,
        endLineNumber: position.lineNumber,
        endColumn: word.endColumn
      };

      const lineText = model.getValueInRange({
        startLineNumber: position.lineNumber,
        startColumn: 1,
        endLineNumber: position.lineNumber,
        endColumn: position.column,
      });

      const objectMatch = lineText.match(/(\w+)\.$/);
      if (objectMatch) {
        const objectName = objectMatch[1];
        if (tickers.includes(objectName)) {
          return {
            suggestions: get_ticker_methods(objectName, monaco, range)
          };
        }
      }
      return {
        suggestions: get_top_level_functions(monaco, range)
      };
    }
  });
};