import '../SignUp.scss'
import React, { useState, useEffect } from 'react'
import { db } from '../../../firebase'
import {loggedInUserAtom, openModalAtom } from '../../../types/global_types'
import {
  AlpacaEmploymentStatusType,
  SignUpStage,
} from '../../../types/user_types'
import { useAtom } from 'jotai'
import { Button, DropdownSelect, Input } from '../../reusable'
import { ErrorModal } from '../../modals/ErrorModal'

export const EmploymentStep = () => {
  const [loggedInUser ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)

  // This signup stage
  const [employmentStatus, setEmploymentStatus] = useState<AlpacaEmploymentStatusType | null>(loggedInUser?.alpacaObject?.disclosures?.employment_status || null)
  const [employer, setEmployer] = useState<string>(loggedInUser?.alpacaObject?.disclosures?.employer_name || '')
  const [position, setPosition] = useState<string>(loggedInUser?.alpacaObject?.disclosures?.employment_position || '')
  const [employerAddress, setEmployerAddress] = useState<string>(loggedInUser?.alpacaObject?.disclosures?.employer_address || '')

  const previousSignUpStage: SignUpStage = 'BasicInfo'
  const nextSignUpStage: SignUpStage = 'FinancialProfile'

  useEffect(() => {
    if (employmentStatus === 'EMPLOYED') {
      setButtonEnabled(!!employmentStatus && !!employer && !!position && !!employerAddress);
    }
  }, [employmentStatus, employer, position, employerAddress]);

  useEffect(() => {
    if (employmentStatus !== 'EMPLOYED') {
      setButtonEnabled(!!employmentStatus);
    }
  }, [employmentStatus]);

  if (!loggedInUser) return null

  return (
    <div className='signup-container'>
      <div className='signup-form-container'>
        <div className='signup-context-title text-align-left'>
          Employment profile
        </div>
        <DropdownSelect
          label={'Employment status'}
          options={[
            {display: 'Employed', value: 'EMPLOYED'},
            {display: 'Unemployed', value: 'UNEMPLOYED'},
            {display: 'Retired', value: 'RETIRED'},
            {display: 'Student', value: 'STUDENT'},
          ]}
          className={'margin-top-15'}
          width={494}
          height={32}
          value={employmentStatus}
          onChange={(val) => {setEmploymentStatus(val)}}
        />
        {employmentStatus !== 'EMPLOYED' ? null : (
          <div className='column width-full'>
            <Input
              label={'Employer name'}
              type={'text'}
              // placeholder={'Acme'}
              className={'margin-top-20'}
              style={{width: '100%'}}
              initialValue={employer}
              onChange={(val) => {setEmployer(val)}}
            />
            <Input
              label={'Position'}
              type={'text'}
              // placeholder={'VP of Success'}
              className={'margin-top-20'}
              style={{width: '100%'}}
              initialValue={position}
              onChange={(val) => {setPosition(val)}}
            />
            <Input
              label={'Employer address'}
              type={'text'}
              // placeholder={'24 Company Way, Pioneer, MI 07893'}
              className={'margin-top-20'}
              style={{width: '100%'}}
              initialValue={employerAddress}
              onChange={(val) => {setEmployerAddress(val)}}
            />
          </div>
        )}
        <Button
          display={'Next'}
          className={'margin-top-25'}
          style={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
          isDisabled={!buttonEnabled || buttonIsLoading}
          isLoading={buttonIsLoading}
          onClick= {async () => {
            try {
              setButtonIsLoading(true)
              await db.collection('users').doc(loggedInUser.uid).update({
                'alpacaObject.disclosures.employment_status': employmentStatus,
                'alpacaObject.disclosures.employer_name': employmentStatus === 'EMPLOYED' ? employer : null,
                'alpacaObject.disclosures.employment_position': employmentStatus === 'EMPLOYED' ? position : null,
                'alpacaObject.disclosures.employer_address': employmentStatus === 'EMPLOYED' ? employerAddress : null,
                signUpStage: nextSignUpStage,
              })
              setButtonIsLoading(false)
            } catch (err) {
              setButtonIsLoading(false)
              setOpenModal( <ErrorModal errorMessage={err.message}/> )
            }
          }}
        />
        <Button
          display={'Back'}
          className={'margin-top-15'}
          variant={'secondary'}
          style={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
          onClick= {async () => { await db.collection('users').doc(loggedInUser.uid).update({signUpStage: previousSignUpStage})}}
        />
      </div>
    </div>
  )

}