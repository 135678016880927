import '../reusable/Modal.scss'
import { useAtom } from 'jotai'
import React, { useEffect, useState, useRef } from 'react'
import { Input } from '../reusable/Input'
import { Icon, Modal } from '../reusable'
import { loggedInUserAtom, userLiveDocAtom } from '../../types/global_types'
import axios from 'axios'
import { currentUser, db } from '../../firebase'
import { getConfig } from '../../config'
import {
  topStockChartsObjArr,
  updateLiveData,
  ORANGE,
  uuid
} from '../../logic/u'
import { allSymbolsAtom } from '../../types/global_types'
import { WatchlistObj } from '../../types/user_types'
const config = getConfig() as any

interface AddTickersModalProps {
  watchlistId: WatchlistObj['id']
}

export const AddTickersModal = (props: AddTickersModalProps) => {
  // General helpers
  const [user, ] = useAtom(loggedInUserAtom)
  const [uld, ] = useAtom(userLiveDocAtom)
  const [allSymbols] = useAtom(allSymbolsAtom)
  const [searchString, setSearchString] = useState<string>('')
  const [col1Selection, setCol1Selection] = useState<string>('All')
  const [subtab, setSubtab] = useState<string>('')
  const [err_msg, setErr_msg] = useState<string>('')
  const inputRef = useRef(null)

  const {watchlistId } = props

  const topStockCharts = topStockChartsObjArr

  if (!user) return null

  const all = topStockCharts.map(s => {
    return {
      value: s.symbol,
      display: s.name
    }
  })

  const watchlist = user?.watchlists.find(w => w.id === watchlistId)?.tickers?.map(t => {
    return {
      value: t,
      display: allSymbols?.find(s => s.symbol === t)?.name
    }
  })

  const position = Object.keys(uld?.liveData.POSITIONS).map(p => {
    return {
      value: p,
      display: allSymbols?.find(s => s.symbol === p)?.name
    }
  })

  const all_options = {
    All: all,
    Watchlist: watchlist,
    Position: position,
  }

  const make_first_col_buckets = () => {
    let ret: any = []
    Object.keys(all_options).forEach((option: string) => {
      let className = 'bucket'
      if (col1Selection === option) className += ' s'
      ret.push(
        <div
          key={option}
          className={className}
          onClick={() => {
            // @ts-ignore
            inputRef.current?.clear()
            setErr_msg('')
            setCol1Selection(option)
            setSearchString('')
            setSubtab(Object.keys(all_options[option]).filter(o => o !== 'hasSubtabs')[0])
          }}
        >
          {option}
        </div>
      )
    })
    return ret
  }

  const make_subtabs = () => {
    let ret: any =[]
    const subtabs = all_options[col1Selection].hasSubtabs ? Object.keys(all_options[col1Selection]).filter(o => o !== 'hasSubtabs') : []
    subtabs.forEach(s => {
      let className = 'indicators-modal-subtab'
      if (subtab === s) className += ' selected'
      ret.push(
        <div
          className={className}
          onClick={() => {
            setErr_msg('')
            setSubtab(s)}
          }
        >
          {s}
        </div>
      )
    })
    return ret
  }

  const filterOptions = (options: any[], searchString: string) => {
    const ticker_matches = options.filter(option => {
      return option.symbol.toLowerCase().startsWith(searchString.toLowerCase())
    })

    // Name matches too
    const name_matches = options.filter(option => {
      return option.name.toLowerCase().startsWith(searchString.toLowerCase())
    })

    let all_matches = [...ticker_matches, ...name_matches]
    return all_matches.slice(0, 50)
  }

  const make_second_col_buckets = () => {
    let ret: any[] = []

    // Things on watchlists
    const watchlist_items: any[] = []
    const watchlists = user.watchlists || []
    watchlists.forEach((w: any) => {
      w.tickers.forEach((ticker: string) => {
        watchlist_items.push({
          symbol: ticker,
          name: lookup_ticker_name(ticker)
        })
      })
    })

    // Positions the user has open
    const position_items: any[] = []
    Object.keys(uld?.liveData.POSITIONS).forEach(p => {
      position_items.push({
        symbol: p,
        name: lookup_ticker_name(p)
      })
    })

    // Assemble lists we'll have if there's no search term
    let options: any[] = []
    if (col1Selection === 'All') {
      options = topStockCharts
    } else if (col1Selection === 'Watchlist') {
      options = watchlist_items
    } else if (col1Selection === 'Position') {
      options = position_items
    }

    // If we do have a search term, we filter by that
    if (searchString.length) {
      options = filterOptions(allSymbols, searchString)
    }

    const printableOptions: string[] = []
    options.forEach(o => {
      printableOptions.push(o.symbol)
    })

    for (let i = 0; i < options.length; i++) {
      const option = options[i]
      ret.push(make_listing({
        display: option.name,
        value: option.symbol
      }))
    }

    return ret.length ? ret : <div className='no-results'>No tickers.</div>
  }


  const lookup_ticker_name = (ticker: string) => {
    return allSymbols.find(s => s.symbol === ticker)?.name || ticker
  }


  interface Option {
    value: string
    display: string
  }
  const make_listing = (option: Option) => {
    return <div 
      className={'bucket'} 
      key={uuid()}
      onClick={ async () => {
        const tickers = user.watchlists.find(w => w.id === watchlistId)?.tickers || []
        const ticker = option.value
        if (tickers.includes(ticker)) {
          const updatedTickers = tickers.filter(t => t !== ticker)
          let charts = user.charts
          delete charts[ticker]
          await db.collection('users').doc(user.uid).update({
            watchlists: user.watchlists.map(w =>
              w.id === watchlistId ? { ...w, tickers: updatedTickers, updatedAt: new Date() } : w
            ),
            charts
          })
        } else {
          const updatedTickers = [...tickers, ticker]
          await db.collection('users').doc(user.uid).update({
            watchlists: (user.watchlists || []).map(w =>
              w.id === watchlistId ? { ...w, tickers: updatedTickers, updatedAt: new Date() } : w
            ),
            charts: {
              ...user.charts,
              [ticker]: {
                symbol: ticker,
                display: option.display,
                annotations: {},
                indicators: {},
              }
            }
          })
        }
        await updateLiveData(user)
      }}
    >
      <div className='row'>
        <Icon
          icon={'square-' + option.value.charAt(0)}
          set='sharp-solid'
          size={15}
          className={'key-icon'}
          style={{ marginTop: -1, marginRight: 10 }}
        />
        <div className='indicators-modal-val smaller'>{option.value}</div>
        <div className='indicators-modal-display'>{option.display}</div>
      </div>
      {(user.watchlists || []).find(w => w.id === watchlistId)?.tickers.includes(option.value) ? <div className='orange-dot' /> : null}
    </div>
  }


  return (
    <Modal
      title={'TICKERS'}
      contents={[(
        <div className='indicators-modal-container'>
          <Input
            noLabel={true}
            placeholder={'Search'}
            value={searchString}
            ref={inputRef}
            onChange={(val) => {
              setErr_msg('')
              setSearchString(val)
              setCol1Selection('')
              if (val === '') {
                // Reset to default when search is cleared
                setCol1Selection('All')
              }
            }}
          />
          <div className='col-container'>
            <div className='first-col'>
              {make_first_col_buckets()}
            </div>
            <div className='second-col-conatiner'>
              {searchString === '' && all_options[col1Selection].hasSubtabs ? <div className='indicators-modal-subheader'>
                {make_subtabs()}
              </div> : null}
              <div className='second-col'>
                {make_second_col_buckets()}
              </div>
              {err_msg ? <div className='err-message'>{err_msg}</div> : null}
            </div>
          </div>
        </div>
      )]}
      style={{width: 800, marginLeft: -200}}
    />
  )
}

