

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Modal } from '../reusable'
import { updateLiveData, uuid } from '../../logic/u'
import { loggedInUserAtom, openModalAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { FunctionObj } from '../../types/user_types'
import { currentUser, db } from '../../firebase'
import { Backtest } from '../../types/backtest_types'
import axios from 'axios'
import { getConfig } from '../../config'
const config = getConfig() as any


interface CancelBacktestModalProps {
  backtestObj: Backtest
}

export const CancelBacktestModal = (props: CancelBacktestModalProps) => {  
  const {backtestObj} = props

  // General helpers
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)

  if (!user || !backtestObj) return null

  return (
    <Modal
      title={'Cancel'}
      contents={[
        <div className='column width-full font-size-13 orange-text'>
          <div className='row'>
            Are you sure you want to cancel the backtest {backtestObj.name}? This operation cannot be undone.
          </div>
        </div>
      ]}
      yesButtonText={'Cancel'}
      isDanger
      isButtonLoading={buttonIsLoading}
      twoStepConfirmation={true}
      onYes={async () => {
        try {
          setButtonIsLoading(true)
          const token = await currentUser()?.getIdToken()
          const url = `${config.api_root_url}deleteBacktest`
        
          const data = {
            userId: user.uid,
            backtestId: backtestObj.id,
          }
          await axios.post(url, data, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })                     
          updateLiveData(user)
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

