/*
Icons representing different layout configurations
*/

import './DividerPaneIcon.scss';

import React from 'react';
import { ConfigArrangement } from '../../types/user_types';



export interface DividerPaneIconProps {
  configuration: ConfigArrangement
  style: any
  onClick?: any
  isSelected?: boolean
}

export function DividerPaneIcon(props: DividerPaneIconProps) {

  const style = props.style || {}

  let contents: any = null

  if (props.configuration === '1') {
    contents = (
        <div
          className='box'
          style={{
            width: '100%',
            height: '100%',
          }}
        />
    )
  } else if (props.configuration === '2-one-top-one-bottom') {
    contents = (
      <div className='wrapper column'>
        <div className='box'/>
        <div className='box'/>
      </div>
    )
  } else if (props.configuration === '2-one-left-one-right') {
    contents = (
      <div className='wrapper row'>
        <div className='box no-bottom-border'/>
        <div className='box no-bottom-border'/>
      </div>
    )
  } else if (props.configuration === '4') {
    contents = (
      <div className='wrapper column'>
        <div className='wrapper row' style={{width: '100%', height: '50%'}}>
          <div className='box' style={{width: '50%'}}/>
          <div className='box' style={{width: '50%'}}/>
        </div>
        <div className='wrapper row' style={{width: '100%', height: '50%'}}>
          <div className='box' style={{width: '50%'}}/>
          <div className='box' style={{width: '50%'}}/>
        </div>
      </div>
    )
  }

  return (
    <div className='divider-pane-icon-wrapper' style={style}>
      <div
        className={`divider-pane-icon ${props.isSelected ? 'selected' : ''}`}
        onClick={props.onClick}
      >
        {contents}
      </div>
    </div>
  )
}
