import './BottomPane.scss';

import React, { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { EvaluationsBit } from './EvaluationsBit'
import { loggedInUserAtom, openModalAtom, selectedEmptyChartAtom, userLiveDocAtom } from '../../types/global_types'
import { Icon } from '../reusable';
import { db } from '../../firebase';
import { Variable } from '../../types/user_types';
import { returnChartPaneContextObj, set_open_chart, valueForTable } from '../../logic/u';
import { CreateVariableModal } from '../modals/CreateVariableModal';

export const BottomPane = () => {
  const [user ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)

  if (!user) return null

  return (
    <div className='bottom-pane-container'>
      <div className='handle' />
      <div className='logs-pane-container'>
        <div className='logs-pane-header'>
          <div className='header-tab selected' >
            Logs
          </div>
        </div>
        <EvaluationsBit />
      </div>
      
    </div>
  )
}

