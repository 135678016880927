import './RightPane.scss'
import { useAtom } from 'jotai'
import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  ordersAtom,
  openModalAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { formatDateForTable, formatNumberForTable, roundToSigFigs } from '../../logic/u'
import { Icon } from '../reusable'
import { ORANGE } from '../../logic/colors'

import { _load_more_orders, sort_orders } from '../../logic/order_record_utils'

import { TickerDropdown } from '../dropdowns/TickerDropdown';
import { TickerModal } from '../modals/TickerModal';

const SCROLL_THRESHOLD = 200

export const OrdersPane = () => {
  const [orders, setOrders] = useAtom(ordersAtom);
  const [uld] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [selectedOrderCategory, setSelectedOrderCategory] = useState<string>('recent')
  const [openDropdown, setOpenDropdown] = useState<any>(null)

  const containerRef = useRef<HTMLDivElement>(null);

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const ordersRef = useRef(orders);
  useEffect(() => {
    ordersRef.current = orders;
  }, [orders]);

  const load_more_orders = useCallback(async () => {
    if (!uld || isLoadingMore || !hasMore) return;

    const currentOrders = ordersRef.current
    setIsLoadingMore(true);

    try {
      const untilTimestamp = currentOrders?.earliest_ts
      const additional_orders = await _load_more_orders(uld.id, untilTimestamp)

      if (additional_orders.length === 0) {
        setHasMore(false);
      } else {
        const allOld = currentOrders.unsorted || []
        const new_raw_orders = allOld.concat(additional_orders)
        const new_orders = sort_orders(new_raw_orders)
        setOrders(new_orders)

        if (additional_orders.length < 50) {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error('Error loading more orders:', error);
    } finally {
      setIsLoadingMore(false);
    }
  }, [isLoadingMore, hasMore, uld, setOrders]);

  useEffect(() => {
    if (!uld) return;

    const handleScroll = () => {
      if (uld.orders.length < 50) {
        setHasMore(false);
        return;
      }

      if (containerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        if (scrollHeight - scrollTop - clientHeight < SCROLL_THRESHOLD) {
          load_more_orders();
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [uld, load_more_orders]);

  if (!uld) return null;

  const ordersToShow = orders?.[selectedOrderCategory] || [];

  return (
    <>
      <SubHeader
        selectedOrderCategory={selectedOrderCategory}
        setSelectedOrderCategory={setSelectedOrderCategory}
      />
      <div className='sect-body has-subheader' ref={containerRef}>
        {openDropdown}
        {ordersToShow.map((order) => (
          <OrderEntry
            key={order.id}
            order={order}
            setOpenModal={setOpenModal}
            setOpenDropdown={setOpenDropdown}
          />
        ))}
        {(isLoadingMore || !hasMore) && (
          <div style={{color: ORANGE, padding: 10, fontSize: 10, textAlign: 'center'}}>
            {isLoadingMore ? 'Loading more...' : null}
            {!hasMore && ordersToShow.length >= 6 ? 'No more orders' : null}
          </div>
        )}
        {!ordersToShow.length && (
          <div className='margin-top-10 margin-left-10 orange-text font-size-11'>
            No {selectedOrderCategory} orders.
          </div>
        )}
      </div>
    </>
  );
};

const OrderEntry = ({ order, setOpenModal, setOpenDropdown }) => {
  let price = order.filled_avg_price
  let otherPrice: number | null = null
  if (order.type === 'limit') {
    price = order.limit_price
  }
  if (order.type === 'stop') {
    price = order.stop_price
  }
  if (order.type === 'stop_limit') {
    price = order.stop_price
    otherPrice = order.limit_price
  }

  const unrounded_qty = order.notional ? order.filled_qty : order.qty
  const rounded_qty = roundToSigFigs(unrounded_qty, price)

  const chartIcon = 'square-' + order.symbol.charAt(0)

  return (
    <div
      className={order.status === 'canceled' ? 'entry cancelled' : 'entry'}
      onClick={() => {
        setOpenModal(<TickerModal symbol={order.symbol} orderId={order.id} />)
      }}
      onContextMenu={(event) => {
        event.preventDefault()
        const width = 220
        const clickX = event.clientX - (width + 5)
        const clickY = event.clientY - 10
        setOpenDropdown(
          <TickerDropdown
            symbol={order.symbol}
            orderObj={order}
            left={clickX}
            top={clickY}
            width={width}
            onClose={() => setOpenDropdown(null)}
          />
        )
      }}
    >
      <div className={'column'}>
        <div className='row space-between'>
          <div className='row'>
            <Icon
              icon={chartIcon}
              set='sharp-solid'
              size={12}
              className={order.status === 'canceled' ? 'key-icon-cancelled' : 'key-icon'}
              style={{marginTop: 2, marginRight: 5}}
            />
            <div>{order.symbol}</div>
          </div>
          <div>
            {rounded_qty}
            {' @ ' + formatNumberForTable(price) + (otherPrice ? '-' + formatNumberForTable(otherPrice) : '')}
          </div>
        </div>
        <div className='row space-between font-size-11'>
          <div>{order.side}</div>
          <div>{order.type + ' • ' + order.time_in_force}</div>
        </div>
        <div className='row space-between font-size-10'>
          <div>{order.status}</div>
          <div>{formatDateForTable(order.updated_at)}</div>
        </div>
      </div>
    </div>
  )
}

const SubHeader = ({ selectedOrderCategory, setSelectedOrderCategory }) => {
  return (
    <div className='subheader'>
      <div
        className={selectedOrderCategory === 'recent' ? 'subtab selected' : 'subtab'}
        onClick={() => setSelectedOrderCategory('recent')}
      >
        Recent
      </div>
      <div
        className={selectedOrderCategory === 'working' ? 'subtab selected' : 'subtab'}
        onClick={() => setSelectedOrderCategory('working')}
      >
        Working
      </div>
      <div
        className={selectedOrderCategory === 'filled' ? 'subtab selected' : 'subtab'}
        onClick={() => setSelectedOrderCategory('filled')}
      >
        Filled
      </div>
    </div>
  )
}