import React, { useState, useEffect } from 'react'
import { Modal, Input, SliderToggle, Icon, showModal, DropdownSelect, CurrencyInput } from '../reusable'
import axios  from 'axios'
import { currentUser } from '../../firebase'
import { getConfig } from '../../config'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { useAtom } from 'jotai'
import { ErrorModal } from './ErrorModal'
import firebase from 'firebase/app';
import { BORDER_INPUT, INTERVALS, TEXT_GRAY, updateLiveData, valueForTable } from '../../logic/u'
import { get_empty_bars } from '../TBChart/chart_utils'
import { db } from '../../firebase'
import { Backtest } from '../../types/backtest_types'
const config = getConfig() as any

const EVALUATIONS_LIMIT = 20000

interface CreateBacktestModalProps {
  backtests: Backtest[]
}
export const CreateBacktestModal = (props: CreateBacktestModalProps) => {
  // General helpers
  const [ user ] = useAtom(loggedInUserAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)

  const [name, setName] = useState<string>('')
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [intervalObj, setIntervalObj] = useState<any>({})
  const [initialCapital, setInitialCapital] = useState<number>(0)
  const [estimatedEvals, setEstimatedEvals] = useState<number>(0)

  const {backtests} = props

  // Trigger type and interval
  useEffect(() => {
    const initialTriggerTypeObj = {}
    const initialIntervalObj = {}
    user?.functions.forEach(f => {
      initialTriggerTypeObj[f.id] = 'interval'
      initialIntervalObj[f.id] = '1m'
    })
    // setTriggerTypeObj(initialTriggerTypeObj)
    setIntervalObj(initialIntervalObj)
  }, [])

  // Check for errors when onclick isnt running
  let errorMessage = ''
  if (!buttonIsLoading) {
    // Check if name is unique
    let conflictingBacktests: Backtest[] = []
    conflictingBacktests = backtests?.filter(b => b.name === name) || []
    if (conflictingBacktests.length) {
      errorMessage = 'Backtest name is being used'
    }

    // Limit to 50 characters
    if (name?.length > 50) {
      errorMessage = 'Backtest name must be < 50 characters'
    }

    // Ensure name doesn't start with number
    if (/^\d/.test(name)) {
      errorMessage = 'Backtest name must not start with a number'
    }
  }

  // Button enabled
  useEffect(() => {
    setButtonEnabled(!!name && !!startDate && !!endDate && !errorMessage)
  }, [name, startDate, endDate, estimatedEvals, errorMessage]);


  // Estimated evaluations
  useEffect(() => {
    // let est_evaluations_formatted = '-'
    if (startDate && endDate) {
      const start_iso = new Date(startDate).toISOString()
      const end_iso = new Date(endDate).toISOString()

      let min_timeframe = '1d'
      let min_timeframe_ms = 1000 * 60 * 60 * 24
      console.log(intervalObj)
      for (const key in intervalObj) {
        if (intervalObj[key] === 'off') continue
        const int_ms = INTERVALS[intervalObj[key]]
        if (int_ms < min_timeframe_ms) {
          min_timeframe = intervalObj[key]
          min_timeframe_ms = int_ms
        }
      }

      const empty_bars = get_empty_bars({
        timeframe: min_timeframe,
        limit: 1000000,
        start: start_iso,
        end: end_iso,
      })
      const num_bars = empty_bars.length
      setEstimatedEvals(num_bars)

      if (estimatedEvals > EVALUATIONS_LIMIT) {
        errorMessage = (`Backtests are limited to ${EVALUATIONS_LIMIT.toLocaleString()} evaluations. Please select a larger interval or a shorter time period.`)
      } else if (startDate && endDate) {
        if (new Date(startDate) > new Date(endDate)) {
          errorMessage = 'End date must be later than start date'
        } else {
          errorMessage = ''
        }
      } else {
        errorMessage = ''
      }
    }
  }, [startDate, endDate, intervalObj])

  if (!user || !uld) return null


  // Estimated evaluations display
  let est_evaluations_formatted = '-'
  if (estimatedEvals > 0) {
    est_evaluations_formatted = estimatedEvals.toLocaleString()
  }

  return (
    <Modal
      title={'Backtest'}
      contents={[
        <div className='column width-full'>
          <Input
            label='Name'
            type='text'
            onChange={(val) => {
              let modified = val.replace(/\s/g, '_').toLowerCase().trim();
              modified = modified.slice(0, 50);
              setName(modified);
            }}
            style={{
              width: '100%'
            }}
          />
          {name && <div className='under-input-label'>
            {'Compiled: ' + name}
          </div>}
          <div className='row space-between margin-top-20'>
            <Input
              label={'Start date'}
              type={'date'}
              maxDate={new Date().toISOString().split('T')[0]}
              onChange={(val) => setStartDate(val)}
              style={{
                width: 'calc(50% - 5px)'
              }}
            />
            <Input
              label={'End date'}
              type={'date'}
              minDate={startDate ? startDate : undefined}
              key={`end-date-${startDate}`}     // associated with above
              maxDate={new Date().toISOString().split('T')[0]}
              onChange={(val) => {
                if (new Date(val) > new Date()) {
                  val = new Date().toISOString().split('T')[0]
                }
                setEndDate(val)
              }}
              style={{
                width: 'calc(50% - 5px)',
              }}
            />
          </div>

          <div className='label-text' style={{marginBottom: 5, marginTop: 20}}>
            Functions
          </div>
          <div
            style={{
              width: 'calc(100% - 18px)',
              height: 100,
              border: `1px solid ${BORDER_INPUT}`,
              borderRadius: 3,
              overflowY: 'scroll',
              padding: 8
            }}
          >
            {user.functions.map((f, i) => {
              return <div className={'row space-between justify-center' + (i !== 0 ? ' margin-top-5' : '')}>
                <div className='margin-top-1 white-text bold add-ellipses-cutoff'>{f.name}</div>
                <div className='row space-between'>
                  <DropdownSelect
                    style={{marginLeft: 20}}
                    variant='borderless'
                    width={'auto'}
                    dropDownMenuWidth={150}
                    value={'1m'}
                    chevronMarginTop={4}
                    onChange={(val) => {
                      setIntervalObj(prevObj => ({
                        ...prevObj,
                        [f.id]: val
                      }))
                    }}
                    options={[
                      {display: 'off', value: 'off'},
                      {display: '1m', value: '1m'},
                      {display: '5m', value: '5m'},
                      {display: '15m', value: '15m'},
                      {display: '30m', value: '30m'},
                      {display: '1h', value: '1h'},
                      {display: 'open', value: 'open'},
                    ]}
                  />
                </div>
              </div>
            })}
          </div>

          <CurrencyInput
            className={'margin-top-20'}
            label='Initial capital'
            width={380}
            onChange={(val) => setInitialCapital(val ? val : 0)}
          />
          <div className='divider-green margin-bottom-20' style={{marginTop: 22}}></div>
          <div className='column width-full font-size-11 orange-text'>
            <div className='row space-between'>
              <div className=''>Est. evaluations</div>
              <div className=''>{est_evaluations_formatted}</div>
            </div>

            {errorMessage && <div className='row space-between margin-top-10 red-text'>
              {errorMessage}
            </div>}
          </div>
        </div>
      ]}
      yesButtonText='Run'
      isButtonDisabled={!buttonEnabled || buttonIsLoading}
      isButtonLoading={buttonIsLoading}
      twoStepConfirmation={true}
      onYes={async () => {
        try {
          setButtonIsLoading(true)
          create_and_run({
            user,
            name,
            startDate,
            endDate,
            intervalObj,
            initialCapital,
          })

          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal( <ErrorModal errorMessage={error.message}/> )
        }
        updateLiveData(user)
      }}
    />
  )
}

interface CreateAndRunParams {
  user: any
  name: string
  startDate: string
  endDate: string
  intervalObj: any
  initialCapital: number
}
async function create_and_run(params: CreateAndRunParams) {

  // Start and end dates default to the beginning and end of the day
  const { name, startDate, endDate, intervalObj, initialCapital, user } = params
  const start_time_iso = new Date(new Date(startDate).setHours(0, 0, 0, 0)).toISOString()
  const end_time_iso = new Date(new Date(endDate).setHours(23, 59, 59, 999)).toISOString()

  // Assemble functions in minified form
  const functions: any[] = []
  for (const key in intervalObj) {
    if (intervalObj[key] === 'off') continue
    const func_obj = user.functions.find(f => f.id === key)
    functions.push({
      id: key,
      name: func_obj.name,
      triggerInterval: intervalObj[key],
      codeBlock: func_obj.codeBlock
    })
  }

  // Create a backtest object under the user
  try {
    const backtest = {
      name,
      userId: user.uid,
      start_time_iso,
      end_time_iso,
      functions,
      initialCash: initialCapital,
      status: 'new',
      // createdAt: firebase.firestore.FieldValue.serverTimestamp()
    }

    const backtestRef = await db.collection('users').doc(user.uid).collection('backtests').add(backtest)
    const backtest_id = backtestRef.id


    // Call initializeBacktest
    const token = await currentUser()?.getIdToken();
    await axios.post(`${config.api_root_url}initializeBacktest`, {
      uid: user.uid,
      backtestId: backtest_id
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

  } catch (error) {
    console.log(error)
  }
}