/*
Error modal
*/

import React from 'react'
import { Modal } from '../reusable'


export const ErrorModal = ({errorMessage}) => {
  return (
    <Modal
      title='Error'
      contents={[errorMessage]}
      yesButtonText='Ok'
    />
  )
}