

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Checkbox, Modal } from '../reusable'
import { createEELog, updateLiveData, uuid } from '../../logic/u'
import { DropdownSelect } from '../reusable/DropdownSelect'
import { loggedInUserAtom, openModalAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { FunctionObj } from '../../types/user_types'
import { db } from '../../firebase'

interface CreateFunctionModalProps {
  functionObj?: FunctionObj
}

export const CreateFunctionModal = (props: CreateFunctionModalProps) => {
  const {functionObj} = props

  // General helpers
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)

  // Form fields
  const [name, setName] = useState<string>(functionObj?.name.replace(/\s/g, '_') || '')
  const [turnOffWhenCodeFails, setTurnOffWhenCodeFails] = useState<boolean>(functionObj?.turnOffWhenCodeFails || false)
  const [triggerType, setTriggerType] = useState<FunctionObj['triggerType']>(functionObj?.triggerType || 'interval')
  const [triggerInterval, setTriggerInterval] = useState<FunctionObj['triggerInterval']>(functionObj?.triggerInterval || '1h')
  const [yesButtonText, setYesButtonText] = useState<string>(functionObj ? 'Update' : 'Create')

  // Check for errors when onclick isnt running
  let errorMessage = ''
  if (!buttonIsLoading) {
    // Check if name is unique
    let conflictingFunctions: FunctionObj[] = []
    if (functionObj) {
      conflictingFunctions = user?.functions?.filter(f => f.id !== functionObj.id && f.name === name) || []
    } else {
      conflictingFunctions = user?.functions?.filter(f => f.name === name) || []
    }
    if (conflictingFunctions.length) {
      errorMessage = 'Function name is being used'
    }

    // Limit to 50 characters
    if (name?.length > 50) {
      errorMessage = 'Function name must be < 50 characters'
    }

    // Ensure name doesn't start with number
    if (/^\d/.test(name)) {
      errorMessage = 'Function name must not start with a number'
    }
  }

  useEffect(() => {
    // New path
    setButtonEnabled(!!name && !!triggerType && !errorMessage)

    // New and interval
    if (triggerType === 'interval') {
      setButtonEnabled(!!name && !!triggerType && !!triggerInterval && !errorMessage)
    }

    // Update path
    if (functionObj) {
      setButtonEnabled(!!name && !errorMessage)
    }
  }, [name, turnOffWhenCodeFails, triggerType, triggerInterval, errorMessage]);

  if (!user) return null

  const content = (
    <div className='column width-full'>

      {/* Name input */}
      <Input
        label={'Name'}
        type={'text'}
        style={{width: '100%'}}
        initialValue={name}
        onChange={(val) => {
          let modified = val.replace(/\s/g, '_').toLowerCase().trim();
          modified = modified.slice(0, 50);
          setName(modified);
        }}
      />
      {name && <div className='under-input-label'>
        {'Compiled: ' + name}
      </div>}

      {!functionObj ? <>
        <DropdownSelect
          label={'Trigger'}
          width={362}
          className={'margin-top-20'}
          options={[
            {display: 'Run on interval', value: 'interval'},
            {display: 'Run only when manually triggered', value: 'callable'}
          ]}
          isAutocomplete={true}
          value={triggerType}
          onChange={(val) => {
            setTriggerType(val)
          }}
        />

        {triggerType === 'interval' ? <DropdownSelect
          label={'Trigger interval'}
          width={362}
          className={'margin-top-20'}
          options={[
            {display: '1m', value: '1m'},
            {display: '5m', value: '5m'},
            {display: '15m', value: '15m'},
            {display: '30m', value: '30m'},
            {display: '1h', value: '1h'},
            {display: 'open', value: 'open'}
          ]}
          isAutocomplete={true}
          value={triggerInterval}
          onChange={(val) => {
            setTriggerInterval(val)
          }}
        /> : null}
      </> : null}

      <div className='row margin-top-20'>
        <Checkbox
          // value={functionObj?.turnOffWhenCodeFails || false}
          value={turnOffWhenCodeFails}
          onChange={(val) => {
            setTurnOffWhenCodeFails(val)
          }}
          style={{marginRight: 10}}
        />
        <div className='white-text bold'>Turn off when code fails</div>
      </div>

      {/* Error message, if relevant */}
      <div
        className={errorMessage ? 'font-size-10 red-text' : 'display-none'}
        style={{
          marginTop: 15,
          marginBottom: -10,
        }}
      >
        {errorMessage}
      </div>
    </div>
  )

  return (
    <Modal
      title={functionObj ? 'Function settings' : 'Create Function'}
      contents={[content]}
      yesButtonText={yesButtonText}
      isButtonDisabled={!buttonEnabled || buttonIsLoading}
      isButtonLoading={buttonIsLoading}
      onYes={async () => {
        try {
          setButtonIsLoading(true)

          
          // Update codepath
          if (functionObj) {
            
            const updatedFunctionObj: FunctionObj = {
              ...functionObj,
              name: name,
              turnOffWhenCodeFails: turnOffWhenCodeFails,
              updatedAt: new Date()
            }
            let currentFunctions = user.functions || []
            const functionObjToUpdateIndex = currentFunctions.findIndex(f => f.id === functionObj.id)
            currentFunctions[functionObjToUpdateIndex] = updatedFunctionObj
            await db.collection('users').doc(user.uid).update({ functions: currentFunctions })
          } else {
            // New codepath
            const newFunctionObj: FunctionObj = {
              userId: user.uid,
              id: uuid(),
              createdAt: new Date(),
              updatedAt: new Date(),
              name: name,
              turnOffWhenCodeFails: turnOffWhenCodeFails,
              triggerType: triggerType,
              triggerInterval: triggerInterval ? triggerInterval : null,
              triggerDate: null,
              isRunning: false,
              codeBlock: ''
            }
            let currentFunctions = user.functions || []
            currentFunctions.push(newFunctionObj)
            await db.collection('users').doc(user.uid).update({functions: currentFunctions})
          }
          updateLiveData(user)
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

