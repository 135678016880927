/*
As of 1 May 2024, used exclusively by FunctionsTab
*/

import { useAtom } from 'jotai'
import { loggedInUserAtom, functionsWithUnsavedChangesAtom } from '../../types/global_types'
import React, { useState, useEffect, useRef } from 'react'
// import { Couplet } from '../../types/couplet_types'
import { FunctionObj } from '../../types/user_types'
import { Editor, loader } from '@monaco-editor/react'
import { registerCompletionProvider } from '../../logic/monaco_completions'

interface CodeEditorProps {
  functionObj: FunctionObj,
}
export const CodeEditor = (props: CodeEditorProps) => {
  const {functionObj} = props
  const [editorValue, setEditorValue] = useState<any>(functionObj.codeBlock)
  const [user, ] = useAtom(loggedInUserAtom)
  const [functionsWithUnsavedChanges, setFunctionsWithUnsavedChanges] = useAtom(functionsWithUnsavedChangesAtom)
  const containerRef: any = useRef(null) // do we need this
  const editorRef = useRef<any>(null) // do we need this

  useEffect(() => {
    loader.init().then(monaco => {
      monaco.editor.defineTheme('my-theme', {
        base: 'vs-dark',
        inherit: true,
        rules: [],
        colors: {
          'editor.background': '#00000000',
          'minimap.background': '#00000000',
          'minimapSlider.background': '#55555533',
          'minimapSlider.hoverBackground': '#66666633',
          'minimapSlider.activeBackground': '#88888833',
        },
      })

      // Register a completion item provider
      registerCompletionProvider(monaco, ["TSLA", "NVDA"])

    })

    return () => {
      setEditorValue(null)
    };
  }, [])

  useEffect(() => {
    setEditorValue(functionObj.codeBlock)
  }, [functionObj])

  const handleEditorChange = value => {
    setEditorValue(value)
    let newState = {...functionsWithUnsavedChanges}
    newState[functionObj.id] = {
      unsavedEditorValue: value
    }
    setFunctionsWithUnsavedChanges(newState)
  }

  if (!user || !functionObj) return null

  return (
    <div className={`code-editor-container`} ref={containerRef}>
      <Editor
        height="100%"
        width="100%"
        theme="my-theme"
        defaultLanguage="python"
        value={editorValue}
        onChange={handleEditorChange}
        onMount={(editor) => {
          editorRef.current = editor
        }}
        options={{
          readOnly: false,
          // lineNumbersMinChars: 2,
          padding: {
            top: 0
          },
          minimap: {
            enabled: true
          },
          scrollbar: {
            // vertical: 'visible',
            // verticalScrollbarSize: 14,
            // horizontal: 'visible',
          },
          // scrollBeyondLastLine: true,
          // wordWrap: 'on',
        }}
      />
    </div>
  )
}


