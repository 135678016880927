import './Checkbox.scss';
import React from 'react';

import { Icon } from './Icon';


interface CheckboxProps {
  value: boolean
  isDisabled?: boolean
  onChange: (value: boolean) => void
  className?: string
  style?: any
  checkMarginTop?: number
}
export function Checkbox(props: CheckboxProps) {

  let mainClassName = 'checkbox'
  if (props.className) {
    mainClassName += ' ' + props.className
  }
  if (props.value) {
    mainClassName += ' checked'
  }
  if (props.isDisabled) {
    mainClassName += ' disabled'
  }



  return (
    <div
      className={mainClassName}
      style={props.style}
      onClick={() => {
        if (props.isDisabled) return
        props.onChange(!props.value)
      }}
    >
      {props.value ? <Icon
        icon={'check'}
        set={'sharp-solid'}
        size={12}
        style={{
          marginTop: props.checkMarginTop ? props.checkMarginTop : -0,
          marginLeft: 1,
          svg: {fill: 'black'}

        }}
      /> : null}


    </div>
  )

}
