import axios  from 'axios'
import React from 'react'
import { activityTypes, formatDateForTable, formatNumberForTable } from '../../logic/u'
import { currentUser } from '../../firebase'
import { Modal, showModal } from '../reusable'

import { getConfig } from '../../config'
import { ErrorModal } from './ErrorModal'
import { useAtom } from 'jotai'
import { openModalAtom } from '../../types/global_types'
const config = getConfig() as any

export interface TransactionsDetailsModalProps {
  transaction: any
}

export const TransactionsDetailsModal = (props: TransactionsDetailsModalProps) => {

  const transaction = props.transaction
  const type = activityTypes.find((activityType) => activityType.activityType === transaction.activity_type)?.description || transaction.type
  const amount = transaction.price ? transaction.price : transaction.net_amount
  const date = transaction.transaction_time ? transaction.transaction_time : transaction.date 
  
  const contents = (
    <div className='column width-full font-size-13 orange-text'>

      <div className='row space-between margin-bottom-10'>
        <div className='margin-right-10'>type: </div>
        <div className='add-ellipses-cutoff' title={type}>{type}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>symbol: </div>
        <div className=''>{transaction.symbol || '-'}</div>
      </div>
      
      <div className='row space-between margin-bottom-10'>
        <div className=''>side: </div>
        <div className=''>{transaction.side || '-'}</div>
      </div>
      
      <div className='row space-between margin-bottom-10'>
        <div className=''>qty: </div>
        <div className=''>{transaction.qty || '-'}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>leaves_qty: </div>
        <div className=''>{transaction.leaves_qty || '-'}</div>
      </div>
     
      <div className='row space-between margin-bottom-10'>
        <div className=''>cum_qty: </div>
        <div className=''>{transaction.cum_qty || '-'}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>per_share_amount: </div>
        <div className=''>{formatNumberForTable(transaction.per_share_amount) || '-'}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>amount: </div>
        <div className=''>{formatNumberForTable(amount)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>status: </div>
        <div className=''>{transaction.status || transaction.order_status || '-'}</div>
      </div>
      
      <div className='row space-between margin-bottom-10'>
        <div className=''>transaction_time: </div>
        <div className=''>{formatDateForTable(date)}</div>
      </div>
      
      
    </div>
  )
  
  return (
    <Modal
      title='Transaction details'
      contents={[contents]}
    />
  )
}