/*
Duplicate SCSS variables should be maintained in App.scss
*/

export const ORANGE = '#FF8F0E'
export const ORANGE_DULL = '#ae6213'
export const ORANGE_FAINT = '#2e1a03'

// CANDLE is bright, TAG is designed for use as a background
export const CANDLE_RED = '#ff5555'   // "neon but saturated, without being pastel"
export const CANDLE_GREEN = '#00cc66'
export const TAG_RED = '#990000'
export const TAG_GREEN = '#006600'
export const GREEN_FAINT = '#00331a'
export const RED_FAINT = '#401515'

// Used for user-facing colors
export const NOCTIS_OBSCURO_YELLOW = '#FFD702'
export const NOCTIS_OBSCURO_ORANGE = '#E66533'
export const NOCTIS_OBSCURO_MUSTARD = '#D5971A'
export const NOCTIS_OBSCURO_GREEN = '#49E9A6'
export const NOCTIS_OBSCURO_PALE_PURPLE = '#DA70D6'
export const NOCTIS_OBSCURO_GRAY = '#B2CACD'
export const NOCTIS_OBSCURO_DULL_ORANGE = '#D67E5C'
export const NOCTIS_OBSCURO_PINK = '#DF769B'
export const NOCTIS_OBSCURO_BLUE = '#17A3B6'
export const NOCTIS_OBSCURO_PURPLE = '#7060EB'