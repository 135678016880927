/*
Single component view of an EELog, used both in evaluationsBit and in the EELogsTable.

Note that
*/

import React, { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { EELog } from '../../types/user_types'
import { loggedInUserAtom, openModalAtom } from '../../types/global_types'
import {
  humanDateForFirebaseDate,
  stringToFormattedAmount,
} from '../../logic/u'
import { Icon } from './Icon'
import { db } from '../../firebase'
import { LogModal } from '../modals/LogModal'


interface EvaluationsRowProps {
  log: EELog
}
export const EvaluationRow = (props: EvaluationsRowProps) => {
  const { log } = props
  const [user] = useAtom(loggedInUserAtom)


  if (!user) return null

  if (Object.keys(log).length === 0) return null

  let header_portion: any = null
  let action_portion: any = null
  let have_one_error = false

  // Function scenario
  const nameCharLim = 10
  if (['func-interval', 'func-call'].includes(log.opType)) {
    // const function_name = log.function_name && log.function_name?.length > nameCharLim ? <>
    //   <span>{log.function_name.substring(0, nameCharLim)}</span>
    //   <span style={{
    //     letterSpacing: -5,
    //     marginLeft: -2,
    //     marginRight: 3
    //   }}
    //   >...</span>
    // </> : log.function_name
    const function_name = log.function_name
    let icon = (
      <Icon
        icon='clock'
        className='method-icon'
        size={13}
        style={{marginTop: 1.75}}
        hoverText={`Ran on interval`}
      />
    )

    if (log.opType === 'func-call') {
      icon = (
        <Icon
          icon='bullseye-pointer'
          className='method-icon'
          style={{marginTop: 1.25}}
          size={12}
          hoverText='Ran on call'
        />
      )
    }
    header_portion = (
      <div className='header-portion'>
        <div className='header-box'>{function_name}</div>
        <div className='trigger-box'>
          {icon}
          {log.triggerInterval ? <div style={{marginLeft: 4}}>{log.triggerInterval}</div> : null}
        </div>
      </div>
    )
  } else if (log.opType === 'manual') {
    header_portion = (
      <div className='header-portion'>
        <div className='header-box'>manual_operation</div>
      </div>
    )
  } else if (log.opType === 'daily_log') {
    header_portion = (
      <div className='header-portion'>
        <div className='header-box'>daily_log</div>
      </div>
    )
  } else if (log.opType === 'server_event') {
    header_portion = (
      <div className='header-portion'>
        <div className='header-box'>server_event</div>
      </div>
    )
  }

  // function_activation
  else {
    header_portion = (
      <div className='header-portion'>
        <div className='header-box'>function_activation</div>
      </div>
    )
  }

  // else if (log.opType === 'user_log') {
  //   header_portion = (
  //     <div className='header-portion'>
  //       <div className='header-box'>create_log</div>
  //     </div>
  //   )
  // }


  // Action items - in all cases, we show ACTION and ERROR items in console_output
  const action_items: any[] = []
  if (log.console_output && Array.isArray(log.console_output)) {
    log.console_output.forEach((co: any, i) => {

      // Ordinary print statements don't appear here
      if (!co.text) return

      // Actions get a green box
      if (co.className.includes('action')) {
        action_items.push(
          <div className='log-item action' key={i}>{co.text}</div>
        )
      }

      // Function error - only show one line of it
      else if (co.className.includes('err')) {
        if (!have_one_error) {
          action_items.push(
            <div className='log-item error' key={i}>{co.text}</div>
          )
        }
        have_one_error = true
      }

      // `log` creates an ordinary log, except it's visible here
      else if (co.className.includes('log')) {
        action_items.push(
          <div className='log-item' key={i}>{co.text}</div>
        )
      }

      // old, duplicate system - consider omitting and prefixing with create_log
      else if (co.text.indexOf('log: ') === 0) {
        action_items.push(
          <div className='log-item ' key={i}>{co.text}</div>
        )
      }
    })
  }

  let show_hyphen = !action_items.length && ['func-interval', 'func-call'].includes(log.opType)
  action_portion = (
    log.opType !== 'daily_log' ? <div className='action-container'>
      {action_items}
      {show_hyphen ? <div className='log-item' key='i'>-</div> : null}
    </div> : null
  )

  return (
    <div
      className={'eval-row'}
      onClick={async () => {
        // console.log(log)

        // Get full log from the database
        const log_id = log.id
        const log_doc = await db.collection('users')
          .doc(user.uid)
          .collection('EELogs')
          .doc(log_id)
          .get()
        const full_log = log_doc.data()
        console.log(full_log)
      }}
    >
      {header_portion}
      {action_portion}
    </div>
  )
}
