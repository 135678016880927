import '../SignUp.scss'
import React, { useEffect } from 'react'
import { db, currentUser } from '../../../firebase'
import {loadingAtom, loggedInUserAtom } from '../../../types/global_types'
import {
  SignUpStage,
} from '../../../types/user_types'
import { useAtom } from 'jotai'
import {
  updateLiveData,
} from '../../../logic/u'
import axios from 'axios'
import { CreatePlaidLinkButtonModal } from '../../modals/CreatePlaidLinkButtonModal'
import { getConfig } from '../../../config'
const config: any = getConfig()

export const BankInfoStep = () => {
  const [loggedInUser] = useAtom(loggedInUserAtom)
  const [, setLoading] = useAtom(loadingAtom)
  const nextSignUpStage: SignUpStage = 'Done'

  useEffect(() => {
    if (loggedInUser?.hasAchRelationship && !loggedInUser.hasReceivedOnboardingEmail) {
      setLoading(true)
      const sendOnboardingEmail = async () => {
        const token = await currentUser()?.getIdToken()
        const data = {
          to: loggedInUser?.email
        }
        await axios.post(`${config.api_root_url}sendOnboardingEmail`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
      }
      sendOnboardingEmail()
      db.collection('users').doc(loggedInUser?.uid).update({
        signUpStage: nextSignUpStage,
        hasReceivedOnboardingEmail: true
      })

      // Run runTick for the first time
      updateLiveData(loggedInUser).then(() => {
        setLoading(false)
      })
    }
  }, [loggedInUser])

  if (!loggedInUser) return null

  return (
    <div className='signup-container'>
      <div className='signup-form-container'>
        <div className='signup-context-title center'>
          {'Your trading account is set up.'}
        </div>
        <div className='signup-context-subtitle center'>
          {'Connect your bank to complete the sign up process.'}
        </div>
        <div className='row'>
          <CreatePlaidLinkButtonModal />
        </div>
      </div>
    </div>
  )
}