import '../SignUp.scss'
import React, { useState, useEffect } from 'react'
import { Input, Button, PhoneInput } from '../../reusable'
import { db } from '../../../firebase'
import { loggedInUserAtom, openModalAtom } from '../../../types/global_types'
import {
  SignUpStage,
} from '../../../types/user_types'
import { useAtom } from 'jotai'
import { ErrorModal } from '../../modals/ErrorModal'

export const TrustedContactStep = () => {
  const [loggedInUser ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)

  // This signup stage
  const [ firstName, setFirstName ] = useState<string>(loggedInUser?.alpacaObject?.trusted_contact?.given_name || '')
  const [ lastName, setLastName ] = useState<string>(loggedInUser?.alpacaObject?.trusted_contact?.family_name || '')
  const [ emailAddress, setEmailAddress ] = useState<string>(loggedInUser?.alpacaObject?.trusted_contact?.email_address || '')
  const [ phoneNumber, setPhoneNumber ] = useState<string>(loggedInUser?.alpacaObject?.trusted_contact?.phone_number || '')

  const previousSignUpStage: SignUpStage = 'DisclosuresImmediateFamilyExposed'
  const nextSignUpStage: SignUpStage = 'TaxInfo'

  useEffect(() => {
    setButtonEnabled(!!firstName && !!lastName && !!emailAddress && !!phoneNumber);
  }, [firstName, lastName, emailAddress, phoneNumber]);

  if (!loggedInUser) return null

  return (
    <div className='signup-container'>
      <div className='signup-form-container'>
        <div className='signup-context-title'>
          Trusted contact information
        </div>
        <Input
          label={`First name`}
          type={'text'}
          className={'margin-top-15'}
          style={{width: '100%'}}
          initialValue={firstName}
          onChange={(val) => {setFirstName(val)}}
        />
        <Input
          label={`Last name`}
          type={'text'}
          className={'margin-top-20'}
          style={{width: '100%'}}
          initialValue={lastName}
          onChange={(val) => {setLastName(val)}}
        />
        <Input
          label={`Email address`}
          type={'email'}
          className={'margin-top-20'}
          style={{width: '100%'}}
          initialValue={emailAddress}
          onChange={(val) => {setEmailAddress(val)}}
        />
        <PhoneInput
          label={`Phone number (US only)`}
          style={{width: '100%'}}
          className={'margin-top-20'}
          initialValue={phoneNumber}
          onBlur={(val) => {
            let phoneNumber = val
            let cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
            cleanedPhoneNumber = '+' + cleanedPhoneNumber
            setPhoneNumber(cleanedPhoneNumber)
          }}
        />
        <Button
          display={'Next'}
          className={'margin-top-25'}
          style={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
          isDisabled={!buttonEnabled || buttonIsLoading}
          isLoading={buttonIsLoading}
          onClick= {async () => {
            try {
              setButtonIsLoading(true)
              await db.collection('users').doc(loggedInUser.uid).update({
                'alpacaObject.trusted_contact.given_name': firstName,
                'alpacaObject.trusted_contact.family_name': lastName,
                'alpacaObject.trusted_contact.email_address': emailAddress,
                'alpacaObject.trusted_contact.phone_number': phoneNumber,
                signUpStage: nextSignUpStage,
              })
              setButtonIsLoading(false)
            } catch (err) {
              setButtonIsLoading(false)
              setOpenModal( <ErrorModal errorMessage={err.message}/> )
            }
          }}
        />
        <Button
          display={'Back'}
          className={'margin-top-15'}
          variant={'secondary'}
          style={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
          onClick= {async () => {await db.collection('users').doc(loggedInUser.uid).update({signUpStage: previousSignUpStage})}}
        />
      </div>
    </div>
  )

}