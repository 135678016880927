import React, { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatDateForTable,
  valueForTable,
  uuid,
  handleMUISorting,
  get_contextmenu_off,
} from '../../logic/u'
import {
  loggedInUserAtom,
  openModalAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { CreateVariableModal } from '../modals/CreateVariableModal'
import { VariableDropdown } from '../dropdowns/VariableDropdown'
import { CustomGridOverlay } from './CustomGridOverlay'
import { Backtest } from '../../types/backtest_types'

interface StateTabProps {
  backtest?: Backtest | null
}
export const StateTab = (props: StateTabProps) => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'last_set',
      sort: 'desc' as GridSortDirection
    },
  ]);
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)


  if (!user || !uld) return null

  const LD_DATA_SOURCE = props.backtest ? props.backtest.liveData : uld.liveData
  const VARIABLES_DATA_SOURCE = props.backtest ? props.backtest.state : user.variables
  const variableRows: any[] = Object.keys(VARIABLES_DATA_SOURCE || [])?.sort().map(v => {
    const variable = {...VARIABLES_DATA_SOURCE[v], id: uuid()}
    return variable
  })

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  }

  const handleContextMenuClick = (event: any, params: any, openLeft?: boolean) => {
    if (get_contextmenu_off()) return
    event.preventDefault()
    const width = 220
    const clickX = openLeft? event.clientX - (width + 5) : event.clientX + 5
    const clickY = event.clientY - 10
    setOpenDropdown(
      <VariableDropdown
        variableObj={params.row}
        left={clickX}
        top={clickY}
        width={width}
        onClose={() => setOpenDropdown(null)}
      />
    )
  }

  return (
    <div className='tab-contents has-subtab'>
      {openDropdown}
      <div className='table-container'>
        <DataGridPro
          className='clickable-grid'
          rows={variableRows}
          // getRowHeight={() => 'auto'}
          // getEstimatedRowHeight={() => 36}
          columns={[
            {
              field: 'symbol',
              headerName: 'name',
              flex: .5,
              type: 'string',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    padding: 10,
                    textAlign: 'left'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {params.row.symbol}
                </div>
              )
            },
            {
              field: 'type',
              headerName: 'type',
              flex: .5,
              headerAlign: 'left',
              align: 'left',
              type: 'string',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    padding: 10,
                    textAlign: 'left'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {params.row.type}
                </div>
              )
            },
            {
              field: 'value',
              headerName: 'value',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    padding: 10,
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {valueForTable(params.row.symbol, LD_DATA_SOURCE.STATE[params.row.symbol])}
                </div>
              )
            },
            {
              field: 'last_set',
              headerName: 'last_set',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'date',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    padding: 10,
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {formatDateForTable(params.row?.last_set?.toDate())}
                </div>
              )
            },
            {
              field: 'created_at',
              headerName: 'created_at',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'date',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    padding: 10,
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params, true)
                  }}
                >
                  {formatDateForTable(params.row?.created_at?.toDate())}
                </div>
              )
            }
          ]}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          density='compact'
          components={{
            NoRowsOverlay: () => {
              if (!variableRows) {
                return (
                  <CustomGridOverlay text='Loading variables...' />
                )
              }
              return (
                <CustomGridOverlay text='No variables.' />
              )
            },
          }}
          sx={{
            ...tableSX,
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { p: '0px' },
          }}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          localeText={{
            footerTotalRows: 'Total variables:',
          }}
          onRowClick={(params) => setOpenModal(<CreateVariableModal variable={params.row} />)}
        />
      </div>

    </div>
  )
}


