/*
  - contents: {
      display: string,
      onClick: () -> void,
      noSelect: boolean,
      isDisabled: () -> bool
    }[]
  - onClose: () -> void

NOTES REGARDING DUMMYINPUT:
  - was having a ton of trouble getting the keydown listener to trap focus and prevent scrolling
  - came up with a cheap hack involving an invisible dummy input
  - while we're at it, we should probably move the dropdown listener on the window to this system
  - but it works as-is, so we'll leave it for now
*/

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './DropdownMenu.scss'

export class DropdownMenu extends React.Component {
  constructor(props) {
    super(props)
    this.contents = this.props.contents
    this.posLeft = this.props.posLeft
    this.posRight = this.props.posRight
    this.posTop = this.props.posTop
    this.width = this.props.width
    this.height = this.props.height
    // this.fontWeight = this.props.fontWeight
    this.onClose = this.props.onClose || (() => {return})
    this.onSelect = this.props.onSelect || (() => {return})

    this.footerContents = this.props.footerContents || []
    this.lighterColorScheme = this.props.lighterColorScheme
    this.selectedIndex = this.props.selectedIndex || -1
    this.noArrowKeys = this.props.noArrowKeys


    // ALTERNATE INTERFACE - use this for async loading
    this.getContents = this.props.getContents  // async () -> [<contents>]

    this.previousFilteredLength = null

    // Decoy input to trap focus
    this.dummyInputRef = React.createRef()
  }

  state = {
    selectedIndex: -1,
    contents: [],
    filterString: '',
    posTop: this.props.posTop,
    isVisible: false
  }

  componentDidMount() {
    document.addEventListener('keydown', keydownListener)
    window.dropdownRef = this

    // Either load the contents or get the contents
    if (this.contents) {
      this.setState({contents: this.contents})
    }
    if (this.getContents) {
      this.getContents((contents) => {
        this.setState({
          contents,
          filteredContents: contents
        })
      })
    }

    // Dumb trick to trap the cursor and prevent the entire div from scrolling
    this.dummyInputRef.current.focus()

    // Adjust position after the DOM has updated (adjusts posTop to ensure the thing is above the fold)
    setTimeout(() => {
      this.adjustPosition();
    }, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.contents !== prevProps.contents) {
      this.setState({ contents: this.props.contents });
    }

    // If using getContents for async loading
    if (this.props.getContents !== prevProps.getContents) {
      this.props.getContents((contents) => {
        this.setState({
          contents,
          filteredContents: contents
        });
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', keydownListener)
  }

  onKeyDown(e) {
    // console.log(`DropdownMenu keydown: ${e.key}`)
    if (e.key === 'ArrowDown') {
      e.stopPropagation()
      if (this.noArrowKeys) return
      if (this.state.selectedIndex < (this.state.contents.length + this.footerContents.length) - 1) {
        this.setState({selectedIndex: this.state.selectedIndex + 1})
      }
    } else if (e.key === 'ArrowUp') {
      e.stopPropagation()
      if (this.noArrowKeys) return
      if (this.state.selectedIndex > -1) {
        this.setState({selectedIndex: this.state.selectedIndex - 1})
      }
    } else if (e.key === 'Escape') {
      e.stopPropagation()
      this.onClose()
    } else if (['Enter', 'Tab'].includes(e.key)) {
      window.defaultLastPrevented = new Date()    // for the benefit of modal
      if (this.state.selectedIndex > this.state.contents.length - 1) {
        this.footerContents[this.state.selectedIndex]?.onClick()
      } else {
        this.state.contents[this.state.selectedIndex]?.onClick()
        console.log('selecting ' + this.state.selectedIndex)
        this.onSelect(this.state.contents[this.state.selectedIndex])
      }
      this.onClose()
    }
    else {
      let found = false
      this.contents.forEach((item, index) => {
        const firstLetter = item.display[0]?.toLowerCase()
        if ((firstLetter === e.key) && !found) {
          found = true
          this.setState({selectedIndex: index})

          // Scroll to selection
          const itemHeight = 32
          const menuHeight = this.height || 200
          const scrollTop = this.state.selectedIndex * itemHeight
          const scrollBottom = scrollTop + itemHeight
          const scrollPosition = scrollTop - menuHeight
          const scrollBottomPosition = scrollBottom - menuHeight
          const menu = document.querySelector('.dropdown-menu')
          if (scrollPosition < menu.scrollTop) {
            menu.scrollTop = scrollPosition
          } else if (scrollBottomPosition > menu.scrollTop) {
            menu.scrollTop = scrollBottomPosition
          }
        }
      })
    }
  }

  adjustPosition() {
    const viewportHeight = window.innerHeight;
    const dropdown = this.dummyInputRef.current.nextSibling; // Get reference to the dropdown element
    if (dropdown) {
      const dropdownHeight = dropdown.offsetHeight;
      let adjustedTop = this.state.posTop;
      // Adjust vertical position if dropdown would be cut off at the bottom
      if (this.state.posTop + dropdownHeight > viewportHeight) {
        adjustedTop = this.props.posTop - dropdownHeight + 10; // Render above the click
      }
      this.setState({
        posTop: adjustedTop,
        isVisible: true
      });
    }
  }

  render() {

    let filteredContents = this.state.contents
    if (this.state.filterString) {
      filteredContents = this.state.contents.filter((item) => {
        return item.display?.toLowerCase().indexOf(this.state.filterString?.toLowerCase()) === 0
      })
      if (this.state.selectedIndex !== -1) {
        if (filteredContents.length !== this.previousFilteredLength) {
          const oldIndex = this.state.selectedIndex
          const oldValue = this.state.contents[oldIndex]
          const newIndex = filteredContents.indexOf(oldValue)
          console.log(`resetting index from ${oldIndex} to ${newIndex}`)
          this.setState({selectedIndex: newIndex})
          this.previousFilteredLength = filteredContents.length
        }
      }
    }

    let classList = 'dropdown-menu'
    if (this.lighterColorScheme) {
      classList += ' lighter'
    }

    return (
      <div
        className={'dropdown-scrim'}
        onClick={() => {this.onClose()}}
      >
        <input
          ref={this.dummyInputRef}
          style={{
            height: 0,
            width: 0,
            border: 'none',
            outline: 'none',
            backgroundColor: 'transparent'
          }}
        />
        <div
          className={classList}
          style={{
            left: this.posLeft,
            right: this.posRight,
            top: this.state.posTop,
            width: this.width,
            maxHeight: this.height || 200,
            overflowY: 'scroll,',
            visibility: this.state.isVisible ? 'visible' : 'hidden',  // we do this so there's no flicker when we adjust posTop
          }}
        >
          {filteredContents.map((item, i) => {
          // {this.state.contents.map((item, i) => {

            let classList = 'menu-item'
            if (i === this.state.selectedIndex) {
              classList += ' selected'
            }
            if (!item.onClick) {
              classList += ' no-select'
            }

            const isDisabled = item.isDisabled
            if (isDisabled) {
              classList += ' disabled'
            }
            return (
              <div
                className={classList}
                key={i}
                onClick={(e) => {
                  e.stopPropagation()
                  if (isDisabled) {
                    return
                  }
                  if (item.onClick) {
                    item.onClick(e)
                    this.onSelect(item)
                    if (this.props.onClose) {
                      this.onClose()
                    }
                  }
                }}
              >
                <div className={'left'}>{item.display}</div>
                {this.getButtonRegion(item.buttons)}
              </div>
            )
          })}
          {this.getFooter()}
        </div>
      </div>
    )
  }

  getFooter() {
    if (this.footerContents.length) {
      return (
        <div className={'footer'}>
          {this.footerContents.map((item, i) => {
            let classList = 'menu-item'
            if (i + (this.state.contents.length) === this.state.selectedIndex) {
              classList += ' selected'
            }
            return (
              <div
                className={classList}
                onClick={(e) => {
                  item.onClick(e)
                  if (this.props.onClose) {
                    this.onClose()
                  }
                }}
              >
                {item.display}
              </div>
            )
          })}
        </div>
      )
    }
  }

  getButtonRegion(buttons) {
    if (!buttons) {return}
    return (
      <div className={'button-region'}>
        {buttons.map((button) => {
          return (
            <FontAwesomeIcon
              icon={button.icon}
              className={'btn'}
              onClick={button.onClick}
            />
          )
        })}
      </div>
    )
  }

  // Call from DropdownSelect to set a filter string
  filterOptions(filterString) {
    this.setState({filterString})
  }

}

// Universal listener, which may be set and unset on document
const keydownListener = (e) => {
  window.dropdownRef?.onKeyDown(e)
}