import './LeftPane.scss'
import Split from 'react-split'
import { useAtom } from 'jotai'
import { db } from "../../firebase"
import React, { useState, useEffect, useRef } from 'react';
import {
  loggedInUserAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { TickersPane } from './TickersPane';
import { BalancesPane } from './BalancesPane';
import { StatePane } from './StatePane';


const SUBTABS = [
  {display: 'Tickers', value: 'tickers'},
  {display: 'State', value: 'state'},
  {display: 'Balances', value: 'balances'},
]

export const LeftPane = () => {
  const [user] = useAtom(loggedInUserAtom);
  const [uld] = useAtom(userLiveDocAtom)
  const [ subTab, setSubTab ] = useState('tickers')

  let subTabContent: any = null
  switch (subTab) {
    case 'tickers':
      subTabContent = <TickersPane />
      break
    case 'state':
      subTabContent = <StatePane />
      break
    case 'balances':
      subTabContent = <BalancesPane />
      break
  }

  // NOTE: if liveData hasn't loaded, sidebar will be missing
  if (!user) return null
  if (!uld || !uld.liveData) return null

  return (
    <div className={'left-pane'}>
      <div className='header'>
        <div className='row'>
          <div className='header-tab selected'>Charts</div>
        </div>
      </div>
      <div className='subheader'>
        {SUBTABS.map((s) => {
          let className = 'subtab'
          if (s.value === subTab) {
            className += ' selected'
          }
          return (
            <div
              className={className}
              onClick={() => setSubTab(s.value)}
            >
              {s.display}
            </div>
          )
        })}
      </div>
      {subTabContent}
    </div>
  )
}