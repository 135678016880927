// NOTE: do not use Jotai here, or it will cause trouble

import React from 'react';
import { Annotation } from '../../../types/user_types';

import { uuid } from '../../../logic/u'
import { db } from '../../../firebase'
import firebase from 'firebase/app'


interface TrendlineProps {
  annotation: Annotation
  get_candle_index_for_timestamp: (timestamp: number) => number
  get_x_for_candle_index: (candle_index: number) => number
  get_y_for_price: (price: number) => number

  // Things we don't get from Jotai
  selectedAnnotation: any
  setSelectedAnnotation: (annotation: any) => void
  user: any
  setIsDragging: (isDragging: boolean) => void
  selectedChart: string

  // crosshair_date: string
  // crosshair_price: string

}

export const Trendline = (props: TrendlineProps) => {
  const setIsDragging = props.setIsDragging
  const user = props.user
  const selectedAnnotation = props.selectedAnnotation
  const setSelectedAnnotation = props.setSelectedAnnotation
  const selectedChart = props.selectedChart



  const isSelected = selectedAnnotation?.id === props.annotation.id


  const ret: any[] = []

  const coords = JSON.parse(props.annotation.data)

  const start_timestamp = new Date(coords.x1).getTime()
  const start_candle_index = props.get_candle_index_for_timestamp(start_timestamp)
  let x1 = props.get_x_for_candle_index(start_candle_index)
  let y1 = props.get_y_for_price(coords.y1)

  const end_timestamp = new Date(coords.x2).getTime()
  const end_candle_index = props.get_candle_index_for_timestamp(end_timestamp)
  let x2 = props.get_x_for_candle_index(end_candle_index)
  let y2 = props.get_y_for_price(coords.y2)

  // Are we currently dragging around one end of this line?
  if (selectedAnnotation?.point === 1) {
    x1 = selectedAnnotation.xVal || 0
    y1 = selectedAnnotation.yVal || 0
  } else if (selectedAnnotation?.point == 2) {
    x2 = selectedAnnotation.xVal || 0
    y2 = selectedAnnotation.yVal || 0
  }


  ret.push(
    <line

      x1={x1}
      y1={y1}
      x2={x2}
      y2={y2}
      stroke={coords.color || '#673ab8'}
      strokeWidth={coords.width || 2}
      key={'annotation-line-' + props.annotation.id}
      style={{cursor: 'pointer', zIndex: 2}}
      // onClick={(e) => {
      //   e.stopPropagation()
      //   e.preventDefault()
      // }}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        if (!isSelected) {
          setSelectedAnnotation({
            id: props.annotation.id,
            point: null,
            xVal: null,
            yVal: null,
            xDate: null,
            yPrice: null,
          })
        }
      }}
    />
  )
  if (isSelected) {

    // Add orange circles at either end
    ret.push(
      <circle
        cx={x1}
        cy={y1}
        r={5}
        stroke={'#006DFF'}
        strokeWidth={2}
        key={'annotation-circleA-' + props.annotation}
        style={{cursor: 'move', zIndex: 110}}
        onMouseDown={(e) => {
          e.stopPropagation()
          setIsDragging(true)
          setSelectedAnnotation({
            id: props.annotation.id,
            point: 1,
            xVal: x1,
            yVal: y1,
            xDate: null,
            yPrice: null,
          })
        }}
        onMouseUp={async () => {
          const new_x1 = selectedAnnotation.xDate || coords.x1
          const new_y1 = selectedAnnotation.yPrice || coords.y1
          await write_trendline_to_db({
            x1: new_x1,
            y1: new_y1,
            x2: coords.x2,
            y2: coords.y2,
            id_to_update: props.annotation.id,
            user,
            selectedChart,
          })
          setIsDragging(false)
        }}
      />
    )
    ret.push(
      <circle
        cx={x2}
        cy={y2}
        r={5}
        stroke={'#006DFF'}
        strokeWidth={2}
        key={'annotation-circleB-' + props.annotation}
        style={{cursor: 'move', zIndex: 110}}
        onMouseDown={(e) => {
          e.stopPropagation()
          setIsDragging(true)
          setSelectedAnnotation({
            id: props.annotation.id,
            point: 2,
            xVal: x2,
            yVal: y2,
            xDate: null,
            yPrice: null,
          })
        }}
        // onMouseDown={() => {setIsDragging(true)}}
        onMouseUp={async () => {
          const new_x2 = selectedAnnotation.xDate || coords.x2
          const new_y2 = selectedAnnotation.yPrice || coords.y2
          await write_trendline_to_db({
            x1: coords.x1,
            y1: coords.y1,
            x2: new_x2,
            y2: new_y2,
            id_to_update: props.annotation.id,
            user,
            selectedChart,
          })
          setIsDragging(false)
        }}
      />
    )
  }

  // return ret
  return (
    <svg>{ret}</svg>
  )
}

interface WriteTrendlineToDbArgs {
  x1: string
  y1: number
  x2: string
  y2: number
  id_to_update?: string
  user: any
  selectedChart: string
}
export const write_trendline_to_db = async (args: WriteTrendlineToDbArgs) => {
  const { x1, y1, x2, y2, id_to_update, user, selectedChart } = args

  let new_annotation: any
  if (id_to_update) {
    new_annotation = {
      modifiedAt: new Date().toISOString(),
      data: JSON.stringify({ x1, x2, y1, y2 }),
    };
  } else {
    new_annotation = {
      display: 'trendlineA',
      synopsis: '',
      createdAt: new Date().toISOString(),
      modifiedAt: new Date().toISOString(),
      type: 'trendline',
      data: JSON.stringify({ x1, x2, y1, y2 }),
      id: uuid(),
    };
  }

  try {
    const userRef = db.collection('users').doc(user.uid)
    const annotationPath = `charts.${selectedChart}.annotations`

    if (id_to_update) {
      // Read the current annotations array
      const userDoc = await userRef.get();
      const annotations = userDoc.get(annotationPath) || [];

      // Find the annotation with the matching ID and update it
      const updatedAnnotations = annotations.map((annotation: any) => {
        if (annotation.id === id_to_update) {
          return {
            ...annotation,
            ...new_annotation,
          };
        }
        return annotation
      })

      // Write the updated annotations back to Firestore
      const updateObject = {}
      updateObject[annotationPath] = updatedAnnotations
      await userRef.update(updateObject)
    } else {
      // If no ID is provided, proceed with the original logic

      // Step 1: Ensure the "annotations" array exists under the active chart
      const initialChartObject = {}
      initialChartObject[annotationPath] = []
      await userRef.set(initialChartObject, { merge: true })

      // Step 2: Add the annotation object to the annotations array of the active chart
      const updateObject = {}
      updateObject[annotationPath] = firebase.firestore.FieldValue.arrayUnion(new_annotation)

      await userRef.update(updateObject)
    }
  } catch (error) {
    console.log('error writing annotation to db', error)
  }
};



