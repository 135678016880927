import '../SignUp.scss'
import React, { useState, useEffect } from 'react'
import { db } from '../../../firebase'
import {loggedInUserAtom, openModalAtom } from '../../../types/global_types'
import {
  SignUpStage,
} from '../../../types/user_types'
import { useAtom } from 'jotai'
import { Button, Input, PhoneInput } from '../../reusable'
import { ErrorModal } from '../../modals/ErrorModal'

export const BasicInfoStep = () => {
  const [loggedInUser] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)

  // This signup stage
  const [firstName, setFirstName] = useState<string>(loggedInUser?.alpacaObject?.identity?.given_name || '')
  const [lastName, setLastName] = useState<string>(loggedInUser?.alpacaObject?.identity?.family_name || '')
  const [ dateOfBirth, setDateOfBirth ] = useState<string>(loggedInUser?.alpacaObject?.identity?.date_of_birth || '')
  const [ phoneNumber, setPhoneNumber ] = useState<string>(loggedInUser?.alpacaObject?.contact?.phone_number || '')

  const nextSignUpStage: SignUpStage = 'Employment'

  useEffect(() => {
    setButtonEnabled(!!firstName && !!lastName && !!dateOfBirth && !!phoneNumber);
  }, [firstName, lastName, dateOfBirth, phoneNumber]);

  if (!loggedInUser) return null

  return (
    <div className='signup-container'>
      <div className='signup-form-container'>
        <div className='signup-context-title text-align-left'>
          Welcome to Tickerbot.
        </div>
        <div className='signup-context-subtitle text-align-left'>
          You've been granted access. Let's set up your trading account.
        </div>
        <div className='input-container'>
           <Input
            label={'First name'}
            type={'text'}
            // placeholder={'John'}
            style={{width: '100%'}}
            initialValue={firstName}
            onChange={(val) => {setFirstName(val)}}
          />
          <Input
            label={'Last name'}
            type={'text'}
            // placeholder={'Doe'}
            style={{width: '100%'}}
            initialValue={lastName}
            onChange={(val) => {setLastName(val)}}
          />
        </div>
        <Input
          label={'Date of birth'}
          type={'date'}
          className={'margin-top-15'}
          style={{width: '100%'}}
          initialValue={dateOfBirth}
          onChange={(val) => {setDateOfBirth(val)}}
        />
        <PhoneInput
          label={'Phone number (US only)'}
          style={{width: '100%'}}
          className={'margin-top-20'}
          initialValue={phoneNumber}
          onBlur={(val) => {
            let phoneNumber = val
            let cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
            cleanedPhoneNumber = '+' + cleanedPhoneNumber
            setPhoneNumber(cleanedPhoneNumber)
          }}
        />
        <Button
          display={'Next'}
          className={'margin-top-25'}
          style={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
          isDisabled={!buttonEnabled || buttonIsLoading}
          isLoading={buttonIsLoading}
          onClick= {async () => {
            try {
              setButtonIsLoading(true)
              await db.collection('users').doc(loggedInUser?.uid).update({
                'alpacaObject.identity.given_name': firstName,
                'alpacaObject.identity.family_name': lastName,
                'alpacaObject.contact.phone_number': phoneNumber,
                'alpacaObject.contact.email_address': loggedInUser?.email,
                'alpacaObject.identity.date_of_birth': dateOfBirth,
                signUpStage: nextSignUpStage,
              })
              setButtonIsLoading(false)
            } catch (err) {
              setButtonIsLoading(false)
              setOpenModal( <ErrorModal errorMessage={err.message}/> )
            }
          }}
        />
      </div>
    </div>
  )
}