import React, { useState, useEffect, useRef } from 'react';
import { useAtom } from 'jotai';
import {
  functionExecutingAtom,
  consoleOutputAtom,
  loggedInUserAtom,
  cmdKAtom,
  appTabAtom,
  openModalAtom,
} from '../../types/global_types';
import { Icon } from '../reusable/Icon';
import { Spinner } from '../reusable/Spinner';
import axios from 'axios';

const ANIMATION_LINE_DELAY = 40;

interface CodeConsoleProps {
  fromModal?: boolean
}

export const CodeConsole = (props: CodeConsoleProps) => {
  const [functionExecuting, setFunctionExecuting] = useAtom(functionExecutingAtom);
  const [user] = useAtom(loggedInUserAtom);
  const [consoleOutput, setConsoleOutput] = useAtom(consoleOutputAtom);
  const [visibleOutput, setVisibleOutput] = useState<any>([]);
  const [cmdK] = useAtom(cmdKAtom);
  const containerRef = useRef<any>(null);
  const cancelTokenSourceRef = useRef(axios.CancelToken.source());
  const isInitialRender = useRef(true);
  const {fromModal} = props

  // Cmd + k to clear console
  useEffect(() => {
    if (!cmdK) return;
    setVisibleOutput([]);
    setConsoleOutput([]);
  }, [cmdK, setConsoleOutput]);

  // Scroll to bottom when new content is added
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [visibleOutput]);

  // Handle console output changes
  useEffect(() => {    
    if (isInitialRender.current) {
      setVisibleOutput(consoleOutput);
      isInitialRender.current = false;
    } else {
      const newLines = consoleOutput.slice(visibleOutput.length);
      if (newLines.length > 0) {
        const animateNewLines = async () => {
          for (let line of newLines) {
            await new Promise(resolve => setTimeout(resolve, ANIMATION_LINE_DELAY));
            setVisibleOutput(prev => [...prev, line]);
          }
        };
        animateNewLines();
      }
    }
  }, [consoleOutput]);

  if (!user) return null;

  return (
    <div className={!fromModal ? 'code-console-container' : 'code-console-container modal-version'}>
      <div className={!fromModal ? 'code-console-header' : 'code-console-header modal-version'}>
        <div>Console</div>
        <div className='row'>
          {functionExecuting && (
            <div
              className='functions-tab-header-btn'
              style={{marginRight: 10}}
              onClick={() => {
                setFunctionExecuting(false);
                cancelTokenSourceRef.current.cancel('Function execution canceled.');
                const newOutput = [...consoleOutput, {text: 'Function execution killed.', className: 'err'}];
                setConsoleOutput(newOutput);
                // @ts-ignore
                window.functionCanceled = true;
                cancelTokenSourceRef.current = axios.CancelToken.source();
              }}
            >
              <Icon
                icon='x'
                className='functions-tab-header-icon'
                hoverText='Kill function execution'
                set='sharp-solid'
                size={8}
                style={{marginTop: 1, marginRight: 5}}
                onClick={() => {
                  // for css
                }}
              />
              <div>Kill</div>
            </div>
          )}
          <div
            className='functions-tab-header-btn'
            onClick={() => {
              setVisibleOutput([]);
              setConsoleOutput([]);
            }}
          >
            <Icon
              icon='eraser'
              className='functions-tab-header-icon'
              hoverText='Clear console'
              set='sharp-solid'
              size={12}
              style={{marginTop: 1, marginRight: 5}}
              onClick={() => {
                // for css
              }}
            />
            <div>Clear console</div>
          </div>
        </div>
      </div>
      <div className={!fromModal ? 'code-console-body' : 'code-console-body modal-version'} ref={containerRef}>
        {visibleOutput.map((x: any, i) => (
          <div key={i} className={x.className}>{x.text}</div>
        ))}
        {visibleOutput.length === 0 && 'Test or run your function to see output here.'}
        {functionExecuting && (
          <div style={{marginTop: 5, marginLeft: 2}}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};