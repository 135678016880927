// cancels all open orders for a symbol or just one

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Modal } from '../reusable'
import { loggedInUserAtom, openModalAtom, ordersAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { currentUser, db } from '../../firebase'
import { getConfig } from '../../config'
import axios from 'axios'
import { TickerModal } from './TickerModal'
const config = getConfig() as any

interface CancelOpenOrderModalProps {
  symbol: string
  orderId?: string // cancels just one if specified
  showBack?: boolean
}

export const CancelOpenOrderModal = (props: CancelOpenOrderModalProps) => {    
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [orders, setOrders] = useAtom(ordersAtom)
  const {symbol, orderId} = props

  if (!user) return null

  let titleText = 'Cancel orders'
  let buttonText = 'Cancel orders'
  let text = `Are you sure you want to cancel all open orders for ${symbol}?`
  if (orderId) {
    titleText = 'Cancel orders'
    buttonText = 'Cancel order'
    text = `Are you sure you want to cancel this open order for ${symbol}?`
  }
  
  const openOrders = orders.working.filter(o => o.symbol === symbol) || []
  if (!openOrders.length) {
    titleText = 'Cancel orders'
    buttonText = ''
    text = `You have no open orders for ${symbol}.`
  }

  return (
    <Modal
      title={titleText}
      contents={[
        <div className='column width-full font-size-13 orange-text'>
          <div className='row'>
            {text}
          </div>
        </div>
      ]}
      yesButtonText={buttonText}
      isDanger
      isButtonLoading={buttonIsLoading}
      twoStepConfirmation={true}
      backIconOnClick={props.showBack ? () => setOpenModal(props.symbol ? <TickerModal symbol={props.symbol} /> : null) : null}
      onYes={ async () => {
        try {
          setButtonIsLoading(true)
          const token = await currentUser()?.getIdToken()
          const ordersToCancel = orderId || orders.working

          for (const id of ordersToCancel) {
            const url = `${config.api_root_url}deleteOrder?orderId=${id}&create_ee_log=true`
            await axios.delete(url, { headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            } })
          }
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

