/*
Reset password page
*/

import './SignUp.scss'
import React, { useState, useEffect } from 'react'
import { Input, Button } from '../reusable'
import { ErrorModal } from '../modals/ErrorModal'
import { resetPassword } from '../../firebase'
import { loggedOutTabAtom, openModalAtom, resetPasswordEmail } from '../../types/global_types'
import { useAtom } from 'jotai'

export function ResetPassword() {
  const [, setLoggedOutTab] = useAtom(loggedOutTabAtom)
  const [, setOpenModal] = useAtom(openModalAtom)

  // This form
  const [email, setEmail] = useAtom(resetPasswordEmail)
  
  
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)

  useEffect(() => {
    setButtonEnabled(!!email);
  }, [email]);

  return (
    <div className='logged-out-screen'>
      <div className='signup-container'>
        <div className='signup-form-container'>
          <div className='signup-context-title text-align-left'>
            Reset password
          </div>
          <div className='signup-context-subtitle text-align-left'>
            Enter your email to receive a password reset link.
          </div>
          <Input
            label={'Email'}
            type={'email'}
            // placeholder={'john.doe@example.com'}
            className={'margin-top-20'}
            style={{width: '100%'}}
            onChange={(val) => {setEmail(val)}}
          />
          <Button 
            display={'Get reset password link'}
            className={'margin-top-20'}
            style={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
            isDisabled={!buttonEnabled || buttonIsLoading}
            isLoading={buttonIsLoading}
            onClick={async () => {
                
              setButtonIsLoading(true)
                
                const res = await resetPassword({email})
                
                if (res?.error) {
                  setButtonIsLoading(false)
                  setOpenModal( <ErrorModal errorMessage={res.error}/> )
                  return
                }
                setButtonIsLoading(false)
                setLoggedOutTab('reset_pw_confirmation')
                
              }
            }
          />
          <div className='row width-full'>
            <div 
              className='text-button margin-top-5 font-size-11 text-align-left'
              onClick={() => {setLoggedOutTab('log_in')}}
  
            >
              Back to log in
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}



