import React, { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  formatNumberForTable,
  activityTypes,
  BACKGROUND,
  formatDateForTable,
  capitalizeFirstLetterOfAWord,
  handleMUISorting
} from '../../logic/u'
import axios  from 'axios'
import { currentUser } from '../../firebase'
import {
  openModalAtom,
} from '../../types/global_types'
import { getConfig } from '../../config'
import { TransactionsDetailsModal } from '../modals/TransactionDetailsModal'
import { CustomGridOverlay } from './CustomGridOverlay'
const config = getConfig() as any

export const TransactionsTab = () => {
  const [ transactions, setTransactions ] = useState<any[] | null>(null)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'date',
      sort: 'desc' as GridSortDirection
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await currentUser()?.getIdToken()
        const endpoint = `${config.api_root_url}getAccountActivity?&getAll=true`
        const res = await axios.get(endpoint, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })

        const transactionsRaw = res.data
        const transactionsParsed: any[] = []
        if (transactionsRaw) {
          transactionsRaw.forEach((transaction: any) => {
            const transactionParsed: any = {}
            Object.keys(transaction).forEach(key => {
              let result
              const value = transaction[key]

              if (typeof value === 'string') {
                // Convert to number if it's numeric, otherwise keep as string
                result = !isNaN(Number(value)) ? parseFloat(value) : value;

              } else {
                // Preserve non-string values
                result = value
              }
              transactionParsed[key] = result
            })
            transactionsParsed.push(transactionParsed)
          })
        }
        setTransactions(transactionsParsed)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData()
  }, [])

  const transactionRows = transactions?.map(t => {
    let amount = t.net_amount
    if (t.price) {
      amount = t.price * t.qty
    }

    const type = activityTypes.find((activityType) => activityType.activityType === t.activity_type)?.description || t.type
    
    let date = t.date
    if (t.transaction_time) {
      date = t.transaction_time
    }
    
    delete t.net_amount
    delete t.transaction_time
    return {
      ...t,
      amount: formatNumberForTable(amount),
      type,
      date
    }
  }) || []

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  };

  console.log(transactionRows)

  return (
    <div className='tab-contents has-subtab'>
      <div className='table-container'>
        <DataGridPro
          rows={transactionRows}
          className='clickable-grid uppercase-grid'
          columns={[
            {
              field: 'type',
              headerName: 'type',
              flex: 1,
              type: 'string',
              sortComparator: handleMUISorting,
              valueGetter: (params) => {                
                return params.row.type.toLowerCase();
              }
            },
            {
              field: 'symbol',
              headerName: 'ticker',
              flex: .5,
              headerAlign: 'right',
              align: 'right',
              type: 'string',
              sortComparator: handleMUISorting,
              valueGetter: (params) => {
                return params.row.symbol || '-'
              }
            },
            {
              field: 'side',
              headerName: 'side',
              flex: .5,
              headerAlign: 'right',
              align: 'right',
              type: 'string',
              sortComparator: handleMUISorting,
              valueGetter: (params) => {
                return params.row.side || '-'
              }
            },
            {
              field: 'qty',
              headerName: 'qty',
              flex: .5,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              valueGetter: (params) => {
                return params.row.qty || '-'
              }
            },
            {
              field: 'amount',
              headerName: 'amount',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              valueGetter: (params) => {
                return params.row.amount; // Sort by raw numeric value
              },
              // valueFormatter: (params) => {
              //   return formatNumberForTable(params.row.amount); // Display formatted value
              // }
            },
            {
              field: 'status',
              headerName: 'status',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'string',
              sortComparator: handleMUISorting,
              valueGetter: (params) => {
                return params.row.status || params.row.order_status || '-'
              }
            },
            {
              field: 'date',
              headerName: 'date',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'date',
              sortComparator: handleMUISorting,
              valueGetter: (params) => {                
                return formatDateForTable(params.row.date)
              }
            }
          ]}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          disableColumnReorder
          density='compact'
          components={{
            NoRowsOverlay: () => {
              if (!transactions) {
                return (
                  <CustomGridOverlay text='Loading transactions...' />
                )
              }
              return (
                <CustomGridOverlay text='No transactions.' />
              )
            },
          }}
          sx={tableSX}
          onRowClick={(params) => setOpenModal(<TransactionsDetailsModal transaction={params.row} />)}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          localeText={{
            footerTotalRows: 'Total transactions:',
          }}
          pageSize={transactionRows.length}
        />
      </div>
    </div>
  )
}