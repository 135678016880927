/*
Reset password page confirmation screen
*/

import './SignUp.scss'
import React from 'react'
import { loggedOutTabAtom, resetPasswordEmail } from '../../types/global_types'
import { useAtom } from 'jotai'

export function ResetPasswordConfirmationScreen() {
  const [, setLoggedOutTab] = useAtom(loggedOutTabAtom)
  const [email, ] = useAtom(resetPasswordEmail)

  return (
    <div className='logged-out-screen'>
      <div className='signup-container'>
        <div className='signup-form-container'>
          <div className='signup-context-title center'>
            Please check your email
          </div>
          <div className='signup-context-subtitle center'>
            We sent an email to {email}, which contains a link to reset your Tickerbot password.
          </div>
          <div 
            className='text-button margin-top-5 font-size-11'
            onClick={() => {setLoggedOutTab('log_in')}}

          >
            Back to log in
          </div>
        </div>
      </div>
    </div>
  )
}



