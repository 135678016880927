import React, { useState, useEffect } from "react";
import InputMask from 'react-input-mask';

interface PIProps {
  label: string;
  onBlur?: (value: string) => void;
  style: any;
  className: string;
  initialValue?: string;
  isError?: () => void
}

export function PhoneInput(props: PIProps) {
  const [isError, setIsError] = useState<boolean>(false);
  const [value, setValue] = useState<string>(props.initialValue || '')

  let classList = 'input-component';
  if (props.className) {
    classList += ' ' + props.className;
  }

  let inputClassName = '';
  if (isError) {
    inputClassName += ' error';
  }

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (isError) {
      const isValidUSPhone = validateUSPhone(newValue);
      if (isValidUSPhone) {
        setIsError(false);
      }
    }
  };

  const handleInputBlur = (e) => {
    const newValue = e.target.value;
    const isValidUSPhone = validateUSPhone(newValue);
    if (!isValidUSPhone) {
      setIsError(true);
    } else if (isError && isValidUSPhone) {
      setIsError(false);
    }
    if (props.onBlur) {
      props.onBlur(newValue);
    }
  };

  return (
    <div
      className={classList}
      style={{
        ...props.style,
        width: '100%',
      }}
    >
      <div className='label'>{props.label}</div>
      <InputMask
        mask="+1 (999) 999-9999"
        maskChar=""
        // placeholder="Enter phone number"
        className={`input-component ${inputClassName}`}
        value={value}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
      />
    </div>
  );
}

const validateUSPhone = (val: string) => {
  // Remove non-numeric characters
  val = val.replace(/\D/g, '');

  // Check if the cleaned value starts with '1' (country code)
  const hasCountryCode = val.startsWith('1');     // todo make this work for other countries

  // Adjust the length check based on the presence of the country code
  const isValidUSPhone = hasCountryCode ? val.length === 11 : val.length === 10;

  return isValidUSPhone;
}
