import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatPercent,
  roundToSigFigs,
  formatNumberForTable,
  valueForTable,
  uuid,
  handleMUISorting,
} from '../../logic/u'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom
} from '../../types/global_types'
import { getConfig } from '../../config'
import { TickerDropdown } from '../dropdowns/TickerDropdown'
import { CustomGridOverlay } from './CustomGridOverlay'
import { TickerModal } from '../modals/TickerModal'
const config = getConfig() as any

export const LogsModalFunctionsTab = () => {
  const [, setOpenModal] = useAtom(openModalAtom)
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'symbol',
      sort: 'asc' as GridSortDirection
    },
  ]);

  if (!user || !uld) return null

  return (
    <div className='modal-subcontent-container'>

    </div>
  )
}