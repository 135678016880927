import './LeftPane.scss'
import Split from 'react-split'
import { useAtom } from 'jotai'
import { db } from "../../firebase"
import React, { useState, useEffect, useRef } from 'react';
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  streamingQuotesAtom,
  selectedEmptyChartAtom,
} from '../../types/global_types'
import { valueForTable, GREEN_PARTIAL, RED_PARTIAL, returnChartPaneContextObj, set_open_chart, formatDateForTable, timeAgo } from '../../logic/u'
import { Icon } from '../reusable'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Chart, Variable, WatchlistObj } from '../../types/user_types'
import { VariableDropdown } from '../dropdowns/VariableDropdown';
import { CreateVariableModal } from '../modals/CreateVariableModal';

export const StateBox = () => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [selectedEmptyChart] = useAtom(selectedEmptyChartAtom)
  const [variables, setVariables] = useState<Variable[]>([])
  const [validDrop, setValidDrop] = useState<boolean>(false)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [openDropdown, setOpenDropdown] = useState<any>(null)

  // Render tree
  useEffect(() => {
    if (!user?.variables) {return}
    const variables = Object.keys(user?.variables || []).map(v => {
      const variable: Variable = user?.variables[v]
      return variable
    })
    variables.sort((a, b) => a.index - b.index);
    setVariables(variables)
  }, [user])

  const onDragUpdate = (update) => {
    const { source, destination } = update
    if (!destination) {
      setValidDrop(false)
      return
    }
    if (source.droppableId === destination.droppableId) {
      setValidDrop(true)
    }
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result

    // If no destination or destinations don't match then return
    if (!destination) {
      return
    }
    if (source.droppableId !== destination.droppableId) {
      return
    }

    // If we have a valid drop then reorder
    let variablesClone = Array.from(variables)

    // Reorder
    const [reorderedItem] = variablesClone.splice(source.index, 1) // remove dragged item
    variablesClone.splice(destination.index, 0, reorderedItem) // add it back

    // Set the new custom result on the user
    setVariables(variablesClone) // update local state to smooth transition

    // Turn it back to object
    const variablesObj = {}
    variablesClone.forEach((v, i) => {
      v.index = i
      variablesObj[v.symbol] = v
    })
    
    db.collection('users').doc(user?.uid).update({ variables: variablesObj })
  }

  if (!user) return null
  if (!uld) return null

  return (
    <div className='state-box'>
      <div className='state-box-header'>
        <div className='header-tab selected'>State</div>
        <Icon
          icon='plus'
          set='sharp-solid'
          size={11}
          style={{
            marginTop: -8
          }}
          onClick={() => {            
            setOpenModal(<CreateVariableModal />)
          }}
        />
      </div>
      <div className='sect-body'>
        {openDropdown}
        {Object.keys(user.variables).length ? <DragDropContext
          onDragUpdate={onDragUpdate}
          onDragEnd={onDragEnd}
          key={'modal-content'}
        >
          <div className='droppable-container'>
            <Droppable droppableId={'1'} type='bucket'>
              {(provided, snapshot) => (
                <div
                  className='draggable-container'
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {variables.map((variable, index) => {
                    const chart = 'STATE.' + variable.symbol
                    const chartPaneContextObj = returnChartPaneContextObj(user, chart)
                    const chartIsVisible = chartPaneContextObj?.chartIsVisible
                    const date = variable?.last_set?.toDate()        
                    const time_ago = timeAgo(date)
                    let parentClass = 'entry smaller'
                    if (chartIsVisible) {
                      parentClass += ' selected'
                    }
                    return (
                      <Draggable
                        key={variable.symbol}
                        draggableId={variable.symbol}
                        index={index}
                      >
                        {(provided, snapshot) => {
                          return (
                            <div
                              className={parentClass}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                backgroundColor: snapshot.isDragging ? (validDrop ? GREEN_PARTIAL : RED_PARTIAL) : ''
                              }}
                              onClick={ async (event) => {
                                event.stopPropagation();
                                set_open_chart(user, uld, selectedEmptyChart || 1, chart)
                                return
                              }}
                              onContextMenu={(event) => {
                                event.preventDefault()
                                const width = 220
                                const clickX = event.clientX + 5
                                const clickY = event.clientY - 10
                                setOpenDropdown(
                                  <VariableDropdown
                                    variableObj={variable}
                                    left={clickX}
                                    top={clickY}
                                    width={width}                      
                                    onClose={() => setOpenDropdown(null)}
                                  />
                                )
                              }}
                            >
                              <div className={'top-row'}>
                                <div className='row'>                                  
                                  <div className='icon-container'>
                                    <Icon
                                      icon='grip-dots-vertical'
                                      size={5.5}
                                      className='grip-icon'
                                    />
                                  </div>
                                  <div>{variable.symbol}</div>
                                </div>
                                <div className='row'>
                                  <div>{valueForTable(chart, uld.liveData.STATE[variable.symbol])}</div>
                                </div>
                              </div>                            
                            </div>
                          )
                        }}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext> : <div style={{marginTop: 10, marginLeft: 10, fontSize: 11.5}}>
          No variables.
        </div>}
      </div>
    </div>
  )
}