/*
Simplified icon created to solve bugs with the original one
*/

import './Icon.scss';

import React from 'react';
import { ReactSVG } from "react-svg";

export interface IconProps {
  icon: string
  set?: 'regular' | 'light' | 'solid' | 'thin' | 'duotone' | 'brands' | 'sharp-solid' | 'tickerbot'
  style?: any
  size?: number
  className?: string
  hoverStyle?: any
  hoverText?: string
  spin?: boolean
  isDisabled?: boolean
  onClick?: (e: any) => void
}

export function Icon2(props: IconProps) {
  const set = props.set || 'regular'
  const src = `fa/${set}/${props.icon}.svg`

  const size = props.size || 16
  const style = structuredClone(props.style) || {}
  let hoverStyle = structuredClone(props.hoverStyle) || {}
  hoverStyle.cursor = 'pointer'

  let className = 'icon'
  if (props.className) {
    className += ' ' + props.className
  }
  if (props.onClick && !props.isDisabled) {
    className += ' is-button'
  } else if (props.isDisabled) {
    className += ' is-disabled'
    style.cursor = 'not-allowed'
  }
  if (props.spin) {
    className += ' spin'
  }

  hoverStyle = {...style, ...hoverStyle}

  return (
    <div
      className='icon2-wrapper'
      title={props.hoverText}
      style={{
        cursor: 'pointer',
        paddingLeft: 3,
        paddingRight: 3,
        borderRadius: 5,
        ...style,
      }}
      onClick={(e) => {
        if (props.onClick && !props.isDisabled) {
          props.onClick(e)
        }
      }}
    >
      <ReactSVG
        className={className}
        style={{
          width: size,
          height: size,
          pointerEvents: 'none',
        }}
        src={src}
      />
    </div>
  )
}
