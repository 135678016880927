import '../SignUp.scss'
import React, { useState, useEffect } from 'react'
import { Button, DropdownSelect } from '../../reusable'
import { db } from '../../../firebase'
import { loggedInUserAtom, openModalAtom } from '../../../types/global_types'
import {
  SignUpStage,
} from '../../../types/user_types'
import { useAtom } from 'jotai'
import { ErrorModal } from '../../modals/ErrorModal'

export const DisclosuresPoliticallyExposedStep = () => {
  const [loggedInUser ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)

  // This signup stage
  const [isPoliticallyExposed, setIsPoliticallyExposed] = useState<boolean | undefined>(loggedInUser?.alpacaObject?.disclosures?.is_politically_exposed)

  const previousSignUpStage: SignUpStage = 'DisclosuresControlPerson'
  const nextSignUpStage: SignUpStage = 'DisclosuresImmediateFamilyExposed'

  useEffect(() => {
    if (isPoliticallyExposed !== null && isPoliticallyExposed !== undefined) {
      setButtonEnabled(true);
    }
  }, [isPoliticallyExposed]);

  if (!loggedInUser) return null

  return (
    <div className='signup-container'>
      <div className='signup-form-container'>
        <div className='signup-context-title'>
          Disclosures (3 of 4)
        </div>
        <DropdownSelect
          label={'Are you or an immediate family member currently or formerly a Politically Exposed Person or Public Official?'}
          options={[
            {display: 'Yes', value: true},
            {display: 'No', value: false},
          ]}
          className={'margin-top-15'}
          width={494}
          height={32}
          value={isPoliticallyExposed}
          onChange={(val) => {setIsPoliticallyExposed(val)}}
        />
        <Button
          display={'Next'}
          className={'margin-top-25'}
          style={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
          isDisabled={!buttonEnabled || buttonIsLoading}
          isLoading={buttonIsLoading}
          onClick= {async () => {
            try {
              setButtonIsLoading(true)
              await db.collection('users').doc(loggedInUser.uid).update({
                'alpacaObject.disclosures.is_politically_exposed': isPoliticallyExposed,
                signUpStage: nextSignUpStage
              })
              setButtonIsLoading(false)
            } catch (err) {
              setButtonIsLoading(false)
              setOpenModal( <ErrorModal errorMessage={err.message}/> )
            }
          }}
        />
        <Button
          display={'Back'}
          className={'margin-top-15'}
          variant={'secondary'}
          style={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
          onClick= {async () => {await db.collection('users').doc(loggedInUser.uid).update({signUpStage: previousSignUpStage})}}
        />
      </div>
    </div>
  )

}