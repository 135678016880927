/* Orange box indicating that we have a position open & at what price */

import React from 'react';
import { formatStockPrice } from '../../logic/u';
import { ORANGE, ORANGE_FAINT } from '../../logic/colors';
import './Indicators.scss';
import { TickerModal } from '../modals/TickerModal';



interface PositionIndicatorProps {
  position: any;       // position_object
  price: number;
  y_position_pixel: number;
  chartHeight: number;
  setOpenModal: (modal: any) => void;

}

export const PositionIndicator = (props: PositionIndicatorProps) => {
  const { position, price, chartHeight, y_position_pixel, setOpenModal } = props;

  const INDICATOR_HEIGHT = 20;
  const EDGE_THRESHOLD = 24

  const isOffTop = y_position_pixel < EDGE_THRESHOLD
  const isOffBottom = y_position_pixel > chartHeight - EDGE_THRESHOLD

  const qty = position.qty;
  const qty_rounded = Number(qty.toFixed(2));
  const formattedPrice = formatStockPrice(price, true);
  const label = `POS: ${qty_rounded} @ ${formattedPrice}`;

  let indicatorStyle: any = {}

  let show_dashed_line = false;
  let show_top_arrow = false;
  let show_bottom_arrow = false;
  if (isOffTop) {
    indicatorStyle.top = EDGE_THRESHOLD - 10
    show_top_arrow = true;
  } else if (isOffBottom) {
    indicatorStyle.bottom = EDGE_THRESHOLD - 10
    show_bottom_arrow = true;
  } else {
    indicatorStyle.top = `${y_position_pixel - INDICATOR_HEIGHT / 2}px`;
    show_dashed_line = true;
  }

  return (
    <div
      className='tbc-pos-ind'
      style={indicatorStyle}
      onClick={() => setOpenModal(<TickerModal symbol={position.symbol} />)}
    >
      {label}
      {show_dashed_line && (
        <div className='dashed-line' />
      )}
      {show_top_arrow && (
        <div className='arrow-top'>
          <div className='arrow-top-outline' />
          <div className='arrow-top-fill' />
        </div>
      )}
      {show_bottom_arrow && (
      <div className='arrow-bottom'>
        <div className='arrow-bottom-outline' />
        <div className='arrow-bottom-fill' />
      </div>
    )}
    </div>
  );
};

export default PositionIndicator;