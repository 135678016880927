import axios  from 'axios'
import { useAtom } from 'jotai'
import {
  Icon,
  Input,
  Switch,
  showModal,
  DropdownSelect,
  CustomNoRowsOverlay,
  Button,
} from '../reusable'
import {
  activityTypes,
  tableSX,
  fundingSourceOptions,
  returnFinancialProfileMinMax,
  formatDateForTable
} from '../../logic/u'
import {
  loggedInUserAtom, userLiveDocAtom,
} from "../../types/global_types"

import React, { useState, useEffect } from 'react'
import { DataGridPro, GridOverlay } from '@mui/x-data-grid-pro'

import { getConfig } from '../../config'

import { db } from '../../firebase'


const config = getConfig() as any


export function AdminSocket() {
  const [ user ] = useAtom(loggedInUserAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [ latestEELog, setLatestEELog ] = useState<any>(null)
  const [ latestDailyLog, setLatestDailyLog ] = useState<any>(null)

  if (!user || !uld) return null

  // Compile most recent quote status
  const symbols: string[] = Object.keys(uld.quotes)
  const quote_data: any = []
  const quote_status_divs: any = []
  for (let i = 0; i < symbols.length; i++) {
    const last_updated_human = formatDateForTable(uld.quotes[symbols[i]].last_updated, true)

    quote_data.push({
      symbol: symbols[i],
      last_updated: uld.quotes[symbols[i]].last_updated,
      last_updated_human: formatDateForTable(uld.quotes[symbols[i]].last_updated),
    })

    quote_status_divs.push(
      <div style={{display: 'flex'}}>
        <div style={{width: 100}}>{symbols[i]}</div>
        <div style={{width: 200}}>{last_updated_human}</div>
      </div>
    )
  }

  // Load the most recent server_event EELog
  useEffect(() => {
    const fetchLatestEELog = async () => {
      try {
        const userDoc = db.collection('users').doc(user.uid);
        const eeLogsRef = userDoc.collection('EELogs');
        const eeLogQuery = eeLogsRef
          .where('opType', '==', 'server_event')
          .orderBy('time', 'desc')
          .limit(1);

        const querySnapshot = await eeLogQuery.get();

        if (!querySnapshot.empty) {
          const latestLog = querySnapshot.docs[0].data();
          setLatestEELog(latestLog);
        } else {
          console.log('No EELog entries found with opType "server_event"');
        }
      } catch (error) {
        console.error('Error fetching latest EELog:', error);
      }
    };

    fetchLatestEELog();
  }, [user.uid]);

  // Load the most recent daily_log EELog
  useEffect(() => {
    const fetchLatestEELog = async () => {
      try {
        const userDoc = db.collection('users').doc(user.uid);
        const eeLogsRef = userDoc.collection('EELogs');
        const eeLogQuery = eeLogsRef
          .where('opType', '==', 'daily_log')
          .orderBy('time', 'desc')
          .limit(1);

        const querySnapshot = await eeLogQuery.get();

        if (!querySnapshot.empty) {
          const latestLog = querySnapshot.docs[0].data();
          latestLog.id = querySnapshot.docs[0].id;
          setLatestDailyLog(latestLog);
        } else {
          console.log('No EELog entries found with opType "daily_log"');
        }
      } catch (error) {
        console.error('Error fetching latest EELog:', error);
      }
    };

    fetchLatestEELog();
  }, [user.uid]);


  let most_recent_SSE_log_date_human = 'No server_event EELogs found'
  if (latestEELog) {
    const formatted_date = formatDateForTable(latestEELog.timestamp)
    const text = latestEELog.console_output[0].text
    most_recent_SSE_log_date_human = `${formatted_date} - ${text}`
  }

  let daily_log_in_cache = 'FALSE'
  let most_recent_daily_log_date_human = 'No daily_log EELogs found'
  if (latestDailyLog) {
    const formatted_date = formatDateForTable(latestDailyLog.timestamp)
    most_recent_daily_log_date_human = `${formatted_date}`

    // const daily_log_id = latestDailyLog.id

    // left off here

  }



  return (
    <div className='tab-contents has-subtab'>

      {/* Server identity */}
      <div className='bold header-text-size margin-bottom-15'>Server identity</div>

      <div className='orange-text'>

        <div style={{display: 'flex'}}>
          <div style={{width: 180}}>serverId</div>
          <div style={{width: 400}}>{uld.socketServerId?.id}</div>
        </div>
        <div style={{display: 'flex'}}>
          <div style={{width: 180}}>startupDate</div>
          <div style={{width: 400}}>{uld.socketServerId?.startupDateHuman}</div>
        </div>
        <div style={{display: 'flex'}}>
          <div style={{width: 180}}>httpEndpoint</div>
          <div style={{width: 400}}>{uld.socketServerId?.httpEndpoint}</div>
        </div>

      </div>

      {/* Quotes last updated */}
      <div className='bold header-text-size margin-bottom-15 margin-top-40'>Quotes last updated</div>
      <div className='orange-text'>
        {quote_status_divs}
      </div>

      {/* Last SSE detected */}
      <div className='bold header-text-size margin-bottom-15 margin-top-40'>Last SSE detected</div>
      <div className='orange-text'>{most_recent_SSE_log_date_human}</div>

      {/* Last daily_log detected */}
      <div className='bold header-text-size margin-bottom-15 margin-top-40'>Last daily_log detected</div>
      <div className='orange-text'>{most_recent_daily_log_date_human}</div>
      <div className='orange-text'>present in cache? {daily_log_in_cache}</div>




    </div>
  )
}