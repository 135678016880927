import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatPercent,
  roundToSigFigs,
  formatNumberForTable,
  valueForTable,
  uuid,
  handleMUISorting,
} from '../../logic/u'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom
} from '../../types/global_types'
import { getConfig } from '../../config'
import { TickerDropdown } from '../dropdowns/TickerDropdown'
import { CustomGridOverlay } from './CustomGridOverlay'
import { TickerModal } from '../modals/TickerModal'
const config = getConfig() as any

export const LogsModalBalancesTab = () => {
  const [, setOpenModal] = useAtom(openModalAtom)
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'symbol',
      sort: 'asc' as GridSortDirection
    },
  ]);

  if (!user || !uld) return null

  let positionRows: any[] = []
  Object.keys(uld.liveData?.POSITIONS).forEach(key => {
    const position = uld.liveData?.POSITIONS[key]
    positionRows.push({...position, id: uuid()})
  })

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  };

  const handleContextMenuClick = (event: any, params: any, openLeft?: boolean) => {
    event.preventDefault()
    const width = 220
    const clickX = openLeft? event.clientX - (width + 5) : event.clientX + 5
    const clickY = event.clientY - 10
    const symbol = params.row.symbol
    setOpenDropdown(
      <TickerDropdown
        symbol={symbol}
        left={clickX}
        top={clickY}
        width={width}                      
        onClose={() => setOpenDropdown(null)}
        fromTable={true}
      />
    )
  }

  return (
    <div className='tab-contents has-subtab'>
      {openDropdown}
      <div className='summary-table'>
        <div>
          Summary
        </div>
        <div className='row'>
          <div className='summary-bit'>
            <div>mkt_value</div>
            <div className='val-thing'>{valueForTable('mkt_value', uld.liveData.BALANCES.mkt_value)}</div>
          </div>
          <div className='summary-bit'>
            <div>unrealized P/L since yesterday</div>
            <div className='val-thing'>
              <div className=''>{valueForTable('day_unrealized_pl', uld.liveData.BALANCES.day_unrealized_pl)}</div>
              <div className=''>{'[' + valueForTable('day_unrealized_pl_pc', uld.liveData.BALANCES.day_unrealized_pl_pc) + ']'}</div>
            </div>
          </div>
          <div className='summary-bit last-thing'>
            <div>overall unrealized P/L</div>
            <div className='val-thing'>
              <div className=''>{valueForTable('unrealized_pl', uld.liveData.BALANCES.unrealized_pl)}</div>
              <div className=''>{'[' + valueForTable('unrealized_pl_pc', uld.liveData.BALANCES.unrealized_pl_pc) + ']'}</div>
            </div>
          </div>
        </div>
      </div>
      <div className='table-container'>
        <DataGridPro
          className='clickable-grid'
          rows={positionRows}
          columns={[
            {
              field: 'symbol',
              headerName: 'ticker',
              flex: 0.5,
              type: 'string',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div 
                  style={{
                    width: '100%',
                    padding: 10,
                    textAlign: 'left'
                  }}
                  onContextMenu={(event) => {                    
                    handleContextMenuClick(event, params)
                  }}                
                >
                  {params.row.symbol}
                </div>
              )
            },
            {
              field: 'qty',
              headerName: 'qty',
              flex: 0.5,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div 
                  style={{
                    width: '100%',
                    padding: 10,
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {                    
                    handleContextMenuClick(event, params)
                  }}                
                >
                  {roundToSigFigs(params.row.qty, params.row.avg_entry_price)}
                </div>
              )
            },
            {
              field: 'current_price',
              headerName: 'current_price',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div 
                  style={{
                    width: '100%',
                    padding: 10,
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {                    
                    handleContextMenuClick(event, params)
                  }}                
                >
                  {formatNumberForTable(params.row.current_price)}
                </div>        
              )
            },
            {
              field: 'avg_entry_price',
              headerName: 'avg_entry_price',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div 
                  style={{
                    width: '100%',
                    padding: 10,
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}                
                >
                  {formatNumberForTable(params.row.avg_entry_price)}
                </div>               
              )
            },
            {
              field: 'cost_basis',
              headerName: 'cost_basis',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div 
                  style={{
                    width: '100%',
                    padding: 10,
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {                    
                    handleContextMenuClick(event, params)
                  }}                
                >
                  {formatNumberForTable(params.row.cost_basis)}
                </div>                
              )
            },
            {
              field: 'mkt_value',
              headerName: 'mkt_value',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div 
                  style={{
                    width: '100%',
                    padding: 10,
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {                    
                    handleContextMenuClick(event, params)
                  }}                
                >
                  {formatNumberForTable(params.row.mkt_value)}
                </div>
              )
            },
            {
              field: 'day_unrealized_pl',
              headerName: 'day_unrealized_pl',
              flex: 1.25,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div 
                  className={params.row.day_unrealized_pl >= 0 ? ' pos' : ' neg'}
                  style={{
                    width: '100%',
                    padding: 10,
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {                    
                    handleContextMenuClick(event, params)
                  }}                  
                >
                  {formatNumberForTable(params.row.day_unrealized_pl)}
                </div>
              )
            },
            {
              field: 'day_unrealized_pl_pc',
              headerName: 'day_unrealized_pl_pc',
              flex: 1.3,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div 
                  className={params.row.day_unrealized_pl >= 0 ? ' pos' : ' neg'}
                  style={{
                    width: '100%',
                    padding: 10,
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {                    
                    handleContextMenuClick(event, params)
                  }}
                >
                  {formatPercent(params.row.day_unrealized_pl_pc)}
                </div>
              )
            },
            {
              field: 'unrealized_pl',
              headerName: 'unrealized_pl',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div 
                  className={params.row.unrealized_pl >= 0 ? ' pos' : ' neg'}
                  style={{
                    width: '100%',
                    padding: 10,
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {                    
                    handleContextMenuClick(event, params)
                  }}
                >
                  {formatNumberForTable(params.row.unrealized_pl)}
                </div>
              ),
            },
            {
              field: 'unrealized_pl_pc',
              headerName: 'unrealized_pl_pc',
              flex: 1.2,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div 
                  className={params.row.unrealized_pl >= 0 ? ' pos' : ' neg'}
                  style={{
                    width: '100%',
                    padding: 10,
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {                    
                    handleContextMenuClick(event, params, true)
                  }}
                >
                  {formatPercent(params.row.unrealized_pl_pc)}
                </div>
              )
            }

          ]}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          disableColumnReorder
          density='compact'
          components={{
            NoRowsOverlay: () => {
              // unnecessary?
              if (!uld.liveData) {
                return (                  
                  <CustomGridOverlay text='Loading positions...' />
                )
              }
              return (
                <CustomGridOverlay text='No positions.' />
              )
            },
          }}
          sx={{
            ...tableSX,
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { p: '0px' },
          }}
          onRowClick={(params, event) => {
            setOpenModal(<TickerModal symbol={params.row.symbol} />)            
            // setOpenModal(<PositionDetailsModal symbol={params.row.symbol} />)            
          }}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          localeText={{
            footerTotalRows: 'Total positions:',
          }}
        />
      </div>
    </div>
  )
}