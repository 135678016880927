/*
Functions for generating chart axis labels
*/

import { utcToZonedTime } from 'date-fns-tz'
import {
	INTERVALS,
  is_internal,
	isMarketOpen,
  barISOToNYCHuman,
	getMostRecentMarketOpenTime,
} from '../../logic/u'
import { db } from '../../firebase'
import { currentUser } from '../../firebase'
import axios from 'axios'
import { add_indicators_to_dataset } from '../../logic/indicator_helpers'

import { getConfig } from '../../config'
import { UserLiveDoc } from '../../types/user_types'
const config: any = getConfig()



// export const get_y_label_prices = (lowerBound: number, upperBound: number) => {
//   const difference = upperBound - lowerBound
//   const lowerLimit = lowerBound + 0.03 * difference
//   const upperLimit = upperBound - 0.03 * difference

//   let approximateLabelCount = (upperLimit - lowerLimit) / 6
//   let magnitude = Math.pow(10, Math.floor(Math.log10(approximateLabelCount)))
//   let step
//   if (approximateLabelCount < 1.5 * magnitude) {
//     step = magnitude
//   } else if (approximateLabelCount < 3 * magnitude) {
//     step = 2 * magnitude
//   } else if (approximateLabelCount < 7.5 * magnitude) {
//     step = 5 * magnitude
//   } else {
//     step = 10 * magnitude
//   }

//   let startingValue = Math.ceil(lowerLimit / step) * step

//   let labels: string[] = []
//   for (let price = startingValue; price <= upperLimit; price += step) {
//     labels.push(price.toFixed(2))
//   }
//   return labels
// }


// export const make_x_axis_labels = (timestampArray: number[], interval: string, numCandles: number, width: number) => {
//   const result: { label: string, timestamp: string }[] = []
//   const pixels_per_candle = width / numCandles

//   const cases: any[] = []

//   for (let i = 0; i < numCandles; i++) {
//     const ts = timestampArray[i]
//     let date: any = ts ? new Date(ts) : undefined
//     let nyc = date ? getNYCDate(date) : undefined
//     let label: string | undefined

//     if (nyc) {
//       switch (interval) {
//         case '1m':
//           if (pixels_per_candle > 5) {
//             if (nyc.getHours() === 9 && nyc.getMinutes() === 30) {
//               label = `${getDayOfWeek(nyc)}`
//               cases.push({label, case: '1m: >5, 1'})
//             } else if (nyc.getMinutes() === 0 && nyc.getHours() !== 16) {
//               console.log('skipping 4pm')
//               // marc-todo: eliminate 4 pm
//             } else if (nyc.getMinutes() === 0) {
//               if (nyc.getHours() === 16) continue
//               label = `${getHoursMinutes(nyc)}`
//               cases.push({label, case: '1m: >5, 2'})
//             } else if (numCandles > 30 && date.getMinutes() % 10 === 0) {
//               label = `${getHoursMinutes(nyc)}`
//               cases.push({label, case: '1m: >5, 3'})
//             } else if (numCandles <= 30 && date.getMinutes() % 30 === 0) {
//               label = `${getHoursMinutes(nyc)}`
//               cases.push({label, case: '1m: >5, 4'})
//             } else {
//               cases.push({label: '', case: '1m: >5, else'})
//             }
//           } else if (pixels_per_candle > 1) {
//             if (nyc.getHours() === 9 && nyc.getMinutes() === 30) {
//               label = `${getDayOfWeek(nyc)}`
//               cases.push({label, case: '1m: >1, 1'})
//             } else if (nyc.getMinutes() === 0) {
//               label = `${getHoursMinutes(nyc)}`
//               cases.push({label, case: '1m: >1, 2'})
//             } else if (pixels_per_candle > 3 && nyc.getMinutes() === 30) {
//               label = `${getHoursMinutes(nyc)}`
//               cases.push({label, case: '1m: >1, 3'})
//             } else {
//               cases.push('1m: >1, else')
//               cases.push({label: '', case: '1m: >1, else'})
//             }
//           } else {
//             if (nyc.getHours() === 9 && nyc.getMinutes() === 30) {
//               label = `${getDayOfWeek(nyc)}`
//               cases.push({label: '', case: '1m: else, 1'})
//             } else {
//               cases.push({label: '', case: '1m: else, else'})
//             }
//           }
//           break

//         case '5m':
//           if (nyc.getHours() === 9 && nyc.getMinutes() === 30) {
//             label = `${getDayOfWeek(nyc)}`
//           } else if (nyc.getHours() === 16 && nyc.getMinutes() === 0) {
//             continue    // skip 4:00p, so as not to collide with next morning
//           } else if (pixels_per_candle > 5) {
//             if (nyc.getMinutes() === 0) {
//               label = `${getHoursMinutes(nyc)}`
//             }
//           } else if (pixels_per_candle > 3) {
//             if ([12, 14, 16].includes(nyc.getHours()) && nyc.getMinutes() === 0) {
//               label = `${getHoursMinutes(nyc)}`
//             }
//           } else if (pixels_per_candle > 2) {
//             if ([13].includes(nyc.getHours()) && nyc.getMinutes() === 0) {
//               label = `${getHoursMinutes(nyc)}`
//             }
//           }
//           break

//         case '30m':
//           if (pixels_per_candle < 6) {
//             if (nyc.getHours() === 9 && nyc.getMinutes() === 30 && nyc.getDate() % 2 === 1) {
//               label = `${getDate(nyc, false)}`
//             }
//           } else {
//             if (nyc.getHours() === 9 && nyc.getMinutes() === 30) {
//               label = `${getDate(nyc, false)}`
//             }
//             if (numCandles < 150 && nyc.getHours() === 13 && nyc.getMinutes() === 0 && numCandles > 10) {
//               label = '1:00p'
//             }
//           }
//           break

//         case '1h':
//           if (pixels_per_candle > 5) {
//             if (nyc.getHours() === 10 && nyc.getMinutes() === 0) {
//               label = `${getDateOrMonth(nyc, timestampArray, false)}`
//             }
//           } else {
//             if (is_first_of_month(timestampArray, i)) {
//               label = `${getDateOrMonth(nyc, timestampArray, false)}`
//             } else if (pixels_per_candle > 3 && nyc.getHours() === 10 && nyc.getDate() % 5 === 0 && nyc.getDate() !== 30) {
//               label = `${getDateOrMonth(nyc, timestampArray, false)}`
//             } else if (nyc.getHours() === 10 && nyc.getDate() % 10 === 0 && nyc.getDate() !== 30) {
//               label = `${getDateOrMonth(nyc, timestampArray, false)}`
//             }
//           }
//           break

//         case '2h':
//           if (pixels_per_candle > 20) {
//             if (nyc.getHours() === 10 && nyc.getMinutes() === 0) {
//               label = `${getDateOrMonth(nyc, timestampArray, false)}`
//             }
//           } else if (pixels_per_candle > 10) {
//             if (nyc.getHours() === 10 && nyc.getMinutes() === 0 && nyc.getDate() % 2 === 0) {
//               label = `${getDateOrMonth(nyc, timestampArray, false)}`
//             }
//           } else if (pixels_per_candle > 5) {
//             if (nyc.getHours() === 10 && nyc.getMinutes() === 0 && nyc.getDate() % 3 === 0) {
//               label = `${getDateOrMonth(nyc, timestampArray, false)}`
//             }
//           } else {
//             if (is_first_of_month(timestampArray, i)) {
//               label = `${getDateOrMonth(nyc, timestampArray, false)}`
//             }
//           }
//           break;

//         case '4h':
//           if (pixels_per_candle > 30) {
//             if (nyc.getHours() === 8 && nyc.getMinutes() === 0) {
//               label = `${getDateOrMonth(nyc, timestampArray, false)}`
//             }
//           } else if (pixels_per_candle > 20) {
//             if (nyc.getHours() === 8 && nyc.getMinutes() === 0 && nyc.getDate() % 2 === 0) {
//               label = `${getDateOrMonth(nyc, timestampArray, false)}`
//             }
//           } else if (pixels_per_candle > 10) {
//             if (nyc.getHours() === 8 && nyc.getMinutes() === 0 && nyc.getDate() % 3 === 0) {
//               label = `${getDateOrMonth(nyc, timestampArray, false)}`
//             }
//           } else if (pixels_per_candle > 5) {
//             if (nyc.getHours() === 8 && nyc.getMinutes() === 0 && nyc.getDate() % 5 === 0) {
//               label = `${getDateOrMonth(nyc, timestampArray, false)}`
//             }
//           } else {
//             if (is_first_of_month(timestampArray, i)) {
//               label = `${getDateOrMonth(nyc, timestampArray, false)}`
//             }
//           }
//           break;

//         case '1d':
//           if (is_first_of_month(timestampArray, i)) {
//             label = `${nyc.toLocaleString('default', { month: 'short' })}`
//           } else if (true) {
//             const mid_month_date = is_middle_of_month_and_get(timestampArray, i)
//             if (mid_month_date) {
//               label = `${mid_month_date}`
//             }
//           }
//           break

//           case '1w':
//             if (pixels_per_candle > 8) {
//               if (is_first_of_year(timestampArray, i)) {
//                 label = `${nyc.getFullYear()}`;
//               } else if (is_first_of_month(timestampArray, i)) {
//                 label = `${nyc.toLocaleString('default', { month: 'short' })}`;
//               }
//             } else if (pixels_per_candle > 4) {
//               if (is_first_of_year(timestampArray, i)) {
//                 label = `${nyc.getFullYear()}`;
//               } else if (is_quarter(timestampArray, i)) {
//                 label = `${nyc.toLocaleString('default', { month: 'short' })}`;
//               }
//             } else if (pixels_per_candle > 2) {
//               if (is_half_year(timestampArray, i)) {
//                 label = `${nyc.toLocaleString('default', { month: 'short' })}`;
//               } else if (is_every_other_year(timestampArray, i)) {
//                 label = `${nyc.getFullYear()}`;
//               }
//             } else {
//               if (is_first_of_year(timestampArray, i)) {
//                 label = `${nyc.getFullYear()}`;
//               }
//             }
//             break;

//         case '1mo':
//           if (numCandles < 150 && nyc.getMonth() === 0) {
//             label = `${nyc.getFullYear()}`
//           }
//           break
//       }
//     }

//     // NOTE: problem is lack of timestamp in the timestamp array, because numCandles is higher

//     if (label) {
//       result.push({ label, timestamp: date?.toISOString() || '' })
//     }
//   }

//   // console.log({
//   //   result,
//   //   interval,
//   //   pixels_per_candle,
//   //   cases,
//   //   numCandles,
//   //   timestampArrayLength: timestampArray.length,
//   // })

//   return result
// }

// const is_half_year = (timestampArray: number[], index: number): boolean => {
//   const current = new Date(timestampArray[index])
//   const prev = new Date(timestampArray[index - 1] || 0)
//   return current.getMonth() === 6 && prev.getMonth() !== 6
// }

// const is_every_other_year = (timestampArray: number[], index: number): boolean => {
//   const current = new Date(timestampArray[index])
//   const prev = new Date(timestampArray[index - 1] || 0)
//   return current.getFullYear() % 2 === 0 && prev.getFullYear() !== current.getFullYear()
// }

// const is_first_of_month = (timestampArray: number[], i: number) => {
// 	const date = new Date(timestampArray[i])
// 	const prevDate = new Date(timestampArray[i - 1])
// 	return date.getMonth() !== prevDate.getMonth()
// }

// // True if first of January, April, July, or October
// const is_quarter = (timestampArray: number[], i: number) => {
// 	const date = new Date(timestampArray[i])
// 	const prevDate = new Date(timestampArray[i - 1])
// 	return Math.floor(date.getMonth() / 3) !== Math.floor(prevDate.getMonth() / 3)
// }

// const is_first_of_year = (timestampArray: number[], i: number) => {
// 	const date = new Date(timestampArray[i])
// 	const prevDate = new Date(timestampArray[i - 1])
// 	return date.getFullYear() !== prevDate.getFullYear()
// }

// // Returns 15, 16, or 16, or false
// const is_middle_of_month_and_get = (timestampArray: number[], i: number) => {
// 	const date = new Date(timestampArray[i])
// 	if (date.getDay() === 15) return 15
// 	if (date.getDay() === 16) {
// 		const prevDate = new Date(timestampArray[i - 1])
// 		if (prevDate.getDay() !== 15) return 16
// 	}
// 	if (date.getDay() === 17) {
// 		const prevDate = new Date(timestampArray[i - 1])
// 		if (prevDate.getDay() !== 16) return 17
// 	}
// 	return false
// }


export const getDayOfWeek = (date: Date) => {
	const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
	return days[date.getDay()]
}

export const getNYCDate = (date: Date) => {
	const nycTimezone = 'America/New_York'
	return utcToZonedTime(date, nycTimezone)
}

// 11a, 3p (CHANGED TO 11:00a)
export const getHours = (date: Date) => {
	const milHours = date.getHours()
	if (milHours < 12) {
		return `${milHours}:00a`
		// return `${milHours}a`
	} else if (milHours === 12) {
		return `${milHours}:00p`
		// return `${milHours}p`
	} else {
		return `${milHours - 12}:00p`
		// return `${milHours - 12}p`
	}
}

// 11:30a, 3:35p
export const getHoursMinutes = (date: Date) => {
  const milHours = date.getHours()
  const minutes = date.getMinutes()
  if (minutes === 0) {
    return getHours(date)

    // return `${getHours(date)}:00`
  }
  if (milHours < 12) {
    return `${milHours}:${minutes}a`
  } else if (milHours === 12) {
    return `${milHours}:${minutes}p`
  } else {
    return `${milHours - 12}:${minutes}p`
  }
}

// "15 Jul", OR "15 Jul '23"
export const getDate = (date: Date, includeYear = true) => {
	const day = date.getDate()
	const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep",
											"Oct", "Nov", "Dec"]
	const month = monthNames[date.getMonth()]
	const year = date.getFullYear()
	if (includeYear) {
		return `${day} ${month} '${year.toString().slice(2)}`
	} else {
		return `${day} ${month}`
	}
}


// "13 Jan", "14 Jan '23"
export const getDateOrMonth = (date: Date, timestampArray: number[], includeYear = false) => {
  const firstTimestampDate = new Date(timestampArray[0])

  // Convert all timestamps to Date objects
  const dateArray: any = timestampArray.map(ts => new Date(ts))

  // Check if the date is the first occurrence of that month in timestampArray
  const isFirstDateOfMonthInArray = () => {
      const datesOfTheSameMonth = dateArray.filter((d: { getMonth: () => number; getFullYear: () => number }) => d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear())
      if (datesOfTheSameMonth.length === 0) return false
      const earliestDateOfTheMonth = new Date(Math.min(...datesOfTheSameMonth))
      return earliestDateOfTheMonth.toDateString() === date.toDateString()
  }

  const formatDate = (dateObj: Date) => {
      const day = dateObj.getDate()
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      const month = monthNames[dateObj.getMonth()]

      if (includeYear) {
          const year = dateObj.getFullYear().toString().slice(-2)
          return `${day} ${month} '${year}`
      } else {
          return `${day} ${month}`
      }
  }

  if (date.toDateString() === firstTimestampDate.toDateString()) {
      return formatDate(date)
  } else if (isFirstDateOfMonthInArray()) {
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      const month = monthNames[date.getMonth()]

      if (includeYear) {
          const year = date.getFullYear().toString().slice(-2)
          return `${month} '${year}`
      } else {
          return month
      }
  } else {
      return date.getDate().toString()
  }
}











// For datasets we get directly from alpaca
const loadAlpacaData = async (fixedTimeframe: string, interval: string) => {
  console.log(`loadAlpacaData firing`)

  let _period = '7D'
  let _timeframe = '1D'

  if (fixedTimeframe === '1D') {
    _period = '1D'
    _timeframe = '1Min'
  } else if (fixedTimeframe === '1W') {
    _period = '7D'
    _timeframe = '1D'
  } else if (fixedTimeframe === '1M') {
    _period = '1M'
    _timeframe = '1D'
  } else if (fixedTimeframe === '6M') {
    _period = '6M'
    _timeframe = '1D'
  } else if (fixedTimeframe === '1Y') {
    _period = '1A'
    _timeframe = '1D'
  } else if (fixedTimeframe === 'YTD') {
    _period = '1A'
    _timeframe = '1D'
  } else if (fixedTimeframe === 'All') {
    _period = '10A'
    _timeframe = '1D'
  }

  const token = await currentUser()?.getIdToken()
  const res = await axios.get(`${config.api_root_url}getAccountPortfolioHistory`, {
    params: {
      period: _period,
      timeframe: _timeframe
    },
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  // Unpack alpaca response
  const equity_arr: any[] = []
  const profit_loss_arr: any[] = []
  const pl_pct_arr: any[] = []
  for (let i = 0; i < res.data.timestamp.length; i++) {
    const timestamp = res.data.timestamp[i] * 1000
    const equity = res.data.equity[i]
    const profit_loss = res.data.profit_loss[i]
    const pl_pct = res.data.profit_loss_pct[i]

    console.log(`date: ${new Date(timestamp)}, equity: ${equity}, base_value: ${res.data.base_value}, profit_loss: ${profit_loss}`)

    equity_arr.push([timestamp, equity])
    profit_loss_arr.push([timestamp, profit_loss])
    pl_pct_arr.push([timestamp, pl_pct])
  }

  /* At this point we have data directly consumable by our chart, but it isn't mapped to the appropriate times */

  // Get empty bars and map what we've got onto them, for each dataset
  const first_timestamp = equity_arr[0][0]
  const last_timestamp = equity_arr[equity_arr.length - 1][0]
  const emptyBars = get_empty_bars({
    timeframe: interval,
    limit: 1000,
    start: new Date(first_timestamp),
    end: new Date(last_timestamp)
  })
  let eq = 0
  let pl = 0
  let pl_pct = 0
  let equity_arr_processed: any[] = []
  let profit_loss_arr_processed: any[] = []
  let pl_pct_arr_processed: any[] = []

  // For >= one day timeframe, no need for further processing
  if (['1d', '1w'].includes(interval)) {
    return {
      equity: equity_arr,
      profit_loss: profit_loss_arr,
      pl_pct: pl_pct_arr,
    }
  }

  // Find most recent value for each empty bar time
  for (let i = 0; i < emptyBars.length; i++) {
    const timestamp = new Date(emptyBars[i].t).getTime()
    const next_timestamp = emptyBars[i + 1] ? new Date(emptyBars[i + 1].t).getTime() : 0

    // Filter the datapoints that come after timestamp but before next_timestamp
    equity_arr.filter((log, i) => {
      const ts = log[0]
      const ret = ts >= timestamp && ts < next_timestamp
      if (ret) {
        eq = log[1]
        pl = profit_loss_arr[i][1]
        pl_pct = pl_pct_arr[i][1]
      }
      return ret
    })

    equity_arr_processed.push([timestamp, eq])
    profit_loss_arr_processed.push([timestamp, pl])
    pl_pct_arr_processed.push([timestamp, pl_pct])
  }

  return {
    equity: equity_arr_processed,
    profit_loss: profit_loss_arr_processed,
    pl_pct: pl_pct_arr_processed,
  }
}

/*
In order to plot our own charts, we create fake, empty candles
*/
export const get_empty_bars = (params: any) => {
  let { timeframe, limit, start, end } = params

  const intervalMs = INTERVALS[timeframe]
  if (!intervalMs) {
    throw new Error(`Invalid timeframe: ${timeframe}`)
  }

  const bars: any[] = []
  const endTime = new Date(end).getTime()
  let currentTime

  if (start) {
    currentTime = new Date(start).getTime()

    // Adjust start time to reasonable snap-to
    const snapTo = get_recent_even_multiple(currentTime, timeframe)
    currentTime = snapTo.getTime()

    while (currentTime <= endTime && bars.length < limit) {
      if (intervalMs < 86400000) { // Check market open only if interval is less than a day
        if (isMarketOpen(currentTime)) {
          bars.push({ t: currentTime })
        }
      } else {
        bars.push({ t: currentTime }) // Assume open for intervals of a day or more
      }
      currentTime += intervalMs
    }
  } else {
    currentTime = endTime

    while (bars.length < limit) {
      if (intervalMs < 86400000) { // Check market open only if interval is less than a day
        if (isMarketOpen(currentTime)) {
          bars.push({ t: currentTime })
        }
      } else {
        bars.push({ t: currentTime }) // Assume open for intervals of a day or more
      }
      currentTime -= intervalMs
    }
  }

  // Convert timestamp to ISO string format outside of the loop if necessary
  bars.forEach(bar => bar.t = new Date(bar.t).toISOString())

  return bars
}

// marc-todo: next up, load_more_bars here, using getBackMarketHoursSIPBars




export const get_recent_even_multiple = (timestamp: number, interval: string) => {
  let date = new Date(timestamp)

  // Determine the interval type and value
  const intervalType = interval.slice(-1) // 'm' for minutes, 'h' for hours, 'd' for days, 'w' for weeks
  const intervalValue = parseInt(interval.slice(0, -1), 10) // numeric value of interval

  if (intervalType === 'm') {
    // For minute intervals
    const minutes = date.getMinutes()
    const nearestMultiple = minutes - (minutes % intervalValue)
    date.setMinutes(nearestMultiple)
  } else if (intervalType === 'h') {
    // For hour intervals
    const hours = date.getHours()
    const nearestMultiple = hours - (hours % intervalValue)
    date.setHours(nearestMultiple)
    date.setMinutes(0) // Reset minutes for hour intervals
  } else if (intervalType === 'd') {
    // For day intervals
    date.setHours(0, 0, 0, 0) // Reset to the start of the day
  } else if (intervalType === 'w') {
    // For week intervals
    const dayOfWeek = date.getDay()
    date.setDate(date.getDate() - dayOfWeek)
    date.setHours(0, 0, 0, 0) // Reset to the start of the week
  }

  // Reset seconds and milliseconds to zero
  date.setSeconds(0)
  date.setMilliseconds(0)

  return date
}


// How far to step for a given fixed timeframe
export const STEP_BACK_INTERALS = {
	'1D': 1000 * 60 * 60 * 24,
	'1W': 1000 * 60 * 60 * 24 * 7,
	'1M': 1000 * 60 * 60 * 24 * 30,
	'3M': 1000 * 60 * 60 * 24 * 30 * 3,
	'6M': 1000 * 60 * 60 * 24 * 30 * 6,
	'1Y': 1000 * 60 * 60 * 24 * 365,
	'5Y': 1000 * 60 * 60 * 24 * 365 * 5,
	'All': 1000 * 60 * 60 * 24 * 365 * 100,
}

// TB, not alpaca
export const TIMEFRAMES_MS: any = {
  '1m': 1 * 60 * 1000,
  '5m': 5 * 60 * 1000,
  '30m': 30 * 60 * 1000,
  '1h': 60 * 60 * 1000,
  '2h': 2 * 60 * 60 * 1000,
  '4h': 4 * 60 * 60 * 1000,
  '1d': 24 * 60 * 60 * 1000,
  '1w': 7 * 24 * 60 * 60 * 1000,
  '1M': 30 * 24 * 60 * 60 * 1000
}

/*
STREAMING HELPERS

Use these to turn a stream of latest quotes into an array of bars
*/
let LIVESTREAM_DATA = {
  bars: <any> [],
  timeframe: '',
  timeframe_ms: 0,
  symbol: '',
  start_date_iso: '',
  last_bar_timestamp: 0,        // close of historical dataset
  last_bar_close: 0,
  latest_price: 0,
  latest_price_time_iso : '',
  most_recent_bar_close_ts: 0,    // close of recent streaming bar
  current_bar: {o: 0, h: 0, l: 0, c: 0, t: ''}
}
export const initialize_streaming_data = (symbol: string, timeframe: string, start_date_iso: string, last_bar_close: number) => {
  const timeframe_ms = TIMEFRAMES_MS[timeframe]
  const last_bar_timestamp = new Date(start_date_iso).getTime()

  LIVESTREAM_DATA = {
    bars: [],
    timeframe,
    timeframe_ms,
    symbol,
    start_date_iso,
    last_bar_timestamp,
    last_bar_close,
    latest_price: 0,
    latest_price_time_iso: '',
    most_recent_bar_close_ts: last_bar_timestamp,
    current_bar: {o: 0, h: 0, l: 0, c: 0, t: ''}
  }
}

// Assumes we are correctly initialized
export const add_streaming_datapoint = (value: number, time_iso: string) => {
  const LSD = LIVESTREAM_DATA
  LSD.latest_price = value
  LSD.latest_price_time_iso = time_iso

  // How many bars ago was the last bar we have?
  const ts = new Date(time_iso).getTime()
  const time_since_last_bar = ts - LSD.last_bar_timestamp
  let how_many_bars_ago = time_since_last_bar / LSD.timeframe_ms

  // If this was multiple bars ago, create multiple "empty" bars to fill in the gap
  while (how_many_bars_ago > 2) {
    const price = LSD.latest_price || LSD.last_bar_close
    const timestamp = LSD.last_bar_timestamp + LSD.timeframe_ms
    const iso = new Date(timestamp).toISOString()
    const bar = {o: price, h: price, l: price, c: price, t: iso}
    LSD.bars.push(bar)

    how_many_bars_ago -= 1
  }

  // If we have a bar in progress that has finished, finish it, begin new bar
  if (how_many_bars_ago > 1) {
    LSD.current_bar.c = value
    if (!LSD.current_bar.o) {
      LSD.current_bar.t = new Date(LSD.most_recent_bar_close_ts + LSD.timeframe_ms).toISOString()
      LSD.current_bar.o = value
      LSD.current_bar.h = value
      LSD.current_bar.l = value
    }
    LSD.bars.push(LSD.current_bar)
    LSD.most_recent_bar_close_ts = new Date(LSD.current_bar.t).getTime()

    // Begin new bar
    const new_bar_timestamp = LSD.most_recent_bar_close_ts + LSD.timeframe_ms
    const new_bar_t = new Date(new_bar_timestamp).toISOString()
    LSD.current_bar = {
      o: value,
      h: value,
      l: value,
      c: value,
      t: new_bar_t
    }
  }

  // If we have a bar in progress which is not yet finished, add to it
  if (how_many_bars_ago <= 1) {

    // Do we have a latest_bar initialized? If not, initialize it
    if (LSD.current_bar.o === 0) {
      const new_bar_timestamp = LSD.most_recent_bar_close_ts + LSD.timeframe_ms
      const new_bar_t = new Date(new_bar_timestamp).toISOString()
      LSD.current_bar = {
        o: value,
        h: value,
        l: value,
        c: value,
        t: new_bar_t
      }
    }

    LSD.current_bar.h = Math.max(LSD.current_bar.h, value)
    LSD.current_bar.l = Math.min(LSD.current_bar.l, value)
    LSD.current_bar.c = value
  }
}

export const get_streaming_bars = () => {
  const ret = JSON.parse(JSON.stringify(LIVESTREAM_DATA.bars))

  // Dump whatever we've got for the current bar
  if (LIVESTREAM_DATA.current_bar.o !== 0) {
    ret.push(LIVESTREAM_DATA.current_bar)
  }

  return ret
}

export const splice_on_streaming_bars = (dataset: any) => {
  let ret = dataset
  const new_bars = get_streaming_bars()
  const last_ts = new Date(dataset[dataset.length - 1].t).getTime()
  for (let i = 0; i < new_bars.length; i++) {
    const bar = new_bars[i]
    const ts = new Date(bar.t).getTime()
    if (ts > last_ts) {
      ret.push(bar)
    }
  }
  return ret
}

export const initialize_streaming_data_if_necessary = (symbol: string, timeframe: string, start_date_iso: string, last_bar_close: number) => {
  const lst_dne = LIVESTREAM_DATA.start_date_iso === ''
  const different_symbol = LIVESTREAM_DATA.symbol !== symbol
  const different_timeframe = LIVESTREAM_DATA.timeframe !== timeframe
  const new_day = new Date(start_date_iso).getDate() !== new Date(LIVESTREAM_DATA.start_date_iso).getDate()

  if (lst_dne || different_symbol || different_timeframe || new_day) {
    initialize_streaming_data(symbol, timeframe, start_date_iso, last_bar_close)
  }
}

/*
TODO:
- initialize:
  - if not initialized
  - if new symbol selected
  -
*/

export const formatNumber = (num: any) => {
  if (num === undefined || num === null) return ''
  num = Number(num)
  let formatted_price = parseFloat(num)
    .toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  if (formatted_price.length > 6) {
    formatted_price = parseFloat(num)
      .toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  }
  return formatted_price
}
