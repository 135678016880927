

export const getYBounds = (visibleCandles: any) => {
  if (!visibleCandles || visibleCandles.length === 0) return { start: 10, range: 90 };

  // marc-look
  let maxPrice = Math.max(...visibleCandles.map((candle: any) => candle.h || candle.c));
  let minPrice = Math.min(...visibleCandles.map((candle: any) => candle.l || candle.c));
  let margin = (maxPrice - minPrice) * 0.05;
  minPrice -= margin;
  maxPrice += margin;
  let range = maxPrice - minPrice;

  if (range === 0) {
    minPrice = -10
    range = 120
  }

  return { start: minPrice, range};
};