

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Modal } from '../reusable'
import { updateLiveData, uuid } from '../../logic/u'
import { DropdownSelect } from '../reusable/DropdownSelect'
import { consoleOutputAtom, functionExecutingAtom, functionsWithUnsavedChangesAtom, loggedInUserAtom, openModalAtom, userLiveDocAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { FunctionObj } from '../../types/user_types'
import { db } from '../../firebase'
import { run_function_once } from '../../logic/run_function_once'

interface TestFunctionModalProps {
  functionObj: FunctionObj
}

export const TestFunctionModal = (props: TestFunctionModalProps) => {
  const {functionObj} = props

  // General helpers
  const [ user ] = useAtom(loggedInUserAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [ consoleOutput, setConsoleOutput ] = useAtom(consoleOutputAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [ , setFunctionExecuting ] = useAtom(functionExecutingAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [functionsWithUnsavedChanges, setFunctionsWithUnsavedChanges] = useAtom(functionsWithUnsavedChangesAtom)


  if (!user || !functionObj) return null

  return (
    <Modal
      title={'Test'}
      style={{width: 440}}
      contents={[
        <div className='column width-full font-size-13 orange-text'>
          This operation will run your function once in test mode. This means:
          <br />
          <br />
          • orders will not be placed or canceled
          <br />
          • positions will not be closed
          <br />
          • transfers will not be submitted
          <br />
          • state variables will only be modified locally
          <br />
          • no permanent log will be created
          <br />
          <br />
          Do you want to proceed?
          {functionsWithUnsavedChanges[functionObj.id]?.unsavedEditorValue ? <>
            <br />
            <br />
            <div style={{fontSize: 11, color: 'gray', fontStyle: 'italic'}}>
              Note: Unsaved changes will not be included.
            </div>
          </> : null}
        </div>
      ]}
      yesButtonText={'Test'}
      isDanger
      isButtonLoading={buttonIsLoading}
      twoStepConfirmation={false}
      onYes={async () => {
        try {
          run_function_once({
            user,
            code: functionObj.codeBlock,
            liveData: uld?.liveData,
            consoleOutput,
            setConsoleOutput,
            setFunctionExecuting,
            function_id: functionObj.id,
            function_name: functionObj.name,
            simulated_mode: true,
            cancelToken: null,
          })
          setOpenModal(null)
          db.collection('users')
            .doc(user?.uid)
            .update({codeConsoleVisible: true})
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

