import '../SignUp.scss'
import React, { useState, useEffect } from 'react'
import { db, currentUser } from '../../../firebase'
import {loggedInUserAtom } from '../../../types/global_types'
import {
  SignUpStage,
} from '../../../types/user_types'
import { useAtom } from 'jotai'
import axios from 'axios'
import { getConfig } from '../../../config'
const config: any = getConfig()

export const WaitListConfirmatioStep = () => {
  const [user] = useAtom(loggedInUserAtom)
  const [loading, setLoading] = useState<boolean>(true)
  const basicInfoStage: SignUpStage = 'BasicInfo'

  useEffect(() => {
    const checkWaitlist = async () => {
      try {
        const token = await currentUser()?.getIdToken()
        const url = `${config.api_root_url}countUsersWithAlpacaIds`
        const res = await axios.get(url, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })

        // Grant onboarding access
        if (res.data.giveOnboardingAccess) {
          
          // Send sign up email
          if (!user?.hasReceivedSignUpEmail) {
            const token = await currentUser()?.getIdToken()
            const data = {
              to: user?.email
            }
            
            await axios.post(`${config.api_root_url}sendSignUpEmail`, data, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            })
            
            db.collection('users').doc(user?.uid).update({
              hasReceivedSignUpEmail: true,
            });
          }

          // Go to next stage
          db.collection('users').doc(user?.uid).update({
            signUpStage: basicInfoStage,
            isWaitlisted: false
          });

          // Put on waitlist
        } else {

          // Send waitlist email
          if (!user?.hasReceivedWaitlistEmail) {
            const token = await currentUser()?.getIdToken()
            const data = {
              to: user?.email
            }
            await axios.post(`${config.api_root_url}sendWaitlistEmail`, data, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            })
            db.collection('users').doc(user?.uid).update({
              hasReceivedWaitlistEmail: true
            });
          }

          // Put on waitlist
          db.collection('users').doc(user?.uid).update({
            isWaitlisted: true
          });
        }

        // Show waitlist
        setLoading(false)
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    }

    setTimeout(() => {
      if (user?.isWaitlisted) {
        setLoading(false)
      } else {
        checkWaitlist()
      }
    }, 2000);
  }, [])

  if (!user) return null

  if (loading) {
    return (
      <div className='signup-container'>
        <div className='signup-form-container'>
          <div className='signup-context-title center'>
            Checking waitlist...
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className='signup-container'>
      <div className='signup-form-container'>
        <div className='signup-context-title center'>
          You're on the waitlist.
        </div>
        <div className='signup-context-subtitle center'>
          You'll receive an email when you've been granted access to complete the signup process.
        </div>
      </div>
    </div>
  )
}