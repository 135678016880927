export const get_y_label_prices = (lowerBound: number, upperBound: number) => {
  const difference = upperBound - lowerBound
  const lowerLimit = lowerBound + 0.03 * difference
  const upperLimit = upperBound - 0.03 * difference

  let approximateLabelCount = (upperLimit - lowerLimit) / 6
  let magnitude = Math.pow(10, Math.floor(Math.log10(approximateLabelCount)))
  let step
  if (approximateLabelCount < 1.5 * magnitude) {
    step = magnitude
  } else if (approximateLabelCount < 3 * magnitude) {
    step = 2 * magnitude
  } else if (approximateLabelCount < 7.5 * magnitude) {
    step = 5 * magnitude
  } else {
    step = 10 * magnitude
  }

  let startingValue = Math.ceil(lowerLimit / step) * step

  let labels: string[] = []
  for (let price = startingValue; price <= upperLimit; price += step) {
    labels.push(price.toFixed(2))
  }
  return labels
}