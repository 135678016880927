import React from 'react'
import { GridOverlay } from '@mui/x-data-grid-pro'
import { BACKGROUND } from '../../logic/u';

interface CustomGridOverlayProps {
  text?: string
}
export const CustomGridOverlay = (props: CustomGridOverlayProps) => {
  const {text} = props
  const displayStr = text ? text : 'No data.'
  return (
    <GridOverlay sx={{ backgroundColor: BACKGROUND }}>
      <div>{displayStr}</div>
    </GridOverlay>
  )
}