import '../SignUp.scss'
import React from 'react'
import { loggedInUserAtom } from '../../../types/global_types'
import { useAtom } from 'jotai'
import { WaitListConfirmatioStep } from './WaitListConfirmationStep'
import { BasicInfoStep } from './BasicInfoStep'
import { EmploymentStep } from './EmploymentStep'
import { FinancialProfileStep } from './FinancialProfileStep'
import { DisclosuresAffiliatedExchangeOrFinraStep } from './DisclosuresAffiliatedExchangeOrFinraStep'
import { DisclosuresControlPersonStep } from './DisclosuresControlPersonStep'
import { DisclosuresPoliticallyExposedStep } from './DisclosuresPoliticallyExposedStep'
import { DisclosuresImmediateFamilyExposedStep } from './DisclosuresImmediateFamilyExposedStep'
import { TrustedContactStep } from './TrustedContactStep'
import { TaxInfoStep } from './TaxInfoStep'
import { AlpacaReviewStep } from './AlpacaReviewStep'
import { BankInfoStep } from './BankInfoStep'

export const AlpacaSignUp = () => {
  const [ loggedInUser ] = useAtom(loggedInUserAtom)

  if (!loggedInUser) return null

  const signUpStage = loggedInUser?.signUpStage
  let stageContent: any = null
  switch (signUpStage) {
    case 'WaitlistConfirmation':
      stageContent = <WaitListConfirmatioStep />
      break
    case 'BasicInfo':
      stageContent = <BasicInfoStep />
      break
    case 'Employment':
      stageContent = <EmploymentStep />
      break
    case 'FinancialProfile':
      stageContent = <FinancialProfileStep />
      break
    case 'DisclosuresAffiliatedExchangeOrFinra':
      stageContent = <DisclosuresAffiliatedExchangeOrFinraStep />
      break
    case 'DisclosuresControlPerson':
      stageContent = <DisclosuresControlPersonStep />
      break
    case 'DisclosuresPoliticallyExposed':
      stageContent = <DisclosuresPoliticallyExposedStep />
      break
    case 'DisclosuresImmediateFamilyExposed':
      stageContent = <DisclosuresImmediateFamilyExposedStep />
      break
    case 'TrustedContact':
      stageContent = <TrustedContactStep />
      break
    case 'TaxInfo':
      stageContent = <TaxInfoStep />
      break
    case 'AlpacaReview':
      stageContent = <AlpacaReviewStep />
      break
    case 'BankInfo':
      stageContent = <BankInfoStep />
      break
  }

  return (
    <div className='logged-out-screen'>
      {stageContent}
    </div>
  )
}


