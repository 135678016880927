

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Modal } from '../reusable'
import { formatNumberForTable, updateLiveData, uuid, valueForTable } from '../../logic/u'
import { loggedInUserAtom, openModalAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { FunctionObj } from '../../types/user_types'
import { currentUser, db } from '../../firebase'
import { getConfig } from '../../config'
import axios from 'axios'
const config = getConfig() as any


interface CancelPendingTransferModalProps {
  transferObj: any
}

export const CancelPendingTransferModal = (props: CancelPendingTransferModalProps) => {    
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const {transferObj: transfer} = props

  if (!user) return null

  return (
    <Modal
      title={'Cancel transfer'}
      contents={[
        <div className='column width-full font-size-13 orange-text'>
          <div className='row'>
            Are you sure you want to cancel the {transfer.direction.toLowerCase()} transfer of {valueForTable('cash', transfer.amount)}?
          </div>
        </div>
      ]}
      yesButtonText={'Cancel transfer'}
      isDanger
      isButtonLoading={buttonIsLoading}
      twoStepConfirmation={true}
      onYes={ async () => {
        try {
          setButtonIsLoading(true)
          const token = await currentUser()?.getIdToken()
          const deleteTransferUrl = `${config.api_root_url}deleteTransfer?transferId=${transfer.id}`
          await axios.delete(deleteTransferUrl, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

